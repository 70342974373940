// EventModal.js
import React, { useState } from "react";
import { Container, Form, Modal } from "react-bootstrap";

import moment from "moment";
import ColorLabels from "../../components/Calendar/ColorLabels";
import EventCheckboxes from "../../components/Calendar/EventCheckboxes";
import RecurrenceOptions from "../../components/Calendar/RecurrenceOptions";
import EventModeSelector from "../../components/Calendar/EventModeSelector";

// Split form fields into a separate component
const EventForm = ({ formData, setFormData, isRecurring = false }) => {
  const minDate = moment().format("YYYY-MM-DD");

  return (
    <Form>
      <Form.Group className="mb-3">
        <Form.Control
          type="text"
          placeholder="Event Name"
          value={formData.title}
          onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          required
        />
      </Form.Group>

      <div className="nmdw">
        <Form.Label>Start:</Form.Label>
        <div className="nmdw1">
          <Form.Control
            type="date"
            value={formData.eventDay}
            onChange={(e) =>
              setFormData({ ...formData, eventDay: e.target.value })
            }
            min={minDate}
            required
            className="nmdw2"
          />
          <Form.Control
            type="time"
            value={formData.startTime}
            onChange={(e) =>
              setFormData({ ...formData, startTime: e.target.value })
            }
            required
            className="nmdw2"
          />
        </div>
      </div>

      <Form.Group className="mb-3">
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Event Description"
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
          required
        />
      </Form.Group>

      <EventModeSelector
        mode={formData.eventMode}
        onChange={(mode) => setFormData({ ...formData, eventMode: mode })}
      />

      {formData.eventMode !== "InPerson" && (
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            placeholder="Event Link"
            value={formData.eventLink}
            onChange={(e) =>
              setFormData({ ...formData, eventLink: e.target.value })
            }
          />
        </Form.Group>
      )}

      {formData.eventMode !== "OnlineOnly" && (
        <Form.Group className="mb-3">
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Event Location"
            value={formData.eventLocation}
            onChange={(e) =>
              setFormData({ ...formData, eventLocation: e.target.value })
            }
            required
          />
        </Form.Group>
      )}

      <EventCheckboxes formData={formData} setFormData={setFormData} />

      <ColorLabels
        selectedLabel={formData.label}
        onSelectLabel={(label) => setFormData({ ...formData, label })}
      />

      {isRecurring && (
        <RecurrenceOptions formData={formData} setFormData={setFormData} />
      )}
    </Form>
  );
};

export default EventForm;
