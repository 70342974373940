import React, { useState, useEffect, useCallback, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "../../../components/Alert/Alert";
import { useAppContext } from "../../../context/appContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import "./giving.css";
import { isNonNegativeInteger } from "../../../utils";
import { currencyFormatter } from "../../../utils/helpers";
import { toast } from "react-toastify";
import CustomToastContent from "../../../components/Toast/CustomToastContent";
const GivingHome = () => {
  const wrapperRef = useRef(null);
  const navigate = useNavigate();
  const [giverName, setGiverName] = useState("");

  const [amount, setAmount] = useState();
  const [category, setCategory] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [note, setNote] = useState();
  const [checkNumber, setCheckNumber] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const [cardDigit, setCardDigit] = useState();
  const [batch, setBatch] = useState({});
  const [fundValue, setFundValue] = useState([]);
  const [methodValue, setMethodValue] = useState([]);
  const [closed, setClosed] = useState(false);
  const [show, setShow] = useState(false);
  const [batchText, setBatchText] = useState();
  const [totalEntries, setTotalEntries] = useState();
  const [givingDate, setGivingDate] = useState();
  const [totalAmount, setTotalAmount] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showmore, setShowMore] = useState(false);
  const {
    addGiving,
    displayAlert,
    logoutUser,
    showAlert,
    completemembers,
    getCompleteMembers,
    funds,
    getAllFund,
    user,
    addedData,
  } = useAppContext();

  useEffect(() => {
    getBatch(); // Call the function inside the useEffect
  }, []); // Include getBatch as a dependency

  const showplenty = () => setShowMore(!showmore);

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    getFund();
    getMethod();
  }, []);

  useEffect(() => {
    totalAmountbyBatch();
  }, [batch]);

  const totalAmountbyBatch = useCallback(async () => {
    if (!batch?.batchId) return;

    try {
      const { data } = await authFetch.get(
        `/giving/give/total/${batch.batchId}`
      );
      if (data && data.length > 0) {
        const [datax] = data;
        setTotalAmount(datax);
      } else {
        setTotalAmount({ totalAmount: 0 }); // Set default value if no data
      }
    } catch (error) {
      console.error("Error fetching total amount:", error);
      setTotalAmount({ totalAmount: 0 }); // Set default value on error
    }
  }, [batch?.batchId, authFetch]);

  const countGivingbyBatch = useCallback(async () => {
    if (!batch?.batchId) return;

    const updatedBatch = {
      batchId: batch.batchId,
    };
    try {
      const { data } = await authFetch.post(`/giving/give/count`, updatedBatch);
      setTotalEntries(data);
    } catch (error) {
      console.error("Error counting batch:", error);
    }
  }, [batch?.batchId, authFetch]);

  useEffect(() => {
    getCompleteMembers();
  }, []);

  const [completeDetails, setCompleteDetails] = useState([]);
  useEffect(() => {
    if (completemembers && completemembers.length > 0) {
      setCompleteDetails(completemembers);
    }
  }, [completemembers]);

  useEffect(() => {
    getAllFund();
  }, []);

  useEffect(() => {
    if (batch?.batchId) {
      countGivingbyBatch();
    }
  }, [batch?.batchId, countGivingbyBatch]);

  const getBatch = useCallback(async () => {
    try {
      const { data } = await authFetch.get(`/giving/batch`);

      if (data === undefined || data.length === 0) {
        const addBatch = {
          batchId: 1,
          churchName: user.churchName,
        };
        await authFetch.post(`/giving/add`, addBatch);
      } else {
        const [datax] = data;

        setBatch({ batchId: datax.batchId });
      }
    } catch (error) {
      // Handle any errors here
    }
  }, [authFetch, setBatch, user.churchName]);

  const getFund = useCallback(async () => {
    try {
      const { data } = await authFetch.get(`/giving/give/funds`);
      if (data === undefined || data.length === 0) {
        return;
      } else {
        setFundValue(data);
      }
    } catch (error) {
      // Handle any errors here
    }
  }, [authFetch, setFundValue]);

  useEffect(() => {
    getFund(); // Call the function inside the useEffect
    // Rest of your useEffect code

    return () => {
      // Cleanup or remove any event listeners if needed
    };
  }, []); // Include getFund as a dependency

  const getMethod = useCallback(async () => {
    try {
      const { data } = await authFetch.get(`/giving/give/getMethod`);
      if (data === undefined || data.length === 0) {
        return;
      } else {
        setMethodValue(data);
      }
    } catch (error) {
      // Handle any errors here
    }
  }, [authFetch, setMethodValue]);

  useEffect(() => {
    getMethod(); // Call the function inside the useEffect
    // Rest of your useEffect code

    return () => {
      // Cleanup or remove any event listeners if needed
    };
  }, []); // Include getMethod as a dependency

  // const closeBatch = async () => {
  //   const updateBatch = {
  //     // batchId: batch.batchId + 1,
  //     churchName: user.churchName,
  //   };

  //   await authFetch.post(`/giving/add`, updateBatch);
  // };

  const closeBatch = async () => {
    try {
      // Close current batch and create new one

      const response = await authFetch.post("/giving/add", {
        currentBatchId: batch.batchId,
        batchName: batchText, // if you're setting a name for the closed batch
        churchName: user.churchName,
      });

      if (response.data.success) {
        // Update local state with new batch
        setBatch(response.data.newBatch);
        toast.success("Batch closed successfully");
      }
    } catch (error) {
      toast.error("Error closing batch");
    }
  };

  const cancelThisBatchForm = async (e) => {
    e.preventDefault();
    setClosed(true);
    if (!batchText) {
      displayAlert();
      return;
    }

    const batchNameUpdate = {
      batchName: batchText,
      batchStatus: true,
      batchId: batch.batchId,
    };
    // console.log(batch);

    await authFetch.post(`/giving/give/update`, batchNameUpdate);
    getBatch();
    setBatchText("");
    closeBatch();
    handleClose();
  };

  const onChange = (event) => {
    setGiverName(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!giverName || !amount || !paymentMethod || !batch || !givingDate) {
      displayAlert();
      return;
    }

    if (!isNonNegativeInteger(amount)) {
      toast(
        <CustomToastContent
          title={"Error"}
          message={"Please enter valid amount"}
        />
      );
      return;
    }

    const [fname, lname] = giverName.split(" ");
    const currentGiving = {
      giverName: giverName,
      amount: amount,
      category: category,
      paymentMethod: paymentMethod,
      note: note,
      checkNumber: checkNumber,
      accountNumber: accountNumber,
      cardDigit: cardDigit,
      batchId: batch.batchId,
      fname: fname,
      lname: lname,
      churchName: user.churchName,
      givingDate: givingDate,
    };
    addGiving({ currentGiving });
    if (addedData.status === 201) {
      countGivingbyBatch();
      totalAmountbyBatch(batch.batchId);
      getBatch();
      getAllFund();
      getFund();
      getMethod();
    }

    navigate("/auth/givinghome");
    setPaymentMethod("");
    setCategory("");
    setGiverName("");
    setAmount("");
    setNote("");
    setCheckNumber("");
    setAccountNumber("");
    setCardDigit("");
    setGivingDate("");
  };

  const onSearch = (searchTerm) => {
    setGiverName(searchTerm);
  };

  const [showSuggestions, setShowSuggestions] = React.useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <>
      <Container fluid>
        <Row className="courseheader">
          <p
            className="sahs"
            style={{ display: "flex", justifyContent: "left" }}
          >
            Add Giving
          </p>
        </Row>

        {(methodValue?.length === 0 || fundValue?.length === 0) && (
          <div
            className="notification-prompt flex justify-center flex-row"
            style={{ marginTop: "6rem" }}
          >
            Set up your
            <Link to="/auth/addfunds" className="banner-link we2">
              Giving Categories
            </Link>
            and
            <Link to="/auth/methods" className="banner-link we2">
              Giving Methods
            </Link>
            to start managing your payments with ease.
          </div>
        )}

        {methodValue?.length > 0 && fundValue?.length > 0 && (
          <>
            <Row
              style={{
                marginLeft: "2rem",
                marginRight: "3rem",
                backgroundColor: "var(--primary-color)",
                height: "12rem",
                color: "white",
              }}
            >
              <div className="sewefw">
                <div className="wrf343">
                  <p className="batchnum2cs">{batch.batchId}</p>
                  <p className="batchnum">Current Batch</p>
                </div>

                <div className="wrf343">
                  <p className="batchnum2cs">{totalEntries}</p>
                  <p className="batchnum">Number of Entries</p>
                </div>

                <div className="wrf343">
                  <p className="batchnum2cs">
                    {totalAmount?.totalAmount
                      ? currencyFormatter({
                          amount: totalAmount?.totalAmount,
                          currency: "USD",
                        })
                      : currencyFormatter({ amount: 0, currency: "USD" })}
                  </p>
                  <p className="batchnum">Accumulated Amount</p>
                </div>
              </div>
              <Row style={{ justifyContent: "center" }}>
                <div className="wrf343x">
                  <Button
                    variant="danger"
                    onClick={handleShow}
                    className="erer"
                  >
                    Close Batch
                  </Button>
                </div>
              </Row>
            </Row>
            <Row style={{ paddingBottom: "2rem" }}>
              {showAlert && <Alert />}
              <Col className="leftgivediv">
                <form className="givingform">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Giving Date <span className="myast">*</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      value={givingDate}
                      onChange={(e) => setGivingDate(e.target.value)}
                      name="givingDate"
                      style={{ fontSize: "1em" }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4" ref={wrapperRef}>
                    <Form.Label className="font-medium text-lg mb-2 text-[#243060]">
                      Giver Name <span className="text-[#FE7064]">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter giver's name"
                      value={giverName}
                      onChange={(e) => {
                        onChange(e);
                        setShowSuggestions(true);
                      }}
                      onFocus={() => setShowSuggestions(true)}
                      name="giverName"
                      className="p-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-[#FED970] focus:border-transparent text-base"
                    />

                    {showSuggestions && giverName && (
                      <div className="mt-1 border rounded-lg shadow-sm bg-white">
                        {completeDetails
                          .filter((item) => {
                            const searchTerm = giverName.toLowerCase();
                            const fullname = (
                              item.mfirstName + item.mlastName
                            ).toLowerCase();
                            return (
                              searchTerm &&
                              fullname.includes(searchTerm) &&
                              fullname !== searchTerm
                            );
                          })
                          .slice(0, 10)
                          .map((item) => (
                            <div
                              key={item.mfirstName}
                              onClick={() => {
                                onSearch(
                                  `${item.mfirstName} ${item.mlastName}`
                                );
                                setShowSuggestions(false);
                              }}
                              className="p-3 cursor-pointer transition-all duration-200 text-base border-b last:border-b-0 hover:!bg-blue-600 hover:!text-white"
                            >
                              {item.mfirstName} {item.mlastName}
                            </div>
                          ))}
                      </div>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Amount <span className="myast">*</span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Amount"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      name="amount"
                      style={{ fontSize: "1em" }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Giving Category <span className="myast">*</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Floating label select example"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                      style={{ fontSize: "1em" }}
                    >
                      <option value="" style={{ fontSize: "1em" }}>
                        Select a giving category
                      </option>
                      {funds?.map((fundv) => (
                        <option
                          value={fundv.fundName}
                          style={{ fontSize: "1em" }}
                        >
                          {fundv.fundName}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Giving Method <span className="myast">*</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Floating label select example"
                      value={paymentMethod}
                      name="paymentMethod"
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      style={{ fontSize: "1em" }}
                    >
                      <option value="">Select a giving method</option>
                      {methodValue?.map((methodv) => (
                        <option
                          value={methodv.methodName}
                          style={{ fontSize: "1em" }}
                        >
                          {methodv.methodName}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <p
                    onClick={showplenty}
                    style={{ fontSize: "1em" }}
                    className="addmore"
                  >
                    Add more details {">>"}
                  </p>
                  {showmore && (
                    <>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{ fontSize: "1em" }}>
                          Note
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Leave a comment here"
                          style={{ height: "100px", fontSize: "1em" }}
                          value={note}
                          name="note"
                          onChange={(e) => setNote(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{ fontSize: "1em" }}>
                          Check Number
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Add Check Number"
                          value={checkNumber}
                          onChange={(e) => setCheckNumber(e.target.value)}
                          name="checkNumber"
                          style={{ fontSize: "1em" }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{ fontSize: "1em" }}>
                          Account Number
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Add Account Number"
                          value={accountNumber}
                          onChange={(e) => setAccountNumber(e.target.value)}
                          name="accountNumber"
                          style={{ fontSize: "1em" }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{ fontSize: "1em" }}>
                          Card Number
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Add the last 4 digits of your credit card number"
                          value={cardDigit}
                          onChange={(e) => setCardDigit(e.target.value)}
                          name="cardDigit"
                          style={{ fontSize: "1em" }}
                        />
                      </Form.Group>
                    </>
                  )}

                  <Button
                    className="givebu"
                    style={{ fontSize: "1em" }}
                    onClick={handleSubmit}
                  >
                    Add
                  </Button>
                </form>
              </Col>
            </Row>
          </>
        )}
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Close Batch {batch.batchId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Note: Once this batch is closed, it cannot be edited. Ensure all
            entries are accurate before closing this batch.
          </p>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Enter Batch Name"
                value={batchText}
                onChange={(e) => setBatchText(e.target.value)}
                name="batchText"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose}>
            Cancel
          </button>
          <button className="jiluil" onClick={cancelThisBatchForm}>
            Close Batch
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GivingHome;
