import React, { useState, useRef, useEffect, useMemo } from "react";
import { Modal, Form } from "react-bootstrap";
import { useAppContext } from "../../context/appContext";
import { toast } from "react-toastify";
import axios from "axios";
const BulkSMSEditor = ({
  show,
  userPhone,
  isSMSSent,
  handleClose,
  maxChars = 2500,
}) => {
  const [smsMessage, setSMSMessage] = useState("");
  const { user, logoutUser, bulkEmailtoMembers, sendBulkSMS, savedView } =
    useAppContext();

  const authFetch = useMemo(() => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL,
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
    });

    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          logoutUser();
        }
        return Promise.reject(error);
      }
    );

    return instance;
  }, [logoutUser]);
  const [isError, setIsError] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const smsMessageRef = useRef();

  const variables = [
    { label: "First Name", value: "[FirstName]" },
    { label: "Last Name", value: "[LastName]" },
    { label: "Email", value: "[Email]" },
    { label: "Phone Number", value: "[PhoneNumber]" },
  ];

  useEffect(() => {
    if (user && user.primaryPhoneNumber) {
      getCurrentNumber();
    }
  }, []);
  const [churchnumber, setChurchNumber] = useState("");

  const getCurrentNumber = async () => {
    try {
      const response = await authFetch.get("auth/churchaccount");

      if (response.status === 200 || response.status === 201) {
        const { data } = response;

        // console.log("data", churchSettings);
        setChurchNumber(data.churchSettings.primaryPhoneNumber);
      }

      // setChurchNumber(data?.primaryPhoneNumber);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const insertAtCursorPosition = (content) => {
    if (!content || !smsMessageRef.current) return;

    const cursorPosition = smsMessageRef.current.selectionStart;
    const textBeforeCursor = smsMessage.slice(0, cursorPosition);
    const textAfterCursor = smsMessage.slice(cursorPosition);
    const newText = textBeforeCursor + content + textAfterCursor;

    if (newText.length <= maxChars) {
      setSMSMessage(newText);
      setTimeout(() => {
        if (smsMessageRef.current) {
          smsMessageRef.current.selectionStart =
            smsMessageRef.current.selectionEnd =
              cursorPosition + content.length;
          smsMessageRef.current.focus();
        }
      }, 0);
    }
  };

  // const handleSend = () => {

  //   setSMSMessage("");
  //   setStatusMessage("Text message sent to selected members");
  //   setTimeout(() => setStatusMessage(""), 3000);
  //   handleClose();
  // };
  const handleSMSSend = async (smsData) => {
    if (!smsMessage.trim()) {
      setIsError(true);
      return;
    }
    setIsError(false);
    try {
      const smsData = {
        recipients: userPhone,
        textmessage: smsMessage,
      };
      await sendBulkSMS({ smsData });
      setSMSMessage("");
      setStatusMessage("Text message sent to selected members");
      setTimeout(() => setStatusMessage(""), 3000);
      handleClose();
      // toggleModal("sms", false);
      toast.success("SMS sent successfully");
    } catch (error) {
      toast.error("Failed to send SMS");
      console.error("SMS error:", error);
    }
  };
  const handleSMSMessageChange = (e) => {
    setSMSMessage(e.target.value);
  };
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Send SMS</Modal.Title>
      </Modal.Header>

      <Modal.Body className="space-y-4">
        {isError && (
          <div className="p-3 text-red-600 bg-red-100 rounded">
            Text Message is required to proceed!
          </div>
        )}

        {statusMessage && (
          <div className="p-3 text-green-600 bg-green-100 rounded">
            {statusMessage}
          </div>
        )}

        <div className="text-right text-sm text-gray-600">
          {`${smsMessage.length} / ${maxChars}`} characters
          {smsMessage.length > 2500 && (
            <span className="text-amber-600 ml-2">
              For better delivery, use a maximum of 2500 characters.
            </span>
          )}
        </div>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          {isSMSSent && <p className="inlinesuccess">{isSMSSent}</p>}
          <div className="character-count flex items-center justify-end">
            {`${smsMessage.length} / ${maxChars}`} characters. For better
            delivery, use a maximum of 2500 characters.
          </div>
          <Form.Label>From</Form.Label>
          {churchnumber && (
            <Form.Control
              type="text"
              autoFocus
              disabled
              value={churchnumber ? churchnumber : ""}
              // onChange={(e) => setSMSNumber(e.target.value)}
            />
          )}
        </Form.Group>

        <Form.Group>
          <Form.Label>To (Members)</Form.Label>
          <Form.Control
            type="text"
            value={userPhone.length >= 1 ? userPhone : ""}
            disabled
            className="bg-gray-50"
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            ref={smsMessageRef}
            rows={6}
            value={smsMessage}
            onChange={handleSMSMessageChange}
            required
            className={`resize-none ${
              smsMessage.length > maxChars ? "border-red-500" : ""
            }`}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Insert Variable</Form.Label>
          <Form.Select
            onChange={(e) => {
              if (e.target.value !== "Select Variables") {
                insertAtCursorPosition(e.target.value);
                e.target.value = "Select Variables";
              }
            }}
            className="w-48"
          >
            <option>Select Variables</option>
            {variables.map((variable) => (
              <option key={variable.value} value={variable.value}>
                {variable.label}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Modal.Body>

      <Modal.Footer className="flex justify-end gap-3">
        <button
          onClick={handleClose}
          className="px-4 py-2 text-gray-700 bg-gray-100 rounded hover:bg-gray-200"
        >
          Cancel
        </button>
        <button
          onClick={handleSMSSend}
          disabled={smsMessage.length > maxChars}
          className="jiluil  text-white bg-blue-600 rounded hover:bg-blue-700 disabled:bg-blue-300"
        >
          Send SMS
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default BulkSMSEditor;
