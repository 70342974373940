import React, { useState, useEffect } from "react";
import axios from "axios";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";
import Badge from "react-bootstrap/Badge";
import Image from "react-bootstrap/Image";
import "./profile.css";
import Resizer from "react-image-file-resizer";
import Profilez from "../../assets/images/blank2.png";
import { useAppContext } from "../../context/appContext";
import { Modal } from "react-bootstrap";
import Alert from "../Alert/Alert";

const ChurchInformation = ({ show, setShow }) => {
  const { user, isLoading, showAlert, getMemberById, updateUser } =
    useAppContext();

  useEffect(() => {
    if (user?.aboutUs) setAboutUs(user?.aboutUs);
    if (user?.churchName) setChurchName(user?.churchName);
  }, [user]);

  const [email, setEmail] = useState(user.memail);
  const [churchDomain, setChurchDomain] = useState(user.churchDomain);
  const [firstName, setFirstName] = useState(user.mfirstName);
  const [lastName, setLastName] = useState(user.mlastName);
  const [phoneNumber, setPhoneNumber] = useState(user.mworkPhone);
  const [churchName, setChurchName] = useState();
  const [aboutUs, setAboutUs] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [preview, setPreview] = useState("");
  const [taxNumber, setTaxNumber] = useState(user.taxNumber);
  const [website, setWebsite] = useState(user.website);
  const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState(
    user.primaryPhoneNumber
  );
  const [image, setImage] = useState(user.mProfilePicture);

  const [uploadButtonText, setUploadButtonText] = useState("Upload Image");

  const { mProfilePicture, logoutUser } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setIsUpdating(true);
  //   const currentUser = {
  //     memail: email,
  //     mfirstName: firstName,
  //     mlastName: lastName,
  //     churchName: churchName,
  //     // country: country,
  //     aboutUs: aboutUs,
  //     mworkPhone: phoneNumber,
  //     mProfilePicture: image,
  //     website: website,
  //     taxNumber: taxNumber,
  //     churchDomain: churchDomain,
  //   };

  //   await updateUser({ currentUser });
  //   await getMemberById(user._id);
  //   toast.success("Profile updated successfully");
  //   setIsUpdating(false);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    try {
      const currentUser = {
        memail: email,
        mfirstName: firstName,
        mlastName: lastName,
        churchName: churchName,
        aboutUs: aboutUs,
        mworkPhone: phoneNumber,
        mProfilePicture: image,
        website: website,
        taxNumber: taxNumber,
        churchDomain: churchDomain,
      };

      await updateUser({ currentUser });
      // After successful update, fetch member details again
      await getMemberById(user._id);
      toast.success("Profile updated successfully");
      handleClose();
    } catch (error) {
      toast.error("Failed to update profile");
    } finally {
      setIsUpdating(false);
    }
  };

  const handleImage = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      setPreview(URL.createObjectURL(file));
      setUploadButtonText(file.name);

      Resizer.imageFileResizer(
        file,
        520,
        300,
        "JPEG",
        100,
        0,
        async (uri) => {
          try {
            const { data } = await authFetch.post("/members/upload-image", {
              image: uri,
            });
            setImage(data);
            await getMemberById(user._id);
          } catch (err) {
            toast.error("Error uploading image");
            setPreview("");
            setUploadButtonText("Upload Image");
          }
        },
        "base64"
      );
    } catch (error) {
      console.error("Error processing image:", error);
      toast.error("Error processing image");
    }
  };

  const handleImageRemove = async (e) => {
    e.preventDefault();

    try {
      await authFetch.post("/members/remove-image", { image });
      setImage({});
      setPreview("");
      setUploadButtonText("Upload Removed");
      await getMemberById(user._id);
    } catch (err) {
      console.error("Error in uploadDefaultImage function:", err);
    }
  };
  const [fullscreen, setFullscreen] = useState(true);

  const handleClose = () => setShow(false);

  return (
    <Modal show={show} fullscreen={fullscreen} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Church Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="leftgivediv">
          <form className="srgerge">
            {showAlert && <Alert />}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1rem",
                marginBottom: "2rem",
                backgroundColor: "#ffffff",
              }}
              className="mb-3 pb-3"
            >
              <Image
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                  backgroundColor: "#ffffff",
                  cursor: preview ? "pointer" : "default",
                }}
                src={
                  user?.mProfilePicture?.Location
                    ? user?.mProfilePicture?.Location
                    : Profilez
                }
                onClick={preview ? handleImageRemove : undefined}
                roundedCircle
              />

              {preview && (
                <div className="preview-container">
                  <Badge
                    onClick={handleImageRemove}
                    style={{ cursor: "pointer" }}
                  >
                    <Image
                      style={{ maxWidth: "200px", height: "auto" }}
                      src={preview}
                    />
                  </Badge>
                </div>
              )}

              <div className="d-flex gap-3">
                <label className="btn btn-outline-secondary">
                  Upload Logo
                  <Form.Control
                    type="file"
                    name="image"
                    onChange={handleImage}
                    accept="image/*"
                    hidden
                  />
                </label>

                {(image?.Location || preview) && (
                  <Button variant="outline-danger" onClick={handleImageRemove}>
                    Remove Logo
                  </Button>
                )}
              </div>
            </div>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontWeight: "600" }}>First Name</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="forminput"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontWeight: "600" }}>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="forminput"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontWeight: "600" }}>Church Name</Form.Label>
              <Form.Control
                type="text"
                name="churchName"
                placeholder="Church Name"
                value={churchName}
                onChange={(e) => setChurchName(e.target.value)}
                className="forminput"
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontWeight: "600" }}>
                Church Domain
              </Form.Label>
              <Form.Control
                type="text"
                name="churchDomain"
                placeholder="Church Domain"
                value={churchDomain}
                onChange={(e) => setChurchDomain(e.target.value)}
                className="forminput"
                disabled
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontWeight: "600" }}>
                Church Email
              </Form.Label>
              <Form.Control
                type="text"
                name="churchEmail"
                placeholder="Church Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="forminput"
                disabled
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontWeight: "600" }}>Website</Form.Label>
              <Form.Control
                type="text"
                name="website"
                placeholder="Website"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
                className="forminput"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontWeight: "600" }}>
                Church Phone Number
              </Form.Label>
              <Form.Control
                type="text"
                name="phoneNumber"
                placeholder="Phone"
                value={
                  primaryPhoneNumber?.length
                    ? primaryPhoneNumber
                    : "No data available"
                }
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="forminput"
                disabled
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontWeight: "600" }}>
                Tax ID Number
              </Form.Label>
              <Form.Control
                type="text"
                name="taxNumber"
                placeholder="taxNumber"
                value={taxNumber?.length ? taxNumber : "No data available"}
                onChange={(e) => setTaxNumber(e.target.value)}
                className="forminput"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontWeight: "600" }}>
                How did you hear about us{" "}
              </Form.Label>
              <Form.Select
                value={aboutUs?.length ? aboutUs : user?.aboutUs}
                name="aboutUs"
                onChange={(e) => setAboutUs(e.target.value)}
              >
                <option value="" selected="selected"></option>
                <option value="SearchedOnline">Searched Online</option>
                <option value="ReferredBySomeone">Referred by Someone</option>
                <option value="SocialMedia">Social Media</option>
                <option value="YouContactedMe">You contacted me</option>
                <option value="Other">Other</option>
              </Form.Select>
            </Form.Group>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3 " onClick={handleClose}>
          Close
        </button>

        <button className="jiluil" onClick={handleSubmit} disabled={isUpdating}>
          {isUpdating ? "Updating..." : "Update"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChurchInformation;
