import React, { useState, useEffect } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Nav, Table, Col } from "react-bootstrap";

import "./profile.css";

import { useAppContext } from "../../context/appContext";
import moment from "moment-timezone";
import Alert from "../Alert/Alert";
import Select from "react-select";
import currencyCodes from "currency-codes";

const statesUS = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];

const provincesCanada = [
  { value: "AB", label: "Alberta" },
  { value: "BC", label: "British Columbia" },
  { value: "MB", label: "Manitoba" },
  { value: "NB", label: "New Brunswick" },
  { value: "NL", label: "Newfoundland and Labrador" },
  { value: "NS", label: "Nova Scotia" },
  { value: "ON", label: "Ontario" },
  { value: "PE", label: "Prince Edward Island" },
  { value: "QC", label: "Quebec" },
  { value: "SK", label: "Saskatchewan" },
  { value: "NT", label: "Northwest Territories" },
  { value: "NU", label: "Nunavut" },
  { value: "YT", label: "Yukon" },
];

const Location = ({ show, setShow }) => {
  const { user, isLoading, showAlert, displayAlert, updateUser, logoutUser } =
    useAppContext();

  const [timeZones, setTimeZones] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState("America/New_York"); // Default time zone
  const [timeFormat, setTimeFormat] = useState("24-hour"); // Default time format
  const [country, setCountry] = useState(user.country || ""); // Default to US
  const [churchName, setChurchName] = useState(user.churchName);
  const [currency, setCurrency] = useState("");
  const [streetAddress, setStreetAddress] = useState(user.maddress);
  const [aptUnitBox, setAptUnitBox] = useState(user.aptUnitBox);
  const [city, setCity] = useState(user.mcity);
  const [stateValue, setStateValue] = useState("");
  const [stateLabel, setStateLabel] = useState("");
  const [zipCode, setZipCode] = useState(user.mpostal);

  const stripeSupportedCurrencies = ["USD", "CAD", "EUR", "NGN", "GBP", "JPY"];

  const currencyOptions = stripeSupportedCurrencies
    .map((code) => ({
      code,
      name: currencyCodes.code(code)?.currency || code, // Fallback to code if name not found
    }))
    .filter((currency) => currency.name);

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (user?.timeZone) setSelectedTimeZone(user?.timeZone);
    if (user?.timeFormat) setTimeFormat(user?.timeFormat);
    if (user?.country) setCountry(user?.country);
    if (user?.currency) setCurrency(user?.currency);
    if (user?.mstate2) setStateValue(user?.mstate2);
    if (user?.mstate) {
      const stateObj =
        statesUS.find((state) => state.label === user.mstate) ||
        provincesCanada.find((province) => province.label === user.mstate);
      if (stateObj) {
        setStateValue(stateObj.value);
        setStateLabel(stateObj.label);
      }
    }
  }, [user]);

  useEffect(() => {
    const getTimeZones = () => {
      const usCanadaZones = moment.tz
        .names()
        .filter((zone) => zone.startsWith("America/"))
        .map((zone) => {
          const offset = moment.tz(zone).format("Z");
          const abbr = moment.tz(zone).format("z");
          return {
            value: zone,
            label: `(GMT${offset}) ${abbr} (${zone})`,
          };
        });
      setTimeZones(usCanadaZones);
    };

    getTimeZones();
  }, []);

  const handleSelect = (e) => {
    setSelectedTimeZone(e.target.value);
  };

  const handleStateChange = (e) => {
    const selectedOption = e.target.selectedOptions[0];
    setStateValue(selectedOption.value);
    setStateLabel(selectedOption.label);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const currentUser = {
      timeFormat,
      timeZone: selectedTimeZone,
      country,
      churchName,
      currency,
      mcity: city,
      region: stateValue,
      mstate: stateLabel,
      mpostal: zipCode,
      maddress: streetAddress,
      aptUnitBox: aptUnitBox,
    };

    updateUser({ currentUser });
  };

  const handleClose = () => setShow(false);

  return (
    <Modal show={show} fullscreen onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Localization</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="leftgivediv eweyuu">
          <form className="srgerged" onSubmit={handleSubmit}>
            {showAlert && <Alert />}
            <p className="profiletitle"></p>
            <Form.Group className="mb-3" controlId="timeZoneSelect">
              <Form.Label>Time Zone</Form.Label>
              <Form.Select value={selectedTimeZone} onChange={handleSelect}>
                {timeZones.map((zone, index) => (
                  <option key={index} value={zone.value}>
                    {zone.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCountry">
              <Form.Label>Country</Form.Label>
              <Form.Select
                value={country}
                name="country"
                onChange={(e) => setCountry(e.target.value)}
              >
                <option value="">Select Country</option>
                <option value="US">United States</option>
                <option value="CA">Canada</option>
                {/* Add other countries if necessary */}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicState">
              <Form.Label>State/Province</Form.Label>
              <Form.Select
                value={stateValue}
                name="state"
                onChange={handleStateChange}
              >
                {country === "US" &&
                  statesUS.map((state) => (
                    <option key={state.value} value={state.value}>
                      {state.label}
                    </option>
                  ))}
                {country === "CA" &&
                  provincesCanada.map((province) => (
                    <option key={province.value} value={province.value}>
                      {province.label}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                className="forminput"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Street Address</Form.Label>
              <Form.Control
                type="text"
                name="streetAddress"
                value={streetAddress}
                onChange={(e) => setStreetAddress(e.target.value)}
                className="forminput"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Apt/Unit/Box</Form.Label>
              <Form.Control
                type="text"
                name="aptUnitBox"
                value={aptUnitBox}
                onChange={(e) => setAptUnitBox(e.target.value)}
                className="forminput"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Zip/Postal Code</Form.Label>
              <Form.Control
                type="text"
                name="zipCode"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                className="forminput"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="currencySelect">
              <Form.Label>Currency</Form.Label>
              <Form.Select
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
              >
                {currencyOptions.map(({ code, name }) => (
                  <option key={code} value={code}>{`${name} (${code})`}</option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="timeFormatSelect">
              <Form.Label>Time Format</Form.Label>
              <Form.Select
                value={timeFormat}
                onChange={(e) => setTimeFormat(e.target.value)}
              >
                <option value="12-hour">12-Hour Format</option>
                <option value="24-hour">24-Hour Format</option>
              </Form.Select>
            </Form.Group>

            <button className="jiluil" type="submit">
              Update
            </button>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Location;
