import React from "react";
import { FaCheck } from "react-icons/fa";

const LABEL_COLORS = [
  "brown",
  "orange",
  "green",
  "blue",
  "gray",
  "purple",
  "red",
  "black",
];

const ColorLabels = ({ selectedLabel, onSelectLabel }) => (
  <div>
    <p className="text-base font-semibold">Label</p>
    <div className="itez">
      {LABEL_COLORS.map((color) => (
        <span
          key={color}
          onClick={() => onSelectLabel(color)}
          className="tailwindd"
          style={{
            backgroundColor: color,
            color: "white",
            width: "30px",
            height: "30px",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            margin: "0 4px",
            cursor: "pointer",
          }}
        >
          {selectedLabel === color && <FaCheck />}
        </span>
      ))}
    </div>
  </div>
);
export default ColorLabels;
