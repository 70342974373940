import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { useAppContext } from "../../../context/appContext";
import CustomToastContent from "../../../components/Toast/CustomToastContent";
import axios from "axios";

import WorkflowTriggerSelector from "../../../components/WorkFlow/WorkflowTriggerSelector";

const WorkFlowView = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { id, name } = useParams();
  const navigate = useNavigate();
  const { getContainers, logoutUser } = useAppContext();

  // State management
  const [workflowId, setWorkflowId] = useState("");
  const [workflowName, setWorkflowName] = useState("");
  const [uniqueWorkflow, setUniqueWorkflow] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [newSelectedOption, setNewSelectedOption] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [showPrompt, setShowPrompt] = useState(false);

  // Modal handlers
  const handleClose = () => setShowPrompt(false);

  useEffect(() => {
    setWorkflowId(id);
    setWorkflowName(name);
  }, [id, name]);

  useEffect(() => {
    getContainers();
  }, []);

  // Fetch workflow data
  useEffect(() => {
    const getUniqueWorkflow = async () => {
      try {
        setLoading(true);
        const { data } = await authFetch.get(`/workflow/unique/${workflowId}`);
        setUniqueWorkflow(data);
        setSelectedOption(data.trigger);
        setError(null);
      } catch (err) {
        setError(err.message);
        toast(
          <CustomToastContent
            title="Error"
            message="Failed to load workflow details"
            type="error"
          />
        );
      } finally {
        setLoading(false);
      }
    };

    if (workflowId) {
      getUniqueWorkflow();
    }
  }, [workflowId]);

  const handleTriggerSelect = (triggerId) => {
    setSelectedOption(triggerId);
  };

  console.log(uniqueWorkflow);

  const handleData = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      if (!selectedOption) {
        toast(
          <CustomToastContent
            title="Error"
            message="Select a trigger to proceed"
            type="error"
          />
        );
        return;
      }

      // Case 1: No trigger defined yet (initial setup)
      if (!uniqueWorkflow.trigger) {
        // Save the trigger directly without showing prompt
        const workflowData = {
          trigger: selectedOption,
        };

        const response = await authFetch.patch(
          `/workflow/updatenew/${workflowId}`,
          workflowData
        );

        if (response.status === 200 || response.status === 201) {
          navigate(
            `/auth/workflowdetails/${id}/${workflowName}/${selectedOption}`
          );
        }
        return;
      }

      // Case 2: Trigger exists and is being changed
      if (uniqueWorkflow.trigger !== selectedOption) {
        setNewSelectedOption(selectedOption);
        setShowPrompt(true);
        return;
      }

      // Case 3: Same trigger selected, just navigate
      navigate(`/auth/workflowdetails/${id}/${workflowName}/${selectedOption}`);
    } catch (error) {
      toast(
        <CustomToastContent
          title="Error"
          message={error.message || "Failed to update workflow"}
          type="error"
        />
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleReset = async () => {
    setIsSubmitting(true);
    try {
      // Remove all steps associated with the current workflow
      const response = await authFetch.delete(
        `/workflow/removesteps/${uniqueWorkflow._id}`
      );

      if (response.status === 200 || response.status === 201) {
        await updateAndNavigate();
      }
    } catch (error) {
      toast(
        <CustomToastContent
          title="Error"
          message="Failed to reset workflow steps"
          type="error"
        />
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateAndNavigate = async () => {
    try {
      const workflowData = {
        trigger: newSelectedOption,
      };

      const response = await authFetch.patch(
        `/workflow/updatenew/${workflowId}`,
        workflowData
      );

      if (response.status === 200 || response.status === 201) {
        handleClose();
        navigate(
          `/auth/workflowdetails/${id}/${workflowName}/${selectedOption}`
        );
      }
    } catch (error) {
      toast(
        <CustomToastContent
          title="Error"
          message="Failed to update workflow"
          type="error"
        />
      );
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500" />
      </div>
    );
  }

  return (
    <Container fluid className="max-w-7xl mx-auto px-4">
      {/* Breadcrumb Navigation */}
      {/* <nav className="flex items-center space-x-2 py-4 text-gray-600">
        <Home className="w-4 h-4" />
        <ChevronRight className="w-4 h-4" />
        <span>Workflows</span>
        <ChevronRight className="w-4 h-4" />
        <span className="font-medium text-gray-900">{workflowName}</span>
        <ChevronRight className="w-4 h-4" />
        <span className="text-blue-600">Trigger Settings</span>
      </nav> */}

      <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
        <h1 className="text-2xl font-bold mb-2">Trigger Settings</h1>
        <p className="text-gray-600 mb-6">
          Workflow can be triggered by various events, such as when an
          individual joins a group, when a specific calendar date is reached, or
          when a date recorded in a contact field occurs.
        </p>

        {/* Trigger Selector Component */}
        <WorkflowTriggerSelector
          selectedTrigger={selectedOption}
          onTriggerSelect={handleTriggerSelect}
        />

        {/* Action Buttons */}
        <div className="flex justify-end mt-6 gap-3 space-x-4">
          <button
            onClick={() => navigate(-1)}
            className="jiluil3 px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors"
          >
            Back
          </button>
          <button
            onClick={handleData}
            disabled={isSubmitting || !selectedOption}
            className={`jiluil px-6 py-2 bg-blue-600 text-white 
              ${
                isSubmitting || !selectedOption
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-blue-700"
              } 
              transition-all`}
          >
            {isSubmitting ? "Processing..." : "Next"}
          </button>
        </div>
      </div>

      {/* Confirmation Modal */}
      <Modal
        show={showPrompt}
        onHide={handleClose}
        centered
        className="rounded-lg"
      >
        <Modal.Header closeButton className="border-b">
          <Modal.Title className="text-lg font-semibold">
            Change Workflow Trigger
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-4">
          <div className="text-red-600 mb-2">⚠️ Warning</div>
          <p>
            Changing the workflow trigger will reset the schedule for all steps.
            You will need to review and adjust each step in the workflow.
          </p>
        </Modal.Body>
        <Modal.Footer className="border-t">
          <button
            onClick={handleClose}
            className="px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={handleReset}
            disabled={isSubmitting}
            className={`px-6 py-2 bg-red-600 text-white rounded-lg
              ${
                isSubmitting
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-red-700"
              }
              transition-all`}
          >
            {isSubmitting ? "Processing..." : "Confirm Reset"}
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default WorkFlowView;
