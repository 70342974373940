import React from "react";
import { Form } from "react-bootstrap";
import moment from "moment";

const RecurrenceOptions = ({ formData, setFormData }) => {
  const patterns = ["Daily", "Weekly", "Monthly", "Yearly"];
  const occurrenceTypes = ["End after", "End by"];

  return (
    <div className="mt-4">
      <div className="modallsss2">
        <Form.Group className="mb-3 eeesss">
          <Form.Label>Start Time:</Form.Label>
          <Form.Control
            type="time"
            value={formData.startTime}
            onChange={(e) =>
              setFormData({ ...formData, startTime: e.target.value })
            }
            style={{ fontSize: "1em" }}
          />
        </Form.Group>
        <Form.Group className="mb-3 eeesss">
          <Form.Label>End Time:</Form.Label>
          <Form.Control
            type="time"
            value={formData.endTime}
            onChange={(e) =>
              setFormData({ ...formData, endTime: e.target.value })
            }
            style={{ fontSize: "1em" }}
          />
        </Form.Group>
      </div>

      <div className="modallsss mt-3">
        <Form.Label>Event Pattern</Form.Label>
        <Form.Group className="mb-3">
          {patterns.map((pattern) => (
            <Form.Check
              key={pattern}
              type="radio"
              label={pattern}
              checked={formData.pattern === pattern}
              onChange={() => setFormData({ ...formData, pattern })}
              required
            />
          ))}
        </Form.Group>
      </div>

      <div className="modallsss mt-3">
        <Form.Label>Event Occurrences</Form.Label>
        <Form.Group className="mb-3">
          {occurrenceTypes.map((type) => (
            <Form.Check
              key={type}
              type="radio"
              label={type}
              checked={formData.occurence === type}
              onChange={() => setFormData({ ...formData, occurence: type })}
              required
            />
          ))}
        </Form.Group>

        {formData.occurence === "End after" && (
          <Form.Group className="mb-3">
            <Form.Label>End after</Form.Label>
            <Form.Control
              type="number"
              value={formData.endAfter}
              onChange={(e) =>
                setFormData({ ...formData, endAfter: e.target.value })
              }
              placeholder="Number of occurrences"
            />
            <Form.Text>occurrences</Form.Text>
          </Form.Group>
        )}

        {formData.occurence === "End by" && (
          <Form.Group className="mb-3">
            <Form.Label>End By:</Form.Label>
            <Form.Control
              type="date"
              value={formData.endBy}
              onChange={(e) =>
                setFormData({ ...formData, endBy: e.target.value })
              }
              min={moment().format("YYYY-MM-DD")}
            />
          </Form.Group>
        )}
      </div>

      {formData.occurence && formData.eventDay && formData.startTime && (
        <p className="mt-3">
          Events starts on {formData.eventDay} at {formData.startTime}. It will
          occur {formData.pattern?.toLowerCase() || ""}
          {formData.occurence === "End after"
            ? ` and will end after ${formData.endAfter} occurrences`
            : formData.occurence === "End by"
            ? ` and will end by ${formData.endBy}`
            : ""}
        </p>
      )}
    </div>
  );
};
export default RecurrenceOptions;
