import React, { useState, useEffect } from "react";
import { Table, Button, Form, Modal, Container, Row } from "react-bootstrap";
import { useAppContext } from "../../../context/appContext";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToastContent from "../../../components/Toast/CustomToastContent";
import nodd from "../../../assets/newi/nodd.png"; // Adjust the import path according to your project structure

const Budget = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { logoutUser, user, getExpense, expenses } = useAppContext();

  useEffect(() => {
    getExpense();
  }, []);

  useEffect(() => {
    if (expenses && expenses.length > 0) {
      setExpenseData(expenses);
    }
  }, [expenses]);

  const [budget, setBudget] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [expenseData, setExpenseData] = useState([]);
  const [newExpense, setNewExpense] = useState({
    budgetName: "",
    category: "",
    amount: 0,
    startDate: new Date().toISOString().split("T")[0],
    alertThreshold: 80,
  });

  useEffect(() => {
    getBudget();
  }, []);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);

  const handleExpenseChange = (e) => {
    setNewExpense({ ...newExpense, [e.target.name]: e.target.value });
  };

  const getBudget = async () => {
    try {
      const { data } = await authFetch.get("/giving/budget");
      console.log(data);
      setBudget(data);
    } catch (error) {
      console.error(error);
    }
  };

  const submitExpense = async (e) => {
    e.preventDefault();

    if (
      newExpense.category === "" ||
      newExpense.amount === 0 ||
      newExpense.budgetName === ""
    ) {
      toast(
        <CustomToastContent type="error" message="Please fill all fields" />
      );
      return;
    }

    const budgetData = {
      budgetName: newExpense.budgetName,
      category: newExpense.category,
      totalAmount: newExpense.amount,
      startDate: newExpense.startDate,
      alertThreshold: newExpense.alertThreshold,
      churchName: user.churchName,
    };

    try {
      await authFetch.post("/giving/budget/add-budget", budgetData);
      // Reset form
      setNewExpense({
        budgetName: "",
        category: "",
        amount: 0,
        startDate: new Date().toISOString().split("T")[0],
        alertThreshold: 80,
      });
      getBudget();
      handleCloseModal();
    } catch (error) {
      console.error(error);
      toast(
        <CustomToastContent
          type="error"
          message="Budget category already exist!"
        />
      );
    }
  };

  const [selectedBudget, setSelectedBudget] = useState(null);
  const [clickedBudget, setClickedBudget] = useState([]);

  const handleShow = (event, category) => {
    event.preventDefault();
    setShow(true);
    setClickedBudget(category);
  };

  const deleteThisBudget = async () => {
    try {
      const response = await authFetch.delete(
        `/giving/budget/${clickedBudget._id}`
      );
      if (response.status === 200 || response.status === 201) {
        toast(
          <CustomToastContent
            type="success"
            message="Budget deleted successfully"
          />
        );
        getBudget();
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [newExpenseData, setNewExpenseData] = useState(null);
  const [showModal22, setShowModal22] = useState(false);
  const handleCloseModal22 = () => setShowModal22(false);
  const [newBudgetName, setNewBudgetName] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [newAmount, setNewAmount] = useState(0);
  const [newStartDate, setNewStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [newAlertThreshold, setNewAlertThreshold] = useState(80);
  const [newBudgetId, setNewBudgetId] = useState("");

  const handleShowEdit = async (event, category) => {
    event.preventDefault();
    try {
      const response = await authFetch.get(`/giving/budget/${category._id}`);

      if (response.status === 200 || response.status === 201) {
        setNewBudgetName(response.data.budgetName);
        setNewCategory(response.data.category);
        setNewAmount(response.data.totalAmount);
        const dateValue = response.data.startDate
          ? new Date(response.data.startDate).toISOString().split("T")[0]
          : new Date().toISOString().split("T")[0];

        setNewStartDate(dateValue);
        setNewAlertThreshold(response.data.alertThreshold || 80);
        setNewBudgetId(response.data._id);
        setShowModal22(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (newExpenseData) {
      setNewBudgetName(newExpenseData.budgetName);
      setNewCategory(newExpenseData.category);
      setNewAmount(newExpenseData.amount);
      setNewBudgetId(newExpenseData.budgetId);
    }
  }, [newExpenseData]);

  const handleEditBudget = async (e) => {
    e.preventDefault();
    const budgetData = {
      budgetName: newBudgetName,
      category: newCategory,
      totalAmount: newAmount,
      startDate: newStartDate,
      alertThreshold: newAlertThreshold,
      churchName: user.churchName,
    };

    try {
      await authFetch.patch(`/giving/budget/${newBudgetId}`, budgetData);
      // Reset form
      setNewBudgetName("");
      setNewCategory("");
      setNewAmount(0);
      setNewStartDate(new Date().toISOString().split("T")[0]);
      setNewAlertThreshold(80);
      getBudget();
      handleCloseModal22();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Container fluid>
        <Row className="headerbanner">
          <div className="bannerbig">Budget Tracker</div>
        </Row>
        <Row className="sssssssecwef">
          <div className="bnmjo">
            <Button className="wedweded" onClick={handleShowModal}>
              Add Budget
            </Button>
          </div>
        </Row>

        {/* Conditional Rendering: Show table if budget data exists, otherwise show "no data" message */}
        {budget.length > 0 ? (
          <Table hover responsive>
            <thead>
              <tr>
                <th>Category</th>
                <th>Budget Name</th>
                <th>Total Budget</th>
                <th>Spent</th>
                <th>Remaining</th>
                <th>Alert At</th> {/* New column */}
                <th>Status</th> {/* New column */}
              </tr>
            </thead>
            <tbody>
              {budget.map((category) => {
                const percentageUsed =
                  (category.amountSpent / category.totalAmount) * 100;
                const isNearThreshold =
                  percentageUsed >= category.alertThreshold;

                return (
                  <tr key={category.id}>
                    <td>{category.category}</td>
                    <td>{category.budgetName}</td>
                    <td>
                      {category.totalAmount.toLocaleString(undefined, {
                        style: "currency",
                        currency: user.currency,
                      })}
                    </td>
                    <td>
                      {category.amountSpent.toLocaleString(undefined, {
                        style: "currency",
                        currency: user.currency,
                      })}
                    </td>
                    <td>
                      {(
                        category.totalAmount - category.amountSpent
                      ).toLocaleString(undefined, {
                        style: "currency",
                        currency: user.currency,
                      })}
                    </td>
                    <td>{category.alertThreshold}%</td>
                    <td>
                      {isNearThreshold ? (
                        <span className="text-danger">Alert</span>
                      ) : (
                        <span className="text-success">Normal</span>
                      )}
                    </td>
                    <td className="rightco">
                      <button
                        className="jiluil3"
                        onClick={(event) => handleShowEdit(event, category)}
                      >
                        Edit
                      </button>
                      <button
                        className="jiluil"
                        onClick={(event) => handleShow(event, category)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <div className="noData">
            <img src={nodd} alt="No data available" />
            <p className="noDataBackup">No data available for display.</p>
          </div>
        )}
        <Row className="bottomSpacer"></Row>
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add Budget</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={submitExpense}>
              {/* Existing fields */}
              <Form.Group className="mb-3">
                <Form.Label>
                  Title <span className="myast">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="budgetName"
                  value={newExpense.budgetName}
                  onChange={handleExpenseChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  Category <span className="myast">*</span>
                </Form.Label>
                <Form.Select
                  name="category"
                  value={newExpense.category}
                  onChange={handleExpenseChange}
                >
                  <option>Select a category</option>
                  {expenseData.map((category) => (
                    <option key={category.id} value={category.expenseName}>
                      {category.expenseName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  Amount <span className="myast">*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  name="amount"
                  value={newExpense.amount}
                  onChange={handleExpenseChange}
                />
                <small className="text-muted">
                  Currency will be displayed in {user.currency}
                </small>
              </Form.Group>

              {/* New recommended fields */}
              <Form.Group className="mb-3">
                <Form.Label>
                  Start Date <span className="myast">*</span>
                </Form.Label>
                <Form.Control
                  type="date"
                  name="startDate"
                  value={newExpense.startDate}
                  onChange={handleExpenseChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Alert at % of budget</Form.Label>
                <Form.Control
                  type="number"
                  name="alertThreshold"
                  value={newExpense.alertThreshold}
                  onChange={handleExpenseChange}
                  placeholder="80"
                  min="1"
                  max="100"
                />
                <small className="text-muted">
                  Get notified when budget usage reaches this percentage
                </small>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button className="jiluil" onClick={submitExpense}>
              Add Budget
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={showModal22} onHide={handleCloseModal22}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Budget</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>
                  Title <span className="myast">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="budgetName"
                  value={newBudgetName}
                  onChange={(e) => setNewBudgetName(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  Category <span className="myast">*</span>
                </Form.Label>
                <Form.Select
                  name="category"
                  value={newCategory}
                  onChange={(e) => setNewCategory(e.target.value)}
                >
                  <option>Select a category</option>
                  {expenseData.map((category) => (
                    <option key={category.id} value={category.expenseName}>
                      {category.expenseName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  Amount <span className="myast">*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  name="amount"
                  value={newAmount}
                  onChange={(e) => setNewAmount(e.target.value)}
                />
                <small className="text-muted">
                  Currency will be displayed in {user.currency}
                </small>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  Start Date <span className="myast">*</span>
                </Form.Label>
                <Form.Control
                  type="date"
                  name="startDate"
                  value={newStartDate}
                  onChange={(e) => setNewStartDate(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Alert at % of budget</Form.Label>
                <Form.Control
                  type="number"
                  name="alertThreshold"
                  value={newAlertThreshold}
                  onChange={(e) => setNewAlertThreshold(e.target.value)}
                  min="1"
                  max="100"
                />
                <small className="text-muted">
                  Get notified when budget usage reaches this percentage
                </small>
              </Form.Group>
              <Modal.Footer>
                <button className="jiluil" onClick={(e) => handleEditBudget(e)}>
                  Update
                </button>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Budget</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            This action will permanently delete this budget. This action cannot
            be reversed.
          </Modal.Body>
          <Modal.Footer>
            <button className="jiluil3" onClick={handleClose}>
              Close
            </button>
            <button className="jiluil" onClick={deleteThisBudget}>
              Delete Budget
            </button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};

export default Budget;
