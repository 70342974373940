import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Container,
  Row,
  Form,
  InputGroup,
  Button,
  Table,
  Spinner,
  Alert,
  Modal,
  ButtonGroup,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { useAppContext } from "../../../context/appContext";
import {
  CSVExport,
  ExcelExport,
  PDFExport,
} from "../../../components/PowerQuery/QueryExports";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import "./powerquery.css";
import PrintQuery from "../../../components/PowerQuery/PrintQuery";
import { toast } from "react-toastify";
import {
  FaSave,
  FaFileExport,
  FaPrint,
  FaUserPlus,
  FaEnvelope,
  FaSms,
  FaDownload,
  FaFileAlt,
  FaUserFriends,
  FaListUl,
  FaGolfBall,
} from "react-icons/fa";
import SaveViewQuery from "../../../components/PowerQuery/SaveViewQuery";
import AddMultiple from "../../../components/ContainerGroup/AddMultiple";
import BulkSMSEditor from "../../../components/People/BulkSMSEditor";
import BulkEmailEditor from "../../../components/People/BulkEmailEditor";
import ToastManager from "../../../components/Toast/ToastManager";
import LoadQueryModal from "../../../components/PowerQuery/LoadQueryModal";
import SaveQueryModal from "../../../components/PowerQuery/SaveQueryModal";

const ActionButtons = ({
  results,
  onSave,
  onShowSavedQuery,
  onPrint,
  onAddToGroup,
}) => {
  const { logoutUser } = useAppContext();
  const authFetch = useMemo(() => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL,
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
    });

    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          logoutUser();
        }
        return Promise.reject(error);
      }
    );

    return instance;
  }, [logoutUser]);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [showBulkSMSModal, setShowBulkSMSModal] = useState(false);
  const [queryName, setQueryName] = useState("");
  const [showSaveViewModal, setShowSaveViewModal] = useState(false);

  const [selectedGroup, setSelectedGroup] = useState("");
  const [groups, setGroups] = useState([]); // You'll need to fetch this
  const [exportFormat, setExportFormat] = useState("csv");
  const { user } = useAppContext();
  const [showBulkEmailModal, setShowBulkEmailModal] = useState(false);

  const [queries, setQueries] = useState(null);
  const [error, setError] = useState(null);

  const handleSave = () => {
    onSave(queryName);
    setShowSaveModal(false);
  };

  const handleAddToGroup = () => {
    onAddToGroup(selectedGroup);
    setShowGroupModal(false);
  };

  const handleBulkSMS = () => {
    setShowBulkSMSModal(true);
  };

  const handleBulkEmail = () => {
    setShowBulkEmailModal(true);
  };

  return (
    <div className="d-flex gap-3 mb-4 flex-wrap">
      <ButtonGroup>
        <Button
          variant="outline-primary"
          onClick={onShowSavedQuery}
          className="d-flex align-items-center gap-2"
        >
          <FaSave /> Save Query
        </Button>

        <DropdownButton
          as={ButtonGroup}
          title="Export"
          variant="outline-success"
        >
          <Dropdown.Item>
            <div className="px-2 py-1">
              <ExcelExport results={results} />
            </div>
          </Dropdown.Item>
          <Dropdown.Item>
            <div className="px-2 py-1">
              <PDFExport results={results} />
            </div>
          </Dropdown.Item>
        </DropdownButton>

        <Button
          variant="outline-info"
          onClick={onPrint}
          className="d-flex align-items-center gap-2"
        >
          <FaPrint /> Print
        </Button>
      </ButtonGroup>

      <ButtonGroup>
        <Button
          variant="outline-primary"
          onClick={() => setShowGroupModal(true)}
          className="d-flex align-items-center gap-2"
        >
          <FaUserPlus /> Add to Group
        </Button>

        <DropdownButton
          as={ButtonGroup}
          title="Communication"
          variant="outline-secondary"
        >
          <Dropdown.Item onClick={handleBulkEmail}>
            <FaEnvelope className="me-2" /> Send Email
          </Dropdown.Item>
          <Dropdown.Item onClick={handleBulkSMS}>
            <FaSms className="me-2" /> Send SMS
          </Dropdown.Item>
        </DropdownButton>
        <Button
          variant="outline-primary"
          onClick={() => setShowSaveViewModal(true)}
          className="d-flex align-items-center gap-2"
        >
          <FaGolfBall /> Save View
        </Button>
      </ButtonGroup>

      {/* Add to Group Modal */}
      <Modal show={showGroupModal} onHide={() => setShowGroupModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add to Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Select Group</Form.Label>
            <Form.Select
              value={selectedGroup}
              onChange={(e) => setSelectedGroup(e.target.value)}
            >
              <option value="">Select a group...</option>
              {groups.map((group) => (
                <option key={group.id} value={group.id}>
                  {group.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowGroupModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleAddToGroup}>
            Add to Group
          </Button>
        </Modal.Footer>
      </Modal>
      <SaveViewQuery
        show={showSaveViewModal}
        handleClose={setShowSaveViewModal}
        results={results}
      />
      <AddMultiple
        show={showGroupModal}
        setShow={setShowGroupModal}
        username={results}
      />
      <BulkSMSEditor
        show={showBulkSMSModal}
        handleClose={() => setShowBulkSMSModal(false)}
        userPhone={results.map((r) => r.mmobilePhone).join(",")}
      />
      <BulkEmailEditor
        show={showBulkEmailModal}
        handleClose={() => setShowBulkEmailModal(false)}
        user={user}
        checkedItems={results.map((r) => r.memail).join(",")}
      />
    </div>
  );
};
// Field options configuration
const FIELD_OPTIONS = {
  people: "People",
};

const VALUE_OPTIONS = {
  mfirstName: "First Name",
  mlastName: "Last Name",
  preferredName: "Preferred Name",
  mProfilePicture: "Photo",
  mBirthMonth: "Birthday",
  mAnniversaryMonth: "Anniversary",
  createdAt: "Created",
  updatedAt: "Last Updated",
  memail: "Email",
  mmobilePhone: "Mobile Phone",
  mgender: "Gender",
  mnotes: "Notes",
  isActive: "Account is Active",
  familyId: "Household",
};

const OPERATOR_CONFIGS = {
  text: {
    options: [
      { value: "starts_with", label: "Starts with" },
      { value: "ends_with", label: "Ends with" },
      { value: "contains", label: "Contains" },
      { value: "not_equals", label: "Not Equal" },
      { value: "equals", label: "Equals" },
    ],
    fields: [
      "mfirstName",
      "mlastName",
      "preferredName",
      "memail",
      "mmobilePhone",
    ],
  },
  date: {
    options: [
      { value: "is_set", label: "Is Defined" },
      { value: "on_date", label: "On" },
      { value: "on_or_before", label: "On or Before" },
      { value: "on_or_after", label: "On or After" },
      { value: "is_today", label: "Today" },
    ],
    fields: ["createdAt", "updatedAt"],
  },
  existence: {
    options: [
      { value: "exist_indb", label: "Exist" },
      { value: "not_exist_indb", label: "Not Exist" },
    ],
    fields: ["familyId", "mnotes"],
  },
  object: {
    options: [
      { value: "is_object", label: "Exist" },
      { value: "is_not_object", label: "Not Exist" },
    ],
    fields: ["mProfilePicture"],
  },
  boolean: {
    options: [
      { value: "is_active", label: "True" },
      { value: "is_not_active", label: "False" },
    ],
    fields: ["isActive"],
  },
  month: {
    options: [
      { value: "equals", label: "Equals" },
      { value: "not_equals", label: "Not Equal" },
    ],
    fields: ["mBirthMonth", "mAnniversaryMonth"],
  },
  gender: {
    options: [
      { value: "equals", label: "Is" },
      { value: "not_equals", label: "Not" },
    ],
    fields: ["mgender"],
  },
};

const MONTHS = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
].map((month) => ({
  value: month,
  label: month.charAt(0).toUpperCase() + month.slice(1),
}));

const QueryCondition = ({
  condition,
  conditions,
  onUpdate,
  onDelete,
  onAddCondition,
  showAddCondition,
}) => {
  const getOperatorConfig = (fieldValue) => {
    return Object.entries(OPERATOR_CONFIGS).find(([_, config]) =>
      config.fields.includes(fieldValue)
    )?.[1];
  };

  return (
    <div className="query-condition mb-4">
      <InputGroup className="mb-3">
        <Form.Select
          value={condition.include ? "include" : "exclude"}
          onChange={(e) => onUpdate("include", e.target.value === "include")}
          className="w-auto"
        >
          <option value="include">Include</option>
          <option value="exclude">Exclude</option>
        </Form.Select>

        <Form.Select
          value={condition.field}
          onChange={(e) => onUpdate("field", e.target.value)}
          className="w-auto"
        >
          <option value="">Select Field</option>
          {Object.entries(FIELD_OPTIONS).map(([value, label]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Form.Select>

        {condition.field && (
          <Form.Select
            value={condition.value}
            onChange={(e) => onUpdate("value", e.target.value)}
            className="w-auto"
          >
            <option value="">Select Value</option>
            {Object.entries(VALUE_OPTIONS).map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Form.Select>
        )}

        {condition.value && getOperatorConfig(condition.value) && (
          <Form.Select
            value={condition.operator}
            onChange={(e) => onUpdate("operator", e.target.value)}
            className="w-auto"
          >
            <option value="">Select Operator</option>
            {getOperatorConfig(condition.value).options.map((op) => (
              <option key={op.value} value={op.value}>
                {op.label}
              </option>
            ))}
          </Form.Select>
        )}

        {condition.operator && (
          <>
            {["on_date", "on_or_before", "on_or_after"].includes(
              condition.operator
            ) && (
              <Form.Control
                type="date"
                value={condition.inputValue || ""}
                onChange={(e) => onUpdate("inputValue", e.target.value)}
              />
            )}

            {OPERATOR_CONFIGS.text.fields.includes(condition.value) && (
              <Form.Control
                type="text"
                value={condition.inputValue || ""}
                onChange={(e) => onUpdate("inputValue", e.target.value)}
                placeholder="Enter value..."
              />
            )}

            {condition.value === "mgender" && (
              <Form.Select
                value={condition.inputValue || ""}
                onChange={(e) => onUpdate("inputValue", e.target.value)}
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </Form.Select>
            )}

            {["mBirthMonth", "mAnniversaryMonth"].includes(condition.value) && (
              <Form.Select
                value={condition.inputValue || ""}
                onChange={(e) => onUpdate("inputValue", e.target.value)}
              >
                <option value="">Select Month</option>
                {MONTHS.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.label}
                  </option>
                ))}
              </Form.Select>
            )}
          </>
        )}

        <button
          className="jiluil344d px-3"
          onClick={() => onDelete()}
          disabled={showAddCondition && conditions?.length === 1} // Disable delete if it's the last condition
        >
          ✕
        </button>
      </InputGroup>

      {showAddCondition && (
        <div className="d-flex gap-2 mb-3">
          <Form.Select
            value={condition.parameter}
            onChange={(e) => onUpdate("parameter", e.target.value)}
            className="w-auto"
          >
            <option value="">Add condition</option>
            <option value="AND">AND</option>
            <option value="OR">OR</option>
          </Form.Select>

          <button
            className={
              condition.parameter === "AND"
                ? "jiluil"
                : condition.parameter === "OR"
                ? "jiluil344"
                : "jiluil4"
            }
            // variant={
            //   condition.parameter === "AND"
            //     ? "primary"
            //     : condition.parameter === "OR"
            //     ? "secondary"
            //     : "dark"
            // }
            onClick={onAddCondition}
            disabled={!condition.parameter}
          >
            Add Condition
          </button>
        </div>
      )}
    </div>
  );
};

const ResultsTable = ({ results }) => (
  <div className="results-section mt-4">
    <h5 className="text-primary mb-3">
      {results?.length} record{results?.length !== 1 ? "s" : ""} found
    </h5>

    <Table hover responsive className="bg-light mb-5">
      <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Preferred Name</th>
          <th>Gender</th>
          <th>Marital Status</th>
          <th>Phone Number</th>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>
        {results.map((result, index) => (
          <tr key={index}>
            <td>{result.mfirstName}</td>
            <td>{result.mlastName}</td>
            <td>{result.preferredName}</td>
            <td>{result.mgender}</td>
            <td>{result.mmarital}</td>
            <td>{result.mmobilePhone}</td>
            <td>{result.memail}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
);

const PowerQuery = () => {
  const { logoutUser } = useAppContext();
  const [queries, setQueries] = useState([
    [
      {
        field: "",
        operator: "",
        value: "",
        include: true,
        parameter: "",
        inputValue: "", // Add this to prevent undefined inputValue
      },
    ],
  ]);
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showSavedQueryModal, setShowSavedQueryModal] = useState(false);
  const [showLoadQueryModal, setShowLoadQueryModal] = useState(false);
  const [showSaveQueryModal, setShowSaveQueryModal] = useState(false);
  const [savedQueries, setSavedQueries] = useState([]);
  const [loadingQueries, setLoadingQueries] = useState(true);
  const authFetch = useMemo(() => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL,
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
    });

    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          logoutUser();
        }
        return Promise.reject(error);
      }
    );

    return instance;
  }, [logoutUser]);

  const handleConditionChange = (queryIndex, conditionIndex, key, value) => {
    setQueries(
      queries.map((conditions, qIndex) =>
        qIndex === queryIndex
          ? conditions.map((condition, cIndex) =>
              cIndex === conditionIndex
                ? {
                    ...condition,
                    [key]: value,
                    ...(key === "field" && { operator: "", inputValue: "" }),
                    ...(key === "operator" && { inputValue: "" }),
                  }
                : condition
            )
          : conditions
      )
    );
  };
  const handleSaveClick = () => {
    if (!results) {
      toast.warning("Please perform a search before saving the query");
      return;
    }
    setShowSaveQueryModal(true);
  };

  const handleLoadClick = () => {
    setShowLoadQueryModal(true);
  };
  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const payload = queries.map((group) =>
        group.map(
          ({ field, operator, value, include, inputValue, parameter }) => ({
            field,
            operator,
            value,
            include,
            inputValue,
            parameter,
          })
        )
      );

      const { data } = await authFetch.post("/powerquery", payload);
      setResults(data);
    } catch (error) {
      setError(error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadSavedQueries();
  }, []);

  const loadSavedQueries = async () => {
    try {
      setLoadingQueries(true);
      const response = await authFetch.get("/powerquery/list");
      setSavedQueries(response.data.queries);
    } catch (error) {
      toast.error("Failed to load saved queries");
    } finally {
      setLoadingQueries(false);
    }
  };
  // Consolidated query management functions
  const handleSaveQuery = async (queryData) => {
    try {
      await authFetch.post("/powerquery/save", {
        name: queryData.name,
        description: queryData.description,
        query: queries,
      });
      toast.success("Query saved successfully");
      setShowSavedQueryModal(false);
      // Optionally refresh saved queries list if you're maintaining one
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to save query");
    }
  };

  const handleLoadQuery = (queryConfig) => {
    if (!queryConfig || !Array.isArray(queryConfig)) {
      toast.error("Invalid query configuration");
      return;
    }
    setQueries(queryConfig);
    toast.success("Query loaded successfully");
    setShowSavedQueryModal(false);
  };

  const handleDeleteQuery = async (queryId) => {
    try {
      await authFetch.delete(`/powerquery/${queryId}`);
      toast.success("Query deleted successfully");
      // Optionally refresh saved queries list
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to delete query");
    }
  };

  const handleExport = async (format) => {
    try {
      const response = await authFetch.post(
        "/powerquery/export",
        {
          format,
          data: results,
        },
        { responseType: "blob" }
      );

      // Create download link
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `query-results.${format}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setError("Failed to export data");
    }
  };

  const handleDeleteCondition = (queryIndex, conditionIndex) => {
    setQueries(
      queries.map((group, qIndex) => {
        if (qIndex !== queryIndex) return group;

        // If this is the last condition in the group, reset it instead of removing
        if (group.length === 1) {
          return [
            {
              field: "",
              operator: "",
              value: "",
              include: true,
              parameter: "",
              inputValue: "",
            },
          ];
        }

        // Otherwise remove the condition
        return group.filter((_, cIndex) => cIndex !== conditionIndex);
      })
    );
  };

  const componentRef = useRef();
  const printQueryResults = useReactToPrint({
    content: () => componentRef.current,
  });
  const printMe = () => {
    printQueryResults();
  };

  const handleAddToGroup = async (groupId) => {
    try {
      await authFetch.post("/groups/add-members", {
        groupId,
        members: results.map((r) => r._id),
      });
      // Show success message
    } catch (error) {
      setError(error.response?.data || error.message);
    }
  };
  return (
    <Container fluid>
      <div className="page-header bg-light p-4 mb-4">
        <h2 className="mb-0">Power Query</h2>
        <p className="text-muted mb-0">
          Build complex queries to search and filter your data
        </p>
      </div>

      <Form onSubmit={handleSearch} className="query-builder mx-4">
        {queries.map((conditions, queryIndex) => (
          <div key={queryIndex} className="query-group mb-4">
            {conditions.map((condition, conditionIndex) => (
              <QueryCondition
                key={`${queryIndex}-${conditionIndex}`}
                condition={condition}
                conditions={conditions} // Add this prop
                onUpdate={(key, value) =>
                  handleConditionChange(queryIndex, conditionIndex, key, value)
                }
                onDelete={() =>
                  handleDeleteCondition(queryIndex, conditionIndex)
                }
                onAddCondition={() => {
                  setQueries(
                    queries.map((group, qIndex) =>
                      qIndex === queryIndex
                        ? [
                            ...group,
                            {
                              field: "",
                              operator: "",
                              value: "",
                              include: true,
                              parameter: "",
                              inputValue: "",
                            },
                          ]
                        : group
                    )
                  );
                }}
                showAddCondition={conditionIndex === conditions.length - 1}
              />
            ))}
          </div>
        ))}

        <div className="d-flex justify-content-center gap-3 mb-4">
          <Button
            type="submit"
            variant="primary"
            className="px-5"
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner size="sm" className="me-2" />
                Searching...
              </>
            ) : (
              "Search"
            )}
          </Button>
          {!loadingQueries && savedQueries.length > 0 && (
            <Button
              variant="outline-primary"
              onClick={handleLoadClick}
              className="d-flex align-items-center gap-2"
            >
              <FaSave /> Load Saved Query
            </Button>
          )}
          <Button
            variant="outline-secondary"
            onClick={() => {
              setQueries([
                [
                  {
                    field: "",
                    operator: "",
                    value: "",
                    include: true,
                    parameter: "",
                    inputValue: "",
                  },
                ],
              ]);
              setResults(null); // Clear results when filters are cleared
            }}
            className="d-flex align-items-center gap-2"
          >
            Clear Filter
          </Button>
        </div>
      </Form>

      {error && (
        <Alert variant="danger" className="mx-4 mb-4">
          {error}
        </Alert>
      )}

      {results && (
        <>
          <ActionButtons
            results={results}
            onExport={handleExport}
            onPrint={printMe}
            onAddToGroup={handleAddToGroup}
            onShowSaveQuery={handleSaveClick}
          />
          <ResultsTable results={results} />
        </>
      )}
      <PrintQuery ref={componentRef} results={results} />
      <LoadQueryModal
        show={showLoadQueryModal}
        onHide={() => setShowLoadQueryModal(false)}
        onLoad={handleLoadQuery}
        onDelete={handleDeleteQuery}
        savedQueries={savedQueries}
        onQueriesChange={loadSavedQueries}
      />

      {/* Save Query Modal */}
      <SaveQueryModal
        show={showSaveQueryModal}
        onHide={() => setShowSaveQueryModal(false)}
        onSave={handleSaveQuery}
        currentQuery={queries}
        onQuerySaved={loadSavedQueries}
      />
    </Container>
  );
};

export default PowerQuery;
