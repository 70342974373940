import React, { useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";

const UpdateExpenses = ({
  show,
  setShow,
  refreshWorkflows,
  selectedValue,
  actualfile,
}) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const {
    logoutUser,
    user,
    getExpenseDetails,
    expensedetails,
    getExpense,
    expenses,
  } = useAppContext();
  const [amount, setAmount] = useState("");
  const [expenseName, setExpenseName] = useState("");
  const [expenseDescription, setExpenseDescription] = useState("");
  const [documentFile, setDocumentFile] = useState(null);
  const [error, setError] = useState(false);
  const [uploadButtonText, setUploadButtonText] = useState("Upload Expenses");
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [expenseData, setExpenseData] = useState([]);
  const [expenseCategory, setExpenseCategory] = useState("");
  const [expenseDate, setExpenseDate] = useState("");
  const [selectedDocument, setSelectedDocument] = useState(null);
  console.log("selectedValue", actualfile);
  useEffect(() => {
    if (actualfile) {
      setExpenseName(actualfile.expenseName);
      setExpenseDescription(actualfile.expenseDescription);
      setExpenseCategory(actualfile.expenseCategory);
      setAmount(actualfile.amount);
      setExpenseDate(actualfile.expenseDate);
      setDocumentFile(actualfile.document ? actualfile.document : null);
    }
  }, []);

  //   const getExpenseById = async () => {
  //     try {
  //       const response = await authFetch(
  //         `/giving/expense/selected/${selectedValue._id}`
  //       );

  //       if (response.status === 200 || response.status === 201) {
  //         response.data.expenseName && setExpenseName(response.data.expenseName);
  //         setExpenseDescription(response.data.expenseDescription);
  //         setExpenseCategory(response.data.expenseCategory);
  //         setAmount(response.data.amount);
  //         setExpenseDate(response.data.expenseDate);
  //         setDocumentFile(response.data.documentFile);

  //         setShow(true);
  //       }
  //     } catch (error) {
  //       console.error("Error:", error);
  //     }
  //   };

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (!expenseName || !expenseCategory || !amount || !expenseDate) {
      toast(
        <CustomToastContent
          message="Please fill in all required fields"
          type="error"
        />
      );
      return;
    }

    const expenseData = {
      expenseName,
      expenseDescription,
      expenseCategory,
      amount,
      expenseDate,
    };

    try {
      const response = await authFetch.patch(
        `/giving/expense/selected/${selectedValue._id}`,
        expenseData
      );

      if (response.status === 200 || response.status === 201) {
        setExpenseName("");
        setExpenseDescription("");
        setExpenseCategory("");
        setAmount("");
        setExpenseDate("");
        refreshWorkflows();
        handleClose5();
        toast(
          <CustomToastContent
            message="Expense Updated Successfully"
            type="success"
          />
        );
      } else {
        toast(
          <CustomToastContent
            message="An error occurred while updating the expense"
            type="error"
          />
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getExpense();
  }, []);

  useEffect(() => {
    if (expenses && expenses.length > 0) {
      setExpenseData(expenses);
    }
  }, [expenses]);

  const handleDocumentUpload = async (e) => {
    try {
      const file = e.target.files[0];
      setDocumentFile(file);
      if (!file) {
        //toast("No file selected");
        return;
      }

      if (file.type !== "application/pdf") {
        setError("Only PDF files are allowed");
        return;
      }

      setUploadButtonText(file.name);
      setUploading(true);

      const documentData = new FormData();
      documentData.append("document", file); // Changed key to "document"

      // Sending document as form data to the backend
      try {
        const { data } = await authFetch.patch(
          `/giving/expense/upload-new-expense/${selectedValue._id}`, // Changed endpoint to handle documents
          documentData,
          {
            headers: {
              Accept: "application/json", // Assuming you expect a JSON response
            },
            onUploadProgress: (e) => {
              setProgress(Math.round((100 * e.loaded) / e.total));
            },
          }
        );

        setDocumentFile(data); // Changed state setter to reflect documents
      } catch (uploadErr) {
        console.error("Upload error:", uploadErr);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setUploading(false); // Ensure uploading is set to false in any case
    }
  };
  const handleClose5 = () => setShow(false);

  const openFile = (e) => {
    e.preventDefault();

    const selectedDocument = {
      documentFile: documentFile,
    };

    setSelectedDocument(selectedDocument);
    // setShow3(true);
  };

  const removeDocument = () => {
    try {
      const response = authFetch.patch(
        `/giving/expense/remove-expense-document/${selectedValue._id}`,
        { documentFile }
      );

      if (response.status === 200 || response.status === 201) {
        setDocumentFile(null);
        refreshWorkflows();
        getExpenseDetails();
        toast(
          <CustomToastContent
            message="Document Removed Successfully"
            type="success"
          />
        );
      }
    } catch (error) {
      console.error("Error:", error);

      toast(
        <CustomToastContent
          message="An error occurred while removing the document"
          type="error"
        />
      );
    }
  };
  return (
    <Modal show={show} onHide={handleClose5}>
      <Modal.Header closeButton>
        <Modal.Title>Update Expense</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label htmlFor="inputPassword5">
            Name <span className="myast">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            value={expenseName}
            onChange={(e) => setExpenseName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label className="mt-3" htmlFor="inputPassword5">
            Description (optional)
          </Form.Label>
          <Form.Control
            type="text"
            value={expenseDescription}
            onChange={(e) => setExpenseDescription(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group>
          <Form.Label className="mt-3" htmlFor="inputPassword5">
            Category <span className="myast">*</span>
          </Form.Label>
          <Form.Control
            as="select"
            className="mr-sm-2"
            id="inlineFormCustomSelect"
            custom
            value={expenseCategory}
            onChange={(e) => setExpenseCategory(e.target.value)}
          >
            <option value="0">Select a Category</option>
            {expenseData.map((data, index) => (
              <option key={index} value={data.expenseName}>
                {data.expenseName}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label className="mt-3" htmlFor="inputPassword5">
            Expense Date <span className="myast">*</span>
          </Form.Label>
          <Form.Control
            type="date"
            id="date"
            placeholder="Start Date"
            value={expenseDate}
            className="forminput cnm"
            onChange={(e) => setExpenseDate(e.target.value)}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label className="mt-3" htmlFor="inputPassword5">
            Amount ({user?.currency}) <span className="myast">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </Form.Group>

        {!documentFile && (
          <Form.Group>
            <Form.Label className="mt-3" htmlFor="inputPassword5">
              Attachment
            </Form.Label>
            <Form.Control
              type="file"
              onChange={handleDocumentUpload}
              accept="application/pdf"
              className="lpm"
            />
            <p className="ww233">Note: only PDF can be uploaded.</p>
          </Form.Group>
        )}

        {documentFile && (
          <div className="flex flex-row justify-between mt-4">
            <p>
              {documentFile ? (
                <p
                  style={{
                    cursor: "pointer",
                    color: "var(--secondary-color)",
                  }}
                  onClick={(e) => openFile(e)}
                >
                  {documentFile.key}
                </p>
              ) : (
                <p>No Attachment</p>
              )}
            </p>
            <p
              style={{ cursor: "pointer", color: "var(--secondary-color)" }}
              onClick={removeDocument}
            >
              Remove
            </p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose5}>
          Cancel
        </button>
        <button className="jiluil" onClick={handleUpdate}>
          Update
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateExpenses;
