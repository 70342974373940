import React, { useState, useEffect, useRef } from "react";
import { Select } from "antd";
import { useAppContext } from "../../context/appContext";
import "./addworkflow.css";
import CustomAlert from "../CustomAlert/CustomAlert";
import axios from "axios";
import { Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FaTimes } from "react-icons/fa";
import moment from "moment-timezone";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";
import he from "he";
import { format } from "date-fns";

const EditCalendarStep = ({
  workflowId,
  handleCloseOnDelete,
  workflowName,
  onSuccessfulDelete,
  activeStepId,
  showCalendarEditxTrigger,

  notice,
  setNotice,
  selectedOption,
  setShowNewCalendarTrigger,
}) => {
  const { containers, logoutUser, user, getCompleteMembers, getContainers } =
    useAppContext();
  const quillRef = useRef(null);
  const smsMessageRef = useRef(null);
  const [uniqueSteps, setUniqueSteps] = useState(null);
  const [loading, setLoading] = useState(false);
  const [statusText, setStatusText] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [error, setError] = useState(false);
  const [selectedActionx, setSelectedActionx] = useState("");
  const [showAction, setShowAction] = useState(false);
  const [showFrequency, setShowFrequency] = useState(false);
  const [showTrigger, setShowTrigger] = useState(true);
  const [triggerOn, setTriggerOn] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [selectedFrequency, setSelectedFrequency] = useState("sendOnDate");
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [selectedRepeat, setSelectedRepeat] = useState("");
  const [numberOfRepeats, setNumberOfRepeats] = useState("");
  const [waitDays, setWaitDays] = useState("");
  const [beforeOrAfter, setBeforeOrAfter] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [sendTime, setSendTime] = useState("");
  const [senderName, setSenderName] = useState("");
  const [smsSender, setsmsSender] = useState(user.primaryPhoneNumber);
  const [smsData, setSmsData] = useState("");
  const [smsMessage, setSMSMessage] = useState("");
  const maxChars = 2500;
  const [status, setStatus] = useState(false);
  const [selectedStepId, setSelectedStepId] = useState("");
  const [bgColor, setBgColor] = useState("");
  const [errorx, setErrorx] = useState(false);
  const [editorBgColor, setEditorBgColor] = useState("white");
  const [showActivate, setShowActivate] = useState(false);
  const [showActivate2, setShowActivate2] = useState(false);

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (activeStepId) {
      const getStepData = async () => {
        try {
          setLoading(true);
          const { data } = await authFetch.get(
            `/steps/${workflowId}/${activeStepId}`
          );
          setUniqueSteps(data);
          setStatusText(data.status);
          setError(null);
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };
      getStepData();
    }
  }, [activeStepId, workflowId]);

  useEffect(() => {
    if (uniqueSteps) {
      setSelectedEvent(uniqueSteps.selectedEvent || "");
      setSelectedGroup(uniqueSteps.selectedGroup || "");
      setSelectedActionx(uniqueSteps.selectedAction || "");
      setMessage(he.decode(uniqueSteps?.message || ""));
      setSelectedStepId(uniqueSteps._id || "");
      setEmail(uniqueSteps.replyEmail || "");
      setEmailSubject(uniqueSteps.emailSubject || "");
      setSelectedRepeat(uniqueSteps.selectedRepeat || "");
      setWaitDays(uniqueSteps.waitDay);
      setBeforeOrAfter(uniqueSteps.beforeOrAfter || "");
      setEventTime(uniqueSteps.eventTime || "");
      setSendTime(uniqueSteps.sendTime || "");
      setSenderName(uniqueSteps.senderName || "");
      setSmsData(uniqueSteps.smsData || "");
      setsmsSender(uniqueSteps.smsSender || user.primaryPhoneNumber);
      setEditorBgColor(uniqueSteps.editorBgColor || "");
      setStatus(uniqueSteps.status || false);
      setSMSMessage(uniqueSteps.smsMessage || "");
      setStatusText(uniqueSteps.status || false);
      setTriggerOn(uniqueSteps.triggerOn || "");
      setSelectedStepId(uniqueSteps._id || "");
    }
  }, [uniqueSteps]);

  useEffect(() => {
    getCompleteMembers();
  }, []);

  useEffect(() => {
    getContainers();
  }, []);

  const handleBgColorChange = (selectedOption) => {
    setEditorBgColor(selectedOption.value);
  };

  const handleActionClick = (actionxx) => {
    setSelectedActionx(actionxx);
  };

  const modules = {
    toolbar: {
      container: "#toolbar",
    },
  };

  const colorOptions = [
    { value: "white", label: "White" },
    { value: "purple", label: "Purple" },
    { value: "orange", label: "Orange" },
    { value: "brown", label: "Brown" },
    { value: "black", label: "Black" },
    { value: "lightyellow", label: "Light Yellow" },
    { value: "lightblue", label: "Light Blue" },
    { value: "lightgreen", label: "Light Green" },
    { value: "lightcoral", label: "Light Coral" },
    { value: "lightpink", label: "Light Pink" },
    { value: "lightgray", label: "Light Gray" },
    { value: "lightsalmon", label: "Light Salmon" },
    { value: "lightseagreen", label: "Light Sea Green" },
    { value: "lightcyan", label: "Light Cyan" },
  ];
  const insertPlaceholder = (placeholder) => {
    if (placeholder) {
      const quill = quillRef.current.getEditor();
      const cursorPosition = quill.getSelection()?.index ?? 0;
      quill.insertText(cursorPosition, placeholder);
      quill.setSelection(cursorPosition + placeholder.length);
    }
  };

  const insertAtCursorPosition = (content) => {
    if (!content || !smsMessageRef.current) {
      console.error("Content or smsMessageRef is undefined");
      return;
    }

    const cursorPosition = smsMessageRef.current.selectionStart;
    const textBeforeCursor = smsMessage
      ? smsMessage.slice(0, cursorPosition)
      : "";
    const textAfterCursor = smsMessage ? smsMessage.slice(cursorPosition) : "";
    const newText = textBeforeCursor + content + textAfterCursor;

    if (newText.length <= maxChars) {
      setSMSMessage(newText);
      setTimeout(() => {
        if (smsMessageRef.current) {
          smsMessageRef.current.selectionStart =
            smsMessageRef.current.selectionEnd =
              cursorPosition + content.length;
        }
      }, 0);
    }
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.data.value,
      color: state.data.value === "white" ? "black" : "white",
    }),
  };
  const handleVariableInsert = (event) => {
    const variable = event.target.value;
    if (variable === "Select Variables") return;
    insertAtCursorPosition(variable);
    event.target.value = "Select Variables";
  };

  const handleSMSMessageChange = (e) => {
    setSMSMessage(e.target.value);
  };

  const handleChangex = (value) => {
    setSelectedEvent(value);
    if (error) setError("");
  };

  const handleChange = (value) => {
    setSelectedGroup(value);
    if (error) setError("");
  };

  const handleDescriptionChange = (value) => {
    setMessage(value);
  };

  const handleRadioSelect = (e) => {
    setSelectedFrequency(e.target.value);
  };

  const handleRepeatChange = (e) => {
    setSelectedRepeat(e.target.value);
  };

  const handleFrequencyChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSwitchChange = (e) => {
    setIsSwitchOn(e.target.checked);
  };

  const options = containers.map((container) => ({
    value: container._id,
    label: container.name,
  }));

  const handleActionEmail = async (e, action) => {
    e.preventDefault();

    if (
      !triggerOn ||
      !eventTime ||
      !selectedGroup.length ||
      !selectedActionx ||
      !workflowName ||
      (selectedActionx === "Send Email" &&
        (!message || !email || !emailSubject || !senderName)) ||
      (selectedActionx === "Send Text" && (!smsMessage || !smsSender))
    ) {
      toast(
        <CustomToastContent
          title="Error"
          message="Please fill all fields to proceed!"
        />
      );
      return;
    }

    const datax = {
      churchName: user.churchName,
      churchId: user._id,
      workflow: workflowId,
      selectedEvent: selectedEvent,
      selectedGroup: selectedGroup,
      selectedAction: selectedActionx,
      message: message,
      replyEmail: email,
      emailSubject: emailSubject,
      selectedFrequency: selectedFrequency,
      selectedRepeat: selectedRepeat,
      waitDays: waitDays,
      beforeOrAfter: beforeOrAfter,
      eventTime: eventTime,
      sendTime: sendTime,
      senderName: senderName,
      triggerOn: triggerOn,
      workflowName: workflowName,
      editorBgColor: editorBgColor,
      smsData: smsData,
      smsSender: user.primaryPhoneNumber,
      senderEmail: user.memail,
      numberOfRepeats: numberOfRepeats,
      messageType: "email",
      selectedOption: selectedOption,
      status: action === "activate" ? true : false,
      selectedStepId: selectedStepId,
    };

    try {
      const response = await authFetch.patch(
        `/steps/update/${selectedStepId}`,
        datax
      );

      if (response.status === 201 || response.status === 200) {
        setNotice(true);
        handleCloseOnDelete();
        setShowAction(false);
        onSuccessfulDelete();
        toast(
          <CustomToastContent title="Success" message="Operation Successful!" />
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleActionSMS = async (e, action) => {
    e.preventDefault();

    if (
      !selectedGroup ||
      !selectedActionx ||
      !smsMessage ||
      !selectedFrequency ||
      !workflowName ||
      !eventTime ||
      !triggerOn
    ) {
      toast(
        <CustomToastContent
          title="Error"
          message="Please fill all fields to proceed!"
        />
      );
      return;
    }

    const datax = {
      churchName: user.churchName,
      churchId: user._id,
      workflow: workflowId,
      selectedEvent: selectedEvent,
      selectedGroup: selectedGroup,
      selectedAction: selectedActionx,
      selectedFrequency: selectedFrequency,
      selectedRepeat: selectedRepeat,
      waitDays: waitDays,
      beforeOrAfter: beforeOrAfter,
      eventTime: eventTime,
      sendTime: sendTime,
      senderName: senderName,
      triggerOn: triggerOn,
      workflowName: workflowName,
      smsMessage: smsMessage,
      smsSender: user.primaryPhoneNumber,
      selectedOption: selectedOption,
      messageType: "text",
      status: action === "activate" ? true : false,
      selectedStepId: selectedStepId,
    };

    try {
      const response = await authFetch.patch(
        `/steps/update/sms/${selectedStepId}`,
        datax
      );
      if (response.status === 201 || response.status === 200) {
        setNotice(true);
        handleCloseOnDelete();
        setShowAction(false);
        handleCloseActivation();
        onSuccessfulDelete();
        toast(
          <CustomToastContent title="Success" message="Operation Successful!" />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCloseActivation = () => setShowActivate(false);

  const activateEmail = (e) => {
    e.preventDefault();
    setShowActivate(true);
  };

  const handleCloseActivation2 = () => setShowActivate2(false);

  const activateSMS = (e) => {
    e.preventDefault();
    setShowActivate2(true);
  };

  return (
    <>
      <div
        className="flex items-center justify-end my-3"
        style={{
          fontSize: "1.2rem",
          fontWeight: "bold",
          cursor: "pointer",
          textDecoration: "underline",
        }}
        onClick={() => handleCloseOnDelete()}
      >
        Close <FaTimes className="ml-2" />
      </div>
      <div className="q3323">
        Trigger on {""}
        <Form.Control
          type="date"
          name="days"
          value={triggerOn}
          onChange={(e) => setTriggerOn(e.target.value)}
          className="my-3"
          required
        />{" "}
        at
        <br />
        <Form.Control
          type="time"
          name="time"
          value={eventTime}
          onChange={(e) => setEventTime(e.target.value)}
          className="my-3"
          required
        />
        for everyone in <br />
        <br />
        <Select
          mode="tags"
          style={{ width: "50%" }}
          placeholder="Select group(s)"
          onChange={handleChange}
          value={selectedGroup}
          options={options}
          required
        />{" "}
        group(s)
      </div>

      <div className="q3323">
        <p className="text-bold">Action</p>
        <p className="my-3">
          {" "}
          Here are actions that you can add to your Automated Workflow.
        </p>
        <div className="flex flex-row gap-3">
          <div
            className={`aqwsss ${
              selectedActionx === "Send Email" ? "selected-style" : ""
            }`}
            onClick={() => handleActionClick("Send Email")}
          >
            Send Email
          </div>
          <div
            className={`aqwsss ${
              selectedActionx === "Send Text" ? "selected-style" : ""
            }`}
            onClick={() => handleActionClick("Send Text")}
          >
            Send Text
          </div>
        </div>
      </div>

      <div className="q3323">
        <p className="text-bold">Scheduling Option</p>
        <div>
          <Form.Check
            type="radio"
            label="Send on selected date and time"
            name="formHorizontalRadioss"
            id="formHorizontalRadios1s"
            value="sendOnDate"
            onChange={handleRadioSelect}
            checked={selectedFrequency === "sendOnDate"}
            className="my-3"
          />
        </div>
        <div>
          {selectedActionx === "Send Email" && (
            <>
              <Form>
                <Form.Group
                  className="my-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Sender Name</Form.Label>
                  <Form.Control
                    type="text"
                    autoFocus
                    value={senderName}
                    className="myb-3"
                    onChange={(e) => setSenderName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group
                  className="my-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Reply Email</Form.Label>
                  <Form.Control
                    type="email"
                    autoFocus
                    value={email}
                    className="myb-3"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group
                  className="my-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                    type="text"
                    autoFocus
                    value={emailSubject}
                    onChange={(e) => setEmailSubject(e.target.value)}
                  />
                </Form.Group>

                <div id="toolbar" className="flex items-center">
                  <button className="ql-bold">Bold</button>
                  <button className="ql-italic">Italic</button>
                  <button className="ql-underline">Underline</button>
                  <button className="ql-header" value="1">
                    Header 1
                  </button>
                  <button className="ql-header" value="2">
                    Header 2
                  </button>
                  <button className="ql-list" value="ordered">
                    Ordered List
                  </button>
                  <button className="ql-list" value="bullet">
                    Bullet List
                  </button>
                  <button className="ql-align" value=""></button>
                  <button className="ql-align" value="center"></button>
                  <button className="ql-align" value="right"></button>
                  <button className="ql-align" value="justify"></button>
                  <select className="ql-color">
                    <option value="red">Red</option>
                    <option value="green">Green</option>
                    <option value="blue">Blue</option>
                    <option value="black">Black</option>
                    <option value="yellow">Yellow</option>
                    <option value="orange">Orange</option>
                    <option value="purple">Purple</option>
                    <option value="pink">Pink</option>
                    <option value="gray">Gray</option>
                    <option value="pink">Pink</option>
                    <option value="brown">Brown</option>
                    <option value="grey">Grey</option>
                    <option value="white">White</option>
                  </select>

                  <select
                    className="ql-background"
                    onChange={handleBgColorChange}
                    placeholder="test"
                  >
                    <option value="red">Red</option>
                    <option value="green">Green</option>
                    <option value="blue">Blue</option>
                    <option value="black">Black</option>
                    <option value="yellow">Yellow</option>
                    <option value="orange">Orange</option>
                    <option value="purple">Purple</option>
                    <option value="pink">Pink</option>
                    <option value="gray">Gray</option>
                    <option value="pink">Pink</option>
                    <option value="brown">Brown</option>
                    <option value="grey">Grey</option>
                  </select>
                  <Select
                    options={colorOptions}
                    styles={customStyles}
                    onChange={handleBgColorChange}
                    placeholder="Background"
                  />

                  <select className="ql-size">
                    <option value="small">Small</option>
                    <option selected>Normal</option>
                    <option value="large">Large</option>
                    <option value="huge">Huge</option>
                  </select>
                  <button className="ql-link">Link</button>
                  <button className="ql-image">Image</button>
                </div>

                <ReactQuill
                  ref={quillRef}
                  value={message}
                  defaultValue={message}
                  onChange={handleDescriptionChange}
                  theme="snow"
                  className="react-quill-editor"
                  placeholder="Enter a message here..."
                  modules={modules}
                  style={{ backgroundColor: editorBgColor, minHeight: "400px" }}
                />
              </Form>
            </>
          )}

          {selectedActionx === "Send Text" && (
            <>
              <Form>
                <Form.Group
                  className="my-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Send From</Form.Label>
                  <Form.Control
                    value={user.primaryPhoneNumber}
                    className="mb-3"
                    disabled
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Message:</Form.Label>

                  <Form.Control
                    as="textarea"
                    ref={smsMessageRef}
                    rows={6}
                    required
                    value={smsMessage}
                    onChange={handleSMSMessageChange}
                  />
                </Form.Group>
                <div className="character-count flex items-center justify-end">
                  {`${smsMessage.length} / ${maxChars}`} characters. For better
                  delivery, use a maximum of 2500 characters.
                </div>
              </Form>
            </>
          )}
        </div>
        <div className="flex items-end justify-end my-5">
          {selectedActionx === "Send Text" && (
            <Form.Group className="mb-3">
              <Form.Label>Insert Variable:</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={handleVariableInsert}
                style={{ width: "100%" }}
              >
                <option>Select Variables</option>
                <option value="[FirstName]">First Name</option>
                <option value="[LastName]">Last Name</option>
                <option value="[Email]">Email</option>
                <option value="[PhoneNumber]">Phone Number</option>
              </Form.Select>
            </Form.Group>
          )}
          {selectedActionx === "Send Email" && (
            <div className="flex items-center justify-center gap-3 flex-row w-100">
              <button
                onClick={() => insertPlaceholder(`[FirstName]`)}
                className="jiluil4"
              >
                First Name
              </button>
              <button
                type="button"
                onClick={() => insertPlaceholder(`[LastName]`)}
                className="jiluil4"
              >
                Last Name
              </button>
              <button
                type="button"
                onClick={() => insertPlaceholder(`[Email]`)}
                className="jiluil4"
              >
                Email
              </button>
              <button
                type="button"
                onClick={() => insertPlaceholder(`[PhoneNumber]`)}
                className="jiluil4"
              >
                Phone Number
              </button>
            </div>
          )}
        </div>
        <div className="flex justify-center">
          {selectedActionx === "Send Email" && (
            <div className="flex gap-3">
              <button
                onClick={(e) => handleActionEmail(e, "save")}
                className="jiluil3"
              >
                Save
              </button>
              {statusText ? (
                <button
                  onClick={(e) => activateEmail(e, "deactivate")}
                  className="jiluil"
                  style={{ backgroundColor: "red" }}
                >
                  Deactivate
                </button>
              ) : (
                <button
                  onClick={(e) => activateEmail(e, "activate")}
                  className="jiluil"
                >
                  Activate
                </button>
              )}
            </div>
          )}

          {selectedActionx === "Send Text" && (
            <div className="flex gap-3">
              <button
                onClick={(e) => handleActionSMS(e, "save")}
                className="jiluil3"
              >
                Save
              </button>

              {statusText ? (
                <button
                  onClick={(e) => activateSMS(e, "deactivate")}
                  className="jiluil"
                  style={{ backgroundColor: "red" }}
                >
                  Deactivate
                </button>
              ) : (
                <button
                  onClick={(e) => activateSMS(e, "activate")}
                  className="jiluil"
                >
                  Activate
                </button>
              )}
            </div>
          )}
        </div>
        <p
          style={{ fontSize: "0.8em", textAlign: "center", paddingTop: "20px" }}
        >
          Note: Workflow must be activated to be active.
        </p>
      </div>
      <Modal show={showActivate} onHide={handleCloseActivation}>
        <Modal.Header closeButton>
          <Modal.Title>
            {statusText ? "Deactivate Step" : "Activate Step"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you ready to {statusText ? "deactivate" : "activate"} this step?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleCloseActivation}>
            Close
          </button>
          <button
            className="jiluil"
            style={{ backgroundColor: statusText ? "red" : "" }}
            onClick={
              statusText
                ? (e) => handleActionEmail(e, "deactivate")
                : (e) => handleActionEmail(e, "activate")
            }
          >
            {statusText ? "Deactivate" : "Activate"}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showActivate2} onHide={handleCloseActivation2}>
        <Modal.Header closeButton>
          {statusText ? "Deactivate Step" : "Activate Step"}
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you ready to {statusText ? "deactivate" : "activate"} this step?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleCloseActivation2}>
            Close
          </button>
          <button
            className="jiluil"
            style={{ backgroundColor: statusText ? "red" : "" }}
            onClick={
              statusText
                ? (e) => handleActionSMS(e, "deactivate")
                : (e) => handleActionSMS(e, "activate")
            }
          >
            {statusText ? "Deactivate" : "Activate"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditCalendarStep;
