import React, { useState, useEffect, useMemo } from "react";
import {
  Modal,
  Button,
  Form,
  ListGroup,
  Spinner,
  Alert,
} from "react-bootstrap";
import axios from "axios";
import { FaTrash, FaClock } from "react-icons/fa";
import moment from "moment";
import { toast } from "react-toastify";

import { useAppContext } from "../../context/appContext";

const SaveQueryModal = ({
  show,
  onHide,
  onSave,
  currentQuery,
  onQuerySaved,
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [saving, setSaving] = useState(false);

  const handleSave = async (e) => {
    e.preventDefault();
    if (!name.trim()) return;

    try {
      setSaving(true);
      await onSave({ name, description, query: currentQuery });
      onQuerySaved();
      onHide();
      setName("");
      setDescription("");
    } catch (error) {
      toast.error("Failed to save query");
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Save Current Query</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSave}>
          <Form.Group className="mb-3">
            <Form.Label>Query Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter a name for your query"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description (Optional)</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter a description for your query"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSave}
          disabled={!name.trim() || saving}
        >
          {saving ? (
            <>
              <Spinner size="sm" className="me-2" />
              Saving...
            </>
          ) : (
            "Save Query"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default SaveQueryModal;
