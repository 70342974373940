import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import ToastManager from "./components/Toast/ToastManager";
import { logPageView } from "./analytics";

import {
  Home,
  KnowledgeBaseDetails,
  ProductAccounting,
  KnowledgeBaseView,
  ProductCampus,
  ProductWorkflow,
  ProductUser,
  ProductCenter,
  ProductLearningCenter,
  ProductForms,
  ProductServicePlanner,
  ProductMessaging,
  ProductLivestream,
  ProductMinistry,
  ProductEvents,
  ProductFollowUp,
  ProductFellowship,
  ResetPasswordSent,
  NeedSubmitted,
  Pricing,
  Contact,
  Faq,
  CustomForm,
  Register,
  SessionExpired,
  InvalidSession,
  Login,
  ForgotPassword,
  ResetPassword,
  PasswordReset,
  Error,
  ProtectedRoute,
  NewUserVerify,
  SignupPage,
  Demo,
  GetStartedHome,
  Privacy,
  About,
  PasswordUpdateSuccess,
  Security,
  Terms,
  MultiFactorAuth,
  Careers,
  MobileInfo,
  Payment,
  AddMember,
  Cancel,
  Success,
  OneTimeGiving,
  PayElements,
  SubPayment,
  PaySubscription,
  CustomPay,
  CustomElement,
  SuccessRedirect,
  AddToGroup,
  SignUpPageTrial,
  ProductPeople,
  Need,
  Blog,
  KnowledgeBase,
  BlogView,
  ProductGiving,
  ProductChildren,
  ProductGroup,
  Hero,
  MemberAccountReady,
} from "./pages";
import {
  Calendar,
  PermissionManagement,
  StandardAnnouncements,
  Announcement,
  KnowledgeBaseAdmin,
  PowerQuery,
  Children,
  Livestream,
  EntitlementManually,
  Budget,
  StandardPayElements,
  StandardSubPayment,
  StandardOneTimeGiving,
  GrowthResponses,
  GrowthEdit,
  Classroom,
  Teachers,
  BudgetDashboard,
  OnlineTransactions,
  ContainerGroup,
  ServicePlanner,
  CreateServicePlanner,
  ViewService,
  FollowUp,
  SharedLayout,
  Dashboard,
  MemberList,
  Tags,
  Attendance,
  Profile,
  EditMember,
  FormResponses,
  NewCheckoutPage,
  AddCourse,
  Customgivings,
  LearningHome,
  ViewAllCourses,
  CourseView,
  StandardForm,
  CourseEdit,
  GrowthDisabled,
  LearningMain,
  SingleCourse,
  SingleCourseView,
  UserDashboard,
  GivingHome,
  Batches,
  BatchView,
  Funds,
  Methods,
  People,
  CreatePeople,
  PeopleView,
  PeopleEdit,
  BlogMain,
  TagDetail,
  FolderView,
  UsersManagement,
  RolesManagement,
  ChangePassword,
  SavedView,
  SavedInfo,
  Report,
  OnlineGiving,
  Statements,
  AddFunds,
  PricingList,
  AssignedCourse,
  PaymentSuccess,
  PaymentCancel,
  AccountManagement,
  PrintReport,
  RolesEdit,
  RolesAdd,
  AddMenu,
  Prayers,
  PrayerWall,
  PrayerAchieve,
  StandardHome,
  BulkAction,
  MyLearning,
  FollowUpSettings,
  AllFollowUp,
  AssignedFollowUp,
  Automations,
  MessageCenter,
  VolunteerManagement,
  Checkin,
  CreateVolunteer,
  VolunteerCenter,
  VolunteerSignUp,
  MyVolunteerApp,
  StandardCheckin,
  CheckOut,
  ViewVolunteer,
  StandardGiving,
  Integrations,
  Expenses,
  AddExpense,
  AccountStatement,
  TestimonyAchieve,
  GivingReconciliation,
  ExpenseReconciliation,
  // MyForm,
  // MyFormView,
  // FormSubmitted,
  // FormEntries,
  Forms,
  // EditForms,
  // ViewForms,
  // ContinueEditForm,
  Text,
  WorkFlow,
  WorkFlowView,
  ChurchCampus,
  ChurchEdit,
  GrowthCenter,
  AudioDashboard,
  DocumentDashboard,
  DevelopmentPlan,
  WorkFlowDetails,
  ContainerView,
  PrayerSettings,
  Inbox,
  Outbox,
  DigitalLibrary,
  GrowthTask,
  ReviewPlan,
  StandardUserGiving,
  StandardSuccess,
  CalendarView,
  StandardFormEdit,
  StandardFormSubmitted,
  ReviewForm,
  SummaryFormResponse,
  Testimony,
  TestimonyWall,
  ChatBot,
  CampusDetails,
  BlogDashboard,
  AppAdminDashboard,
  BlogEdit,
  GoToClass,
  TeachersDesk,
  ChildrenLibrary,
  KnowledgeBases,
  Devotional,
  VendorManagement,
  Zoning,
} from "./pages/dashboard";
import { useAppContext } from "./context/appContext";
import { useEffect, useState } from "react";
import Loading from "./components/Loading/Loading";
import MyGrowthPlan from "./pages/dashboard/learning/Development/MyGrowthPlan";

function App() {
  const { user, primaryColor, secondaryColor, accentColor } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [hasStandardRole, setHasStandardRole] = useState(false);
  const [isAppAdmin, setIsAppAdmin] = useState(true);

  useEffect(() => {
    logPageView();
  }, [location]);

  useEffect(() => {
    if (user && user?.role?.length > 0) {
      const standardRoleCheck = user.role.some(
        (role) => role?.roleName === "Standard"
      );
      const roleNames = user.role.map((role) => role.roleName);
      const appAdminRoleCheck = roleNames.includes("App Admin");

      setHasStandardRole(standardRoleCheck);
      setIsAppAdmin(appAdminRoleCheck);
    }
  }, [user]);

  return (
    <>
      <ToastManager />
      <Routes>
        <Route
          path="/auth"
          element={
            <ProtectedRoute>
              <SharedLayout />
            </ProtectedRoute>
          }
        >
          <Route
            index
            element={
              isAppAdmin ? (
                <AppAdminDashboard />
              ) : hasStandardRole ? (
                <StandardHome />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="vendormanagement" element={<VendorManagement />} />
          <Route path="blogedit/:id" element={<BlogEdit />} />
          <Route path="container" element={<ContainerGroup />} />
          <Route path="calendar" element={<Calendar />} />
          <Route path="memberlist" element={<MemberList />} />
          <Route path="attendance" element={<Attendance />} />
          <Route path="tags" element={<Tags />} />
          <Route
            path="givingreconciliation"
            element={<GivingReconciliation />}
          />
          <Route
            path="expensereconciliation"
            element={<ExpenseReconciliation />}
          />
          <Route path="tag-detail/:id" element={<TagDetail />} />
          <Route path="folder-detail/:id" element={<FolderView />} />
          <Route path="growthresponses/:id" element={<GrowthResponses />} />
          <Route path="profile" element={<Profile />} />
          <Route path="childrenlist" element={<Children />} />
          <Route path="gotoclass/:id" element={<GoToClass />} />
          <Route path="teachersdesk/:id" element={<TeachersDesk />} />
          <Route path="editmember" element={<EditMember />} />
          <Route path="addcourse" element={<AddCourse />} />
          <Route path="learninghome" element={<LearningHome />} />
          <Route
            path="standardannouncements"
            element={<StandardAnnouncements />}
          />
          <Route path="devotional" element={<Devotional />} />
          <Route path="zoning" element={<Zoning />} />
          <Route path="viewallcourses" element={<ViewAllCourses />} />
          <Route path="courseview/:slug" element={<CourseView />} />
          <Route path="courseedit/:slug" element={<CourseEdit />} />
          <Route path="learningmain" element={<LearningMain />} />
          <Route path="growthplan" element={<MyGrowthPlan />} />
          <Route path="growthtask/:id" element={<GrowthTask />} />
          <Route path="course/:slug" element={<SingleCourse />} />
          <Route path="powerquery" element={<PowerQuery />} />
          <Route path="singlecourseview/:slug" element={<SingleCourseView />} />
          <Route path="standardsubpayment" element={<StandardSubPayment />} />
          <Route path="userdashboard" element={<UserDashboard />} />
          <Route path="givinghome" element={<GivingHome />} />
          <Route path="people" element={<People />} />
          <Route path="childrenlibrary" element={<ChildrenLibrary />} />
          <Route path="batches" element={<Batches />} />
          <Route path="batchview/:batchId" element={<BatchView />} />
          <Route path="funds" element={<Funds />} />
          <Route path="teachers" element={<Teachers />} />
          <Route path="classroom" element={<Classroom />} />
          <Route path="methods" element={<Methods />} />
          <Route path="livestreaming" element={<Livestream />} />
          <Route path="createpeople" element={<CreatePeople />} />
          <Route path="usersmanagement" element={<UsersManagement />} />
          <Route path="rolesmanagement" element={<RolesManagement />} />
          <Route path="standardform" element={<StandardForm />} />
          <Route path="standardformedit/:id" element={<StandardFormEdit />} />
          <Route path="reviewform/:id" element={<ReviewForm />} />
          <Route path="growthdisabled/:id" element={<GrowthDisabled />} />
          <Route path="knowledgebase" element={<KnowledgeBaseAdmin />} />
          <Route path="knowledgebases/:id" element={<KnowledgeBases />} />
          {/* <Route path="form" element={<MyForm />} /> */}
          <Route path="peopleview/:_id" element={<PeopleView />} />
          <Route path="peopleedit/:_id" element={<PeopleEdit />} />
          <Route path="changepassword" element={<ChangePassword />} />
          <Route path="testimonyachieve" element={<TestimonyAchieve />} />
          <Route path="calendarview" element={<CalendarView />} />
          <Route path="savedview" element={<SavedView />} />
          <Route
            path="addentitlementmanually"
            element={<EntitlementManually />}
          />
          <Route path="savedinfo/:_id" element={<SavedInfo />} />
          <Route path="campus-details/:campus" element={<CampusDetails />} />
          <Route path="reports" element={<Report />} />
          <Route path="online" element={<OnlineGiving />} />
          <Route path="statements" element={<Statements />} />
          <Route path="addfunds" element={<AddFunds />} />
          <Route path="pricinglist" element={<PricingList />} />
          <Route path="paymentsuccess" element={<PaymentSuccess />} />
          <Route path="customgiving" element={<Customgivings />} />

          <Route path="paymentcancel" element={<PaymentCancel />} />
          <Route path="accountmanagement" element={<AccountManagement />} />
          <Route path="chatbot" element={<ChatBot />} />
          <Route path="printreport" element={<PrintReport />} />
          <Route path="rolesedit/:id" element={<RolesEdit />} />
          <Route path="rolesadd" element={<RolesAdd />} />
          <Route path="addmenu" element={<AddMenu />} />
          <Route path="prayers" element={<Prayers />} />
          <Route path="prayerwall" element={<PrayerWall />} />
          <Route path="prayerachieve" element={<PrayerAchieve />} />
          <Route path="standardhome" element={<StandardHome />} />
          <Route path="appdashboard" element={<AppAdminDashboard />} />
          <Route path="permissions" element={<PermissionManagement />} />
          <Route path="bulkaction" element={<BulkAction />} />
          <Route path="mylearning" element={<MyLearning />} />
          <Route path="digitallibrary" element={<DigitalLibrary />} />
          <Route path="formresponses/:id" element={<FormResponses />} />
          <Route path="blogmain" element={<BlogMain />} />
          <Route path="budget" element={<Budget />} />
          <Route path="blogdashboard" element={<BlogDashboard />} />
          <Route
            path="summaryformresponse/:id"
            element={<SummaryFormResponse />}
          />
          {/* <Route path="form-submitted/:id" element={<FormSubmitted />} />
        <Route path="formentries/:id" element={<FormEntries />} /> */}
          <Route path="myfollowup" element={<FollowUp />} />
          <Route path="followupsettings" element={<FollowUpSettings />} />
          <Route path="allfollowup" element={<AllFollowUp />} />
          <Route path="assignedfollowup" element={<AssignedFollowUp />} />
          <Route path="automations" element={<Automations />} />
          <Route path="messagecenter" element={<MessageCenter />} />
          <Route path="volunteermanagement" element={<VolunteerManagement />} />
          <Route path="standardpayelements" element={<StandardPayElements />} />
          <Route path="standardsuccess" element={<StandardSuccess />} />
          <Route
            path="standardonetimegiving"
            element={<StandardOneTimeGiving />}
          />
          <Route path="testimonywall" element={<TestimonyWall />} />
          <Route path="workflowview/:id/:name" element={<WorkFlowView />} />
          <Route path="workflow" element={<WorkFlow />} />
          <Route path="checkin" element={<Checkin />} />
          <Route path="createvolunteer" element={<CreateVolunteer />} />
          <Route path="volunteerhome" element={<VolunteerCenter />} />
          <Route path="assignedcourse" element={<AssignedCourse />} />
          <Route path="volunteersignup/:id" element={<VolunteerSignUp />} />
          <Route path="myvolunteerapp" element={<MyVolunteerApp />} />
          <Route path="standardcheckin" element={<StandardCheckin />} />
          <Route path="checkout" element={<CheckOut />} />
          <Route path="viewvolunteer/:id" element={<ViewVolunteer />} />
          <Route path="standardgiving" element={<StandardGiving />} />
          <Route path="integrations" element={<Integrations />} />
          <Route path="serviceplanner" element={<ServicePlanner />} />
          <Route
            path="createserviceplanner"
            element={<CreateServicePlanner />}
          />
          <Route path="testimony" element={<Testimony />} />
          <Route path="announcement" element={<Announcement />} />
          <Route path="fellowshipsettings" element={<PrayerSettings />} />
          <Route path="accountstatement" element={<AccountStatement />} />
          <Route
            path="containerview/:id/:containername"
            element={<ContainerView />}
          />
          <Route path="viewservice/:id" element={<ViewService />} />
          <Route path="onlinetransactions" element={<OnlineTransactions />} />
          <Route path="mygiving" element={<StandardUserGiving />} />
          <Route path="expenses" element={<Expenses />} />
          <Route path="addexpense" element={<AddExpense />} />
          <Route path="paymentpage" element={<NewCheckoutPage />} />
          <Route path="forms" element={<Forms />} />
          <Route
            path="standardformsubmit"
            element={<StandardFormSubmitted />}
          />
          {/* <Route path="editforms/:id" element={<EditForms />} />
        <Route path="viewforms/:id" element={<ViewForms />} /> */}
          {/* <Route path="continueeditform/:id" element={<ContinueEditForm />} />  */}
          <Route path="text" element={<Text />} />
          <Route path="churchcampus" element={<ChurchCampus />} />
          <Route path="campusedit/:id" element={<ChurchEdit />} />
          <Route path="growthcenter" element={<GrowthCenter />} />
          <Route path="audiodashboard" element={<AudioDashboard />} />
          <Route path="documentdashboard" element={<DocumentDashboard />} />
          <Route path="developmentplan" element={<DevelopmentPlan />} />
          <Route path="growthedit/:id" element={<GrowthEdit />} />
          <Route path="reviewplan/:id" element={<ReviewPlan />} />
          <Route
            path="workflowdetails/:id/:workflowName/:selectedOption"
            element={<WorkFlowDetails />}
          />
          <Route path="inbox" element={<Inbox />} />
          <Route path="outbox" element={<Outbox />} />
        </Route>
        <Route path="/" element={<Home />} />
        <Route
          path="/signup/regular/:size/:features/:billing"
          element={<SignupPage />}
        />
        <Route path="/signuptrial" element={<SignUpPageTrial />} />
        <Route path="/knowledgebase" element={<KnowledgeBase />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword/:id" element={<ResetPassword />} />
        <Route path="/resetpasswordsent" element={<ResetPasswordSent />} />
        <Route path="/passwordreset/:token" element={<PasswordReset />} />
        <Route
          path="/passwordupdatesuccess"
          element={<PasswordUpdateSuccess />}
        />
        <Route path="/contact" element={<Contact />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/memberaccountready" element={<MemberAccountReady />} />
        <Route path="/newuserverify/:token" element={<NewUserVerify />} />
        <Route path="*" element={<Error />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/getstarted" element={<GetStartedHome />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/security" element={<Security />} />
        <Route path="/multifactorauth" element={<MultiFactorAuth />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/mobileinfo" element={<MobileInfo />} />
        <Route path="/payment/:url" element={<Payment />} />
        <Route path="/form/:churchDomain/:formid" element={<CustomForm />} />
        <Route path="/join/:churchurl/:id" element={<AddMember />} />
        {/* <Route path="/join/:churchDomain/:sessionId" element={<AddMember />} /> */}
        <Route path="/session-expired" element={<SessionExpired />} />
        <Route path="/invalid-session" element={<InvalidSession />} />
        <Route path="/stripe/success" element={<Success />} />
        <Route path="/stripe/cancel" element={<Cancel />} />
        <Route path="/onetimegiving" element={<OneTimeGiving />} />
        <Route path="/payelements" element={<PayElements />} />
        <Route path="/subpayment" element={<SubPayment />} />
        <Route path="/paysubscription" element={<PaySubscription />} />
        <Route path="/pay" element={<CustomPay />} />
        <Route path="/payelement" element={<CustomPay />} />
        <Route path="/redirect/success" element={<SuccessRedirect />} />
        <Route path="/group/:churchurl/:groupid" element={<AddToGroup />} />
        <Route path="/product/people" element={<ProductPeople />} />

        <Route path="/church-requirements" element={<Need />} />
        <Route path="/needsubmitted" element={<NeedSubmitted />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blogview/:slug" element={<BlogView />} />
        <Route path="/product/church-giving" element={<ProductGiving />} />

        <Route path="product/groups" element={<ProductGroup />} />
        <Route path="product/children" element={<ProductChildren />} />
        <Route path="product/forms" element={<ProductForms />} />
        <Route
          path="product/church-accounting"
          element={<ProductAccounting />}
        />
        <Route
          path="product/learning-center"
          element={<ProductLearningCenter />}
        />
        <Route
          path="product/service-planner"
          element={<ProductServicePlanner />}
        />
        <Route path="product/messaging" element={<ProductMessaging />} />
        <Route path="product/livestream" element={<ProductLivestream />} />
        <Route path="product/events" element={<ProductEvents />} />
        <Route path="product/followup" element={<ProductFollowUp />} />
        <Route path="product/fellowship" element={<ProductFellowship />} />
        <Route path="product/ministry" element={<ProductMinistry />} />
        <Route path="product/productcampus" element={<ProductCampus />} />
        <Route path="product/productworkflow" element={<ProductWorkflow />} />
        <Route path="product/productuser" element={<ProductUser />} />
        <Route
          path="knowledgebase-details/:category"
          element={<KnowledgeBaseDetails />}
        />
        <Route
          path="product/productmessagecenter"
          element={<ProductCenter />}
        />
        <Route path="knowledgebaseview/:id" element={<KnowledgeBaseView />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/free" element={<Hero />} />
        {/* <Route path="/myformview/:id" element={<MyFormView />} /> */}
      </Routes>
    </>
  );
}

export default App;
