import React from "react";
import { Form } from "react-bootstrap";
import {
  FaCalendarAlt,
  FaUsers,
  FaBell,
  FaCheckCircle,
  FaArrowRight,
} from "react-icons/fa";

const WorkflowTriggerSelector = ({ selectedTrigger, onTriggerSelect }) => {
  const triggers = [
    {
      id: "calendarTrigger",
      title: "Scheduled Date Trigger",
      description:
        "This trigger is set to activate on a specific calendar date for everyone in a group.",
      icon: FaCalendarAlt,
      useCase:
        "Ideal for group-wide announcements or events that need to be communicated on a specific date, such as a holiday or a significant milestone.",
    },
    {
      id: "eventTrigger",
      title: "Personal Event Trigger",
      description:
        "This trigger activates on an individuals specific event date, such as a birthday or anniversary.",
      icon: FaBell,
      useCase:
        "Perfect for sending personalized messages or notifications on special dates for individuals within a group.",
    },
    {
      id: "groupTrigger",
      title: "Group Event Trigger",
      description:
        "This trigger is activated for everyone in a specific group.",
      icon: FaUsers,
      useCase:
        "Useful for sending notifications or updates to all members of a specific group whenever theres a change in membership.",
    },
  ];

  const handleKeyPress = (triggerId, e) => {
    if (e.key === "Enter" || e.key === " ") {
      onTriggerSelect(triggerId);
    }
  };

  return (
    <div className="space-y-4">
      {triggers.map((trigger) => {
        const Icon = trigger.icon;
        const isSelected = selectedTrigger === trigger.id;

        return (
          <div
            key={trigger.id}
            onClick={() => onTriggerSelect(trigger.id)}
            onKeyDown={(e) => handleKeyPress(trigger.id, e)}
            tabIndex={0}
            role="button"
            style={{
              transition: "all 0.3s ease",
              border: `2px solid ${isSelected ? "#3b82f6" : "#e5e7eb"}`,
              backgroundColor: isSelected ? "#eff6ff" : "white",
            }}
            className={`
              p-4 rounded-lg cursor-pointer relative overflow-hidden
              hover:shadow-lg hover:border-blue-300
              group
            `}
          >
            <div className="flex items-start gap-4 relative z-10">
              <div
                className={`
                  p-3 rounded-full 
                  ${isSelected ? "bg-blue-100" : "bg-gray-100"}
                  group-hover:bg-blue-50
                  transition-colors duration-300
                `}
              >
                <Icon
                  className={`
                    text-xl
                    ${isSelected ? "text-blue-500" : "text-gray-500"}
                    group-hover:text-blue-400
                  `}
                />
              </div>

              <div className="flex-1">
                <div className="flex items-center justify-between mb-2">
                  <h3 className="text-lg font-medium flex items-center gap-2">
                    {trigger.title}
                    <FaArrowRight
                      className={`
                        transition-transform duration-300 opacity-0
                        group-hover:opacity-100 group-hover:translate-x-1
                        text-blue-500 text-sm
                      `}
                    />
                  </h3>
                  {/* <Form.Check
                    type="radio"
                    checked={isSelected}
                    onChange={() => onTriggerSelect(trigger.id)}
                    className="custom-radio"
                    style={{ transform: "scale(1.2)" }}
                  /> */}
                </div>

                <p className="text-gray-600 mb-3">{trigger.description}</p>

                <div
                  className={`
                    p-3 rounded-md
                    ${isSelected ? "bg-blue-50" : "bg-gray-50"}
                    group-hover:bg-blue-50
                    transition-colors duration-300
                  `}
                >
                  <p className="text-gray-700 flex items-start gap-2">
                    <FaCheckCircle
                      className={`
                        mt-1 text-sm
                        ${isSelected ? "text-blue-500" : "text-gray-400"}
                        group-hover:text-blue-500
                      `}
                    />
                    <span>
                      <span className="font-semibold">Use Case: </span>
                      {trigger.useCase}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            {/* Background hover effect */}
            <div
              className={`
                absolute inset-0 bg-gradient-to-r from-blue-50/0 via-blue-50/30 to-blue-50/0
                transform -translate-x-full group-hover:translate-x-full
                transition-transform duration-1000
              `}
            />
          </div>
        );
      })}

      <style jsx>{`
        .custom-radio input[type="radio"] {
          cursor: pointer;
          border-color: #3b82f6;
        }

        .custom-radio input[type="radio"]:checked {
          background-color: #3b82f6;
          border-color: #3b82f6;
        }

        @keyframes slide {
          0% {
            transform: translateX(-100%);
          }
          100% {
            transform: translateX(100%);
          }
        }
      `}</style>
    </div>
  );
};

export default WorkflowTriggerSelector;
