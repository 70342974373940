import {
  DISPLAY_ALERT,
  CLEAR_ALERT,
  SETUP_USER_BEGIN,
  SETUP_USER_SUCCESS,
  SETUP_USER_ERROR,
  SETUP_DEMO_BEGIN,
  SETUP_DEMO_SUCCESS,
  SETUP_DEMO_ERROR,
  SETUP_NEED_BEGIN,
  SETUP_NEED_SUCCESS,
  SETUP_NEED_ERROR,
  TOGGLE_SIDEBAR,
  LOGOUT_USER,
  SET_PAGE,
  SET_ROWS_PER_PAGE,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATEMEMBER_USER_BEGIN,
  UPDATEMEMBER_USER_SUCCESS,
  UPDATEMEMBER_USER_ERROR,
  HANDLE_CHANGE,
  GET_LIVESTREAM_BEGIN,
  GET_LIVESTREAM_SUCCESS,
  CLEAR_VALUES,
  CREATE_MEMBER_BEGIN,
  CREATE_MEMBER_SUCCESS,
  CREATE_MEMBER_ERROR,
  CREATE_PRAYER_BEGIN,
  CREATE_PRAYER_SUCCESS,
  CREATE_PRAYER_ERROR,
  GET_MEMBERS_BEGIN,
  GET_MEMBERS_SUCCESS,
  SET_EDIT_MEMBER,
  DELETE_MEMBER_BEGIN,
  DELETE_MEMBER_ERROR,
  DELETE_MEMBER_SUCCESS,
  DELETE_PRAYER_BEGIN,
  DELETE_PRAYER_ERROR,
  DELETE_PRAYER_SUCCESS,
  EDIT_MEMBER_BEGIN,
  EDIT_MEMBER_SUCCESS,
  EDIT_MEMBER_ERROR,
  SHOW_STATS_BEGIN,
  SHOW_STATS_SUCCESS,
  CLEAR_FILTERS,
  CLEAR_FILTERS_MEMBER,
  CHANGE_PAGE,
  GET_CURRENT_USER_BEGIN,
  GET_CURRENT_USER_SUCCESS,
  SEND_MAIL,
  CREATE_EVENT_BEGIN,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_ERROR,
  GET_EVENT_BEGIN,
  GET_EVENT_SUCCESS,
  GET_MAILCHIMP_BEGIN,
  GET_MAILCHIMP_SUCCESS,
  FORGOT_PASSWORD_BEGIN,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD_BEGIN,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  ADD_CONTRIBUTION_BEGIN,
  ADD_CONTRIBUTION_SUCCESS,
  ADD_CONTRIBUTION_ERROR,
  CLOSE_BATCH_BEGIN,
  CLOSE_BATCH_SUCCESS,
  CLOSE_BATCH_ERROR,
  GET_COMPLETE_BEGIN,
  GET_COMPLETE_SUCCESS,
  DELETE_METHOD_BEGIN,
  DELETE_METHOD_ERROR,
  DELETE_METHOD_SUCCESS,
  GET_METHOD_BEGIN,
  GET_METHOD_SUCCESS,
  ADD_METHOD_BEGIN,
  ADD_METHOD_ERROR,
  ADD_METHOD_SUCCESS,
  ADD_FUND_BEGIN,
  ADD_FUND_ERROR,
  ADD_FUND_SUCCESS,
  DELETE_FUND_BEGIN,
  DELETE_FUND_ERROR,
  DELETE_FUND_SUCCESS,
  GET_FUND_BEGIN,
  GET_FUND_SUCCESS,
  GET_BATCHSUMMARY_BEGIN,
  GET_BATCHSUMMARY_SUCCESS,
  GET_BATCHVIEW_BEGIN,
  GET_BATCHVIEW_SUCCESS,
  GET_MEMBERBYID_BEGIN,
  GET_MEMBERBYID_SUCCESS,
  GET_ROOT_FOLDER,
  DELETE_ROLE_BEGIN,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_ERROR,
  GET_FOLDER,
  GET_GROUP_BEGIN,
  GET_GROUP_SUCCESS,
  REMOVE_FROMGROUP_BEGIN,
  REMOVE_FROMGROUP_ERROR,
  REMOVE_FROMGROUP_SUCCESS,
  GET_COMPLETE_USER_BEGIN,
  GET_COMPLETE_USER_SUCCESS,
  SETUP_NEWUSER_BEGIN,
  SETUP_NEWUSER_SUCCESS,
  SETUP_NEWUSER_ERROR,
  UPDATE_USERPASSWORD_BEGIN,
  UPDATE_USERPASSWORD_SUCCESS,
  UPDATE_USERPASSWORD_ERROR,
  DELETE_EVENT_BEGIN,
  DELETE_EVENT_ERROR,
  DELETE_EVENT_SUCCESS,
  UPDATE_EVENT_BEGIN,
  UPDATE_EVENT_ERROR,
  UPDATE_EVENT_SUCCESS,
  CREATE_SAVE_BEGIN,
  CREATE_SAVE_SUCCESS,
  CREATE_SAVE_ERROR,
  GET_SAVED_BEGIN,
  GET_SAVED_SUCCESS,
  GET_VIEWBYID_BEGIN,
  GET_VIEWBYID_SUCCESS,
  GET_MEMBERBYMONTH_BEGIN,
  GET_MEMBERBYMONTH_SUCCESS,
  ADD_ATTENDANCE_BEGIN,
  ADD_ATTENDANCE_SUCCESS,
  ADD_ATTENDANCE_ERROR,
  SHOW_EVENTSSTATS_BEGIN,
  SHOW_EVENTSSTATS_SUCCESS,
  GET_RECENTMEMBER_BEGIN,
  GET_RECENTMEMBER_SUCCESS,
  DELETE_VIEW_BEGIN,
  DELETE_VIEW_ERROR,
  DELETE_VIEW_SUCCESS,
  GET_GIVING_BEGIN,
  GET_GIVING_SUCCESS,
  GET_FUNDALL_BEGIN,
  GET_FUNDALL_SUCCESS,
  GET_PRICING_BEGIN,
  GET_PRICING_SUCCESS,
  CREATE_SUBSCRIPTION_BEGIN,
  CREATE_SUBSCRIPTION_SUCCESS,
  GET_PRICING_BEGIN1,
  GET_PRICING_SUCCESS1,
  STATUS_SUBSCRIPTION_BEGIN,
  STATUS_SUBSCRIPTION_SUCCESS,
  UPDATE_ATTENDANCE_BEGIN,
  UPDATE_ATTENDANCE_SUCCESS,
  UPDATE_ATTENDANCE_ERROR,
  GET_REPORT_BEGIN,
  GET_REPORT_SUCCESS,
  SETUP_NEWPERMISSION_BEGIN,
  SETUP_NEWPERMISSION_SUCCESS,
  SETUP_NEWPERMISSION_ERROR,
  GET_PERMISSION_BEGIN,
  GET_PERMISSION_SUCCESS,
  GET_SAVEDPRAYER_BEGIN,
  GET_SAVEDPRAYER_SUCCESS,
  UPDATE_PRAYER_BEGIN,
  UPDATE_PRAYER_SUCCESS,
  UPDATE_PRAYER_ERROR,
  CREATE_FORM_BEGIN,
  CREATE_FORM_SUCCESS,
  CREATE_FORM_ERROR,
  GET_FORMS_BEGIN,
  GET_FORMS_SUCCESS,
  GET_FORMBYID_BEGIN,
  GET_FORMBYID_SUCCESS,
  UPDATE_FORMRESPONSE_BEGIN,
  UPDATE_FORMRESPONSE_SUCCESS,
  UPDATE_FORMRESPONSE_ERROR,
  DELETE_FORM_BEGIN,
  DELETE_FORM_SUCCESS,
  DELETE_FORM_ERROR,
  UPDATE_FORM_BEGIN,
  UPDATE_FORM_SUCCESS,
  UPDATE_FORM_ERROR,
  UPLOAD_MEMBER_MANY_BEGIN,
  UPLOAD_MEMBER_MANY_SUCCESS,
  UPLOAD_MEMBER_MANY_ERROR,
  UPLOAD_GIVING_MANY_BEGIN,
  UPLOAD_GIVING_MANY_SUCCESS,
  UPLOAD_GIVING_MANY_ERROR,
  ADD_SETTINGS_BEGIN,
  ADD_SETTINGS_SUCCESS,
  ADD_SETTINGS_ERROR,
  GET_SETTINGALL_BEGIN,
  GET_SETTINGALL_SUCCESS,
  DELETE_SETTING_BEGIN,
  DELETE_SETTING_SUCCESS,
  DELETE_SETTING_ERROR,
  DELETE_FOLLOWUP_BEGIN,
  DELETE_FOLLOWUP_SUCCESS,
  DELETE_FOLLOWUP_ERROR,
  GET_ALLFOLLOWUP_BEGIN,
  GET_ALLFOLLOWUP_SUCCESS,
  UPDATE_UPDATESETTINGS_BEGIN,
  UPDATE_UPDATESETTINGS_SUCCESS,
  UPDATE_UPDATESETTINGS_ERROR,
  GET_EVENTBYVOLUNTEER_BEGIN,
  GET_EVENTBYVOLUNTEER_SUCCESS,
  CREATE_SCHEDULE_BEGIN,
  CREATE_SCHEDULE_SUCCESS,
  CREATE_SCHEDULE_ERROR,
  GET_SCHEDULE_BEGIN,
  GET_SCHEDULE_SUCCESS,
  GET_APPLICATION_BEGIN,
  GET_APPLICATION_SUCCESS,
  DELETE_SCHEDULE_BEGIN,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_ERROR,
  DELETE_APPLICATION_BEGIN,
  DELETE_APPLICATION_SUCCESS,
  DELETE_APPLICATION_ERROR,
  UPDATE_SCHEDULE_BEGIN,
  UPDATE_SCHEDULE_SUCCESS,
  UPDATE_SCHEDULE_ERROR,
  GET_SCHEDULEBYID_BEGIN,
  GET_SCHEDULEBYID_SUCCESS,
  GET_APPLICATIONBYID_BEGIN,
  GET_APPLICATIONBYID_SUCCESS,
  GET_CHECKIN_BEGIN,
  GET_CHECKIN_SUCCESS,
  CREATE_CHECKIN_BEGIN,
  CREATE_CHECKIN_SUCCESS,
  CREATE_CHECKIN_ERROR,
  CREATE_SERVICE_BEGIN,
  CREATE_SERVICE_SUCCESS,
  CREATE_SERVICE_ERROR,
  GET_SERVICE_BEGIN,
  GET_SERVICE_SUCCESS,
  DELETE_SERVICE_BEGIN,
  DELETE_SERVICE_SUCCESS,
  DELETE_SERVICE_ERROR,
  UPDATE_SERVICE_BEGIN,
  UPDATE_SERVICE_SUCCESS,
  UPDATE_SERVICE_ERROR,
  GET_SERVICEBYID_BEGIN,
  GET_SERVICEBYID_SUCCESS,
  GET_CONTAINER_BEGIN,
  GET_CONTAINER_SUCCESS,
  GET_CONTAINERBYID_BEGIN,
  GET_CONTAINERBYID_SUCCESS,
  EDIT_CONTAINER_BEGIN,
  EDIT_CONTAINER_SUCCESS,
  GET_TOCONTAINER_BEGIN,
  GET_TOCONTAINER_SUCCESS,
  ADD_QUICKTOCONTAINER_ERROR,
  ADD_QUICKTOCONTAINER_SUCCESS,
  SETUP_CONTAINER_BEGIN,
  SETUP_CONTAINER_SUCCESS,
  BULK_EMAIL_BEGIN,
  BULK_EMAIL_ERROR,
  BULK_EMAIL_SUCCESS,
  BULK_SMS_BEGIN,
  BULK_SMS_ERROR,
  BULK_SMS_SUCCESS,
  ADD_WORKFLOW_BEGIN,
  ADD_WORKFLOW_SUCCESS,
  ADD_WORKFLOW_ERROR,
  GET_WORKFLOW_BEGIN,
  GET_WORKFLOW_SUCCESS,
  UPDATE_CAMPUS_BEGIN,
  UPDATE_CAMPUS_SUCCESS,
  UPDATE_CAMPUS_ERROR,
  CREATE_CAMPUS_BEGIN,
  CREATE_CAMPUS_SUCCESS,
  CREATE_CAMPUS_ERROR,
  ADD_ONLINEGIVING_BEGIN,
  ADD_ONLINEGIVING_SUCCESS,
  ADD_ONLINEGIVING_ERROR,
  GET_ONLINEGIVING_BEGIN,
  GET_ONLINEGIVING_SUCCESS,
  UPDATE_COLOR_BEGIN,
  UPDATE_COLOR_SUCCESS,
  UPDATE_COLOR_ERROR,
  UPDATE_ONLINEGIVING_BEGIN,
  UPDATE_ONLINEGIVING_SUCCESS,
  UPDATE_ONLINEGIVING_ERROR,
  ADD_EXPENSE_BEGIN,
  ADD_EXPENSE_SUCCESS,
  ADD_EXPENSE_ERROR,
  DELETE_EXPENSE_BEGIN,
  DELETE_EXPENSE_SUCCESS,
  DELETE_EXPENSE_ERROR,
  GET_EXPENSE_BEGIN,
  GET_EXPENSE_SUCCESS,
  GET_EXPENSEALL_BEGIN,
  GET_EXPENSEALL_SUCCESS,
  GET_EXPENSEDETAILS_BEGIN,
  GET_EXPENSEDETAILS_SUCCESS,
  CHURCH_ACCOUNT_BEGIN,
  CHURCH_ACCOUNT_SUCCESS,
  CHURCH_ACCOUNT_ERROR,
  ADD_CHILDCHECKIN_BEGIN,
  ADD_CHILDCHECKIN_SUCCESS,
  ADD_CHILDCHECKIN_ERROR,
  RESET_NEWPASSWORD_BEGIN,
  RESET_NEWPASSWORD_SUCCESS,
  RESET_NEWPASSWORD_ERROR,
  ADD_MINISTRY_BEGIN,
  ADD_MINISTRY_SUCCESS,
  ADD_MINISTRY_ERROR,
  DELETE_MINISTRY_BEGIN,
  DELETE_MINISTRY_SUCCESS,
  DELETE_MINISTRY_ERROR,
  ADD_MINISTRY_FOLDER_BEGIN,
  ADD_MINISTRY_FOLDER_SUCCESS,
  ADD_MINISTRY_FOLDER_ERROR,
  DELETE_MINISTRY_FOLDER_BEGIN,
  DELETE_MINISTRY_FOLDER_SUCCESS,
  DELETE_MINISTRY_FOLDER_ERROR,
  UPDATE_MINISTRY_BEGIN,
  UPDATE_MINISTRY_SUCCESS,
  UPDATE_MINISTRY_ERROR,
  UPDATE_MINISTRY_FOLDER_BEGIN,
  UPDATE_MINISTRY_FOLDER_SUCCESS,
  UPDATE_MINISTRY_FOLDER_ERROR,
} from "./actions";

import { initialState } from "./appContext";

const reducer = (state, action) => {
  if (action.type === DISPLAY_ALERT) {
    return {
      ...state,
      showAlert: true,
      alertType: "danger",
      alertText: "Please complete all of the fields before proceeding",
    };
  }
  if (action.type === CLEAR_ALERT) {
    return {
      ...state,
      showAlert: false,
      alertType: "",
      alertText: "",
    };
  }

  if (action.type === SETUP_USER_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === SETUP_USER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      user: action.payload.user,
      memberLocation: action.payload.location,
      showAlert: true,
      alertType: "success",
      alertText: action.payload.alertText,
      token: action.payload.token,
      mfaRequired: action.payload.mfaRequired,
      mfaEmail: action.payload.mfaEmail,
      primaryColor: action.payload.primaryColor,
      secondaryColor: action.payload.secondaryColor,
      accentColor: action.payload.accentColor,
      isTrialExpired: action.payload.isTrialExpired || false,
    };
  }
  if (action.type === SETUP_USER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === ADD_QUICKTOCONTAINER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Members Added to Group!",
    };
  }

  if (action.type === ADD_QUICKTOCONTAINER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === SETUP_DEMO_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === SETUP_DEMO_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: action.payload.alertText,
    };
  }
  if (action.type === SETUP_DEMO_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === SETUP_NEED_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === SETUP_NEED_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",

      userNeedData: action.payload.responsex,
    };
  }
  if (action.type === SETUP_NEED_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === SETUP_NEWUSER_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === SETUP_NEWUSER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: action.payload.alertText,
    };
  }
  if (action.type === SETUP_NEWUSER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === SETUP_NEWPERMISSION_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === SETUP_NEWPERMISSION_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: action.payload.alertText,
    };
  }
  if (action.type === SETUP_NEWPERMISSION_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === BULK_EMAIL_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === BULK_EMAIL_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: action.payload.alertText,
    };
  }
  if (action.type === BULK_EMAIL_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
    };
  }

  if (action.type === BULK_SMS_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === BULK_SMS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: action.payload.alertText,
    };
  }
  if (action.type === BULK_SMS_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === ADD_ATTENDANCE_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === ADD_ATTENDANCE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Attendance Added!",
    };
  }
  if (action.type === ADD_ATTENDANCE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === TOGGLE_SIDEBAR) {
    return {
      ...state,
      showSidebar: !state.showSidebar,
    };
  }
  if (action.type === LOGOUT_USER) {
    return {
      ...initialState,
      userLoading: false,
      user: null,
      token: null,
    };
  }

  if (action.type === GET_COMPLETE_USER_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === GET_COMPLETE_USER_SUCCESS) {
    return {
      ...state,
      isLoading: false,

      completeusers: action.payload.completeusers,

      showAlert: true,
    };
  }

  if (action.type === GET_PERMISSION_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === GET_PERMISSION_SUCCESS) {
    return {
      ...state,
      isLoading: false,

      permission: action.payload.permission,

      showAlert: true,
    };
  }

  if (action.type === UPDATE_USERPASSWORD_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === UPDATE_USERPASSWORD_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      user: action.payload.user,
      token: action.payload.token,
      // userLocation: action.payload.location,
      // memberLocation: action.payload.location,
      showAlert: true,
      alertType: "success",
      alertText: "Password Successfully Updated!",
    };
  }

  if (action.type === UPDATE_USERPASSWORD_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      errorMsg: action.payload.msg,
      alertText: "Please check your current password!",
    };
  }

  if (action.type === CHURCH_ACCOUNT_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === CHURCH_ACCOUNT_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      churchAccountData: action.payload,
    };
  }

  if (action.type === CHURCH_ACCOUNT_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      errorMsg: action.payload.msg,
    };
  }

  if (action.type === UPDATE_COLOR_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === UPDATE_COLOR_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      primaryColor: action.payload.primaryColor,
      secondaryColor: action.payload.secondaryColor,
      accentColor: action.payload.accentColor,

      // userLocation: action.payload.location,
      // memberLocation: action.payload.location,
      showAlert: true,
      alertType: "success",
      alertText: "Color Successfully Updated!",
    };
  }

  if (action.type === UPDATE_COLOR_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      errorMsg: action.payload.msg,
      alertText: "Please try again!",
    };
  }

  if (action.type === UPDATE_EVENT_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === UPDATE_EVENT_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      event: action.payload.event,
      showAlert: true,
      alertType: "success",
      alertText: "Event Password Updated!",
    };
  }

  if (action.type === UPDATE_EVENT_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === UPDATE_SCHEDULE_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === UPDATE_SCHEDULE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      schedule: action.payload.schedule,
      showAlert: true,
      alertType: "success",
      alertText: "Schedule Password Updated!",
    };
  }

  if (action.type === UPDATE_SCHEDULE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === CREATE_CAMPUS_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === CREATE_CAMPUS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      user: action.payload.user,
      token: action.payload.token,
      showAlert: true,
      alertType: "success",
      alertText: "New Campus Created",
    };
  }

  if (action.type === CREATE_CAMPUS_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === UPDATE_USER_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === UPDATE_USER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      user: action.payload.user,
      token: action.payload.token,
      showAlert: true,
      alertType: "success",
      alertText: "User Profile Updated",
    };
  }

  if (action.type === UPDATE_USER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === UPDATEMEMBER_USER_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === UPDATEMEMBER_USER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      userMemberDetails: action.payload.userMemberDetails,
      showAlert: true,
      alertType: "success",
      alertText: "User Profile Updated",
    };
  }

  if (action.type === UPDATEMEMBER_USER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === HANDLE_CHANGE) {
    return {
      ...state,
      page: 1,
      [action.payload.name]: action.payload.value,
      [action.payload.value]: action.payload.value,
    };
  }
  if (action.type === CLEAR_VALUES) {
    const initialState = {
      isEditing: false,
      editMemberId: "",
      firstName: "",
      lastName: "",
      country: "",
      aboutUs: "",
      phoneNumber: "",
      email: "",
      churchName: "",
      memberLocation: state.userLocation,
      memberType: "full-time",
      status: "pending",
      mfirstName: "",
      mlastName: "",
      memail: "",
      mgender: "",
      mstatus: "",
      mmarital: "",
      mage: "",
      mBirthday: "",
      mBirthMonth: "",
      mAnniversaryDay: "",
      mAnniversaryMonth: "",
      mschool: "",
      mnotes: "",
      mgrade: "",
      mfamily: "",
      mhomePhone: "",
      mworkPhone: "",
      memployer: "",
      campus: "",
      mmobilePhone: "",
      mcity: "",
      maddress: "",
      mpostal: "",
      mstate: "",
      preview: "",
      methodName: "",
      image: {},
      dynamicFields: [],
      uploaedButtonText: "upload image",
      giverName: "",
      amount: "",
      category: "",
      paymentMethod: "",
      note: "",
      checkNumber: "",
      accountNumber: "",
      cardDigits: "",
      fname: "",
      lname: "",
      prayerRequest: "",
      prayerSubject: "",
      preferredName: "",
    };

    return {
      ...state,
      ...initialState,
    };
  }
  if (action.type === CREATE_MEMBER_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === CREATE_MEMBER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "New member Created!",
    };
  }
  if (action.type === CREATE_MEMBER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === UPLOAD_MEMBER_MANY_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === UPLOAD_MEMBER_MANY_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Bulk Members Created!",
    };
  }
  if (action.type === UPLOAD_MEMBER_MANY_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === UPLOAD_GIVING_MANY_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === UPLOAD_GIVING_MANY_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Bulk Group Created!",
    };
  }
  if (action.type === UPLOAD_GIVING_MANY_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === UPDATE_UPDATESETTINGS_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === UPDATE_UPDATESETTINGS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Setting Updated!",
    };
  }
  if (action.type === UPDATE_UPDATESETTINGS_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === CREATE_PRAYER_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === CREATE_PRAYER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Prayer Request Submitted!",
    };
  }
  if (action.type === CREATE_PRAYER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === CREATE_SAVE_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === CREATE_SAVE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "View Saved!",
    };
  }
  if (action.type === CREATE_SAVE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === GET_SAVED_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_SAVED_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      savedViews: action.payload.savedViews,
    };
  }

  if (action.type === GET_SAVEDPRAYER_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_SAVEDPRAYER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      prayers: action.payload.prayers,
      totalPrayers: action.payload.totalPrayers,
    };
  }

  if (action.type === GET_MEMBERS_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_MEMBERS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      members: action.payload.members,
      totalMembers: action.payload.totalMembers,
      numOfPages: action.payload.numOfPages,
      allMembers: action.payload.allMembers,
    };
  }

  if (action.type === SET_PAGE) {
    return {
      ...state,
      page: action.payload.page,
    };
  }

  if (action.type === SET_ROWS_PER_PAGE) {
    return {
      ...state,
      rowsPerPage: action.payload.rowsPerPage,
      page: 1,
    };
  }

  if (action.type === GET_COMPLETE_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_COMPLETE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      completemembers: action.payload.completemembers,
    };
  }

  if (action.type === SET_EDIT_MEMBER) {
    const member = state.members.find(
      (member) => member._id === action.payload.id
    );
    const {
      _id,
      mfirstName,
      mlastName,
      dynamicFields,
      memail,
      mgender,
      mstatus,
      mmarital,
      mage,
      campus,
      mBirthday,
      mBirthMonth,
      mAnniversaryDay,
      mAnniversaryMonth,
      mschool,
      mgrade,
      mfamily,
      mhomePhone,
      mworkPhone,
      memployer,
      mmobilePhone,
      mcity,
      mnotes,
      maddress,
      mpostal,
      mstate,
      mProfilePicture,
      churchLogo,
    } = member;
    return {
      ...state,
      isEditing: true,
      editMemberId: _id,
      mfirstName,
      mlastName,
      memail,
      mgender,
      mstatus,
      mmarital,
      dynamicFields,
      mage,
      campus,
      mBirthday,
      mBirthMonth,
      mAnniversaryDay,
      mAnniversaryMonth,
      mschool,
      mnotes,
      mgrade,
      mfamily,
      mhomePhone,
      mworkPhone,
      memployer,
      mmobilePhone,
      mcity,
      maddress,
      mpostal,
      mstate,
      mProfilePicture,
      churchLogo,
    };
  }
  if (action.type === DELETE_MEMBER_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === DELETE_MEMBER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Member(s) Deleted Successfully!",
    };
  }
  if (action.type === DELETE_MEMBER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === DELETE_FORM_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === DELETE_FORM_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Form Deleted!",
    };
  }
  if (action.type === DELETE_FORM_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === UPDATE_FORM_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === UPDATE_FORM_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      form: action.payload.form,
      showAlert: true,
      alertType: "success",
      alertText: "Form Updated!",
    };
  }

  if (action.type === UPDATE_FORM_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === DELETE_PRAYER_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === DELETE_PRAYER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Prayer Deleted!",
    };
  }
  if (action.type === DELETE_PRAYER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === DELETE_VIEW_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === DELETE_VIEW_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "View Deleted!",
    };
  }
  if (action.type === DELETE_VIEW_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === DELETE_ROLE_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === DELETE_ROLE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Role Deleted!",
    };
  }
  if (action.type === DELETE_ROLE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === EDIT_MEMBER_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === EDIT_MEMBER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "member Updated!",
    };
  }
  if (action.type === EDIT_MEMBER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === UPDATE_ATTENDANCE_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === UPDATE_ATTENDANCE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Attendance Updated!",
    };
  }
  if (action.type === UPDATE_ATTENDANCE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === UPDATE_PRAYER_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === UPDATE_PRAYER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Prayer Moved to Acheve!",
    };
  }
  if (action.type === UPDATE_PRAYER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === UPDATE_FORMRESPONSE_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === UPDATE_FORMRESPONSE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Thank you for completing the form!",
      formd: action.payload.data,
    };
  }
  if (action.type === UPDATE_FORMRESPONSE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === SEND_MAIL) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Email sent",
    };
  }
  if (action.type === SHOW_STATS_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }
  if (action.type === SHOW_STATS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      stats: action.payload.stats,
      monthlyNewMembers: action.payload.monthlyNewMembers,
    };
  }

  if (action.type === SHOW_EVENTSSTATS_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }
  if (action.type === SHOW_EVENTSSTATS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      eventStats: action.payload.eventStats,
      monthlyevents: action.payload.monthlyevents,
    };
  }

  if (action.type === CLEAR_FILTERS) {
    return {
      ...state,

      searchByStartDateReport: "",
      searchByEndDateReport: "",
      searchByFundReport: "",
      searchByMethodReport: "",
      searchByBatchReport: "",
      searchByMinAmountReport: "",
      searchByMaxAmountReport: "",
    };
  }

  if (action.type === CLEAR_FILTERS_MEMBER) {
    return {
      ...state,
      search: "",
      searchStatus: "",
      searchMarital: "",
      searchAge: "",
      searchGender: "",
      searchCampus: "",
      searchAnniversary: "",
      searchBirthMonth: "",
      sort: "latest",
    };
  }

  if (action.type === CREATE_EVENT_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === CREATE_EVENT_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "New member Created!",
    };
  }
  if (action.type === CREATE_EVENT_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === CREATE_SCHEDULE_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === CREATE_SCHEDULE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Schedule Created!",
    };
  }
  if (action.type === CREATE_SCHEDULE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === ADD_MINISTRY_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === ADD_MINISTRY_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Ministry Created!",
    };
  }
  if (action.type === ADD_MINISTRY_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === DELETE_MINISTRY_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === DELETE_MINISTRY_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Delete Successful!",
    };
  }
  if (action.type === DELETE_MINISTRY_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === ADD_MINISTRY_FOLDER_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === ADD_MINISTRY_FOLDER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Folder Created!",
    };
  }
  if (action.type === ADD_MINISTRY_FOLDER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === DELETE_MINISTRY_FOLDER_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === DELETE_MINISTRY_FOLDER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Deleted Successfully!",
    };
  }
  if (action.type === DELETE_MINISTRY_FOLDER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === UPDATE_MINISTRY_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === UPDATE_MINISTRY_SUCCESS) {
    return {
      ...state,
      isLoading: false,

      showAlert: true,
      alertType: "success",
      alertText: "Update Successful!",
    };
  }

  if (action.type === UPDATE_MINISTRY_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === UPDATE_MINISTRY_FOLDER_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === UPDATE_MINISTRY_FOLDER_SUCCESS) {
    return {
      ...state,
      isLoading: false,

      showAlert: true,
      alertType: "success",
      alertText: "Update Successful!",
    };
  }

  if (action.type === UPDATE_MINISTRY_FOLDER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === UPDATE_SERVICE_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === UPDATE_SERVICE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      servicePlanner: action.payload.servicePlanner,
      showAlert: true,
      alertType: "success",
      alertText: "Service Updated!",
    };
  }

  if (action.type === UPDATE_SERVICE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === GET_SERVICEBYID_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_SERVICEBYID_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      uniqueservice: action.payload.uniqueservice,
    };
  }

  if (action.type === CREATE_SERVICE_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === CREATE_SERVICE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "New Service Plan Created!",
    };
  }
  if (action.type === CREATE_SERVICE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === CREATE_CHECKIN_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === CREATE_CHECKIN_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Checked In",
    };
  }
  if (action.type === CREATE_CHECKIN_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === CREATE_FORM_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === CREATE_FORM_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "New Form Created!",
    };
  }
  if (action.type === CREATE_FORM_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === DELETE_EVENT_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === DELETE_EVENT_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Event Deleted!",
    };
  }
  if (action.type === DELETE_EVENT_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === DELETE_SCHEDULE_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === DELETE_SCHEDULE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Schedule Deleted!",
    };
  }
  if (action.type === DELETE_SCHEDULE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === DELETE_SERVICE_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === DELETE_SERVICE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Service Deleted!",
    };
  }
  if (action.type === DELETE_SERVICE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === DELETE_APPLICATION_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === DELETE_APPLICATION_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Expression Deleted!",
    };
  }
  if (action.type === DELETE_APPLICATION_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === FORGOT_PASSWORD_BEGIN) {
    return { ...state, showAlert: true };
  }

  if (action.type === FORGOT_PASSWORD_ERROR) {
    return { ...state, showAlert: false };
  }

  if (action.type === RESET_PASSWORD_BEGIN) {
    return { ...state, showAlert: true };
  }

  if (action.type === RESET_PASSWORD_SUCCESS) {
    return {
      ...state,

      showAlert: true,
      alertType: "success",
      alertText: "Password Update Successful! Please Login",
    };
  }

  if (action.type === RESET_PASSWORD_ERROR) {
    return { ...state, showAlert: false };
  }

  if (action.type === RESET_NEWPASSWORD_BEGIN) {
    return { ...state, showAlert: true };
  }

  if (action.type === RESET_NEWPASSWORD_SUCCESS) {
    return {
      ...state,

      showAlert: true,
      alertType: "success",
      alertText: "Profile Successful! Please Login",
    };
  }

  if (action.type === RESET_NEWPASSWORD_ERROR) {
    return { ...state, showAlert: false };
  }

  if (action.type === GET_EVENT_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_EVENT_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      events: action?.payload?.events,
      totalEvents: action?.payload?.totalEvents,
    };
  }

  if (action.type === GET_MAILCHIMP_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_MAILCHIMP_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      mailchimp: action?.payload?.mailchimp,
    };
  }

  if (action.type === GET_SCHEDULE_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_SCHEDULE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      schedule: action?.payload?.schedule,
      totalSchedule: action?.payload?.totalSchedule,
    };
  }

  if (action.type === GET_SERVICE_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_SERVICE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      servicePlanner: action?.payload?.servicePlanner,
      totalServicePlanner: action?.payload?.totalServicePlanner,
    };
  }

  if (action.type === GET_CHECKIN_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_CHECKIN_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      checkin: action?.payload?.checkin,
      totalCheckin: action?.payload?.totalCheckin,
    };
  }

  if (action.type === GET_APPLICATION_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_APPLICATION_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      applicationx: action?.payload?.applicationx,
    };
  }

  if (action.type === GET_EVENTBYVOLUNTEER_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_EVENTBYVOLUNTEER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      eventsByVolunteer: action?.payload?.eventsByVolunteer,
      totalEventsByVolunteer: action?.payload?.totalEventsByVolunteer,
    };
  }

  if (action.type === GET_FORMS_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_FORMS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      forms: action?.payload?.forms,
      totalForms: action?.payload?.totalForms,
    };
  }

  if (action.type === GET_PRICING_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_PRICING_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      prices: action?.payload?.prices,
    };
  }

  if (action.type === GET_PRICING_BEGIN1) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_PRICING_SUCCESS1) {
    return {
      ...state,
      isLoading: false,
      pricesAuth: action?.payload?.pricesAuth,
    };
  }

  if (action.type === CREATE_SUBSCRIPTION_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === CREATE_SUBSCRIPTION_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      stripesub: action?.payload?.stripesub,
    };
  }

  if (action.type === STATUS_SUBSCRIPTION_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === STATUS_SUBSCRIPTION_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      statusSub: action?.payload?.statusSub,
    };
  }

  if (action.type === ADD_ONLINEGIVING_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === ADD_ONLINEGIVING_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      onlineDataPost: action.payload.onlineData,
      showAlert: true,
      alertType: "success",
      alertText: "Payment Details Added",
    };
  }
  if (action.type === ADD_ONLINEGIVING_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === GET_ONLINEGIVING_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_ONLINEGIVING_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      onlineData: action.payload,
    };
  }

  if (action.type === UPDATE_ONLINEGIVING_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === UPDATE_ONLINEGIVING_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: action.payload.alertText,
    };
  }

  if (action.type === UPDATE_ONLINEGIVING_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      errorMsg: action.payload.msg,
      alertText: "Please try again!",
    };
  }

  if (action.type === GET_EXPENSEDETAILS_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_EXPENSEDETAILS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      expensedetails: action.payload,
    };
  }

  ///CHILDREN
  if (action.type === ADD_CHILDCHECKIN_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === ADD_CHILDCHECKIN_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Child Checked In Successfully",
    };
  }
  if (action.type === ADD_CHILDCHECKIN_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  ////WORKFLOW

  if (action.type === ADD_WORKFLOW_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === ADD_WORKFLOW_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Workflow Created",
    };
  }
  if (action.type === ADD_WORKFLOW_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === GET_WORKFLOW_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_WORKFLOW_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      workflow: action.payload,
    };
  }

  if (action.type === GET_CONTAINER_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_CONTAINER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      containers: action.payload.containers,
      tagsCount: action.payload.tagsCount,
    };
  }

  if (action.type === GET_CONTAINERBYID_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_CONTAINERBYID_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      uniquecontainer: action.payload,
    };
  }

  if (action.type === EDIT_CONTAINER_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === EDIT_CONTAINER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      container: action.payload,
      showAlert: true,
      alertType: "success",
      alertText: "Group Successfully Updated!",
    };
  }

  if (action.type === SETUP_CONTAINER_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === SETUP_CONTAINER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      container: action.payload,
      showAlert: true,
      alertType: "success",
      alertText: "Group Successfully Updated!",
    };
  }

  if (action.type === GET_TOCONTAINER_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_TOCONTAINER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      alltagmembers: action.payload,
    };
  }

  if (action.type === GET_LIVESTREAM_BEGIN) {
    return {
      ...state,
      livestreamLoading: true,
    };
  }

  if (action.type === GET_LIVESTREAM_SUCCESS) {
    return {
      ...state,
      livestreamLoading: false,
      videoId: action?.payload?.videoId,
      videoStatus: action?.payload?.videoStatus,
      videoTitle: action?.payload?.videoTitle,
      videoDescription: action?.payload?.videoDescription,
      videoData: action?.payload.videoData,
    };
  }

  if (action.type === ADD_CONTRIBUTION_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === ADD_CONTRIBUTION_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Contribution Added",
      addedData: action.payload.addedData,
    };
  }
  if (action.type === ADD_CONTRIBUTION_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === CLOSE_BATCH_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === CLOSE_BATCH_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Batch Closed",
    };
  }
  if (action.type === CLOSE_BATCH_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === CHANGE_PAGE) {
    return { ...state, page: action.payload.page };
  }
  if (action.type === GET_CURRENT_USER_BEGIN) {
    return { ...state, userLoading: true, showAlert: false };
  }
  if (action.type === GET_CURRENT_USER_SUCCESS) {
    return {
      ...state,
      userLoading: false,
      user: action.payload.user,
    };
  }

  ////////METHOD

  if (action.type === DELETE_METHOD_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === DELETE_METHOD_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Method Deleted!",
    };
  }
  if (action.type === DELETE_METHOD_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === GET_METHOD_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_METHOD_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      allmethods: action.payload.allmethods,
    };
  }

  if (action.type === GET_GIVING_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_GIVING_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      giving: action.payload.giving,
      totalGivings: action.payload.totalGivings,
      numOfPagesGiving: action.payload.numOfPagesGiving,
    };
  }

  if (action.type === GET_REPORT_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_REPORT_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      reports: action.payload.reports,
      totalFiles: action.payload.totalFiles,
      numOfReportPages: action.payload.numOfReportPages,
    };
  }

  if (action.type === GET_FUNDALL_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_FUNDALL_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      funds: action.payload.funds,
    };
  }

  if (action.type === GET_MEMBERBYID_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_MEMBERBYID_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      uniquememberdetails: action.payload.uniquememberdetails,
    };
  }

  if (action.type === GET_RECENTMEMBER_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_RECENTMEMBER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      getRecentMembers: action.payload.getRecentMembers,
    };
  }

  if (action.type === GET_MEMBERBYMONTH_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_MEMBERBYMONTH_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      groupByMonth: action.payload.groupByMonth,
    };
  }

  if (action.type === GET_VIEWBYID_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_VIEWBYID_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      uniqueviewdetails: action.payload.uniqueviewdetails,
    };
  }

  if (action.type === GET_SCHEDULEBYID_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_SCHEDULEBYID_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      uniqueschedule: action.payload.uniqueschedule,
    };
  }

  if (action.type === GET_APPLICATIONBYID_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_APPLICATIONBYID_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      uniqueapplication: action.payload.uniqueapplication,
    };
  }

  if (action.type === GET_FORMBYID_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_FORMBYID_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      uniqueForm: action.payload.uniqueForm,
    };
  }

  if (action.type === ADD_METHOD_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === ADD_METHOD_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Giving Method Successfully",
    };
  }
  if (action.type === ADD_METHOD_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === ADD_SETTINGS_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === ADD_SETTINGS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Settings Created!",
    };
  }
  if (action.type === ADD_SETTINGS_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === GET_SETTINGALL_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_SETTINGALL_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      setting: action.payload.setting,
    };
  }

  if (action.type === GET_ALLFOLLOWUP_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_ALLFOLLOWUP_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      followups: action.payload.followups,
    };
  }

  if (action.type === DELETE_SETTING_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === DELETE_SETTING_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Setting Deleted Successfully!",
    };
  }
  if (action.type === DELETE_SETTING_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  ////delete followup

  if (action.type === DELETE_FOLLOWUP_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === DELETE_FOLLOWUP_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Follow-Up Deleted Successfully!",
    };
  }
  if (action.type === DELETE_FOLLOWUP_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  //////FUND
  if (action.type === DELETE_FUND_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === DELETE_FUND_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Selected Category Deleted Successfully",
    };
  }
  if (action.type === DELETE_FUND_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === GET_FUND_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_FUND_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      allfunds: action.payload.allfunds,
    };
  }

  if (action.type === ADD_FUND_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === ADD_FUND_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "New Giving Category Created!",
    };
  }
  if (action.type === ADD_FUND_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  /////////Expense
  if (action.type === DELETE_EXPENSE_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === DELETE_EXPENSE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Expense Category Deleted Successfully",
    };
  }
  if (action.type === DELETE_EXPENSE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === GET_EXPENSEALL_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_EXPENSEALL_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      funds: action.payload.funds,
    };
  }

  if (action.type === GET_EXPENSE_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_EXPENSE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      expenses: action.payload.allexpenses.expenses,
      totalBudget: action.payload.allexpenses.totalBudget,
      availableBudget: action.payload.allexpenses.availableBudget,
    };
  }

  if (action.type === ADD_EXPENSE_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === ADD_EXPENSE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Expense Category Created!",
    };
  }
  if (action.type === ADD_EXPENSE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  /////////bATCH
  if (action.type === GET_BATCHSUMMARY_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_BATCHSUMMARY_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      batchsummary: action.payload.batchsummary,
    };
  }

  if (action.type === GET_BATCHVIEW_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_BATCHVIEW_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      batchview: action.payload.batchview,
    };
  }

  ////Tags
  if (action.type === GET_ROOT_FOLDER) {
    return {
      ...state,
      isLoading: false,
      rootFolder: action.payload.rootFolder,
    };
  }

  if (action.type === GET_FOLDER) {
    return {
      ...state,
      isLoading: false,
      folderDetail: action.payload.folderDetail,
    };
  }

  if (action.type === GET_GROUP_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_GROUP_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      allgroupmembers: action.payload.allgroupmembers,
    };
  }

  if (action.type === REMOVE_FROMGROUP_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === REMOVE_FROMGROUP_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Removed from Group!",
    };
  }
  if (action.type === REMOVE_FROMGROUP_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  throw new Error(`no such action : ${action.type}`);
};

export default reducer;
