import React, { useState, useEffect, useCallback } from "react";
import { useAppContext } from "../../../context/appContext";
import YouTube from "react-youtube";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AddLivestream from "../../../components/Livestream/AddLivestream";
import axios from "axios";
import { Table, ToastBody } from "react-bootstrap";
import nodd from "../../../assets/newi/nodd.png";
import DeleteLivestream from "../../../components/Livestream/DeleteLivestream";
import EditLivestream from "../../../components/Livestream/EditLivestream";
import PublishLivestream from "../../../components/Livestream/PublishLivestream";
import ViewLivestream from "../../../components/Livestream/ViewLivestream";
import AddThumbnail from "../../../components/Livestream/AddThumbnail";
import AddRecentMessage from "../../../components/Livestream/AddRecentMessage";
import OnboardingTour from "../../../components/OnboardTour/OnboardTour";
import ViewLivestreamRecent from "../../../components/Livestream/ViewLivestreamRecent";
import DeleteLivestreamRecent from "../../../components/Livestream/DeleteLivestreamRecent";
import EditLivestreamRecent from "../../../components/Livestream/EditLivestreamRecent";
import PublishLivestreamRecent from "../../../components/Livestream/PublishLivestreamRecent";
import { format } from "date-fns"; // Add this import

// Custom hook for modal management
const useModal = (initialState = false) => {
  const [isOpen, setIsOpen] = useState(initialState);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  return [isOpen, open, close];
};

const Livestream = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const { logoutUser } = useAppContext();
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);

  const [show22, setShow22] = useState(false);

  const [show33, setShow33] = useState(false);

  const [show44, setShow44] = useState(false);
  //const formatDateString = (date) => formatDate(new Date(date), "MMMM d, yyyy");
  const formatDate = (date) => format(new Date(date), "MMMM d, yyyy");

  // State management
  const [liveStreams, setLiveStreams] = useState([]);
  const [recentStreams, setRecentStreams] = useState([]);
  const [selectedStream, setSelectedStream] = useState(null);
  const [selectedRecentStream, setSelectedRecentStream] = useState(null);

  // Modal states using custom hook
  const [addStreamModal, openAddStream, closeAddStream] = useModal();
  const [thumbnailModal, openThumbnail, closeThumbnail] = useModal();
  const [recentMessageModal, openRecentMessage, closeRecentMessage] =
    useModal();
  const [viewModal, openView, closeView] = useModal();
  const [editModal, openEdit, closeEdit] = useModal();
  const [deleteModal, openDelete, closeDelete] = useModal();
  const [publishModal, openPublish, closePublish] = useModal();
  const [recentViewModal, openRecentView, closeRecentView] = useModal();
  const [recentEditModal, openRecentEdit, closeRecentEdit] = useModal();
  const [recentDeleteModal, openRecentDelete, closeRecentDelete] = useModal();
  const [recentPublishModal, openRecentPublish, closeRecentPublish] =
    useModal();
  const [livestream, setLiveStream] = useState([]);
  const [pickedRecentLiveStream, setPickedRecentLiveStream] = useState({});
  const [selectedLivestream, setSelectedLivestream] = useState(null);
  const [show55, setShow55] = useState(false);
  const [selectedRecentLiveStream, setSelectedRecentLiveStream] = useState([]);

  const loadLiveStream = async () => {
    const { data } = await authFetch.get(`/livestream/recent/data`);
    setSelectedRecentLiveStream(data);
  };
  // Data fetching
  const fetchLiveStreams = async () => {
    try {
      const { data } = await authFetch.get("/livestream");
      setLiveStreams(data);
    } catch (error) {
      console.error("Error fetching livestreams:", error);
    }
  };
  const unpublishedLivestream = () => {
    loadCourse();
  };

  const publishLivestreamRecent = () => {
    loadLiveStream();
  };

  const unpublishLivestreamRecent = () => {
    loadLiveStream();
  };
  const fetchRecentStreams = async () => {
    try {
      const { data } = await authFetch.get("/livestream/recent/data");
      setRecentStreams(data);
    } catch (error) {
      console.error("Error fetching recent streams:", error);
    }
  };

  useEffect(() => {
    fetchLiveStreams();
    fetchRecentStreams();
  }, []);

  const uploadSuccessFunc = () => {
    loadCourse();
  };
  const loadCourse = async () => {
    const { data } = await authFetch.get(`/livestream`);
    setLiveStream(data);
  };

  const uploadSuccessFuncxx = () => {
    loadLiveStream();
  };

  const publishedLivestream = () => {
    loadCourse();
  };

  // Helper functions

  const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()
    );
  };

  const isStreamActive = (endTime) => {
    return new Date(endTime) > new Date();
  };

  // Action handlers
  const handleStreamAction = (stream, action) => {
    setSelectedStream(stream);
    action();
  };

  const handleRecentStreamAction = (stream, action) => {
    setSelectedRecentStream(stream);
    action();
  };

  // UI Components
  const ActionButtons = ({ item, isRecent }) => (
    <div className="d-flex gap-2 justify-content-end">
      <button
        className="jiluil"
        size="sm"
        onClick={() =>
          handleStreamAction(item, isRecent ? openRecentView : openView)
        }
      >
        View
      </button>
      <button
        className="jiluil3"
        size="sm"
        onClick={() =>
          handleStreamAction(item, isRecent ? openRecentEdit : openEdit)
        }
      >
        Edit
      </button>
      <button
        //variant={item.published ? "outline-warning" : "outline-success"}
        className={item.published ? "jiluil344" : "jiluil4"}
        size="sm"
        onClick={() =>
          handleStreamAction(item, isRecent ? openRecentPublish : openPublish)
        }
      >
        {item.published ? "Unpublish" : "Publish"}
      </button>
      <button
        className="jiluil344d"
        size="sm"
        onClick={() =>
          handleStreamAction(item, isRecent ? openRecentDelete : openDelete)
        }
      >
        Delete
      </button>
    </div>
  );

  const StreamsTable = ({ streams, isRecent }) => (
    <Table hover responsive className="bg-light mt-4">
      <thead>
        <tr>
          {isRecent ? (
            <>
              <th>Posted Date</th>
              <th>Title</th>
            </>
          ) : (
            <>
              <th>Event Title</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Status</th>
            </>
          )}
          <th className="text-end">Actions</th>
        </tr>
      </thead>
      <tbody>
        {streams.length > 0 ? (
          streams.map((stream) => (
            <tr key={stream._id} className="align-middle">
              {isRecent ? (
                <>
                  <td>{formatDate(stream.createdAt)}</td>
                  <td>{toTitleCase(stream.title)}</td>
                </>
              ) : (
                <>
                  <td>{stream.eventTitle}</td>
                  <td>{stream.startTime}</td>
                  <td>{stream.endTime}</td>
                  <td>
                    {isStreamActive(stream.endTime)
                      ? "Livestream in progress"
                      : "Livestream ended"}
                  </td>
                </>
              )}
              <td>
                <ActionButtons item={stream} isRecent={isRecent} />
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={isRecent ? 3 : 5} className="text-center py-4">
              {isRecent
                ? "There are no recent livestream listings."
                : "There are no ongoing livestreams"}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );

  return (
    <Container fluid className="mt-5">
      {/* <OnboardingTour /> */}

      <Row className="mb-4">
        <Col>
          <h1 className="h3">Livestream Management</h1>
        </Col>
      </Row>

      <Row className="mb-4 gap-2">
        <Col xs="auto">
          <button className="jiluil" onClick={openRecentMessage}>
            Post Recent Message
          </button>
        </Col>
        <Col xs="auto">
          <button className="jiluil3" onClick={openThumbnail}>
            Add Livestream Thumbnail
          </button>
        </Col>
        <Col xs="auto">
          <button className="jiluil4" onClick={openAddStream}>
            Start a Livestream
          </button>
        </Col>
      </Row>

      <section className="mb-5 mt-5">
        <h2 className="h4 mb-3">In Progress Livestreams</h2>
        <StreamsTable streams={liveStreams} isRecent={false} />
      </section>

      <section>
        <h2 className="h4 mb-3">Recent Livestreams</h2>
        <StreamsTable streams={recentStreams} isRecent={true} />
      </section>

      {/* Modals */}
      <AddLivestream
        show={addStreamModal}
        onHide={closeAddStream}
        uploadSuccessFunc={fetchLiveStreams}
      />
      <DeleteLivestream
        show={show2}
        setShow={setShow2}
        uploadSuccessFunc={uploadSuccessFunc}
        selectedLivestream={selectedLivestream}
      />
      <EditLivestream
        show={show3}
        setShow={setShow3}
        uploadSuccessFunc={uploadSuccessFunc}
        selectedLivestream={selectedLivestream}
      />

      <PublishLivestream
        show={show4}
        setShow={setShow4}
        uploadSuccessFunc={uploadSuccessFunc}
        selectedLivestream={selectedLivestream}
        publishLivestream={publishedLivestream}
        unpublishLivestream={unpublishedLivestream}
      />
      <ViewLivestream
        show={show5}
        setShow={setShow5}
        uploadSuccessFunc={uploadSuccessFunc}
        selectedLivestream={selectedLivestream}
      />
      <AddThumbnail
        show={show6}
        setShow={setShow6}
        uploadSuccessFunc={uploadSuccessFunc}
      />
      <AddRecentMessage
        show={show7}
        setShow={setShow7}
        uploadSuccessFuncxx={uploadSuccessFuncxx}
      />

      <ViewLivestreamRecent
        show={show55}
        setShow={setShow55}
        selectedRecentLiveStream={pickedRecentLiveStream}
      />

      <DeleteLivestreamRecent
        show={show22}
        setShow={setShow22}
        uploadSuccessFuncxx={uploadSuccessFuncxx}
        pickedRecentLiveStream={pickedRecentLiveStream}
      />
      <EditLivestreamRecent
        show={show33}
        setShow={setShow33}
        uploadSuccessFuncxx={uploadSuccessFuncxx}
        pickedRecentLiveStream={pickedRecentLiveStream}
      />
      <PublishLivestreamRecent
        show={show44}
        setShow={setShow44}
        uploadSuccessFuncxx={uploadSuccessFuncxx}
        pickedRecentLiveStream={pickedRecentLiveStream}
        publishLivestreamRecent={publishLivestreamRecent}
        unpublishLivestreamRecent={unpublishLivestreamRecent}
      />
    </Container>
  );
};

export default Livestream;
