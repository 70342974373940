// EventModal.js
import React, { useState, useContext, useEffect } from "react";
import { Container, Form, Modal } from "react-bootstrap";
import { FaBars, FaTimes, FaCheck } from "react-icons/fa";
import { useAppContext } from "../../../context/appContext";
import GlobalContext from "../../../calendarcontext/GlobalContext";
import moment from "moment";
import DeleteCalendar from "../../../components/Calendar/DeleteCalendar";
import axios from "axios";
import EventForm from "../../../components/Calendar/EventForm";

const LABEL_COLORS = [
  "brown",
  "orange",
  "green",
  "blue",
  "gray",
  "purple",
  "red",
  "black",
];

export default function EventModal() {
  const { logoutUser, getEvents } = useAppContext();
  const { setShowEventModal, selectedEvent, daySelected } =
    useContext(GlobalContext);
  const { createEvent, updateEvent, user } = useAppContext();
  const [isRecurring, setIsRecurring] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Auth fetch setup
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  // Add this utility function

  // Events state
  const [events, setEventxs] = useState([]);
  const [minDate, setMinDate] = useState(moment().format("YYYY-MM-DD"));

  // Fetch events on mount
  useEffect(() => {
    getEventById();
  }, []);

  useEffect(() => {
    setMinDate(moment().format("YYYY-MM-DD"));
  }, []);

  const getEventById = async (e) => {
    e?.preventDefault();
    try {
      const { data } = await authFetch.get(`/events`);
      if (data) {
        setEventxs(data.events);
      }
    } catch (error) {
      logoutUser();
    }
  };

  // Form state
  const [formData, setFormData] = useState({
    title: selectedEvent?.eventTitle || "",
    eventDay: selectedEvent?.eventDay || "",
    startTime: selectedEvent?.eventStartTime || "",
    endTime: selectedEvent?.eventEndTime || "",
    endDate: selectedEvent?.eventEndDate || "",
    description: selectedEvent?.eventDescription || "",
    eventMode: selectedEvent?.eventMode || "",
    eventLink: selectedEvent?.eventLink || "",
    eventLocation: selectedEvent?.eventLocation || "",
    volunteers: selectedEvent?.eventVolunteers || false,
    visible: selectedEvent?.eventVisible || false,
    label: selectedEvent?.eventLabel || LABEL_COLORS[0],
    allDay: selectedEvent?.eventAllDay || false,
    repeat: selectedEvent?.eventRepeat || "",
    // Recurring event fields
    pattern: selectedEvent?.eventPattern || "",
    occurence: selectedEvent?.eventOccurence || "",
    endAfter: selectedEvent?.eventEndAfter || "",
    endBy: selectedEvent?.eventEndBy || "",
    reStartTime: selectedEvent?.reStartTime || "",
    reEndTime: selectedEvent?.reEndTime || "",
    reEventStartDate: selectedEvent?.reEventStartDate || "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate required fields
    if (!formData.title.trim()) {
      alert("Please enter an event title");
      return;
    }

    if (!formData.eventDay) {
      alert("Please select a date");
      return;
    }

    // For online events, require link. For in-person events, require location
    if (formData.eventMode === "OnlineOnly" && !formData.eventLink.trim()) {
      alert("Please enter an event link for online events");
      return;
    }

    if (formData.eventMode === "InPerson" && !formData.eventLocation.trim()) {
      alert("Please enter an event location for in-person events");
      return;
    }

    const currentEvent = {
      eventTitle: formData.title.trim(),
      eventDay: formData.eventDay, // Don't use valueOf() here
      eventStartTime: formData.startTime,
      eventEndDate: formData.endDate,
      eventEndTime: formData.endTime,
      eventAllDay: formData.allDay || false,
      eventRepeat: formData.repeat || "",
      eventLocation: formData.eventLocation.trim(),
      eventVolunteers: formData.volunteers || false,
      eventMode: formData.eventMode,
      eventDescription: formData.description.trim(),
      churchName: user.churchName,
      eventLink: formData.eventLink.trim(),
      eventLabel: formData.label,
      eventVisible: formData.visible || false,
      dashBoardDay: daySelected,
      eventId: selectedEvent ? selectedEvent.eventId : Date.now(),
      realId: selectedEvent ? selectedEvent._id : null,
      // Recurring event fields
      reStartTime: formData.reStartTime || "",
      reEndTime: formData.reEndTime || "",
      eventPattern: formData.pattern || "",
      reEventStartDate: formData.reEventStartDate || "",
      eventOccurence: formData.occurence || "",
      eventEndAfter: formData.endAfter || "",
      eventEndBy: formData.endBy || "",
    };

    if (selectedEvent?._id && selectedEvent?.eventId) {
      updateEvent({ currentEvent }, selectedEvent.eventId);
      getEventById();
    } else {
      createEvent({ currentEvent });
      getEventById();
    }

    setShowEventModal(false);
  };

  return (
    <div className="asasfefwef">
      <Container fluid>
        <div className="eventmode">
          <Form onSubmit={handleSubmit}>
            <header className="eventhead">
              <div className="headprev">
                <FaBars style={{ color: "#666" }} />
              </div>
              <button
                onClick={() => setShowEventModal(false)}
                className="asasfefwef1"
                type="button"
                aria-label="Close modal"
              >
                <FaTimes />
              </button>
            </header>

            <div className="event1x">
              <p
                style={{
                  fontSize: "130%",
                  fontWeight: "600",
                  marginBottom: "0",
                }}
              >
                {isRecurring ? "Recurring Event" : "Edit Event"}
              </p>
              {!selectedEvent && (
                <button
                  type="button"
                  onClick={() => setIsRecurring(!isRecurring)}
                  className="ewfwwwefh"
                >
                  {isRecurring ? "Make Single Event" : "Make Recurring Event"}
                </button>
              )}
            </div>

            <EventForm
              formData={formData}
              setFormData={setFormData}
              isRecurring={isRecurring}
              minDate={minDate}
              labelColors={LABEL_COLORS}
            />

            <footer className="weweeeee">
              <button
                type="button"
                className="ewfwwwefh"
                onClick={() => setShowEventModal(false)}
              >
                Cancel
              </button>
              <button type="submit" className="ewfwwwefh22">
                {selectedEvent ? "Update" : "Create"}
              </button>
              {selectedEvent && (
                <button
                  type="button"
                  className="ewfwwwefh"
                  onClick={() => setShowDeleteModal(true)}
                >
                  Delete
                </button>
              )}
            </footer>
          </Form>
        </div>

        <DeleteCalendar
          show3={showDeleteModal}
          setShow3={setShowDeleteModal}
          selectedValue={selectedEvent}
          setShowEventModal={setShowEventModal}
        />
      </Container>
    </div>
  );
}
