// QueryExports.js
import React, { useRef } from "react";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  pdf,
} from "@react-pdf/renderer";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import { FaFileAlt, FaFileExport, FaDownload } from "react-icons/fa";

const styles = StyleSheet.create({
  page: { padding: 30 },
  header: { fontSize: 18, marginBottom: 20, textAlign: "center" },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
  },
  tableRow: { flexDirection: "row" },
  tableCol: { width: "20%", borderStyle: "solid", borderWidth: 1, padding: 5 },
  tableCell: { margin: "auto", fontSize: 10 },
});

const formatData = (results) => {
  return results.map((row) => ({
    FirstName: row.mfirstName,
    LastName: row.mlastName,
    Email: row.memail,
    Gender: row.mgender,
    MaritalStatus: row.mmarital,
  }));
};

const PDFDocument = ({ results }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.header}>Query Results</Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          {["First Name", "Last Name", "Email", "Gender", "Marital Status"].map(
            (header) => (
              <View key={header} style={styles.tableCol}>
                <Text style={styles.tableCell}>{header}</Text>
              </View>
            )
          )}
        </View>
        {formatData(results).map((row, i) => (
          <View key={i} style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row.FirstName}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row.LastName}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row.Email}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row.Gender}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row.MaritalStatus}</Text>
            </View>
          </View>
        ))}
      </View>
    </Page>
  </Document>
);

export const PDFExport = ({ results }) => {
  const handlePDFDownload = async () => {
    const blob = await pdf(<PDFDocument results={results} />).toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "query-results.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="d-flex align-items-center gap-2">
      <FaDownload className="me-2" />
      <button onClick={handlePDFDownload} className="btn btn-link p-0">
        Export to PDF
      </button>
    </div>
  );
};

export const CSVExport = ({ results }) => {
  const csvLinkRef = React.useRef();

  const headers = [
    { label: "First Name", key: "mfirstName" },
    { label: "Last Name", key: "mlastName" },
    { label: "Email", key: "memail" },
    { label: "Gender", key: "mgender" },
    { label: "Marital Status", key: "mmarital" },
  ];

  const formatData = (data) =>
    data.map(({ mfirstName, mlastName, memail, mgender, mmarital }) => ({
      mfirstName,
      mlastName,
      memail,
      mgender,
      mmarital,
    }));

  return (
    <div className="d-flex align-items-center gap-2 mt-4">
      <FaFileAlt className="me-2" />
      <CSVLink
        data={formatData(results)}
        headers={headers}
        filename="query-results.csv"
        ref={csvLinkRef}
      >
        Export to CSV
      </CSVLink>
    </div>
  );
};
export const ExcelExport = ({ results }) => {
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(formatData(results));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Results");
    XLSX.writeFile(wb, "query-results.xlsx");
  };

  return (
    <div className="d-flex align-items-center gap-2">
      <FaFileExport className="me-2" />
      <button onClick={exportToExcel} className="btn btn-link p-0">
        Export to Excel
      </button>
    </div>
  );
};
