import React, { useReducer, useContext, useEffect, useState } from "react";
import reducer from "./reducer";

import axios from "axios";
import {
  DISPLAY_ALERT,
  SET_PAGE,
  SET_ROWS_PER_PAGE,
  CLEAR_ALERT,
  SETUP_USER_BEGIN,
  SETUP_USER_SUCCESS,
  GET_LIVESTREAM_BEGIN,
  GET_LIVESTREAM_SUCCESS,
  SETUP_DEMO_BEGIN,
  SETUP_DEMO_SUCCESS,
  SETUP_DEMO_ERROR,
  SETUP_USER_ERROR,
  SETUP_NEED_BEGIN,
  SETUP_NEED_SUCCESS,
  SETUP_NEED_ERROR,
  TOGGLE_SIDEBAR,
  LOGOUT_USER,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATEMEMBER_USER_BEGIN,
  UPDATEMEMBER_USER_SUCCESS,
  UPDATEMEMBER_USER_ERROR,
  HANDLE_CHANGE,
  CLEAR_VALUES,
  CREATE_MEMBER_BEGIN,
  CREATE_MEMBER_SUCCESS,
  CREATE_MEMBER_ERROR,
  GET_MEMBERS_BEGIN,
  GET_MEMBERS_SUCCESS,
  SET_EDIT_MEMBER,
  DELETE_MEMBER_BEGIN,
  DELETE_MEMBER_ERROR,
  EDIT_MEMBER_BEGIN,
  EDIT_MEMBER_SUCCESS,
  EDIT_MEMBER_ERROR,
  SHOW_STATS_BEGIN,
  SHOW_STATS_SUCCESS,
  CLEAR_FILTERS,
  CLEAR_FILTERS_MEMBER,
  CHANGE_PAGE,
  GET_CURRENT_USER_BEGIN,
  GET_CURRENT_USER_SUCCESS,
  DELETE_MEMBER_SUCCESS,
  CREATE_EVENT_BEGIN,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_ERROR,
  GET_EVENT_BEGIN,
  GET_EVENT_SUCCESS,
  GET_MAILCHIMP_BEGIN,
  GET_MAILCHIMP_SUCCESS,
  FORGOT_PASSWORD_BEGIN,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD_BEGIN,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  ADD_CONTRIBUTION_BEGIN,
  ADD_CONTRIBUTION_SUCCESS,
  ADD_CONTRIBUTION_ERROR,
  GET_COMPLETE_BEGIN,
  GET_COMPLETE_SUCCESS,
  DELETE_METHOD_BEGIN,
  DELETE_METHOD_ERROR,
  GET_METHOD_BEGIN,
  GET_METHOD_SUCCESS,
  ADD_METHOD_BEGIN,
  ADD_METHOD_ERROR,
  ADD_METHOD_SUCCESS,
  ADD_FUND_BEGIN,
  ADD_FUND_ERROR,
  ADD_FUND_SUCCESS,
  DELETE_FUND_BEGIN,
  DELETE_FUND_ERROR,
  DELETE_FUND_SUCCESS,
  GET_FUND_BEGIN,
  GET_FUND_SUCCESS,
  GET_BATCHSUMMARY_BEGIN,
  GET_BATCHSUMMARY_SUCCESS,
  GET_BATCHVIEW_BEGIN,
  GET_BATCHVIEW_SUCCESS,
  GET_MEMBERBYID_BEGIN,
  GET_MEMBERBYID_SUCCESS,
  GET_ROOT_FOLDER,
  DELETE_ROLE_BEGIN,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_ERROR,
  GET_FOLDER,
  GET_GROUP_BEGIN,
  GET_GROUP_SUCCESS,
  REMOVE_FROMGROUP_BEGIN,
  REMOVE_FROMGROUP_ERROR,
  REMOVE_FROMGROUP_SUCCESS,
  GET_COMPLETE_USER_BEGIN,
  GET_COMPLETE_USER_SUCCESS,
  SETUP_NEWUSER_BEGIN,
  SETUP_NEWUSER_ERROR,
  SETUP_NEWUSER_SUCCESS,
  UPDATE_USERPASSWORD_BEGIN,
  UPDATE_USERPASSWORD_SUCCESS,
  UPDATE_USERPASSWORD_ERROR,
  DELETE_EVENT_BEGIN,
  DELETE_EVENT_ERROR,
  DELETE_EVENT_SUCCESS,
  UPDATE_EVENT_BEGIN,
  UPDATE_EVENT_ERROR,
  UPDATE_EVENT_SUCCESS,
  CREATE_SAVE_BEGIN,
  CREATE_SAVE_SUCCESS,
  CREATE_SAVE_ERROR,
  GET_SAVED_BEGIN,
  GET_SAVED_SUCCESS,
  GET_VIEWBYID_BEGIN,
  GET_VIEWBYID_SUCCESS,
  GET_MEMBERBYMONTH_BEGIN,
  GET_MEMBERBYMONTH_SUCCESS,
  ADD_ATTENDANCE_BEGIN,
  ADD_ATTENDANCE_SUCCESS,
  ADD_ATTENDANCE_ERROR,
  SHOW_EVENTSSTATS_BEGIN,
  SHOW_EVENTSSTATS_SUCCESS,
  ADD_CHILDCHECKIN_BEGIN,
  ADD_CHILDCHECKIN_SUCCESS,
  ADD_CHILDCHECKIN_ERROR,
  GET_RECENTMEMBER_BEGIN,
  GET_RECENTMEMBER_SUCCESS,
  DELETE_VIEW_BEGIN,
  DELETE_VIEW_ERROR,
  DELETE_VIEW_SUCCESS,
  GET_GIVING_BEGIN,
  GET_GIVING_SUCCESS,
  GET_FUNDALL_BEGIN,
  GET_FUNDALL_SUCCESS,
  GET_PRICING_BEGIN,
  GET_PRICING_SUCCESS,
  CREATE_SUBSCRIPTION_BEGIN,
  CREATE_SUBSCRIPTION_SUCCESS,
  GET_PRICING_BEGIN1,
  GET_PRICING_SUCCESS1,
  STATUS_SUBSCRIPTION_BEGIN,
  STATUS_SUBSCRIPTION_SUCCESS,
  UPDATE_ATTENDANCE_BEGIN,
  UPDATE_ATTENDANCE_SUCCESS,
  UPDATE_ATTENDANCE_ERROR,
  GET_REPORT_BEGIN,
  GET_REPORT_SUCCESS,
  SETUP_NEWPERMISSION_BEGIN,
  SETUP_NEWPERMISSION_SUCCESS,
  SETUP_NEWPERMISSION_ERROR,
  GET_PERMISSION_BEGIN,
  GET_PERMISSION_SUCCESS,
  CREATE_PRAYER_BEGIN,
  CREATE_PRAYER_SUCCESS,
  CREATE_PRAYER_ERROR,
  GET_SAVEDPRAYER_BEGIN,
  GET_SAVEDPRAYER_SUCCESS,
  DELETE_PRAYER_BEGIN,
  DELETE_PRAYER_SUCCESS,
  DELETE_PRAYER_ERROR,
  UPDATE_PRAYER_BEGIN,
  UPDATE_PRAYER_SUCCESS,
  UPDATE_PRAYER_ERROR,
  CREATE_FORM_BEGIN,
  CREATE_FORM_SUCCESS,
  CREATE_FORM_ERROR,
  GET_FORMS_BEGIN,
  GET_FORMS_SUCCESS,
  GET_FORMBYID_BEGIN,
  GET_FORMBYID_SUCCESS,
  UPDATE_FORMRESPONSE_BEGIN,
  UPDATE_FORMRESPONSE_SUCCESS,
  UPDATE_FORMRESPONSE_ERROR,
  DELETE_FORM_BEGIN,
  DELETE_FORM_SUCCESS,
  DELETE_FORM_ERROR,
  UPLOAD_MEMBER_MANY_BEGIN,
  UPLOAD_MEMBER_MANY_SUCCESS,
  UPLOAD_MEMBER_MANY_ERROR,
  UPLOAD_GIVING_MANY_BEGIN,
  UPLOAD_GIVING_MANY_SUCCESS,
  UPLOAD_GIVING_MANY_ERROR,
  ADD_SETTINGS_ERROR,
  ADD_SETTINGS_SUCCESS,
  ADD_SETTINGS_BEGIN,
  GET_SETTINGALL_BEGIN,
  GET_SETTINGALL_SUCCESS,
  DELETE_SETTING_BEGIN,
  DELETE_SETTING_SUCCESS,
  DELETE_SETTING_ERROR,
  DELETE_FOLLOWUP_BEGIN,
  DELETE_FOLLOWUP_ERROR,
  DELETE_FOLLOWUP_SUCCESS,
  GET_ALLFOLLOWUP_BEGIN,
  GET_ALLFOLLOWUP_SUCCESS,
  UPDATE_UPDATESETTINGS_BEGIN,
  UPDATE_UPDATESETTINGS_SUCCESS,
  UPDATE_UPDATESETTINGS_ERROR,
  GET_EVENTBYVOLUNTEER_BEGIN,
  GET_EVENTBYVOLUNTEER_SUCCESS,
  CREATE_SCHEDULE_BEGIN,
  CREATE_SCHEDULE_SUCCESS,
  CREATE_SCHEDULE_ERROR,
  GET_SCHEDULE_BEGIN,
  GET_SCHEDULE_SUCCESS,
  GET_APPLICATION_BEGIN,
  GET_APPLICATION_SUCCESS,
  DELETE_SCHEDULE_BEGIN,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_ERROR,
  DELETE_APPLICATION_BEGIN,
  DELETE_APPLICATION_SUCCESS,
  DELETE_APPLICATION_ERROR,
  UPDATE_SCHEDULE_BEGIN,
  UPDATE_SCHEDULE_SUCCESS,
  UPDATE_SCHEDULE_ERROR,
  GET_SCHEDULEBYID_BEGIN,
  GET_SCHEDULEBYID_SUCCESS,
  GET_APPLICATIONBYID_BEGIN,
  GET_APPLICATIONBYID_SUCCESS,
  CREATE_CHECKIN_BEGIN,
  CREATE_CHECKIN_SUCCESS,
  CREATE_CHECKIN_ERROR,
  RESET_NEWPASSWORD_BEGIN,
  RESET_NEWPASSWORD_SUCCESS,
  RESET_NEWPASSWORD_ERROR,
  GET_CHECKIN_BEGIN,
  GET_CHECKIN_SUCCESS,
  CREATE_SERVICE_BEGIN,
  CREATE_SERVICE_SUCCESS,
  CREATE_SERVICE_ERROR,
  GET_SERVICE_BEGIN,
  GET_SERVICE_SUCCESS,
  DELETE_SERVICE_BEGIN,
  DELETE_SERVICE_SUCCESS,
  DELETE_SERVICE_ERROR,
  UPDATE_SERVICE_BEGIN,
  UPDATE_SERVICE_SUCCESS,
  UPDATE_SERVICE_ERROR,
  GET_SERVICEBYID_BEGIN,
  GET_SERVICEBYID_SUCCESS,
  UPDATE_FORM_BEGIN,
  UPDATE_FORM_SUCCESS,
  UPDATE_FORM_ERROR,
  GET_CONTAINER_BEGIN,
  GET_CONTAINER_SUCCESS,
  GET_CONTAINERBYID_BEGIN,
  GET_CONTAINERBYID_SUCCESS,
  EDIT_CONTAINER_BEGIN,
  EDIT_CONTAINER_SUCCESS,
  GET_TOCONTAINER_BEGIN,
  GET_TOCONTAINER_SUCCESS,
  ADD_QUICKTOCONTAINER_ERROR,
  ADD_QUICKTOCONTAINER_SUCCESS,
  SETUP_CONTAINER_BEGIN,
  SETUP_CONTAINER_SUCCESS,
  BULK_EMAIL_BEGIN,
  BULK_EMAIL_ERROR,
  BULK_EMAIL_SUCCESS,
  BULK_SMS_BEGIN,
  BULK_SMS_ERROR,
  BULK_SMS_SUCCESS,
  ADD_WORKFLOW_BEGIN,
  ADD_WORKFLOW_SUCCESS,
  ADD_WORKFLOW_ERROR,
  GET_WORKFLOW_BEGIN,
  GET_WORKFLOW_SUCCESS,
  UPDATE_CAMPUS_BEGIN,
  UPDATE_CAMPUS_SUCCESS,
  UPDATE_CAMPUS_ERROR,
  CREATE_CAMPUS_BEGIN,
  CREATE_CAMPUS_SUCCESS,
  CREATE_CAMPUS_ERROR,
  ADD_ONLINEGIVING_BEGIN,
  ADD_ONLINEGIVING_SUCCESS,
  ADD_ONLINEGIVING_ERROR,
  GET_ONLINEGIVING_BEGIN,
  GET_ONLINEGIVING_SUCCESS,
  UPDATE_COLOR_BEGIN,
  UPDATE_COLOR_SUCCESS,
  UPDATE_COLOR_ERROR,
  UPDATE_ONLINEGIVING_BEGIN,
  UPDATE_ONLINEGIVING_SUCCESS,
  UPDATE_ONLINEGIVING_ERROR,
  ADD_EXPENSE_BEGIN,
  ADD_EXPENSE_SUCCESS,
  ADD_EXPENSE_ERROR,
  DELETE_EXPENSE_BEGIN,
  DELETE_EXPENSE_SUCCESS,
  DELETE_EXPENSE_ERROR,
  GET_EXPENSE_BEGIN,
  GET_EXPENSE_SUCCESS,
  GET_EXPENSEALL_BEGIN,
  GET_EXPENSEALL_SUCCESS,
  GET_EXPENSEDETAILS_BEGIN,
  GET_EXPENSEDETAILS_SUCCESS,
  CHURCH_ACCOUNT_BEGIN,
  CHURCH_ACCOUNT_SUCCESS,
  CHURCH_ACCOUNT_ERROR,
  ADD_MINISTRY_BEGIN,
  ADD_MINISTRY_SUCCESS,
  ADD_MINISTRY_ERROR,
  DELETE_MINISTRY_BEGIN,
  DELETE_MINISTRY_SUCCESS,
  DELETE_MINISTRY_ERROR,
  ADD_MINISTRY_FOLDER_BEGIN,
  ADD_MINISTRY_FOLDER_SUCCESS,
  ADD_MINISTRY_FOLDER_ERROR,
  DELETE_MINISTRY_FOLDER_BEGIN,
  DELETE_MINISTRY_FOLDER_SUCCESS,
  DELETE_MINISTRY_FOLDER_ERROR,
  UPDATE_MINISTRY_BEGIN,
  UPDATE_MINISTRY_SUCCESS,
  UPDATE_MINISTRY_ERROR,
  UPDATE_MINISTRY_FOLDER_BEGIN,
  UPDATE_MINISTRY_FOLDER_SUCCESS,
  UPDATE_MINISTRY_FOLDER_ERROR,
} from "./actions";
import { BASE_URL } from "../config";

const token = JSON.stringify(localStorage.getItem("token"));
const user = JSON.stringify(localStorage.getItem("user"));
const initialState = {
  userLoading: true,
  user: user ? JSON.parse(user) : null,
  errorMsg: null,
  token: token,
  mfaRequired: "",
  isTrialExpired: false,
  mfaEmail: "",
  primaryColor: "#243060",
  secondaryColor: "#fe7064",
  accentColor: "#fed970",
  updatedUserColor: {},
  isLoading: false,
  showAlert: false,
  alertText: "",
  alertType: "",
  userLocation: "",
  showSidebar: false,
  isEditing: false,
  editMemberId: "",
  firstName: "",
  lastName: "",
  churchName: "",
  email: "",
  country: "",
  aboutUs: "",
  phoneNumber: "",
  members: [],
  allMembers: [],
  completemembers: [],
  completeusers: [],
  memberdetails: [],
  uniquememberdetails: null, // []
  uniqueschedule: [],
  uniqueservice: [],
  uniqueapplication: [],
  uniqueviewdetails: [],
  userMemberDetails: [],
  getRecentMembers: [],
  recentlyAddedMembers: [],
  groupByMonth: [],
  allmethods: [],
  allfunds: [],
  expenses: [],
  totalBudget: 0,
  availableBudget: 0,
  batchview: [],
  expensedetails: [],
  batchsummary: [],
  totalMembers: 0,
  numOfPages: 1,

  rowsPerPage: 20,
  page: 1,
  stats: {},
  monthlyNewMembers: [],
  monthlyevents: [],
  eventStats: {},
  search: "",
  searchStatus: "",
  searchCampus: "",
  searchMarital: "",
  searchGender: "",
  searchBirthMonth: "",
  searchAnniversaryMonth: "",

  searchByFundReport: [],
  searchByStartDateReport: "",
  searchByEndDateReport: "",
  searchByMethodReport: [],
  searchByMembersReport: "",

  searchByBatchReport: "",
  searchByMinAmountReport: "",
  searchByMaxAmountReport: "",
  reports: [],
  totalFiles: 0,
  numOfPagesFiles: 1,

  statementUser: "",
  statementStartDate: "",
  statementEndDate: "",
  specificUserName: "",

  sortData: "",
  pageNumber: 1,
  prices: [],
  pricesAuth: [],
  giving: [],
  totalGivings: 0,
  checkin: [],
  totalCheckin: 0,
  numOfPagesGiving: 1,
  forms: [],
  totalForms: 0,
  sort: "",
  mfirstName: "",
  mlastName: "",
  memail: "",
  campus: "",
  mgender: "",
  mstatus: "",
  accountStatus: "",
  mmarital: "",
  mage: "",
  mBirthday: "",
  mBirthMonth: "",
  mAnniversaryDay: "",
  mAnniversaryMonth: "",
  mschool: "",
  primaryColor: "",
  secondaryColor: "",
  accentColor: "",
  mgrade: "",
  mfamily: "",
  mhomePhone: "",
  mworkPhone: "",
  dynamicFields: [],
  memployer: "",
  mmobilePhone: "",
  mcity: "",
  maddress: "",
  mfamilydata: "",
  mpostal: "",
  mstate: "",
  mnotes: "",
  mProfilePicture: "",
  churchLogo: "",
  mydata: "",
  eventTitle: "",
  eventDescription: "",
  eventId: "",
  eventLabel: "",
  eventDay: "",
  events: [],
  mailchimp: [],
  totalEvents: 0,
  eventsByVolunteer: [],
  totalEventsByVolunteer: 0,
  schedule: [],
  servicePlanner: [],
  applicationx: [],
  totalSchedule: 0,
  totalServicePlanner: 0,
  giverName: "",
  amount: "",
  category: "",
  paymentMethod: "",
  note: "",
  checkNumber: "",
  accountNumber: "",
  batchId: "",
  batch: "",
  allTagsData: [],
  memberIdOnly: {},
  rootFolder: [],
  folderDetail: [],
  allgroupmembers: [],
  savedViews: [],
  funds: null,
  stripesub: "",
  statusSub: [],
  permission: [],
  prayers: [],
  totalPrayers: 0,
  uniqueForm: [],
  uniquefollowup: [],
  formd: {},
  setting: [],
  followups: [],
  containers: [],
  tagsCount: [],
  uniquecontainer: null,
  alltagmembers: [],
  containerStatus: "",
  workflow: [],
  addedData: [],
  sk: "",
  pk: "",
  onlineData: null,
  onlineDataPost: null,
  churchAccountData: null,
  userNeedData: null,
  livestreamLoading: false,
  videoId: "",
  videoStatus: "",
  videoTitle: "",
  videoDescription: "",
  videoData: "",
};

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [allMenu, setAllMenu] = useState([]);
  const [userColor, setUserColor] = useState({
    primaryColor: "#007bff",
    secondaryColor: "#6c757d",
  });

  const getMenu = async () => {
    const { data } = await authFetch.get("/menu");
    setAllMenu(data?.menus);
  };

  // axios
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });
  // request

  // response

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const displayAlert = () => {
    dispatch({ type: DISPLAY_ALERT });
    clearAlert();
  };

  const clearAlert = () => {
    setTimeout(() => {
      dispatch({ type: CLEAR_ALERT });
    }, 3000);
  };
  // const [receivedColor, setReceivedColor] = useState(null);

  //first admin user after payment
  const setupUser = async ({ currentUser, alertText }) => {
    dispatch({ type: SETUP_USER_BEGIN });

    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: BASE_URL + "/auth/login",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        data: currentUser,
      };

      const response = await axios.request(config);
      const {
        user,
        token,
        primaryColor,
        secondaryColor,
        accentColor,
        mfaEmail,
        trialExpired, // Add this from backend response
      } = response.data;

      getCurrentUser();

      if (response.data.mfaRequired && response.data.mfaEmail) {
        dispatch({
          type: SETUP_USER_SUCCESS,
          payload: {
            mfaRequired: true,
            mfaEmail: mfaEmail,
            alertText,
            primaryColor,
            secondaryColor,
            accentColor,
          },
        });
      } else if (trialExpired) {
        // Handle trial expiry
        dispatch({
          type: SETUP_USER_SUCCESS,
          payload: {
            user,
            token,
            alertText: "Trial expired. Please upgrade your account.",
            primaryColor,
            secondaryColor,
            accentColor,
            isTrialExpired: true,
          },
        });
      } else {
        dispatch({
          type: SETUP_USER_SUCCESS,
          payload: {
            user,
            token,
            alertText,
            primaryColor,
            secondaryColor,
            accentColor,
            isTrialExpired: false,
          },
        });
      }
    } catch (error) {
      console.error(error);
      const errorMessage =
        error?.response?.data?.message ||
        error.message ||
        "Invalid login credentials. Please try again.";
      dispatch({
        type: SETUP_USER_ERROR,
        payload: {
          msg: errorMessage,
        },
      });
    }

    clearAlert();
  };

  const registerMember = async ({ currentUser, endPoint, alertText }) => {
    dispatch({ type: SETUP_USER_BEGIN });

    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BACKEND_URL + "/auth/register",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        data: currentUser,
      };

      const response = await axios.request(config);

      const { user, token } = response.data;

      dispatch({
        type: SETUP_USER_SUCCESS,
        payload: { user, token, alertText },
      });
      console.log("Email data", response.status);
      if (response.status === 200 || response.status === 201) {
        console.log("Email data", currentUser);
        await authFetch.post("/email/registermember", currentUser);
      }
    } catch (error) {
      console.error(error); // logging the error for debugging purposes
      dispatch({
        type: SETUP_USER_ERROR,
        payload: { msg: error?.response?.data?.message },
      });
    }

    clearAlert();
  };

  const registerMemberTrial = async ({ currentUser, endPoint, alertText }) => {
    dispatch({ type: SETUP_USER_BEGIN });

    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BACKEND_URL + "/auth/registertrial",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        data: currentUser,
      };

      const response = await axios.request(config);
      const { user, token } = response.data;

      dispatch({
        type: SETUP_USER_SUCCESS,
        payload: { user, token, alertText },
      });

      return response;
    } catch (error) {
      dispatch({
        type: SETUP_USER_ERROR,
        payload: { msg: error?.response?.data?.message },
      });
      throw error;
    } finally {
      clearAlert();
    }
  };

  const userDemo = async ({ demoUser, alertText }) => {
    dispatch({ type: SETUP_DEMO_BEGIN });
    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/demo/fordemo",
        demoUser
      );
      dispatch({
        type: SETUP_DEMO_SUCCESS,
        payload: { alertText },
      });
    } catch (error) {
      dispatch({
        type: SETUP_DEMO_ERROR,
        payload: { msg: error.response.data.message },
      });
    }
    clearAlert();
  };

  const userNeed = async ({ demoUser }) => {
    dispatch({ type: SETUP_NEED_BEGIN });
    try {
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/demo/forneed",
        demoUser
      );

      dispatch({
        type: SETUP_NEED_SUCCESS,
        payload: { responsex: response.data },
      });
    } catch (error) {
      dispatch({
        type: SETUP_NEED_ERROR,
        payload: { msg: error.response.data.message },
      });
    }
    clearAlert();
  };

  const updateColor = async ({ data }) => {
    dispatch({ type: UPDATE_COLOR_BEGIN });
    try {
      // Update in backend
      const { userId, colorType, colorValue } = data;

      const response = await authFetch.patch("/members/update-color", {
        userId: userId,
        colorType,
        colorValue,
      });

      dispatch({
        type: UPDATE_COLOR_SUCCESS,
        payload: {
          primaryColor: response.data.primaryColor,
          secondaryColor: response.data.secondaryColor,
          accentColor: response.data.accentColor,
        },
      });
      // Update in context
      //setUser((prev) => ({ ...prev, [`${colorType}Color`]: colorValue }));
    } catch (error) {
      dispatch({
        type: UPDATE_COLOR_ERROR,
        payload: { msg: error.response.data.message },
      });

      // Handle error (show alert, etc.)
    }
  };

  const getExpenseDetails = async () => {
    dispatch({ type: GET_EXPENSEDETAILS_BEGIN });
    try {
      const { data } = await authFetch(`/giving/expense/document`);

      dispatch({
        type: GET_EXPENSEDETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  /////Childen

  const childCheckin = async (data) => {
    dispatch({ type: ADD_CHILDCHECKIN_BEGIN });
    try {
      await authFetch.post("/children/childcheckin", data);
      dispatch({ type: ADD_CHILDCHECKIN_SUCCESS });
      //dispatch({ type: CLEAR_VALUES });
      getWorkflows();
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: ADD_CHILDCHECKIN_ERROR,
        payload: {
          msg: error?.response?.data.message,
        },
      });
    }
    clearAlert();
  };

  const addWorkflow = async (newWorkflow) => {
    dispatch({ type: ADD_WORKFLOW_BEGIN });
    try {
      const { status } = await authFetch.post("/workflow", newWorkflow);
      dispatch({ type: ADD_WORKFLOW_SUCCESS, payload: { status } });
      getWorkflows();
      return { status };
      //dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: ADD_WORKFLOW_ERROR,
        payload: { msg: error.response.data.message },
      });
    }
    clearAlert();
  };

  const getWorkflows = async () => {
    dispatch({ type: GET_WORKFLOW_BEGIN });
    try {
      const { data } = await authFetch(`/workflow`);

      dispatch({ type: GET_WORKFLOW_SUCCESS, payload: data.containers });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const getContainers = async () => {
    dispatch({ type: GET_CONTAINER_BEGIN });
    try {
      const { data } = await authFetch(`/container`);
      const { containers, tagsCount } = data;

      dispatch({
        type: GET_CONTAINER_SUCCESS,
        payload: { containers, tagsCount },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const getContainerById = async (tagId) => {
    dispatch({ type: GET_CONTAINERBYID_BEGIN });
    try {
      const { data } = await authFetch(`/container/getcontainerbyid/${tagId}`);

      dispatch({
        type: GET_CONTAINERBYID_SUCCESS,
        payload: data.uniqueContainer,
      });
    } catch (error) {
      // logoutUser();
    }
    clearAlert();
  };

  const editContainer = async (tagId, editedTag) => {
    dispatch({ type: EDIT_CONTAINER_BEGIN });
    try {
      const { data } = await authFetch.patch(`/container/${tagId}`, editedTag);

      dispatch({ type: EDIT_CONTAINER_SUCCESS, payload: data.container });
      getContainers();
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const SetupContainer = async (tagId, containerData) => {
    dispatch({ type: SETUP_CONTAINER_BEGIN });
    try {
      const { data } = await authFetch.patch(
        `/container/setup/${tagId}`,
        containerData
      );

      dispatch({ type: SETUP_CONTAINER_SUCCESS, payload: data.container });
      getContainers();
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const quickAddToContainer = async (newTag) => {
    const { id, data } = newTag;
    const alertText = "Member Added Successfully";

    try {
      await authFetch.put(`/members/tag/quick/${id}/${data}`, { data });
      dispatch({
        type: ADD_QUICKTOCONTAINER_SUCCESS,
        payload: { alertText },
      });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: ADD_QUICKTOCONTAINER_ERROR,
        payload: { msg: error.response.data.message },
      });
    }
    clearAlert();
  };

  const getContainerMembers = async (tag) => {
    dispatch({ type: GET_TOCONTAINER_BEGIN });
    try {
      const { data } = await authFetch(`/members/tag/${tag}`);
      dispatch({
        type: GET_TOCONTAINER_SUCCESS,
        payload: { alltagmembers: data },
      });
    } catch (error) {
      if (error.response.status === 401) return;
      // Consider adding some error handling here, instead of logging out the user directly.
    }
    clearAlert(); // This can be here if you always want to clear alerts regardless of success or failure
  };

  const addAttendance = async ({ addAttendancex }) => {
    dispatch({ type: ADD_ATTENDANCE_BEGIN });
    try {
      await authFetch.patch("/events/add", addAttendancex);
      dispatch({ type: ADD_ATTENDANCE_SUCCESS });
      //dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: ADD_ATTENDANCE_ERROR,
        payload: { msg: error.response.data.message },
      });
    }
    clearAlert();
  };

  const showAttendance = async () => {
    dispatch({ type: SHOW_EVENTSSTATS_BEGIN });
    try {
      const { data } = await authFetch("/events/stats");
      dispatch({
        type: SHOW_EVENTSSTATS_SUCCESS,
        payload: {
          eventStats: data.eventStats,
          monthlyevents: data.monthlyevents,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  //creation of new user from admin page
  const newUser = async ({ addMember }) => {
    const alertText = "New User Created Successfully";
    dispatch({ type: SETUP_NEWUSER_BEGIN });
    try {
      await authFetch.post("/members/newusers", addMember);
      dispatch({ type: SETUP_NEWUSER_SUCCESS, payload: { alertText } });
      //dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: SETUP_NEWUSER_ERROR,
        payload: { msg: error.response.data.message },
      });
    }
    clearAlert();
  };

  const addPermission = async ({ newRole }) => {
    const alertText = "Role Created Successfully";
    dispatch({ type: SETUP_NEWPERMISSION_BEGIN });
    try {
      await authFetch.post("/permission", newRole);
      dispatch({ type: SETUP_NEWPERMISSION_SUCCESS, payload: { alertText } });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: SETUP_NEWPERMISSION_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const getPermission = async () => {
    dispatch({ type: GET_PERMISSION_BEGIN });
    try {
      const { data } = await authFetch(`/permission`);

      const { permission } = data;

      dispatch({
        type: GET_PERMISSION_SUCCESS,
        payload: {
          permission,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const updateMemberDetails = async ({ updateMember }) => {
    dispatch({ type: UPDATEMEMBER_USER_BEGIN });

    try {
      const { data } = await authFetch.post(
        `/members/updatememberdetails`,
        updateMember
      );
      getAllMembers();
      const { userMemberDetails } = data;
      dispatch({
        type: UPDATEMEMBER_USER_SUCCESS,
        payload: { userMemberDetails },
      });
    } catch (error) {
      if (error.response.status !== 401) {
        dispatch({
          type: UPDATEMEMBER_USER_ERROR,
          payload: { msg: error.response.data.msg },
        });
      }
    }
    clearAlert();
  };

  const toggleSidebar = () => {
    dispatch({ type: TOGGLE_SIDEBAR });
  };

  const notifyNewMember = async ({ addMember }) => {
    dispatch({ type: FORGOT_PASSWORD_BEGIN });
    try {
      await authFetch.post("/email/notifynewuser", addMember);
    } catch (error) {
      if (error.response.status !== 401) return;
      dispatch({
        type: FORGOT_PASSWORD_ERROR,
      });
    }
    clearAlert();
  };

  const notifyUpdatedMember = async ({ updateMember }) => {
    dispatch({ type: FORGOT_PASSWORD_BEGIN });
    try {
      await authFetch.patch("/email/notifyupdateduser", updateMember);
    } catch (error) {
      if (error.response.status !== 401) return;
      dispatch({
        type: FORGOT_PASSWORD_ERROR,
      });
    }
    clearAlert();
  };

  const bulkEmailtoMembers = async ({ userMessage }) => {
    dispatch({ type: BULK_EMAIL_BEGIN });
    const alertText = "Email Sent Successfully";

    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: BASE_URL + "/email/bulkemailtomembers",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json; charset=utf-8", // Specify UTF-8 encoding
        },
        data: JSON.stringify(userMessage),
      };

      const response = await axios.request(config);

      dispatch({ type: BULK_EMAIL_SUCCESS, payload: { alertText } });
    } catch (error) {
      if (error.response && error.response.status === 401) return;
      dispatch({
        type: BULK_EMAIL_ERROR,
      });
    }

    clearAlert();
  };

  const sendBulkSMS = async ({ smsData }) => {
    dispatch({ type: BULK_SMS_BEGIN });
    const alertText = "SMS Sent Successfully";
    try {
      await authFetch.post("/sms", smsData);
      dispatch({ type: BULK_SMS_SUCCESS, payload: { alertText } });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: BULK_SMS_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const logoutUser = async () => {
    try {
      await authFetch.get("/auth/logout");
      dispatch({ type: LOGOUT_USER });

      // Reset CSS custom properties to default values
      const root = document.documentElement;
      root.style.setProperty("--primary-color", "#243060"); // Default primary color
      root.style.setProperty("--secondary-color", "#fe7064"); // Default secondary color
      root.style.setProperty("--accent-color", "#fed970"); // Default accent color
    } catch (error) {
      console.error("Error:", error);
      // Handle the error as needed
    }
  };

  const getCompleteUsers = async () => {
    dispatch({ type: GET_COMPLETE_USER_BEGIN });
    try {
      const { data } = await authFetch(`/auth/getcompleteusers`);

      const { completeusers } = data;

      dispatch({
        type: GET_COMPLETE_USER_SUCCESS,
        payload: {
          completeusers,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const createCampus = async ({ newCampus }) => {
    dispatch({ type: CREATE_CAMPUS_BEGIN });

    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BACKEND_URL + "/auth/createcampus",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        data: newCampus,
      };

      const ResData = await axios
        .request(config)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      const { user, token } = ResData || {};

      dispatch({
        type: CREATE_CAMPUS_SUCCESS,
        payload: { user, token },
      });
    } catch (error) {
      dispatch({
        type: CREATE_CAMPUS_ERROR,
        payload: { msg: error?.response?.data?.msg },
      });
    }
    clearAlert();
  };

  const updateUser = async ({ currentUser }) => {
    console.log(currentUser);
    dispatch({ type: UPDATE_USER_BEGIN });

    try {
      let config = {
        method: "patch",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BACKEND_URL + "/auth/updateUser",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        data: currentUser,
      };

      const ResData = await axios
        .request(config)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      const { user, token } = ResData || {};

      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: { user, token },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_USER_ERROR,
        payload: { msg: error?.response?.data?.msg },
      });
    }
    clearAlert();
  };

  const updatePassword = async ({ userPassword }) => {
    dispatch({ type: UPDATE_USERPASSWORD_BEGIN });

    try {
      let config = {
        method: "patch",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BACKEND_URL + "/auth/updatepassword",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        data: userPassword,
      };

      const response = await axios.request(config);
      const { user, token } = response.data;

      dispatch({
        type: UPDATE_USERPASSWORD_SUCCESS,
        payload: { user, token },
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: UPDATE_USERPASSWORD_ERROR,
        payload: { msg: error.response?.data?.msg || error.message },
      });
    } finally {
      // clearAlert(); // Uncomment if you have a clearAlert function to reset alerts
    }
  };

  const handleChange = ({ name, value }) => {
    dispatch({ type: HANDLE_CHANGE, payload: { name, value } });
  };
  const clearValues = () => {
    dispatch({ type: CLEAR_VALUES });
  };

  const createMember = async ({ currentMember }) => {
    dispatch({ type: CREATE_MEMBER_BEGIN });
    try {
      const response = await authFetch.post("/members", currentMember);

      dispatch({ type: CREATE_MEMBER_SUCCESS });
      dispatch({ type: CLEAR_VALUES });
      const { data } = await authFetch.get("/auth/churchaccount");
      const toBeSent = data.churchSettings.emailSettings.sendEmailToNewUser;

      if (response.status === 201 && toBeSent) {
        await authFetch.post("/email/currentmember", currentMember);
      }
    } catch (error) {
      if (error?.response?.status === 401) return;
      dispatch({
        type: CREATE_MEMBER_ERROR,
        payload: { msg: error?.response?.data?.msg },
      });
    }
    clearAlert();
  };

  const uploadMemberMany = async (formData) => {
    dispatch({ type: UPLOAD_MEMBER_MANY_BEGIN });
    try {
      const response = await authFetch.post("/bulk/bulkmember", formData);
      const currentMember = response.data.members;
      dispatch({ type: UPLOAD_MEMBER_MANY_SUCCESS });
      dispatch({ type: CLEAR_VALUES });
      if (response.status === 200 || response.status === 201) {
        await authFetch.post("/email/currentmembermany", currentMember);
      }
    } catch (error) {
      if (error?.response?.status === 401) return;
      dispatch({
        type: UPLOAD_MEMBER_MANY_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const uploadGivingMany = async (formData) => {
    dispatch({ type: UPLOAD_GIVING_MANY_BEGIN });
    try {
      await authFetch.post("/bulk/bulkgiving", formData);

      dispatch({ type: UPLOAD_GIVING_MANY_SUCCESS });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error?.response?.status === 401) return;
      dispatch({
        type: UPLOAD_GIVING_MANY_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const createPrayerRequest = async (prayerRequestData, alertText) => {
    dispatch({ type: CREATE_PRAYER_BEGIN });
    try {
      await authFetch.post("/prayers", prayerRequestData);

      dispatch({ type: CREATE_PRAYER_SUCCESS, payload: { alertText } });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error?.response?.status === 401) return;
      dispatch({
        type: CREATE_PRAYER_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const getPrayerRequest = async () => {
    dispatch({ type: GET_SAVEDPRAYER_BEGIN });
    try {
      const { data } = await authFetch(`/prayers`);

      dispatch({
        type: GET_SAVEDPRAYER_SUCCESS,
        payload: {
          prayers: data?.prayers,
          totalPrayers: data?.totalPrayers,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const savedView = async (saveView, alertText) => {
    dispatch({ type: CREATE_SAVE_BEGIN });
    try {
      await authFetch.post("/saved", saveView);
      dispatch({ type: CREATE_SAVE_SUCCESS, payload: { alertText } });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CREATE_SAVE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const deletedRole = async (roleId, alertText) => {
    dispatch({ type: DELETE_ROLE_BEGIN });
    try {
      await authFetch.delete(`/permission/${roleId}`);

      dispatch({
        type: DELETE_ROLE_SUCCESS,
        payload: { alertText },
      });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: DELETE_ROLE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const deleteView = async (viewId, alertText) => {
    dispatch({ type: DELETE_VIEW_BEGIN });
    try {
      await authFetch.delete(`/saved/${viewId}`);

      dispatch({
        type: DELETE_VIEW_SUCCESS,
        payload: { alertText },
      });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: DELETE_VIEW_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const getSavedView = async () => {
    dispatch({ type: GET_SAVED_BEGIN });
    try {
      const { data } = await authFetch(`/saved`);

      const { savedViews } = data;

      dispatch({
        type: GET_SAVED_SUCCESS,
        payload: {
          savedViews,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const getViewById = async (viewId) => {
    dispatch({ type: GET_VIEWBYID_BEGIN });
    try {
      const { data } = await authFetch(`/saved/getviewbyid/${viewId}`);

      const [uniqueviewdetails] = data;

      dispatch({
        type: GET_VIEWBYID_SUCCESS,
        payload: {
          uniqueviewdetails,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const setPage = (page) => {
    dispatch({ type: SET_PAGE, payload: { page } });
    //  getAllMembers();
  };

  const setRowsPerPage = (rowsPerPage) => {
    dispatch({ type: SET_ROWS_PER_PAGE, payload: { rowsPerPage } });
    setPage(1);
  };

  const getAllMembers = async () => {
    const {
      page,
      search,
      searchStatus,
      searchCampus,
      searchGender,
      searchMarital,
      searchAccountStatus,
      searchAnniversaryMonth,
      searchBirthMonth,
      sort,
      rowsPerPage,
    } = state;

    // Construct URL with only valid parameters
    const urlParams = new URLSearchParams({
      page,
      limit: rowsPerPage,
      mstatus: searchStatus,
      accountStatus: searchAccountStatus,
      mBirthMonth: searchBirthMonth,
      mgender: searchGender,
      mmarital: searchMarital,
      mAnniversaryMonth: searchAnniversaryMonth,
      campus: searchCampus,
      sort,
    });

    // Conditionally append the search parameter
    if (search) {
      urlParams.append("search", search);
    }

    const url = `/members?${urlParams.toString()}`;

    dispatch({ type: GET_MEMBERS_BEGIN });

    try {
      const {
        data: { members, totalMembers, numOfPages, allMembers },
      } = await authFetch(url);

      dispatch({
        type: GET_MEMBERS_SUCCESS,
        payload: {
          members,
          totalMembers,
          numOfPages,
          allMembers,
        },
      });
    } catch (error) {
      logoutUser();
    }

    clearAlert();
  };

  const getCompleteMembers = async () => {
    dispatch({ type: GET_COMPLETE_BEGIN });
    try {
      const { data } = await authFetch(`/members/completemembers`);

      const { completemembers } = data;

      dispatch({
        type: GET_COMPLETE_SUCCESS,
        payload: {
          completemembers,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const memberbyMonth = async () => {
    dispatch({ type: GET_MEMBERBYMONTH_BEGIN });
    try {
      const { data } = await authFetch(`/members/viewbymonth`);

      const { groupByMonth } = data;

      dispatch({
        type: GET_MEMBERBYMONTH_SUCCESS,
        payload: {
          groupByMonth,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const getMemberById = async (memberId) => {
    dispatch({ type: GET_MEMBERBYID_BEGIN });
    try {
      const { data } = await authFetch(`/members/getmemberbyid/${memberId}`);

      const [uniquememberdetails] = data;

      dispatch({
        type: GET_MEMBERBYID_SUCCESS,
        payload: {
          uniquememberdetails,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const RecentMembers = async () => {
    dispatch({ type: GET_RECENTMEMBER_BEGIN });
    try {
      const { data } = await authFetch(`/members/getRecentMembers`);

      const getRecentMembers = data;

      dispatch({
        type: GET_RECENTMEMBER_SUCCESS,
        payload: {
          getRecentMembers,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const setEditMember = (id) => {
    dispatch({ type: SET_EDIT_MEMBER, payload: { id } });
  };

  const updateMember = async ({ editMemberx }, _id) => {
    try {
      dispatch({ type: EDIT_MEMBER_BEGIN });
      await authFetch.patch(`/members/${_id}`, editMemberx);
      dispatch({ type: EDIT_MEMBER_SUCCESS });
    } catch (error) {
      if (error?.response?.status === 401) return;
      dispatch({
        type: EDIT_MEMBER_ERROR,
        payload: { msg: error?.response?.data?.msg },
      });
    }
    clearAlert();
  };

  const editMember = async ({ editMemberx }, _id) => {
    dispatch({ type: EDIT_MEMBER_BEGIN });
    try {
      await authFetch.patch(`/members/${_id}`, editMemberx);
      dispatch({ type: EDIT_MEMBER_SUCCESS });
      dispatch({ type: CLEAR_VALUES });
      // getMemberById(_id);
    } catch (error) {
      if (error?.response?.status === 401) return;
      dispatch({
        type: EDIT_MEMBER_ERROR,
        payload: { msg: error?.response?.data?.msg },
      });
    }
    clearAlert();
  };

  const updateAttendance = async ({ addAttendancenew }, eventId1) => {
    dispatch({ type: UPDATE_ATTENDANCE_BEGIN });
    try {
      await authFetch.patch(`/events/update/${eventId1}`, addAttendancenew);
      dispatch({ type: UPDATE_ATTENDANCE_SUCCESS });
      dispatch({ type: CLEAR_VALUES });
      getMemberById(eventId1);
    } catch (error) {
      if (error?.response?.status === 401) return;
      dispatch({
        type: UPDATE_ATTENDANCE_ERROR,
        payload: { msg: error?.response?.data?.msg },
      });
    }
    clearAlert();
  };

  const updatePrayer = async (prayerRequestUpdate, prayerId) => {
    dispatch({ type: UPDATE_PRAYER_BEGIN });
    try {
      const response = await authFetch.patch(
        `/prayers/${prayerId}`,
        prayerRequestUpdate
      );
      if (response.status === 200 || response.status === 201) {
        dispatch({ type: UPDATE_PRAYER_SUCCESS });
        dispatch({ type: CLEAR_VALUES });
        getPrayerRequest();
        return response;
      }
    } catch (error) {
      if (error?.response?.status === 401) return;
      dispatch({
        type: UPDATE_PRAYER_ERROR,
        payload: { msg: error?.response?.data?.msg },
      });
      return error.response;
    }
    clearAlert();
  };

  const deleteMember = async (memberIds, familyId, alertText) => {
    dispatch({ type: DELETE_MEMBER_BEGIN });
    try {
      await authFetch.delete(`/members/${memberIds}`);
      getAllMembers();
      dispatch({
        type: DELETE_MEMBER_SUCCESS,
        //payload: { alertText },
      });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: DELETE_MEMBER_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const deleteForm = async (formId, alertText) => {
    dispatch({ type: DELETE_FORM_BEGIN });
    try {
      await authFetch.delete(`/forms/${formId}`);
      getForm();
      dispatch({
        type: DELETE_FORM_SUCCESS,
        payload: { alertText },
      });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: DELETE_FORM_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const deletePrayer = async (prayerId, alertText) => {
    dispatch({ type: DELETE_PRAYER_BEGIN });
    try {
      const response = await authFetch.delete(`/prayers/${prayerId}`);
      getPrayerRequest();
      dispatch({
        type: DELETE_PRAYER_SUCCESS,
        payload: { alertText },
      });
      return response;
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: DELETE_PRAYER_ERROR,
        payload: { msg: error.response.data.msg },
      });
      return error.response;
    }
    clearAlert();
  };

  const addToGroup = async ({ addtoGroup }) => {
    const { groupId, fname, lname } = addtoGroup;

    const { data } = await authFetch(
      `/members/getmemberidonly/${fname}/${lname}`
    );
    const [memberIdOnly] = data;

    try {
      await authFetch.put(`/members/group/${groupId}/${memberIdOnly._id}`);
      listofGroupMembers(groupId);
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: ADD_CONTRIBUTION_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const listofGroupMembers = async (id) => {
    dispatch({ type: GET_GROUP_BEGIN });
    try {
      const { data } = await authFetch(`/members/getGroupMember/${id}`);

      const allgroupmembers = data;

      dispatch({
        type: GET_GROUP_SUCCESS,
        payload: {
          allgroupmembers,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const removeFromGroup = async (id, mId, alertText) => {
    let answer = window.confirm("Are you sure?");
    if (!answer) return;
    dispatch({ type: REMOVE_FROMGROUP_BEGIN });
    try {
      await authFetch.put(`/members/removefromgroup/${mId}/${id}`);

      dispatch({
        type: REMOVE_FROMGROUP_SUCCESS,
        payload: { alertText },
      });
      listofGroupMembers(id);
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: REMOVE_FROMGROUP_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const showStats = async () => {
    dispatch({ type: SHOW_STATS_BEGIN });
    try {
      const { data } = await authFetch("/members/stats");
      dispatch({
        type: SHOW_STATS_SUCCESS,
        payload: {
          stats: data.defaultStats,
          monthlyNewMembers: data.monthlyNewMembers,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const clearFilters = () => {
    dispatch({ type: CLEAR_FILTERS });
  };

  const clearFiltersMembers = () => {
    dispatch({ type: CLEAR_FILTERS_MEMBER });
  };
  const changePage = (page) => {
    dispatch({ type: CHANGE_PAGE, payload: { page } });
  };

  const getCurrentUser = async () => {
    dispatch({ type: GET_CURRENT_USER_BEGIN });
    try {
      const { data } = await authFetch("/auth/getCurrentUser");
      const { user, location, churchSettings } = data;
      document.documentElement.style.setProperty(
        "--primary-color",
        churchSettings.primaryColor
      );
      document.documentElement.style.setProperty(
        "--secondary-color",
        churchSettings.secondaryColor
      );
      document.documentElement.style.setProperty(
        "--accent-color",
        churchSettings.accentColor
      );
      dispatch({
        type: GET_CURRENT_USER_SUCCESS,
        payload: { user, location, churchSettings },
      });
    } catch (error) {
      if (error?.response?.status === 401) return;
      logoutUser();
    }
  };
  useEffect(() => {
    getCurrentUser();
  }, [user]);

  const churchAccount = async () => {
    dispatch({ type: CHURCH_ACCOUNT_BEGIN });
    try {
      const response = await authFetch("auth/churchaccount");
      dispatch({
        type: CHURCH_ACCOUNT_SUCCESS,
        payload: { churchAccount: response.data },
      });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CHURCH_ACCOUNT_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const createForm = async (newForm) => {
    dispatch({ type: CREATE_FORM_BEGIN });
    try {
      await authFetch.post("/forms", newForm);
      getEvents();
      dispatch({ type: CREATE_FORM_SUCCESS });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CREATE_FORM_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const getForm = async () => {
    try {
      dispatch({ type: GET_FORMS_BEGIN });
      const { data } = await authFetch("/forms");

      dispatch({
        type: GET_FORMS_SUCCESS,
        payload: {
          forms: data?.forms,
          totalForms: data?.totalForms,
        },
      });
    } catch (error) {}
    clearAlert();
  };

  const updateForm = async ({ newForm }, id) => {
    dispatch({ type: UPDATE_FORM_BEGIN });
    try {
      const form = await authFetch.patch(`/forms/${id}`, { newForm });
      dispatch({ type: UPDATE_FORM_SUCCESS, payload: { form } });
      getForm();
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: UPDATE_FORM_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const getFormById = async (formId) => {
    dispatch({ type: GET_FORMBYID_BEGIN });
    try {
      const { data } = await authFetch(`/forms/${formId}`);

      const [uniqueForm] = data;

      dispatch({
        type: GET_FORMBYID_SUCCESS,
        payload: {
          uniqueForm: uniqueForm,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const getMailChimpStatus = async () => {
    try {
      dispatch({ type: GET_MAILCHIMP_BEGIN });
      const { data } = await authFetch.get(`/mailchimp/enroll-account`);

      dispatch({
        type: GET_MAILCHIMP_SUCCESS,
        payload: {
          mailchimp: data?.mailchimp,
        },
      });
    } catch (error) {
      console.error("Error during authentication:", error);
    }
    clearAlert();
  };

  const formResponse = async (formResponseData, id) => {
    dispatch({ type: UPDATE_FORMRESPONSE_BEGIN });

    try {
      const data = await authFetch.put(
        `/forms/form-response/${id}`,
        formResponseData
      );

      dispatch({ type: UPDATE_FORMRESPONSE_SUCCESS, payload: { data } });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: UPDATE_FORMRESPONSE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const createEvent = async ({ currentEvent }) => {
    dispatch({ type: CREATE_EVENT_BEGIN });
    try {
      await authFetch.post("/events", currentEvent);
      getEvents();
      dispatch({ type: CREATE_EVENT_SUCCESS });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CREATE_EVENT_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const getEvents = async () => {
    try {
      dispatch({ type: GET_EVENT_BEGIN });
      const { data } = await authFetch.get(`/events`);

      dispatch({
        type: GET_EVENT_SUCCESS,
        payload: {
          events: data?.events,
          totalEvents: data?.totalEvents,
        },
      });
    } catch (error) {
      //logoutUser();
    }
    clearAlert();
  };

  const getStream = async () => {
    try {
      dispatch({ type: GET_LIVESTREAM_BEGIN });

      //   console.log(BASE_URL, "BASE_URL");

      const { data } = await authFetch.get(`/livestream/getstream`);

      dispatch({
        type: GET_LIVESTREAM_SUCCESS,
        payload: {
          videoId: data?.videoId,
          videoStatus: data?.status,
          videoDescription: data?.videoDescription,
          videoTitle: data?.videoTitle,
          videoData: data?.videoData,
        },
      });
    } catch (error) {
      console.error("Error fetching live stream info:", error);
      // dispatch({
      //   type: GET_LIVESTREAM_ERROR,
      //   payload: { error: error.message },
      // });
      // Consider re-enabling logoutUser or another form of error response here
    }
    clearAlert();
  };

  const createCheckin = async ({ checkin }) => {
    dispatch({ type: CREATE_CHECKIN_BEGIN });
    try {
      await authFetch.post("/checkin", checkin);
      //  getCheckin();
      dispatch({ type: CREATE_CHECKIN_SUCCESS });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CREATE_CHECKIN_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  // const getCheckin = async () => {
  //   try {
  //     dispatch({ type: GET_CHECKIN_BEGIN });
  //     const { data } = await authFetch.get(`/checkin`);

  //     dispatch({
  //       type: GET_CHECKIN_SUCCESS,
  //       payload: {
  //         checkin: data?.checkin,
  //         totalCheckin: data?.totalCheckin,
  //       },
  //     });
  //   } catch (error) {
  //     logoutUser();
  //   }
  //   clearAlert();
  // };

  const createService = async ({ currentService }) => {
    dispatch({ type: CREATE_SERVICE_BEGIN });
    try {
      await authFetch.post("/serviceplanner", currentService);
      getSchedules();
      dispatch({ type: CREATE_SERVICE_SUCCESS });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CREATE_SERVICE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const getServices = async () => {
    try {
      dispatch({ type: GET_SERVICE_BEGIN });
      const { data } = await authFetch.get(`/serviceplanner`);

      dispatch({
        type: GET_SERVICE_SUCCESS,
        payload: {
          servicePlanner: data?.services,
          totalServicePlanner: data?.totalServicePlanner,
        },
      });
    } catch (error) {
      //logoutUser();
    }
    clearAlert();
  };

  const deleteService = async (serviceId) => {
    try {
      const alertText = "Service Deleted Successfully";
      dispatch({ type: DELETE_SERVICE_BEGIN });
      await authFetch.delete(`/serviceplanner/${serviceId}`);
      getServices();
      dispatch({
        type: DELETE_SERVICE_SUCCESS,
        payload: { alertText },
      });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: DELETE_SERVICE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const updateService = async ({ currentService }) => {
    dispatch({ type: UPDATE_SERVICE_BEGIN });

    try {
      const data = await authFetch.patch(`/serviceplanner`, currentService);
      dispatch({
        type: UPDATE_SERVICE_SUCCESS,
        payload: {
          servicePlanner: data?.servicePlanner,
        },
      });
    } catch (error) {
      if (error?.response?.status === 401) return;
      dispatch({
        type: UPDATE_SERVICE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const serviceViewById = async (serviceId) => {
    dispatch({ type: GET_SERVICEBYID_BEGIN });
    try {
      const { data } = await authFetch.get(`/serviceplanner/${serviceId}`);

      const [uniqueservice] = data;

      dispatch({
        type: GET_SERVICEBYID_SUCCESS,
        payload: {
          uniqueservice,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const createSchedule = async ({ currentSchedule }) => {
    dispatch({ type: CREATE_SCHEDULE_BEGIN });
    try {
      await authFetch.post("/schedule", currentSchedule);
      getSchedules();
      dispatch({ type: CREATE_SCHEDULE_SUCCESS });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CREATE_SCHEDULE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const getSchedules = async () => {
    try {
      dispatch({ type: GET_SCHEDULE_BEGIN });
      const { data } = await authFetch.get(`/schedule`);

      dispatch({
        type: GET_SCHEDULE_SUCCESS,
        payload: {
          schedule: data?.schedule,
          totalSchedule: data?.totalSchedule,
        },
      });
    } catch (error) {
      //logoutUser();
    }
    clearAlert();
  };

  const getApplications = async () => {
    try {
      dispatch({ type: GET_APPLICATION_BEGIN });
      const { data } = await authFetch.get(`/volunteerapp`);

      dispatch({
        type: GET_APPLICATION_SUCCESS,
        payload: {
          applicationx: data?.applicationx,
        },
      });
    } catch (error) {
      //logoutUser();
    }
    clearAlert();
  };

  const deleteSchedule = async (scheduleId, alertText) => {
    try {
      dispatch({ type: DELETE_SCHEDULE_BEGIN });
      await authFetch.delete(`/schedule/${scheduleId}`);
      getSchedules();
      dispatch({
        type: DELETE_SCHEDULE_SUCCESS,
        payload: { alertText },
      });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: DELETE_SCHEDULE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const deleteApplication = async (clickedAppid, alertText) => {
    try {
      dispatch({ type: DELETE_APPLICATION_BEGIN });
      await authFetch.delete(`/volunteerapp/${clickedAppid}`);
      getApplications();
      dispatch({
        type: DELETE_APPLICATION_SUCCESS,
        payload: { alertText },
      });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: DELETE_APPLICATION_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const updateSchedule = async ({ currentSchedule }) => {
    dispatch({ type: UPDATE_SCHEDULE_BEGIN });

    try {
      const schedule = await authFetch.patch(`/schedule`, currentSchedule);
      dispatch({ type: UPDATE_SCHEDULE_SUCCESS, payload: { schedule } });
      getSchedules();
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: UPDATE_SCHEDULE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const getEventByVolunteers = async () => {
    try {
      dispatch({ type: GET_EVENTBYVOLUNTEER_BEGIN });
      const { data } = await authFetch.get(`/events/geteventbyvolunteers`);

      dispatch({
        type: GET_EVENTBYVOLUNTEER_SUCCESS,
        payload: {
          eventsByVolunteer: data?.eventsByVolunteer,
          totalEventsByVolunteer: data?.totalEventsByVolunteer,
        },
      });
    } catch (error) {
      //logoutUser();
    }
    clearAlert();
  };

  const scheduleViewById = async (scheduleId) => {
    dispatch({ type: GET_SCHEDULEBYID_BEGIN });
    try {
      const { data } = await authFetch(`/schedule/${scheduleId}`);

      const [uniqueschedule] = data;

      dispatch({
        type: GET_SCHEDULEBYID_SUCCESS,
        payload: {
          uniqueschedule,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const applicationViewById = async (clickedAppid) => {
    dispatch({ type: GET_APPLICATIONBYID_BEGIN });
    try {
      const { data } = await authFetch(`/volunteerapp/${clickedAppid}`);

      const [uniqueapplication] = data;

      dispatch({
        type: GET_APPLICATIONBYID_SUCCESS,
        payload: {
          uniqueapplication,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  /////

  const deleteEvent = async (eventId, alertText) => {
    console.log("eventId", eventId);

    try {
      dispatch({ type: DELETE_EVENT_BEGIN });
      await authFetch.delete(`/events/${eventId}`);
      getEvents();
      dispatch({
        type: DELETE_EVENT_SUCCESS,
        payload: { alertText },
      });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: DELETE_EVENT_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const updateEvent = async ({ currentEvent }) => {
    dispatch({ type: UPDATE_EVENT_BEGIN });

    try {
      const event = await authFetch.patch(`/events`, currentEvent);
      dispatch({ type: UPDATE_EVENT_SUCCESS, payload: { event } });
      getEvents();
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: UPDATE_EVENT_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const forgotPassword = async ({ userEmail }) => {
    dispatch({ type: FORGOT_PASSWORD_BEGIN });
    try {
      await authFetch.post("/email/forgotpassword", userEmail);
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) return;
      dispatch({
        type: FORGOT_PASSWORD_ERROR,
      });
    }
    clearAlert();
  };

  const resetPassword = async ({ userData }) => {
    dispatch({ type: RESET_PASSWORD_BEGIN });

    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BACKEND_URL + "/email/resetPassword",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        data: userData,
      };

      const response = await axios.request(config);

      dispatch({
        type: RESET_PASSWORD_SUCCESS,
      });

      return response;
    } catch (error) {
      console.error(error);

      dispatch({
        type: RESET_PASSWORD_ERROR,
        payload: { msg: error?.response?.data?.msg },
      });

      throw error; // Re-throw the error to be caught in the handlePasswordReset function
    } finally {
      clearAlert();
    }
  };

  const resetNewMemberPassword = async ({ userData }) => {
    dispatch({ type: RESET_NEWPASSWORD_BEGIN });

    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BACKEND_URL + "/email/resetnewPassword",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        data: userData,
      };

      const response = await axios.request(config);

      dispatch({
        type: RESET_NEWPASSWORD_SUCCESS,
      });

      return response;
    } catch (error) {
      console.error(error);

      dispatch({
        type: RESET_NEWPASSWORD_ERROR,
        payload: { msg: error?.response?.data?.msg },
      });

      throw error; // Re-throw the error to be caught in the handlePasswordReset function
    } finally {
      clearAlert();
    }
  };
  ////////////////////////////FollowUp/////

  const addSettings = async ({ followUpDetails }) => {
    dispatch({ type: ADD_SETTINGS_BEGIN });
    try {
      await authFetch.post("/followup/addFollowUpSettings", followUpDetails);
      getAllSettings();
      dispatch({ type: ADD_SETTINGS_SUCCESS });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: ADD_SETTINGS_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  ////update settings
  const updatedSetting = async ({ updatedSettingx }, selectedId) => {
    dispatch({ type: UPDATE_UPDATESETTINGS_BEGIN });
    try {
      await authFetch.patch(`/followup/update`, updatedSettingx);
      getAllSettings();
      dispatch({ type: UPDATE_UPDATESETTINGS_SUCCESS });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error?.response?.status === 401) return;
      dispatch({
        type: UPDATE_UPDATESETTINGS_ERROR,
        payload: { msg: error?.response?.data?.msg },
      });
    }
    clearAlert();
  };

  /////delete settings
  const deleteSettings = async (_id, alertText) => {
    dispatch({ type: DELETE_SETTING_BEGIN });
    try {
      await authFetch.delete(`/followup/deletesinglesetting/${_id}`);
      getAllSettings();
      dispatch({
        type: DELETE_SETTING_SUCCESS,
        payload: { alertText },
      });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: DELETE_SETTING_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  //
  const deleteFollowUp = async (_id, alertText) => {
    dispatch({ type: DELETE_FOLLOWUP_BEGIN });
    try {
      const response = await authFetch.delete(
        `/followup/deletesinglefollowup/${_id}`
      );

      dispatch({
        type: DELETE_FOLLOWUP_SUCCESS,
        payload: { alertText },
      });
      getAllFollowUp();
      return response;
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: DELETE_FOLLOWUP_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const getAllSettings = async () => {
    dispatch({ type: GET_SETTINGALL_BEGIN });
    try {
      const { data } = await authFetch(`/followup/settings`);

      const setting = data;

      dispatch({
        type: GET_SETTINGALL_SUCCESS,
        payload: {
          setting,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  ///get all followup
  const getAllFollowUp = async () => {
    dispatch({ type: GET_ALLFOLLOWUP_BEGIN });
    try {
      const { data } = await authFetch(`/followup/getallfollowup`);

      const followups = data;

      dispatch({
        type: GET_ALLFOLLOWUP_SUCCESS,
        payload: {
          followups,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  ///////////////////////////////

  const addGiving = async ({ currentGiving }) => {
    const {
      giverName,
      amount,
      category,
      paymentMethod,
      note,
      checkNumber,
      accountNumber,
      cardDigit,
      batchId,
      fname,
      lname,
      churchName,
      givingDate,
    } = currentGiving;

    const { data } = await authFetch(
      `/members/getmemberidonly/${fname}/${lname}`
    );
    const [memberIdOnly] = data;

    const addvalue = {
      giverName: giverName,
      amount: amount,
      category: category,
      paymentMethod: paymentMethod,
      note: note,
      checkNumber: checkNumber,
      accountNumber: accountNumber,
      cardDigit: cardDigit,
      batchId: batchId,
      mId: memberIdOnly?._id,
      memberId: memberIdOnly?._id,
      churchName: churchName,
      givingDate: givingDate,
    };
    dispatch({ type: ADD_CONTRIBUTION_BEGIN });
    try {
      const addedData = await authFetch.post("/giving", addvalue);
      getGiving();
      getAllFund();
      dispatch({ type: ADD_CONTRIBUTION_SUCCESS, payload: { addedData } });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error?.response?.status === 401) return;
      dispatch({
        type: ADD_CONTRIBUTION_ERROR,
        payload: { msg: error?.response?.data?.msg },
      });
    }
    clearAlert();
  };

  const getGiving = async () => {
    const {
      searchByBatchReport,
      searchByEndDateReport,
      searchByFundReport,
      searchByMaxAmountReport,
      searchByMembersReport,
      searchByMethodReport,
      searchByMinAmountReport,
      searchByStartDateReport,
      sortData,
    } = state;

    let url = `/giving/give/get?category=${searchByFundReport}&paymentMethod=${searchByMethodReport}&minAmount=${searchByMinAmountReport}&maxAmount=${searchByMaxAmountReport}&startDate=${searchByStartDateReport}&endDate=${searchByEndDateReport}&sortData=${sortData}}`;

    if (searchByMembersReport) {
      url = url + `&searchText=${searchByMembersReport}`;
    }

    if (searchByBatchReport) {
      url = url + `&batch=${searchByBatchReport}`;
    }
    dispatch({ type: GET_GIVING_BEGIN });

    try {
      const { data } = await authFetch(url);
      const { giving, totalGivings, numOfPagesGiving } = data;

      dispatch({
        type: GET_GIVING_SUCCESS,
        payload: {
          giving,
          totalGivings,
          numOfPagesGiving,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  //////////

  const getReport = async () => {
    const {
      statementUser,
      statementStartDate,
      statementEndDate,
      specificUserName,
    } = state;

    let url = `/giving/give/getreport?statementUser=${statementUser}&statementStartDate=${statementStartDate}&statementEndDate=${statementEndDate}&specificUserName=${specificUserName}`;

    dispatch({ type: GET_REPORT_BEGIN });

    try {
      const { data } = await authFetch(url);
      const { reports, totalFiles, numOfReportPages } = data;

      dispatch({
        type: GET_REPORT_SUCCESS,
        payload: {
          reports,
          totalFiles,
          numOfReportPages,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  /////////////////////////

  ////////////////////////////////////////////////////////////////Method

  ///Add Method
  const addMethod = async ({ addMethods }) => {
    dispatch({ type: ADD_METHOD_BEGIN });
    try {
      await authFetch.post("/giving/give/addmethod", addMethods);
      dispatch({ type: ADD_METHOD_SUCCESS });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: ADD_METHOD_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  /////delete method
  const deleteMethod = async (_id, alertText) => {
    dispatch({ type: DELETE_METHOD_BEGIN });
    try {
      const response = await authFetch.delete(
        `/giving/give/deletemethod/${_id}`
      );
      getMethod();
      dispatch({
        type: DELETE_MEMBER_SUCCESS,
        payload: { alertText },
      });
      return response;
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: DELETE_METHOD_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  ///Get Method

  const getMethod = async () => {
    dispatch({ type: GET_METHOD_BEGIN });
    try {
      const { data } = await authFetch(`/giving/give/getmethod`);

      const allmethods = data;

      dispatch({
        type: GET_METHOD_SUCCESS,
        payload: {
          allmethods,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  ////////FUNDS

  const addFund = async ({ fundDetails }) => {
    dispatch({ type: ADD_FUND_BEGIN });
    try {
      await authFetch.post("/giving/give/addfund", fundDetails);
      getAllFund();
      dispatch({ type: ADD_FUND_SUCCESS });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: ADD_FUND_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  /////delete fund
  const deleteFund = async (_id, alertText) => {
    dispatch({ type: DELETE_FUND_BEGIN });
    try {
      await authFetch.delete(`/giving/give/deletesinglefund/${_id}`);
      getAllFund();
      dispatch({
        type: DELETE_FUND_SUCCESS,
        payload: { alertText },
      });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: DELETE_FUND_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  ///Get Method

  const getFund = async () => {
    dispatch({ type: GET_FUND_BEGIN });
    try {
      const { data } = await authFetch.post(`/giving/funddetails`);

      const allfunds = data;

      dispatch({
        type: GET_FUND_SUCCESS,
        payload: {
          allfunds,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const getAllFund = async () => {
    dispatch({ type: GET_FUNDALL_BEGIN });
    try {
      const { data } = await authFetch(`/giving/give/funds`);

      const funds = data;

      dispatch({
        type: GET_FUNDALL_SUCCESS,
        payload: {
          funds,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  ///////Expense
  const addExpense = async ({ expenseDetails }) => {
    dispatch({ type: ADD_EXPENSE_BEGIN });
    try {
      await authFetch.post("/giving/expense", expenseDetails);
      getAllFund();
      dispatch({ type: ADD_EXPENSE_SUCCESS });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: ADD_EXPENSE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  /////delete fund
  const deleteExpense = async (_id, alertText) => {
    dispatch({ type: DELETE_EXPENSE_BEGIN });
    try {
      await authFetch.delete(`/giving/expense/${_id}`);
      getExpense();
      dispatch({
        type: DELETE_EXPENSE_SUCCESS,
        payload: { alertText },
      });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: DELETE_EXPENSE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  ///Get Method

  const getExpense = async () => {
    dispatch({ type: GET_EXPENSE_BEGIN });
    try {
      const { data } = await authFetch(`/giving/expense`);

      const allexpenses = data;

      dispatch({
        type: GET_EXPENSE_SUCCESS,
        payload: {
          allexpenses,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const getAllExpense = async () => {
    dispatch({ type: GET_EXPENSEALL_BEGIN });
    try {
      const { data } = await authFetch(`/giving/expense/all`);

      const expenses = data;

      dispatch({
        type: GET_EXPENSEALL_SUCCESS,
        payload: {
          expenses,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  //////Batch
  const batchSummary = async () => {
    dispatch({ type: GET_BATCHSUMMARY_BEGIN });
    try {
      const { data } = await authFetch(`/giving/give/get/allbatch`);

      const batchsummary = data;

      dispatch({
        type: GET_BATCHSUMMARY_SUCCESS,
        payload: {
          batchsummary,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const batchDetails = async (batchId) => {
    dispatch({ type: GET_BATCHVIEW_BEGIN });
    try {
      const { data } = await authFetch(`/giving/give/viewbatch/${batchId}`);

      const batchview = data;

      dispatch({
        type: GET_BATCHVIEW_SUCCESS,
        payload: { batchview },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  ////

  const OnlineGiving = async (donationData) => {
    dispatch({ type: ADD_ONLINEGIVING_BEGIN });

    try {
      const { data } = await authFetch.put(
        "/payment/online-giving",
        donationData
      );
      getOnlineGiving();

      dispatch({ type: ADD_ONLINEGIVING_SUCCESS, payload: { data } });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle 401 error
        return;
      }
      dispatch({
        type: ADD_ONLINEGIVING_ERROR,
        payload: {
          msg: error.response ? error.response.data.msg : "An error occurred",
        },
      });
    } finally {
      clearAlert();
    }
  };

  const getOnlineGiving = async () => {
    dispatch({ type: GET_ONLINEGIVING_BEGIN });
    try {
      const { data } = await authFetch(`/payment/online-giving-get`);

      const onlinedata = data;
      dispatch({
        type: GET_ONLINEGIVING_SUCCESS,
        payload: {
          onlinedata,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const updateOnlineGiving = async (data) => {
    const alertText = "Payment Details Cleared!";

    dispatch({ type: UPDATE_ONLINEGIVING_BEGIN });
    try {
      await authFetch.patch(`/payment/clear`, data);
      getOnlineGiving();
      dispatch({
        type: UPDATE_ONLINEGIVING_SUCCESS,
        payload: { alertText },
      });
      getContainers();
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: UPDATE_ONLINEGIVING_ERROR,
        payload: { msg: error.response.data.message },
      });
    }
    clearAlert();
  };

  ////Tags
  const getRootFolder = async () => {
    try {
      const { data } = await authFetch(`/tags/get/root-folders-tags`);
      const rootFolder = data;

      dispatch({
        type: GET_ROOT_FOLDER,
        payload: { rootFolder },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const getFolder = async (id) => {
    try {
      const { data } = await authFetch(`/tags/get-folder?folderId=${id}`);

      const folderDetail = data.folder;

      dispatch({
        type: GET_FOLDER,
        payload: { folderDetail },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const addTag = async (id, tagName, campusName) => {
    dispatch({ type: ADD_MINISTRY_BEGIN });

    try {
      const response = await authFetch.post(
        id ? `/tags/create-tag?folderId=${id}` : `/tags/create-tag`,
        { tagName: tagName, campusName: campusName }
      );
      if (response.status === 200 || response.status === 201) {
        dispatch({ type: ADD_MINISTRY_SUCCESS });
      }
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: ADD_MINISTRY_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
    clearAlert();
  };

  const allTags = async () => {
    try {
      const { data } = await authFetch(`/tags/all-tags`);
      const allTagsData = data;
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const editTag = async (id, tagName, campusName) => {
    dispatch({ type: UPDATE_MINISTRY_BEGIN });
    try {
      const response = await authFetch.put(
        id ? `/tags/update-tag?tagId=${id}` : "",
        {
          tagName: tagName,
          campusName: campusName,
        }
      );

      dispatch({ type: UPDATE_MINISTRY_SUCCESS });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: UPDATE_MINISTRY_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
    clearAlert();
  };

  const deleteTag = async (id) => {
    dispatch({ type: DELETE_MINISTRY_BEGIN });
    try {
      const response = await authFetch.delete(`/tags/delete-tag?tagId=${id}`);

      if (response.status === 200 || response.status === 201) {
        dispatch({ type: DELETE_MINISTRY_SUCCESS });
      }
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: DELETE_MINISTRY_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
    clearAlert();
  };

  const addFolder = async (id, folderName, churchName) => {
    dispatch({ type: ADD_MINISTRY_FOLDER_BEGIN });
    try {
      const response = await authFetch.post(
        id ? `/tags/create-folder?parentFolderId=${id}` : `/tags/create-folder`,
        { folderName: folderName }
      );
      if (response.status === 200 || response.status === 201) {
        dispatch({ type: ADD_MINISTRY_FOLDER_SUCCESS });
      }
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: ADD_MINISTRY_FOLDER_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
    clearAlert();
  };

  const editFolder = async (id, folderName) => {
    dispatch({ type: UPDATE_MINISTRY_FOLDER_BEGIN });
    try {
      const response = await authFetch.put(
        id ? `/tags/update-folder?folderId=${id}` : "",
        {
          folderName: folderName,
        }
      );
      dispatch({ type: UPDATE_MINISTRY_FOLDER_SUCCESS });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: UPDATE_MINISTRY_FOLDER_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
    clearAlert();
  };

  const assignLeader = async (id, data) => {
    try {
      await authFetch.put(`/members/make-lead/${id}`, {
        data,
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const unAssignLeader = async (id, data) => {
    try {
      await authFetch.put(`/members/unmake-lead/${id}`, {
        data,
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const deleteFolder = async (id) => {
    dispatch({ type: DELETE_MINISTRY_FOLDER_BEGIN });
    try {
      const response = await authFetch.delete(
        `/tags/delete-folder?folderId=${id}`
      );
      if (response.status === 200 || response.status === 201) {
        dispatch({ type: DELETE_MINISTRY_FOLDER_SUCCESS });
      }
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: DELETE_MINISTRY_FOLDER_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
    clearAlert();
  };

  //////

  // const getPricing = async () => {
  //   dispatch({ type: GET_PRICING_BEGIN });
  //   try {
  //     let config = {
  //       method: "get",
  //       maxBodyLength: Infinity,
  //       url: process.env.REACT_APP_BACKEND_URL + "/payment/prices",
  //       headers: {
  //         Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET}`, // Add this line
  //       },
  //     };
  //     const response = await axios.request(config);

  //     const prices = response.data || {};

  //     dispatch({
  //       type: GET_PRICING_SUCCESS,
  //       payload: {
  //         prices,
  //       },
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   clearAlert();
  // };

  const axiosConfig = () => ({
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BACKEND_URL}/payment/prices`,
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET}`,
    },
  });

  const getPricing = async () => {
    dispatch({ type: GET_PRICING_BEGIN });

    try {
      const config = axiosConfig();
      const response = await axios.request(config);
      const prices = response.data || {};

      dispatch({
        type: GET_PRICING_SUCCESS,
        payload: { prices },
      });
    } catch (error) {
      console.error(
        "An error occurred while fetching pricing information:",
        error
      );
    } finally {
      clearAlert(); // Ensure clearAlert is called in both success and error scenarios
    }
  };

  const getPricingAuth = async () => {
    dispatch({ type: GET_PRICING_BEGIN1 });
    try {
      const { data } = await authFetch(`/payment/auth/prices`);

      const pricesAuth = data;

      dispatch({
        type: GET_PRICING_SUCCESS1,
        payload: {
          pricesAuth,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const createSubscription = async (priceId) => {
    dispatch({ type: CREATE_SUBSCRIPTION_BEGIN });
    try {
      const { data } = await authFetch.post(`/payment/create-subscription`, {
        priceId,
      });

      const stripesub = data;

      dispatch({
        type: CREATE_SUBSCRIPTION_SUCCESS,
        payload: {
          stripesub,
        },
      });
    } catch (error) {
      //logoutUser();
    }
    clearAlert();
  };

  const statusSubscription = async () => {
    dispatch({ type: STATUS_SUBSCRIPTION_BEGIN });
    try {
      const { data } = await authFetch(`/payment/status-subscription`);

      const statusSub = data;

      dispatch({
        type: STATUS_SUBSCRIPTION_SUCCESS,
        payload: {
          statusSub,
        },
      });
    } catch (error) {}
    clearAlert();
  };

  // const onlinePayment = async (paymentData) => {
  //   dispatch({ type: ADD_ONLINEPAYMENT_BEGIN });

  //   try {
  //     // Make an HTTP PUT request to submit the donation data
  //     const { data } = await authFetch.put(
  //       "/payment/online-giving",
  //       donationData
  //     );

  //     // Check if the necessary data is available in the response
  //     if (data && data.insertedId) {
  //       // Store the insertedId in local storage
  //       localStorage.setItem("sk", JSON.stringify(data.insertedId));
  //       setSkData(true);
  //     } else {
  //       // Log and handle cases where the response data is not as expected
  //       console.error("Invalid response data format:", data);
  //       // Here you might want to update the state to show an error message to the user
  //     }
  //   } catch (error) {
  //     // Log the error for debugging
  //     console.error("Error during submission:", error);

  //     // Handle different types of errors
  //     if (error.response) {
  //       // If the error has a response object, it's an HTTP error
  //       if (error.response.status === 401) {
  //         // Handle 401 Unauthorized error
  //         // Redirect to login or show an error message
  //       } else {
  //         // Handle other HTTP errors
  //         // Update the state to show an error message to the user
  //       }
  //     } else {
  //       // Handle non-HTTP errors (e.g., network errors)
  //       // Update the state to show a generic error message to the user
  //     }
  //   }
  // };

  const [canViewMemberDashboard, setCanViewMemberDashboard] = useState(false);
  const [canViewFinanceDashboard, setCanViewFinanceDashboard] = useState(false);
  const [canViewEventDashboard, setCanViewEventDashboard] = useState(false);
  const [canCreateMemberProfile, setCanCreateMemberProfile] = useState(false);
  const [canEditMemberProfile, setCanEditMemberProfile] = useState(false);
  const [canDeleteMemberProfile, setCanDeleteMemberProfile] = useState(false);
  const [canReadMemberProfile, setCanReadMemberProfile] = useState(false);
  const [canSendSMSToMember, setCanSendSMSToMember] = useState(false);
  const [canEmailMember, setCanEmailMember] = useState(false);
  const [canDeleteMultipleMember, setCanDeleteMultipleMember] = useState(false);
  const [AccessToAdvancedSearchOnMember, setAccessToAdvancedSearchOnMember] =
    useState(false);
  const [canCreateFolder, setCanCreateFolder] = useState(false);
  const [canEditFolder, setCanEditFolder] = useState(false);
  const [canDeleteFolder, setCanDeleteFolder] = useState(false);
  const [canReadFolder, setCanReadFolder] = useState(false);
  const [canCreateGroup, setCanCreateGroup] = useState(false);
  const [canDeleteGroup, setCanDeleteGroup] = useState(false);
  const [canEditGroup, setCanEditGroup] = useState(false);
  const [canReadGroup, setCanReadGroup] = useState(false);
  const [canAddAttendance, setCanAddAttendance] = useState(false);
  const [canViewAttendance, setCanViewAttendance] = useState(false);
  const [canEditAttendance, setCanEditAttendance] = useState(false);
  const [canDeleteAttendance, setCanDeleteAttendance] = useState(false);
  const [canCreateForm, setCanCreateForm] = useState(false);
  const [canEditForm, setCanEditForm] = useState(false);
  const [canReadForm, setCanReadForm] = useState(false);
  const [canDeleteForm, setCanDeleteForm] = useState(false);
  const [canAddGiving, setCanAddGiving] = useState(false);
  const [canViewBatchDetails, setCanViewBatchDetails] = useState(false);
  const [canAddPaymentCategory, setCanAddPaymentCategory] = useState(false);
  const [canAddMethods, setCanAddMethods] = useState(false);
  const [canGenerateReport, setCanGenerateReport] = useState(false);
  const [canGenerateStatements, setCanGenerateStatements] = useState(false);
  const [canGenerateOnlineGivingLink, setCanGenerateOnlineGivingLink] =
    useState(false);
  const [canCreateCourse, setCanCreateCourse] = useState(false);
  const [canEditCourse, setCanEditCourse] = useState(false);
  const [canDeleteCourse, setCanDeleteCourse] = useState(false);
  const [canReadCourse, setCanReadCourse] = useState(false);
  const [canViewCourse, setCanViewCourse] = useState(false);
  const [canPublishCourse, setCanPublishCourse] = useState(false);
  const [canUnpublishCourse, setCanUnpublishCourse] = useState(false);
  const [canAssignCourse, setCanAssignCourse] = useState(false);
  const [canAddPrayerRequest, setCanAddPrayerRequest] = useState(false);
  const [canDeletePrayerRequest, setCanDeletePrayerRequest] = useState(false);
  const [canReadPrayerRequest, setCanReadPrayerRequest] = useState(false);
  const [canViewPrayerRequest, setCanViewPrayerRequest] = useState(false);
  const [canDownloadPrayerRequest, setCanDownloadPrayerRequest] =
    useState(false);
  const [canReinstatePrayerRequest, setCanReinstatePrayerRequest] =
    useState(false);

  const implementPermission = async () => {
    const { data } = await authFetch.get("/auth/getCurrentUser");

    const { user } = data;

    const filteredRoles = user?.role?.filter((role) =>
      role?.permissions?.some((permission) => permission?.selected)
    );

    ////////////////////////Dashboard/////////////////

    const isViewFinance1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "View Finance Dashboard"
      )
    );

    const isViewMember1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "View Member Dashboard"
      )
    );

    const isViewEvent1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "View Event Dashboard"
      )
    );

    const isCreateMember1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Create Member Profile"
      )
    );

    const isEditMember1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Edit Member Profile"
      )
    );

    const isDeleteMember1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Delete Member Profile"
      )
    );

    const isReadMember1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Read Member Profile"
      )
    );

    const isSendSms1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Send SMS to Members"
      )
    );

    const isEmailMember1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Email Members"
      )
    );

    const isDeleteMultiple1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Delete Multiple Members"
      )
    );

    const isAccessToAdvancedSearch1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected &&
          permission.label === "Access to Advanced Search on Members"
      )
    );

    const isViewEvent = { ...isViewEvent1 };
    const isViewMember = { ...isViewMember1 };
    const isViewFinance = { ...isViewFinance1 };
    const isCreateMember = { ...isCreateMember1 };
    const isEditMember = { ...isEditMember1 };
    const isDeleteMember = { ...isDeleteMember1 };
    const isReadMember = { ...isReadMember1 };
    const isSendSms = { ...isSendSms1 };
    const isEmailMember = { ...isEmailMember1 };
    const isDeleteMultiple = { ...isDeleteMultiple1 };
    const isAccessToAdvancedSearch = { ...isAccessToAdvancedSearch1 };

    if (
      isViewFinance[0]?.selected &&
      isViewFinance[0].label === "View Finance Dashboard"
    ) {
      setCanViewFinanceDashboard(true);
    }

    if (
      isViewMember[0]?.selected &&
      isViewMember[0].label === "View Member Dashboard"
    ) {
      setCanViewMemberDashboard(true);
    }

    if (
      isViewEvent[0]?.selected &&
      isViewEvent[0].label === "View Event Dashboard"
    ) {
      setCanViewEventDashboard(true);
    }

    if (
      isCreateMember[0]?.selected &&
      isCreateMember[0].label === "Create Member Profile"
    ) {
      setCanCreateMemberProfile(true);
    }

    if (
      isEditMember[0]?.selected &&
      isEditMember[0].label === "Edit Member Profile"
    ) {
      setCanEditMemberProfile(true);
    }

    if (
      isDeleteMember[0]?.selected &&
      isDeleteMember[0].label === "Delete Member Profile"
    ) {
      setCanDeleteMemberProfile(true);
    }

    if (
      isReadMember[0]?.selected &&
      isReadMember[0].label === "Read Member Profile"
    ) {
      setCanReadMemberProfile(true);
    }

    if (
      isSendSms[0]?.selected &&
      isSendSms[0].label === "Send SMS to Members"
    ) {
      setCanSendSMSToMember(true);
    }

    if (
      isEmailMember[0]?.selected &&
      isEmailMember[0].label === "Email Members"
    ) {
      setCanEmailMember(true);
    }

    if (
      isDeleteMultiple[0]?.selected &&
      isDeleteMultiple[0].label === "Delete Multiple Members"
    ) {
      setCanDeleteMultipleMember(true);
    }

    if (
      isAccessToAdvancedSearch[0]?.selected &&
      isAccessToAdvancedSearch[0].label ===
        "Access to Advanced Search on Members"
    ) {
      setAccessToAdvancedSearchOnMember(true);
    }

    //////////Department///////
    const isCreateFolder1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Create Folder"
      )
    );

    const isEditFolder1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Edit Folder"
      )
    );

    const isDeleteFolder1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Delete Folder"
      )
    );

    const isReadFolder1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Read Folder"
      )
    );

    const isCreateGroup1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Create Group"
      )
    );

    const isDeleteGroup1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Delete Group"
      )
    );

    const isEditGroup1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) => permission.selected && permission.label === "Edit Group"
      )
    );

    const isReadGroup1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) => permission.selected && permission.label === "Read Group"
      )
    );

    const isCreateFolder = { ...isCreateFolder1 };
    const isEditFolder = { ...isEditFolder1 };
    const isDeleteFolder = { ...isDeleteFolder1 };
    const isReadFolder = { ...isReadFolder1 };
    const isCreateGroup = { ...isCreateGroup1 };
    const isDeleteGroup = { ...isDeleteGroup1 };
    const isEditGroup = { ...isEditGroup1 };
    const isReadGroup = { ...isReadGroup1 };

    if (
      isCreateFolder[0]?.selected &&
      isCreateFolder[0].label === "Create Folder"
    ) {
      setCanCreateFolder(true);
    }

    if (isEditFolder[0]?.selected && isEditFolder[0].label === "Edit Folder") {
      setCanEditFolder(true);
    }

    if (
      isDeleteFolder[0]?.selected &&
      isDeleteFolder[0].label === "Delete Folder"
    ) {
      setCanDeleteFolder(true);
    }

    if (isReadFolder[0]?.selected && isReadFolder[0].label === "Read Folder") {
      setCanReadFolder(true);
    }

    if (
      isCreateGroup[0]?.selected &&
      isCreateGroup[0].label === "Create Group"
    ) {
      setCanCreateGroup(true);
    }

    if (
      isDeleteGroup[0]?.selected &&
      isDeleteGroup[0].label === "Delete Group"
    ) {
      setCanDeleteGroup(true);
    }

    if (isEditGroup[0]?.selected && isEditGroup[0].label === "Edit Group") {
      setCanEditGroup(true);
    }

    if (isReadGroup[0]?.selected && isReadGroup[0].label === "Read Group") {
      setCanReadGroup(true);
    }

    //////////Attendance///////
    const isAddAttendance1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Add Attendance"
      )
    );

    const isViewAttendance1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "View Attendance"
      )
    );

    const isEditAttendance1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "View Attendance"
      )
    );

    const isDeleteAttendance1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Delete Attendance"
      )
    );

    const isAddAttendance = { ...isAddAttendance1 };
    const isViewAttendance = { ...isViewAttendance1 };
    const isEditAttendance = { ...isEditAttendance1 };
    const isDeleteAttendance = { ...isDeleteAttendance1 };

    if (
      isAddAttendance[0]?.selected &&
      isAddAttendance[0].label === "Add Attendance"
    ) {
      setCanAddAttendance(true);
    }

    if (
      isViewAttendance[0]?.selected &&
      isViewAttendance[0].label === "View Attendance"
    ) {
      setCanViewAttendance(true);
    }

    if (
      isEditAttendance[0]?.selected &&
      isEditAttendance[0].label === "Edit Attendance"
    ) {
      setCanEditAttendance(true);
    }

    if (
      isDeleteAttendance[0]?.selected &&
      isDeleteAttendance[0].label === "Delete Attendance"
    ) {
      setCanDeleteAttendance(true);
    }

    //////////Form///////

    const isCreateForm1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Create Form"
      )
    );

    const isEditForm1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) => permission.selected && permission.label === "Edit Form"
      )
    );

    const isReadForm1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) => permission.selected && permission.label === "Read Form"
      )
    );

    const isDeleteForm1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Delete Form"
      )
    );

    const isCreateForm = { ...isCreateForm1 };
    const isEditForm = { ...isEditForm1 };
    const isReadForm = { ...isReadForm1 };
    const isDeleteForm = { ...isDeleteForm1 };

    if (isCreateForm[0]?.selected && isCreateForm[0].label === "Create Form") {
      setCanCreateForm(true);
    }

    if (isEditForm[0]?.selected && isEditForm[0].label === "Edit Form") {
      setCanEditForm(true);
    }

    if (isReadForm[0]?.selected && isReadForm[0].label === "Read Form") {
      setCanReadForm(true);
    }

    if (isDeleteForm[0]?.selected && isDeleteForm[0].label === "Delete Form") {
      setCanDeleteForm(true);
    }

    ///////////Giving///////////

    const isAddGiving1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) => permission.selected && permission.label === "Add Giving"
      )
    );

    const isViewBatchDetails1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "View Batch Details"
      )
    );

    const isAddPaymentCategory1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Add Giving Category"
      )
    );

    const isAddMethods1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Add Methods"
      )
    );

    const isGenerateReport1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Generate Report"
      )
    );

    const isGenerateStatements1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Generate Statements"
      )
    );

    const isGenerateOnlineGivingLink1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected &&
          permission.label === "Generate Online giving link"
      )
    );

    const isAddGiving = { ...isAddGiving1 };
    const isViewBatchDetails = { ...isViewBatchDetails1 };
    const isAddPaymentCategory = { ...isAddPaymentCategory1 };
    const isAddMethods = { ...isAddMethods1 };
    const isGenerateReport = { ...isGenerateReport1 };
    const isGenerateStatements = { ...isGenerateStatements1 };
    const isGenerateOnlineGivingLink = { ...isGenerateOnlineGivingLink1 };

    if (isAddGiving[0]?.selected && isAddGiving[0].label === "Add Giving") {
      setCanAddGiving(true);
    }

    if (
      isViewBatchDetails[0]?.selected &&
      isViewBatchDetails[0].label === "View Batch Details"
    ) {
      setCanViewBatchDetails(true);
    }

    if (isAddMethods[0]?.selected && isAddMethods[0].label === "Add Methods") {
      setCanAddMethods(true);
    }

    if (
      isGenerateReport[0]?.selected &&
      isGenerateReport[0].label === "Generate Report"
    ) {
      setCanGenerateReport(true);
    }

    if (
      isGenerateStatements[0]?.selected &&
      isGenerateStatements[0].label === "Generate Statements"
    ) {
      setCanGenerateStatements(true);
    }

    if (
      isGenerateOnlineGivingLink[0]?.selected &&
      isGenerateOnlineGivingLink[0].label === "Generate Online giving link"
    ) {
      setCanGenerateOnlineGivingLink(true);
    }

    if (
      isAddPaymentCategory[0]?.selected &&
      isAddPaymentCategory[0].label === "Add Giving Category"
    ) {
      setCanAddPaymentCategory(true);
    }

    if (isReadGroup[0]?.selected && isReadGroup[0].label === "Read Group") {
      setCanReadGroup(true);
    }

    //////Learning///////
    const isCreateCourse1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Create Course"
      )
    );

    const isEditCourse1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Edit Course"
      )
    );

    const isDeleteCourse1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Delete Course"
      )
    );

    const isReadCourse1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Read Course"
      )
    );

    const isViewCourse1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "View Course"
      )
    );

    const isPublishCourse1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Publish Course"
      )
    );

    const isUnpublishCourse1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Unpublish Course"
      )
    );

    const isAssignCourse1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Assign Course"
      )
    );

    const isCreateCourse = { ...isCreateCourse1 };
    const isEditCourse = { ...isEditCourse1 };
    const isDeleteCourse = { ...isDeleteCourse1 };
    const isReadCourse = { ...isReadCourse1 };
    const isViewCourse = { ...isViewCourse1 };
    const isPublishCourse = { ...isPublishCourse1 };
    const isUnpublishCourse = { ...isUnpublishCourse1 };
    const isAssignCourse = { ...isAssignCourse1 };

    if (
      isCreateCourse[0]?.selected &&
      isCreateCourse[0].label === "Create Course"
    ) {
      setCanCreateCourse(true);
    }

    if (isEditCourse[0]?.selected && isEditCourse[0].label === "Edit Course") {
      setCanEditCourse(true);
    }

    if (
      isDeleteCourse[0]?.selected &&
      isDeleteCourse[0].label === "Delete Course"
    ) {
      setCanDeleteCourse(true);
    }

    if (isReadCourse[0]?.selected && isReadCourse[0].label === "Read Course") {
      setCanReadCourse(true);
    }

    if (isViewCourse[0]?.selected && isViewCourse[0].label === "View Course") {
      setCanViewCourse(true);
    }

    if (
      isPublishCourse[0]?.selected &&
      isPublishCourse[0].label === "Publish Course"
    ) {
      setCanPublishCourse(true);
    }

    if (
      isUnpublishCourse[0]?.selected &&
      isUnpublishCourse[0].label === "Unpublish Course"
    ) {
      setCanUnpublishCourse(true);
    }

    if (
      isAssignCourse[0]?.selected &&
      isAssignCourse[0].label === "Assign Course"
    ) {
      setCanAssignCourse(true);
    }

    ///////////////////Prayer//////////

    const isAddPrayer1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Add Prayer Request"
      )
    );

    const isDeletePrayer1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Delete Prayer Request"
      )
    );

    const isReadPrayer1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Read Prayer"
      )
    );

    const isViewPrayer1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected &&
          permission.label === "View Prayer Request Archive"
      )
    );

    const isDownloadPrayer1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected &&
          permission.label === "Download Prayer Request (Archive)"
      )
    );

    const isReinstatePrayer1 = filteredRoles.flatMap((role) =>
      role.permissions.find(
        (permission) =>
          permission.selected && permission.label === "Reinstate Prayer Request"
      )
    );

    const isAddPrayer = { ...isAddPrayer1 };
    const isDeletePrayer = { ...isDeletePrayer1 };
    const isReadPrayer = { ...isReadPrayer1 };
    const isViewPrayer = { ...isViewPrayer1 };
    const isDownloadPrayer = { ...isDownloadPrayer1 };
    const isReinstatePrayer = { ...isReinstatePrayer1 };

    if (
      isAddPrayer[0]?.selected &&
      isAddPrayer[0].label === "Add Prayer Request"
    ) {
      setCanAddPrayerRequest(true);
    }

    if (
      isDeletePrayer[0]?.selected &&
      isDeletePrayer[0].label === "Delete Prayer Request"
    ) {
      setCanDeletePrayerRequest(true);
    }

    if (isReadPrayer[0]?.selected && isReadPrayer[0].label === "Read Prayer") {
      setCanReadPrayerRequest(true);
    }

    if (
      isViewPrayer[0]?.selected &&
      isViewPrayer[0].label === "Download Prayer Request (Archive)"
    ) {
      setCanViewPrayerRequest(true);
    }

    if (
      isDownloadPrayer[0]?.selected &&
      isDownloadPrayer[0].label === "View Course"
    ) {
      setCanDownloadPrayerRequest(true);
    }

    if (
      isReinstatePrayer[0]?.selected &&
      isReinstatePrayer[0].label === "Reinstate Prayer Request"
    ) {
      setCanReinstatePrayerRequest(true);
    }
  };

  //////////////////Prayer/////////////////////

  return (
    <AppContext.Provider
      value={{
        ...state,
        userColor,
        setUserColor,
        displayAlert,
        getPrayerRequest,
        canViewEventDashboard,
        canViewMemberDashboard,
        canViewFinanceDashboard,
        implementPermission,
        addPermission,
        updateAttendance,
        getPricingAuth,
        statusSubscription,
        createSubscription,
        setupUser,
        registerMember,
        userDemo,
        toggleSidebar,
        logoutUser,
        setPage,
        updateUser,
        handleChange,
        clearValues,
        getSchedules,
        createSchedule,
        updateSchedule,
        deleteSchedule,
        deleteApplication,
        createMember,
        getAllMembers,
        setEditMember,
        deleteMember,
        editMember,
        updateMember,
        showStats,
        getEventByVolunteers,
        clearFilters,
        clearFiltersMembers,
        changePage,
        createEvent,
        getEvents,
        forgotPassword,
        resetPassword,
        addGiving,
        getCompleteMembers,
        deleteMethod,
        getPricing,
        getMethod,
        addMethod,
        getFund,
        deleteFund,
        createPrayerRequest,
        getReport,
        addFund,
        batchSummary,
        batchDetails,
        getMemberById,
        getRootFolder,
        getFolder,
        addTag,
        editTag,
        assignLeader,
        unAssignLeader,
        allTags,
        deleteTag,
        addFolder,
        getApplications,
        deleteFolder,
        editFolder,
        addToGroup,
        removeFromGroup,
        listofGroupMembers,
        getCompleteUsers,
        notifyNewMember,
        notifyUpdatedMember,
        newUser,
        updatePassword,
        deleteEvent,
        updateEvent,
        savedView,
        getSavedView,
        getViewById,
        memberbyMonth,
        addAttendance,
        showAttendance,
        RecentMembers,
        bulkEmailtoMembers,
        deleteView,
        sendBulkSMS,
        applicationViewById,
        getGiving,
        getAllFund,
        updateMemberDetails,
        getMenu,
        //getCheckin,
        createCheckin,
        allMenu,
        setAllMenu,
        getPermission,
        canCreateFolder,
        canEditFolder,
        canDeleteFolder,
        canReadFolder,
        canCreateGroup,
        canDeleteGroup,
        canEditGroup,
        canReadGroup,
        canAddAttendance,
        canViewAttendance,
        canEditAttendance,
        canDeleteAttendance,
        canCreateForm,
        canEditForm,
        canReadForm,
        canDeleteForm,
        canAddGiving,
        canViewBatchDetails,
        canAddPaymentCategory,
        canAddMethods,
        canGenerateReport,
        canGenerateStatements,
        canCreateCourse,
        canGenerateOnlineGivingLink,
        updatePrayer,
        canEditCourse,
        canDeleteCourse,
        canReadCourse,
        canViewCourse,
        canUnpublishCourse,
        canPublishCourse,
        canAssignCourse,
        canCreateMemberProfile,
        canEditMemberProfile,
        canReadMemberProfile,
        canSendSMSToMember,
        canDeleteMemberProfile,
        canDeleteMultipleMember,
        canEmailMember,
        canAddPrayerRequest,
        canDeletePrayerRequest,
        canReadPrayerRequest,
        canViewPrayerRequest,
        canDownloadPrayerRequest,
        canReinstatePrayerRequest,
        AccessToAdvancedSearchOnMember,
        deletePrayer,
        getStream,
        resetNewMemberPassword,
        createForm,
        getForm,
        getFormById,
        formResponse,
        updateForm,
        deleteForm,
        deletedRole,
        uploadMemberMany,
        uploadGivingMany,
        addSettings,
        getAllSettings,
        deleteSettings,
        deleteFollowUp,
        getAllFollowUp,
        updatedSetting,
        scheduleViewById,
        getMailChimpStatus,
        createService,
        serviceViewById,
        updateService,
        deleteService,
        getServices,
        // addContainer,
        getContainers,
        //deleteContainer,
        getContainerById,
        editContainer,
        // addToContainer,
        //removeFromContainer,
        getContainerMembers,
        quickAddToContainer,
        SetupContainer,
        addWorkflow,
        getWorkflows,
        createCampus,
        OnlineGiving,
        updateColor,
        getOnlineGiving,
        updateOnlineGiving,
        addExpense,
        getExpense,
        deleteExpense,
        getAllExpense,
        getExpenseDetails,
        churchAccount,
        registerMemberTrial,
        userNeed,
        childCheckin,
        setRowsPerPage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, initialState, useAppContext };
