import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Form } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CustomAlert from "../../../components/CustomAlert/CustomAlert.js";
import { useAppContext } from "../../../context/appContext";
import Alert from "../../../components/Alert/Alert.js";

const PasswordInput = ({ label, value, onChange, id }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Form.Group className="mb-3">
      <Form.Label style={{ fontWeight: "600" }}>{label}</Form.Label>
      <div style={{ position: "relative" }}>
        <Form.Control
          type={showPassword ? "text" : "password"}
          id={id}
          name={id}
          value={value}
          onChange={onChange}
          className="forminput"
          style={{ paddingRight: "40px" }} // Add padding to prevent text overlap with the icon
        />
        <button
          type="button"
          onClick={() => setShowPassword(!showPassword)}
          style={{
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            background: "none",
            border: "none",
            padding: 0,
            cursor: "pointer",
            color: "#6c757d",
          }}
        >
          {showPassword ? <FaEyeSlash size={16} /> : <FaEye size={16} />}
        </button>
      </div>
    </Form.Group>
  );
};

const ChangePassword = () => {
  const { isLoading, showAlert, updatePassword } = useAppContext();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [renewPassword, reNewPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!currentPassword || !newPassword || !renewPassword) {
      setIsError(true);
      return;
    }

    if (newPassword !== renewPassword) {
      setError(true);
      return;
    }

    const userPassword = {
      password: currentPassword,
      newPassword: newPassword,
    };

    updatePassword({
      userPassword,
    });

    setCurrentPassword("");
    setNewPassword("");
    reNewPassword("");
  };

  return (
    <Container fluid className="container2">
      <Row className="headerbanner">
        <div className="bannerbig">Change Password</div>
      </Row>
      <Row className="body">
        <Col>
          <div className="register leftgivediv">
            <Form className="srgerged">
              <br />
              {isError && (
                <CustomAlert
                  alertText="All fields are required."
                  alertType="danger"
                />
              )}
              {error && (
                <CustomAlert
                  alertText="Password does not match."
                  alertType="danger"
                />
              )}
              {showAlert && <Alert />}

              <PasswordInput
                label="Current Password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                id="currentPassword"
              />

              <PasswordInput
                label="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                id="newPassword"
              />

              <PasswordInput
                label="Reenter Password"
                value={renewPassword}
                onChange={(e) => reNewPassword(e.target.value)}
                id="renewPassword"
              />

              <button
                type="button"
                className="wedweded"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                Change Password
              </button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ChangePassword;
