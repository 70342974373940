import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Modal,
  Form,
  Spinner,
} from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { useAppContext } from "../../../context/appContext";

const GivingReconciliation = () => {
  // State Management
  const { user } = useAppContext();
  const [unreconciled, setUnreconciled] = useState([]);

  const [selectedBatch, setSelectedBatch] = useState("");
  const [selectedUpload, setSelectedUpload] = useState("");
  const [selectedGivings, setSelectedGivings] = useState([]);
  const [detailGiving, setDetailGiving] = useState(null);
  const [showDetailModal, setShowDetailModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const [selectedGiving, setSelectedGiving] = useState(null);
  const [showNewMemberModal, setShowNewMemberModal] = useState(false);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalRecords: 0,
  });
  const [filters, setFilters] = useState({
    search: "",
    batchId: "",
    bulkUploadId: "",
    limit: 10,
  });

  // API Client
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: { "Content-Type": "application/json" },
  });

  useEffect(() => {
    fetchUnreconciled();
  }, [selectedBatch, selectedUpload]);

  const [actionLoading, setActionLoading] = useState(false);

  const [newMemberForm, setNewMemberForm] = useState({
    mfirstName: "",
    mlastName: "",
    memail: "",
    mmobilePhone: "",
  });

  useEffect(() => {
    if (selectedGiving) {
      const [firstName, ...lastNameParts] = selectedGiving.giverName.split(" ");
      setNewMemberForm({
        mfirstName: firstName || "",
        mlastName: lastNameParts.join(" ") || "",
        memail: selectedGiving.email || "",
        mmobilePhone: selectedGiving.phone || "",
      });
    }
  }, [selectedGiving]);

  const handleCreateMember = async (e) => {
    e.preventDefault();
    setActionLoading(true); // Add this
    try {
      if (!newMemberForm.mfirstName || !newMemberForm.mlastName) {
        toast.error("First name and last name are required");
        return;
      }
      const currentMember = {
        ...newMemberForm,
        churchName: user.churchName,
        role: [{ roleName: "Standard" }],
        givingId: selectedGiving._id,
      };

      await authFetch.post("/giving/manualrecongiving", currentMember);
      await fetchUnreconciled();
      setShowNewMemberModal(false);
      setShowDetailModal(false);
      toast.success("Member created and giving reconciled");
    } catch (error) {
      toast.error("Error creating member");
    } finally {
      setActionLoading(false); // Add this
    }
  };

  useEffect(() => {
    fetchUnreconciled();
  }, [pagination.currentPage, filters]);
  // Fetch Unreconciled Givings
  const fetchUnreconciled = async () => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams({
        page: pagination.currentPage, // Ensure currentPage is a number
        limit: filters.limit,
        ...(filters.search && { search: filters.search }),
        ...(filters.batchId && { batchId: filters.batchId }),
        ...(filters.bulkUploadId && { bulkUploadId: filters.bulkUploadId }),
      });

      const response = await authFetch.get(
        `/giving/unreconciled?${queryParams}`
      );

      setUnreconciled(response.data.givings);
      setPagination((prev) => ({
        ...prev,
        currentPage: response.data.currentPage,
        totalPages: response.data.pages,
        totalRecords: response.data.total,
      }));
    } catch (error) {
      toast.error("Error fetching unreconciled givings");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pagination.totalPages) {
      setPagination((prev) => ({
        ...prev,
        currentPage: newPage,
      }));
    }
  };
  // Fetch Members

  // Batch Reconciliation

  // Export Unreconciled Givings
  const handleExport = async () => {
    try {
      const batchId = unreconciled.map((giving) => giving.batchId)[0];
      const bulkUploadId = unreconciled.map((giving) => giving.bulkUploadId)[0];

      const response = await authFetch.get(
        `/giving/export-unreconciled?batchId=${batchId}&bulkUploadId=${bulkUploadId}`,
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "unreconciled-givings.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      toast.error("Error exporting givings");
    }
  };

  return (
    <Container fluid>
      <Row className="headerbanner">
        <Col>
          <h2 className="bannerbig">Giving Reconciliation</h2>
          <p className="bannersmall">
            Effortlessly resolve unmatched church donations with our
            user-friendly platform. Simplify the reconciliation process, ensure
            accuracy, and maintain seamless tracking of all contributions.
          </p>
        </Col>
      </Row>
      {/* Filters */}
      <Row className="mb-3">
        <Col className="d-flex justify-content-end">
          <button onClick={handleExport} className="jiluil4">
            Export to CSV
          </button>
        </Col>
      </Row>
      {/* Table */}
      <Row>
        <Col>
          {loading ? (
            <div className="text-center my-5">
              <Spinner animation="border" role="status" variant="primary">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <p className="mt-2">Loading givings...</p>
            </div>
          ) : unreconciled.length === 0 ? (
            <p>No data available to reconcile.</p>
          ) : (
            <div className="d-flex justify-content-center my-4">
              <Table striped bordered hover className="mb-0 text-start">
                <thead>
                  <tr>
                    <th>Giver Name</th>
                    <th>Contact</th>
                    <th>Amount</th>
                    <th>Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {unreconciled.map((giving) => (
                    <tr key={giving._id}>
                      <td>{giving.giverName}</td>
                      <td>
                        <div>{giving.email}</div>
                      </td>
                      <td>${giving.amount}</td>
                      <td>
                        {new Date(giving.givingDate).toLocaleDateString()}
                      </td>
                      <td>
                        <button
                          className="jiluil"
                          size="sm"
                          onClick={() => {
                            setSelectedGivings([giving._id]);
                            setDetailGiving(giving);
                            setShowDetailModal(true);
                          }}
                        >
                          Reconcile
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </Col>
      </Row>
      {/* Detail Modal */}
      <Modal
        show={showDetailModal}
        onHide={() => setShowDetailModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Reconcile Giving</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {detailGiving && (
            <>
              {/* Giving Details */}
              <div className="giving-details p-3 bg-light rounded mb-4">
                <Row>
                  <Col md={6}>
                    <h6>Giver Information</h6>
                    <Table borderless size="sm">
                      <tbody>
                        <tr>
                          <td>
                            <strong>Name:</strong>
                          </td>
                          <td>{detailGiving.giverName}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Email:</strong>
                          </td>
                          <td>{detailGiving.email || "Not provided"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Phone:</strong>
                          </td>
                          <td>{detailGiving.phone || "Not provided"}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col md={6}>
                    <h6>Giving Information</h6>
                    <Table borderless size="sm">
                      <tbody>
                        <tr>
                          <td>
                            <strong>Amount:</strong>
                          </td>
                          <td>${detailGiving.amount}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Date:</strong>
                          </td>
                          <td>
                            {new Date(
                              detailGiving.givingDate
                            ).toLocaleDateString()}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Category:</strong>
                          </td>
                          <td>{detailGiving.category}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Method:</strong>
                          </td>
                          <td>{detailGiving.paymentMethod}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>

              {/* Action Buttons */}
              <div className="d-flex justify-content-between">
                {/* <Button
                  variant="primary"
                  onClick={() => {
                    setSelectedGiving(detailGiving);
                    setShowNewMemberModal(true);
                  }}
                >
                  Create New Member
                </Button> */}
                <Button
                  variant="primary"
                  onClick={() => {
                    setSelectedGiving(detailGiving);
                    setShowNewMemberModal(true);
                  }}
                >
                  Create New Member
                </Button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={showNewMemberModal}
        onHide={() => setShowNewMemberModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleCreateMember}>
            <Form.Group className="mb-3">
              <Form.Label>
                First Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={newMemberForm.mfirstName}
                onChange={(e) =>
                  setNewMemberForm((prev) => ({
                    ...prev,
                    mfirstName: e.target.value,
                  }))
                }
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Last Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={newMemberForm.mlastName}
                onChange={(e) =>
                  setNewMemberForm((prev) => ({
                    ...prev,
                    mlastName: e.target.value,
                  }))
                }
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={newMemberForm.memail}
                onChange={(e) =>
                  setNewMemberForm((prev) => ({
                    ...prev,
                    memail: e.target.value,
                  }))
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="tel"
                value={newMemberForm.mmobilePhone}
                onChange={(e) =>
                  setNewMemberForm((prev) => ({
                    ...prev,
                    mmobilePhone: e.target.value,
                  }))
                }
              />
            </Form.Group>
            <div className="d-flex justify-content-end gap-2">
              <Button
                variant="secondary"
                onClick={() => setShowNewMemberModal(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={actionLoading}
                style={{ paddingTop: "20px" }}
              >
                {actionLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                    Creating...
                  </>
                ) : (
                  "Create Member"
                )}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <div className="flex justify-between align-center mt-3">
        <span>
          Showing {unreconciled.length} of {pagination.totalRecords} entries
        </span>
        <div className="flex align-center">
          <button
            variant="outline-primary"
            size="sm"
            className="me-2 jiluil"
            disabled={pagination.currentPage === 1}
            onClick={() => handlePageChange(pagination.currentPage - 1)}
          >
            Previous
          </button>
          <span className="mx-2 jiluil3">
            Page {pagination.currentPage} of {pagination.totalPages}
          </span>
          <button
            className="jiluil"
            size="sm"
            disabled={pagination.currentPage === pagination.totalPages}
            onClick={() => handlePageChange(pagination.currentPage + 1)}
          >
            Next
          </button>
        </div>
      </div>
    </Container>
  );
};

export default GivingReconciliation;
