import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../../context/appContext";
import "./attendance.css";
import Alert from "../../../components/Alert/Alert";
import moment from "moment/moment";
import axios from "axios";
import BarChart from "../../../components/BarChart/BarChart2";
import { isNonNegativeInteger } from "../../../utils";

const Attendance = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [show, setShow] = useState(false);
  const [isAttendanceExist, setAttendanceExist] = useState(false);
  const handleClose = () => setShow(false);
  const [selectedId, setSelectedId] = useState();
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);

  const [male, setMale] = useState();
  const [female, setFemale] = useState();
  const [children, setChildren] = useState();

  const [total, setTotal] = useState();
  const [eventId, setEventId] = useState();
  const [singleData, setSingleData] = useState();
  const [male1, setMale1] = useState();
  const [female1, setFemale1] = useState();
  const [children1, setChildren1] = useState();

  const [eventId1, setEventId1] = useState();
  const [zoom1, setZoom1] = useState();
  const [faceBook1, setFaceBook1] = useState();
  const [instagram1, setInstagram1] = useState();
  const [youtube1, setYoutube1] = useState();
  const [total1, setTotal1] = useState();
  const [data, setData] = useState();
  const [zoom, setZoom] = useState();
  const [faceBook, setFaceBook] = useState();
  const [instagram, setInstagram] = useState();
  const [youtube, setYoutube] = useState();

  const {
    showAlert,

    logoutUser,
    deleteEvent,
    addAttendance,

    events,
    getEvents,
    updateAttendance,
  } = useAppContext();

  useEffect(() => {
    if (isAttendanceExist) {
      setTimeout(() => {
        setAttendanceExist(false);
      }, 3000);
    }
  }, [setAttendanceExist]);

  const getEventById = async (value) => {
    try {
      const { data } = await authFetch(`/events/getattendancebyid/${value}`);

      if (data && value) {
        setSingleData(data);
      }
    } catch (error) {
      logoutUser();
    }
  };

  // Extract users older than 30 and add them to a new object
  const olderUsers = events.filter(
    (user) =>
      !!user?.children &&
      !!user?.male &&
      !!user?.female &&
      !!user?.zoom &&
      !!user?.faceBook &&
      !!user?.instagram &&
      !!user?.youtube
  );

  const handleClicked = (value) => {
    setShow1(true);
    if (value) {
      setSelectedId(value);
    }

    getEventById(value);
  };

  useEffect(() => {
    events && getEvents();
  }, []);

  useEffect(() => {
    if (singleData?.singleEvent?.faceBook) {
      setFaceBook1(singleData?.singleEvent?.faceBook);
    }
    if (singleData?.singleEvent?.instagram) {
      setInstagram1(singleData?.singleEvent?.instagram);
    }
    if (singleData?.singleEvent?.youtube) {
      setYoutube1(singleData?.singleEvent?.youtube);
    }

    if (singleData?.singleEvent?.zoom) {
      setZoom1(singleData?.singleEvent?.zoom);
    }
    if (singleData?.singleEvent?.male) {
      setMale1(singleData?.singleEvent?.male);
    }
    if (singleData?.singleEvent?.female) {
      setFemale1(singleData?.singleEvent?.female);
    }
    if (singleData?.singleEvent?.children) {
      setChildren1(singleData?.singleEvent?.children);
    }
    if (singleData?.singleEvent?.total) {
      setTotal1(singleData?.singleEvent?.total);
    }
    if (singleData?.singleEvent?._id) {
      setEventId1(singleData?.singleEvent?._id);
    }
  }, [singleData]);

  const [error, setError] = useState(false);
  const [errorx, setErrorx] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !male ||
      !female ||
      !children ||
      !eventId ||
      !zoom ||
      !faceBook ||
      !instagram ||
      !youtube
    ) {
      setError(true);
      setAttendanceExist(false);
      return;
    }

    if (
      !isNonNegativeInteger(male) ||
      !isNonNegativeInteger(female) ||
      !isNonNegativeInteger(children) ||
      !isNonNegativeInteger(zoom) ||
      !isNonNegativeInteger(faceBook) ||
      !isNonNegativeInteger(instagram) ||
      !isNonNegativeInteger(youtube)

      // Assuming eventId is a string or number and has different validation
    ) {
      setErrorx(true);
      setAttendanceExist(false);
      setError(false);
      return;
    }
    let check = olderUsers.find((o) => o._id === eventId);
    if (!check) {
      const addAttendancex = {
        male: male,
        female: female,
        children: children,

        zoom: zoom,
        faceBook: faceBook,
        instagram: instagram,
        youtube: youtube,
        total:
          +male +
          +female +
          +children +
          +zoom +
          +faceBook +
          +instagram +
          +youtube,
        _id: eventId,
      };

      addAttendance({ addAttendancex });
      setMale("");
      setFemale("");
      setChildren("");

      setTotal("");
      setEventId("");
      setZoom("");
      setFaceBook("");
      setInstagram("");
      setYoutube("");

      getEvents();
      handleClose();
    } else {
      setAttendanceExist(true);
      setError(false);
    }
  };

  const handleSubmit1 = async (e) => {
    e.preventDefault();

    if (
      !isNonNegativeInteger(male1) ||
      !isNonNegativeInteger(female1) ||
      !isNonNegativeInteger(children1) ||
      !isNonNegativeInteger(zoom1) ||
      !isNonNegativeInteger(faceBook1) ||
      !isNonNegativeInteger(instagram1) ||
      !isNonNegativeInteger(youtube1)
      // Assuming eventId is a string or number and has different validation
    ) {
      setErrorx(true);
      setAttendanceExist(false);
      setError(false);
      return;
    }

    const addAttendancenew = {
      male: male1,
      female: female1,
      children: children1,

      zoom: zoom1,
      faceBook: faceBook1,
      instagram: instagram1,
      youtube: youtube1,

      total:
        +male1 +
        +female1 +
        +children1 +
        +zoom1 +
        +faceBook1 +
        +instagram1 +
        +youtube1,
      _id: eventId1,
    };

    updateAttendance({ addAttendancenew }, eventId1);
    setMale1("");
    setFemale1("");
    setChildren1("");

    setTotal1("");
    setZoom1("");
    setFaceBook1("");
    setInstagram1("");
    setYoutube1("");
    setEventId1("");

    handleClose1();
    getEvents();
  };

  const handleVisual = (value, item) => {
    setShow2(true);
    getEventById(value);
    if (value) {
      setSelectedId(value);
    }
    // getEventById(value);
    const data1 = {
      Male: item?.male,
      Female: item.female,
      Children: item?.children,

      Zoom: item?.zoom,
      FaceBook: item?.faceBook,
      Instagram: item?.instagram,
      Youtube: item?.youtube,
    };

    const data2 = Object.entries(data1).map(([key, value]) => ({ key, value }));
    setData(data2);
  };

  return (
    <>
      <Container fluid>
        {showAlert && <Alert />}
        <Row className="fundc" style={{ marginBottom: "2rem" }}>
          <Col className="sahs">Add Attendance</Col>
        </Row>

        <Row className="sssssssecwef">
          <div className="bnmjo">
            <button className="jiluil" onClick={handleShow}>
              Add Attendance
            </button>
          </div>
        </Row>

        <Row className="fundc2">
          <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
            <thead>
              <tr>
                <th style={{ fontSize: "1em" }}>Event Date</th>
                <th style={{ fontSize: "1em" }}>Event Title</th>
                <th style={{ fontSize: "1em", textAlign: "center" }}>Action</th>
              </tr>
            </thead>

            <tbody>
              {olderUsers.map((methods) => (
                <tr key={methods._id}>
                  <td style={{ fontSize: "1em" }}>
                    {moment(`${methods.eventDay}`).format("MMM DD, YYYY")}
                  </td>
                  <td style={{ fontSize: "1em" }}>
                    {methods.eventTitle} -{" "}
                    {moment(`${methods.eventDay}`).format("MMM DD, YYYY")}
                  </td>

                  <td
                    style={{
                      gap: "10px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      className="wewecwe1"
                      onClick={() => handleVisual(methods._id, methods)}
                    >
                      View
                    </button>

                    <button
                      className="wewecwe"
                      onClick={() => handleClicked(methods._id)}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
            Add Attendance
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {isAttendanceExist && (
              <div>
                <p className="inlineerror">
                  Attendance already added for this event!
                </p>
              </div>
            )}

            {error && (
              <div>
                <p className="inlineerror">Add attendance to proceed!</p>
              </div>
            )}
            {errorx && (
              <div>
                <p className="inlineerror">
                  Invalidate numbers, check and try again!
                </p>
              </div>
            )}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Select
                aria-label="Default select example"
                value={eventId}
                onChange={(e) => setEventId(e.target.value)}
                style={{ fontSize: "1em" }}
              >
                <option>Select Event</option>
                {events
                  .filter((event) =>
                    moment(event.eventDay).isSameOrAfter(moment(), "day")
                  ) // Filter for upcoming events
                  .sort((a, b) => moment(a.eventDay).diff(moment(b.eventDay))) // Sort by most recent (upcoming) dates
                  .map((event, index) => (
                    <option key={index} value={event._id}>
                      {`${event.eventTitle} - ${moment(event.eventDay).format(
                        "MMMM Do YYYY"
                      )}`}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Control
                type="number"
                placeholder="Men"
                value={male}
                onChange={(e) => setMale(e.target.value)}
                name="male"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Control
                type="number"
                placeholder="Women"
                value={female}
                onChange={(e) => setFemale(e.target.value)}
                name="female"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="number"
                placeholder="Children"
                value={children}
                onChange={(e) => setChildren(e.target.value)}
                name="children"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Control
                type="number"
                placeholder="Zoom"
                value={zoom}
                onChange={(e) => setZoom(e.target.value)}
                name="zoom"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="number"
                placeholder="Facebook"
                value={faceBook}
                onChange={(e) => setFaceBook(e.target.value)}
                name="faceBook"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="number"
                placeholder="Instagram"
                value={instagram}
                onChange={(e) => setInstagram(e.target.value)}
                name="instagram"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Control
                type="number"
                placeholder="Youtube"
                value={youtube}
                onChange={(e) => setYoutube(e.target.value)}
                name="youtube"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <div style={{ fontSize: "1em" }}>
                {male &&
                  female &&
                  children &&
                  instagram &&
                  zoom &&
                  faceBook &&
                  youtube && (
                    <p>
                      <span style={{ fontWeight: "600" }}>
                        Total Attendance:
                      </span>{" "}
                      {+male +
                        +female +
                        +children +
                        +zoom +
                        +faceBook +
                        +instagram +
                        +youtube}
                    </p>
                  )}
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="jiluil3" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="jiluil" onClick={handleSubmit}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      {/* //////////////////////////////////////// */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontWeight: "600", fontSize: "100%" }}>
            Edit Attendance
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "100%" }}>
          <Form>
            <Form.Group className="mb-3">
              <Form.Select
                aria-label="Default select example"
                value={eventId1}
                onChange={(e) => setEventId1(e.target.value)}
              >
                <option value={singleData?.singleEvent?._id}>
                  {singleData?.singleEvent?.eventTitle} -{" "}
                  {moment(singleData?.singleEvent?.eventDay).format(
                    "MMMM Do YYYY"
                  )}
                </option>
                {events
                  .filter((event) =>
                    moment(event.eventDay).isSameOrAfter(moment(), "day")
                  ) // Filter for upcoming events
                  .sort((a, b) => moment(a.eventDay).diff(moment(b.eventDay))) // Sort by most recent (upcoming) dates
                  .map((event, index) => (
                    <option key={index} value={event._id}>
                      {`${event.eventTitle} - ${moment(event.eventDay).format(
                        "MMMM Do YYYY"
                      )}`}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Male</Form.Label>
              <Form.Control
                type="number"
                placeholder="Men"
                value={male1}
                onChange={(e) => setMale1(e.target.value)}
                name="male"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Female</Form.Label>
              <Form.Control
                type="number"
                placeholder="Women"
                value={female1}
                onChange={(e) => setFemale1(e.target.value)}
                name="female"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Children</Form.Label>
              <Form.Control
                type="number"
                placeholder="Children"
                value={children1}
                onChange={(e) => setChildren1(e.target.value)}
                name="children1"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Zoom</Form.Label>
              <Form.Control
                type="number"
                placeholder="Zoom"
                value={zoom1}
                onChange={(e) => setZoom1(e.target.value)}
                name="zoom1"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Facebook</Form.Label>
              <Form.Control
                type="number"
                placeholder="Facebook"
                value={faceBook1}
                onChange={(e) => setFaceBook1(e.target.value)}
                name="faceBook1"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Instagram</Form.Label>
              <Form.Control
                type="number"
                placeholder="Instagram"
                value={instagram1}
                onChange={(e) => setInstagram1(e.target.value)}
                name="instagram1"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Youtube</Form.Label>
              <Form.Control
                type="number"
                placeholder="Youtube"
                value={youtube1}
                onChange={(e) => setYoutube1(e.target.value)}
                name="youtube1"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <div style={{ fontSize: "1em" }}>
                {male1 &&
                  female1 &&
                  children1 &&
                  zoom1 &&
                  faceBook1 &&
                  instagram1 &&
                  youtube1 && (
                    <p>
                      <span style={{ fontWeight: "600" }}>
                        Total Attendance:
                      </span>{" "}
                      {+male1 +
                        +female1 +
                        +children1 +
                        +zoom1 +
                        +faceBook1 +
                        +instagram1 +
                        +youtube1}
                    </p>
                  )}
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="wewecwex" onClick={handleClose1}>
            Cancel
          </button>
          <button className="wewecwe" onClick={handleSubmit1}>
            Update
          </button>
        </Modal.Footer>
      </Modal>

      {/* //////////////////////////////////////// */}
      <Modal show={show2} onHide={handleClose2} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={{ fontWeight: "600", fontSize: "100%" }}>
            {singleData?.singleEvent?.eventTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "100%" }}>
          <BarChart data={data} />
        </Modal.Body>
        <Modal.Footer>
          <button className="wewecwe" onClick={handleClose2}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Attendance;
