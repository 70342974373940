import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faHome,
  faChurch,
  faUsers,
  faPrayingHands,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "animate.css";
import { text } from "@fortawesome/fontawesome-svg-core";

const styles = {
  container: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: `linear-gradient(135deg, #243060 0%, #1a2340 100%)`,
    position: "relative",
    overflow: "hidden",
  },
  backgroundPattern: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.05,
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M54.627 0l.83.828-1.415 1.415L51.8 0h2.827zM5.373 0l-.83.828L5.96 2.243 8.2 0H5.374zM48.97 0l3.657 3.657-1.414 1.414L46.143 0h2.828zM11.03 0L7.372 3.657 8.787 5.07 13.857 0H11.03zm32.284 0L49.8 6.485 48.384 7.9l-7.9-7.9h2.83zM16.686 0L10.2 6.485 11.616 7.9l7.9-7.9h-2.83zM22.344 0L13.858 8.485 15.272 9.9l9.9-9.9h-2.828zM32.686 0L22.344 10.343 23.758 11.757 35.515 0h-2.829zM48.97 54.627l3.657 3.657-1.414 1.414-3.657-3.657 1.414-1.414zM11.03 54.627l-3.657 3.657 1.414 1.414 3.657-3.657-1.414-1.414zM17.515 48.97l-5.657 5.657 1.414 1.414 5.657-5.657-1.414-1.414zM30.343 35.515l-2.828 2.828 1.414 1.414 2.828-2.828-1.414-1.414z' fill='%23ffffff' fill-opacity='1' fill-rule='evenodd'/%3E%3C/svg%3E")`,
  },
  card: {
    backgroundColor: "white",
    borderRadius: "20px",
    boxShadow: "0 20px 40px rgba(0, 0, 0, 0.2)",
    padding: "3.5rem",
    maxWidth: "600px",
    width: "100%",
    animation: "slideInUp 0.8s ease-out",
  },
  iconWrapper: {
    backgroundColor: "rgba(254, 217, 112, 0.1)",
    borderRadius: "50%",
    width: "120px",
    height: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto 2rem",
    animation: "bounceIn 1s ease-out",
  },
  icon: {
    color: "#FED970",
    fontSize: "4rem",
    filter: "drop-shadow(0 4px 6px rgba(254, 217, 112, 0.3))",
  },
  title: {
    color: "#243060",
    fontSize: "2.5rem",
    fontWeight: "800",
    marginBottom: "1.5rem",
    textAlign: "center",
    lineHeight: "1.2",
  },
  subtitle: {
    color: "#666",
    fontSize: "1.2rem",
    marginBottom: "2.5rem",
    textAlign: "center",
    lineHeight: "1.6",
  },
  featuresGrid: {
    display: "flex",
    justifyContent: "center",
    gap: "1.5rem",
    marginBottom: "2.5rem",
  },
  featureItem: {
    textAlign: "center",
    padding: "1rem",
    backgroundColor: "rgba(36, 48, 96, 0.03)",
    borderRadius: "12px",
    transition: "transform 0.3s ease",
    width: "150px",
  },
  featureIcon: {
    color: "#243060",
    fontSize: "1.8rem",
    marginBottom: "0.5rem",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
  },
  featureText: {
    color: "#666",
    fontSize: "0.9rem",
    margin: 0,
  },
  button: {
    backgroundColor: "#243060",
    border: "none",
    borderRadius: "12px",
    color: "white",
    fontSize: "1.1rem",
    padding: "1rem 2rem",
    transition: "all 0.3s ease",
    cursor: "pointer",
    width: "100%",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.8rem",
  },
  buttonHover: {
    backgroundColor: "#FE7064",
    transform: "translateY(-2px)",
    boxShadow: "0 4px 15px rgba(254, 112, 100, 0.3)",
  },
};

const SuccessRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    toast.success("Welcome to ChurchEase! Your profile has been created.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }, []);

  const handleReturn = () => {
    navigate("/", { replace: true });
  };

  const features = [
    { icon: faChurch, text: "Connect with Your Church" },
    { icon: faUsers, text: "Join the Community" },
    { icon: faPrayingHands, text: "Grow in Faith" },
  ];

  return (
    <>
      <Helmet>
        <title>Welcome to ChurchEase | Profile Created Successfully</title>
        <meta
          name="description"
          content="Your ChurchEase profile has been created successfully. Start connecting with your church community today!"
        />
      </Helmet>

      <Container fluid style={styles.container}>
        <div style={styles.backgroundPattern} />
        <div style={styles.card}>
          <div style={styles.iconWrapper}>
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={styles.icon}
              className="animate__animated animate__pulse animate__infinite"
            />
          </div>

          <h1 style={styles.title}>Welcome to ChurchEase!</h1>
          <p style={styles.subtitle}>
            Your profile has been created successfully. You're now part of our
            growing community of churches and believers.
          </p>

          <div style={styles.featuresGrid}>
            {features.map((feature, index) => (
              <div
                key={index}
                style={styles.featureItem}
                className="animate__animated animate__fadeInUp"
                style={{ animationDelay: `${index * 0.2}s` }}
              >
                <FontAwesomeIcon
                  icon={feature.icon}
                  style={styles.featureIcon}
                />
                <p style={styles.featureText}>{feature.text}</p>
              </div>
            ))}
          </div>

          <button
            style={{
              ...styles.button,
              position: "relative",
              zIndex: 1000, // Force it above other elements
            }}
            onClick={handleReturn}
          >
            Return to Dashboard
          </button>
        </div>
      </Container>
    </>
  );
};

export default SuccessRedirect;
