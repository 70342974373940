import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import axios from "axios";

const FieldSelectorModal = ({ show, setShow }) => {
  const { logoutUser, user } = useAppContext();
  const [selectedFields, setSelectedFields] = useState([]);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) logoutUser();
      return Promise.reject(error);
    }
  );

  // Pre-selected required fields
  const requiredFields = ["firstName", "lastName", "email"];

  // Field definitions with metadata
  const availableFields = [
    {
      id: "firstName",
      label: "First Name",
      section: "General",
      required: true,
      preSelected: true,
    },
    {
      id: "lastName",
      label: "Last Name",
      section: "General",
      required: true,
      preSelected: true,
    },
    {
      id: "email",
      label: "Email",
      section: "Contact",
      required: true,
      preSelected: true,
    },
    { id: "gender", label: "Gender", section: "General", required: false },
    { id: "status", label: "Status", section: "General", required: false },
    { id: "birthday", label: "Birthday", section: "General", required: false },
    {
      id: "anniversary",
      label: "Anniversary",
      section: "General",
      required: false,
    },
    {
      id: "maritalStatus",
      label: "Marital Status",
      section: "General",
      required: false,
    },
    {
      id: "mobilePhone",
      label: "Mobile Phone",
      section: "Contact",
      required: false,
    },
    {
      id: "dateJoined",
      label: "Date Joined",
      section: "General",
      required: false,
    },
    {
      id: "prayerRequest",
      label: "Prayer Request",
      section: "General",
      required: false,
    },
    {
      id: "homePhone",
      label: "Home Phone",
      section: "Contact",
      required: false,
    },
    { id: "address", label: "Address", section: "Contact", required: false },
    { id: "city", label: "City", section: "Contact", required: false },
    { id: "state", label: "State", section: "Contact", required: false },
    { id: "postal", label: "Postal Code", section: "Contact", required: false },
    {
      id: "familyRole",
      label: "Family Role",
      section: "Family",
      required: false,
    },
    {
      id: "addFamily",
      label: "Family Members",
      section: "Family",
      required: false,
    },
  ];

  // Load saved field configuration on mount
  useEffect(() => {
    const loadSavedFields = async () => {
      try {
        const response = await authFetch.get(`/new-member-setup`);
        // Extract just the fieldNames from the response
        const savedFieldNames = response.data.config.fields.map(
          (field) => field.fieldName
        );
        setSelectedFields(savedFieldNames);
      } catch (error) {
        console.error("Failed to load field configuration:", error);
        setError("Failed to load saved fields");
      }
    };

    if (show) {
      loadSavedFields();
    }
  }, [show, user.churchDomain]);

  const handleFieldToggle = (fieldId) => {
    setSelectedFields((prev) => {
      // Don't allow toggling of required fields
      if (requiredFields.includes(fieldId)) return prev;

      // If field is already selected, remove it
      if (prev.includes(fieldId)) {
        return prev.filter((id) => id !== fieldId);
      }
      // If field is not selected, add it
      return [...prev, fieldId];
    });
  };
  const handleSave = async () => {
    setSaving(true);
    setError(null);

    try {
      // Format fields and required fields
      const fieldsToSave = {
        fields: selectedFields, // Send as simple array
        requiredFields, // Send as simple array
        churchDomain: user.churchDomain,
        churchName: user.churchName,
        churchLogo: user.churchLogo.Location || user.churchName,
      };

      console.log("Sending data:", fieldsToSave); // For debugging
      await authFetch.post(`/new-member-setup`, fieldsToSave);
      setShow(false);
    } catch (error) {
      console.error("Failed to save field configuration:", error);
      setError("Failed to save configuration");
    } finally {
      setSaving(false);
    }
  };

  // Group fields by section
  const groupedFields = availableFields.reduce((acc, field) => {
    if (!acc[field.section]) {
      acc[field.section] = [];
    }
    acc[field.section].push(field);
    return acc;
  }, {});

  return (
    <Modal show={show} onHide={() => setShow(false)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Quick Registration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}

        <Form>
          {Object.entries(groupedFields).map(([section, fields]) => (
            <div key={section} className="mb-4">
              <h5 className="mb-3">{section} Information</h5>
              {fields.map((field) => (
                <Form.Check
                  key={field.id}
                  type="switch"
                  id={field.id}
                  label={
                    <span>
                      {field.label}
                      {field.required && (
                        <span className="text-danger ms-1">* (Required)</span>
                      )}
                    </span>
                  }
                  checked={selectedFields.includes(field.id)}
                  onChange={() => handleFieldToggle(field.id)}
                  disabled={field.required}
                />
              ))}
            </div>
          ))}
        </Form>

        <div className="mt-3 p-3 bg-light rounded">
          <h6>Note:</h6>
          <ul className="mb-0">
            <li>Fields marked with * are required and cannot be disabled</li>
            <li>Selected fields will be visible in your registration form</li>
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil" onClick={() => setShow(false)}>
          Cancel
        </button>
        <button className="jiluil" onClick={handleSave} disabled={saving}>
          {saving ? "Saving..." : "Save Configuration"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default FieldSelectorModal;
