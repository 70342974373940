import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToastContent from "../../components/Toast/CustomToastContent";
import "./profile.css";
import { Modal, ProgressBar, Form, Container, Row, Col } from "react-bootstrap";
import { useAppContext } from "../../context/appContext";

const TextMeter = ({ show, setShow }) => {
  const { user, logoutUser } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [fullscreen, setFullscreen] = useState(true);

  const handleClose = () => setShow(false);

  const [storageUsage, setStorageUsage] = useState(0);
  const [storageSpace, setStorageSpace] = useState(0);
  const [smsCount, setSmsCount] = useState(0);
  const [smsUsage, setSmsUsage] = useState(0);

  useEffect(() => {
    if (user) {
      setStorageUsage(user.storageUsage);
      setStorageSpace(user.storageSpace);
      setSmsCount(user.smsCount);
      setSmsUsage(user.smsUsage);
    }
  }, [user]);

  const [showSelect, setShowSelect] = useState(false);
  const [selectedIncrease, setSelectedIncrease] = useState("");
  const [price, setPrice] = useState(null);

  const prices = {
    50: 10,
    100: 18,
    200: 34,
    500: 80,
    1000: 150,
    2000: 280,
  };

  const handleToggleSelect = () => {
    setShowSelect((prevShowSelect) => !prevShowSelect); // Toggle the current state
    setPrice(null); // Reset price
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedIncrease(value);
    setPrice(prices[value]); // Set price based on selected value
  };

  const handleRequestIncrease = async () => {
    try {
      const data = await authFetch.post("/email/increasesms", {
        increase: selectedIncrease,
        price: price,
        email: user.email,
      });

      if (data.status === 200) {
        setShowSelect(false);
        setPrice(null);
        setShow(false);
        toast(
          <CustomToastContent
            title="Success"
            message="Request Submitted Successfully"
            type="success"
          />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal show={show} fullscreen={fullscreen} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Text Analytics</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid className="textme">
          <Row>
            <div className="text-meter">
              <p className="textmtext">Text Meter</p>
              <p>
                Your account has been alloted a total of {smsCount} text
                messages per month. You have used {smsUsage} text messages this
                month.
              </p>
            </div>
          </Row>
          <Row>
            <div className="flex flex-col">
              <ProgressBar now={user.smsUsage} label={`${user.smsUsage}%`} />
              <p className="flex justify-end">
                {user.smsUsage}/{user.smsCount}
              </p>

              <div className="flex justify-center flex-col w-100">
                <button
                  className="jiluil4 "
                  onClick={handleToggleSelect}
                  style={{ width: "30%" }}
                >
                  {showSelect ? "Hide SMS Units" : "Increase SMS Units"}
                </button>

                {showSelect && (
                  <Form.Select
                    aria-label="Default select example"
                    onChange={handleSelectChange}
                    style={{ width: "50%", marginTop: "20px" }}
                  >
                    <option>Select Increment</option>
                    {Object.entries(prices).map(([key, value]) => (
                      <option key={key} value={key}>
                        {key} units
                      </option>
                    ))}
                  </Form.Select>
                )}
              </div>

              {price && showSelect && (
                <div>
                  <p className="mt-3" style={{ fontSize: "1.2em" }}>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Price for upgrade:
                    </span>{" "}
                    ${price}
                  </p>
                  <div className="flex justify-center">
                    <button
                      className="jiluil mt-3 mb-3"
                      style={{ width: "20%" }}
                      onClick={handleRequestIncrease}
                    >
                      Request Increase
                    </button>
                  </div>
                  <p>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Note
                    </span>
                    : The text messaging upgrade is an annual one-time payment,
                    separate from your subscription fee. Upon request, an
                    invoice will be generated and sent to your registered admin
                    email address.
                  </p>
                </div>
              )}
            </div>
          </Row>
          <Row>
            <div
              className="flex align-center justify-center flex-column w-100 pt-5"
              style={{ textAlign: "center" }}
            >
              <hr></hr>
              <p className="qubulkf2">
                Check our{" "}
                <a
                  href="https://churchease.com/knowledgebaseview/6670848bc20672005705ff40"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "var(--secondary-color)",
                    textDecoration: "none",
                  }}
                >
                  knowledge base
                </a>{" "}
                to learn more about managing your church's monthly sms usage.
              </p>
            </div>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default TextMeter;
