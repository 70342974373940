import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { useAppContext } from "../../context/appContext";
import { SiMailchimp } from "react-icons/si";
import axios from "axios";
import "./profile.css";
import {
  FaPhoneAlt,
  FaInfoCircle,
  FaCheckCircle,
  FaExclamationTriangle,
} from "react-icons/fa";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";

const TextSetup = ({ show, setShow }) => {
  const { user, logoutUser } = useAppContext();
  // ... existing state declarations ...
  const [uniqueTextId, setUniqueTextId] = useState({});
  const [uniqueText, setUniqueText] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [phone, setPhone] = useState("");
  const [selectedPhone, setSelectedPhone] = useState("");
  const [alloted, setAlloted] = useState(false);
  const [error, setError] = useState(false);
  const [dataValue, setDataValue] = useState(false);
  const [userNumber, setUserNumber] = useState(user.primaryPhoneNumber);
  const [selectedNumber, setSelectedNumber] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadingNumbers, setLoadingNumbers] = useState(false);

  // Get available phone numbers
  const getPhoneNumbers = async () => {
    setLoadingNumbers(true);
    try {
      const response = await authFetch.get("/text");
      setPhoneNumbers(response.data);
      if (response.data.length === 0) {
        toast(
          <CustomToastContent
            title="Notice"
            message="No phone numbers available in your region. Please try again later."
          />
        );
      }
    } catch (error) {
      console.error("Error fetching numbers:", error);
    } finally {
      setLoadingNumbers(false);
    }
  };

  const getTextById = async () => {
    try {
      const response = await authFetch.get(`/text/getnumberId/${userNumber}`);
      const texts = response.data;
      const [data] = texts;
      setUniqueText(data);
      const text = texts.length > 0 ? texts[0] : null;
      setUniqueTextId(text);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (user && user.primaryPhoneNumber) {
      setUserNumber(user.primaryPhoneNumber);
      setDataValue(user._id);
    }
  }, [user]);

  useEffect(() => {
    getTextById();
  }, [user.primaryPhoneNumber]);

  useEffect(() => {
    if (!selectedNumber) {
      getPhoneNumbers();
    }
  }, []);

  useEffect(() => {
    getMemberById();
  }, []);

  const getMemberById = async () => {
    try {
      const response = await authFetch.get(`/members/getthisuser/${user._id}`);

      if (response.status === 200 || response.status === 201) {
        const { data } = response;

        setSelectedNumber(data.primaryPhoneNumber);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedPhone) {
      toast(
        <CustomToastContent
          title="Error"
          message="Please choose a preferred phone number to continue!"
        />
      );
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await authFetch.post("/text/push", {
        primaryPhoneNumber: selectedPhone,
        churchId: user._id,
      });

      if (response.status === 200 || response.status === 201) {
        await getMemberById();
        handleClose();
        await getPhoneNumbers();
        await getTextById();
        handleClosexx();
        toast(
          <CustomToastContent
            title="Success"
            message="Phone number successfully assigned!"
          />
        );
      }
    } catch (error) {
      console.error("Error assigning number:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const [fullscreen, setFullscreen] = useState(true);

  const handleClose = () => setShow(false);

  const [showxx, setShowxx] = useState(false);
  const handleClosexx = () => setShowxx(false);

  const handleShowxx = () => {
    console.log("selectedPhone", selectedPhone);
    if (selectedPhone) {
      setShowxx(true);
    }
  };
  return (
    <>
      <Modal show={show} fullscreen={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <div className="flex items-center gap-2">
            <FaPhoneAlt className="text-primary" />
            <Modal.Title>Text Messaging Setup</Modal.Title>
          </div>
        </Modal.Header>

        <Modal.Body className="bg-light">
          <div className="container py-4">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-8">
                {/* Warning Alert for Missing Country */}
                {!user.country && (
                  <div className="alert alert-warning d-flex align-items-center mb-4">
                    <FaExclamationTriangle className="me-2" />
                    <div>
                      Please update your church information and country to
                      choose a phone number.
                    </div>
                  </div>
                )}

                {/* Phone Number Selection Card */}
                <div className="card shadow-sm mb-4">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h5 className="card-title mb-0">Select Phone Number</h5>
                      {!selectedNumber && (
                        <span className="text-primary">
                          {phoneNumbers.length} numbers available
                        </span>
                      )}
                    </div>

                    {selectedNumber ? (
                      <div className="bg-light p-3 rounded d-flex align-items-center">
                        <FaCheckCircle className="text-success me-2" />
                        <div>
                          <p className="fw-bold mb-0">{selectedNumber}</p>
                          <small className="text-muted">
                            Primary Phone Number
                          </small>
                        </div>
                      </div>
                    ) : (
                      <Form>
                        <Form.Group className="mb-3">
                          <Form.Select
                            disabled={loadingNumbers}
                            value={selectedPhone}
                            onChange={(e) => setSelectedPhone(e.target.value)}
                          >
                            <option value="">
                              {loadingNumbers
                                ? "Loading available numbers..."
                                : "Select a phone number"}
                            </option>
                            {phoneNumbers?.map((phone) => (
                              <option key={phone._id} value={phone.phoneNumber}>
                                {phone.friendlyName} (
                                {phone.locality ||
                                  phone.region ||
                                  phone.isoCountry}
                                )
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>

                        <button
                          type="button"
                          className="btn btn-primary btn-lg w-100 d-flex align-items-center justify-content-center gap-2"
                          disabled={!user.country || loadingNumbers}
                          onClick={handleShowxx}
                        >
                          <SiMailchimp />
                          <span>Take Number</span>
                        </button>
                      </Form>
                    )}
                  </div>
                </div>

                {/* Information Cards */}
                <div className="card shadow-sm mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-start mb-3">
                      <FaInfoCircle className="text-primary mt-1 me-2" />
                      <div>
                        <h6 className="fw-bold">Important Note</h6>
                        <p className="text-muted mb-0">
                          The selected phone number will be used for all text
                          communications in your church. This action can only be
                          performed once. Selected phone numbers cannot be
                          modified for the entire lifecycle of the church.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card bg-primary bg-opacity-10">
                  <div className="card-body">
                    <p className="mb-0">
                      Check our{" "}
                      <a
                        href="https://churchease.com/knowledgebaseview/66716274c206720057060140"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary text-decoration-none"
                      >
                        knowledge base
                      </a>{" "}
                      for more information on configuring text messaging for
                      your church.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Confirmation Modal */}
      <Modal
        show={showxx}
        onHide={handleClosexx}
        className="darkerxxxxx-backdrop"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="d-flex align-items-center gap-2">
              <FaExclamationTriangle className="text-warning" />
              Confirm Selection
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            This action can only be performed once. Are you sure you want to
            take this number?
          </p>
        </Modal.Body>

        <Modal.Footer>
          <button className="jiluil3" onClick={handleClosexx}>
            Cancel
          </button>
          <button
            className="jiluil"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Assigning..." : "Confirm"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TextSetup;
