import React, { useState, useMemo } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/appContext";
import {
  FaSearch,
  FaFilter,
  FaTimes,
  FaPlus,
  FaLink,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import AddShareLink from "./AddShareLink";

// Active filter tag component
const FilterTag = ({ label, value, onClear }) => (
  <div className="d-inline-flex align-items-center bg-light rounded-pill px-3 py-1 me-2 mb-2">
    <span className="text-secondary me-2">{label}:</span>
    <span className="fw-medium">{value}</span>
    <FaTimes
      onClick={onClear}
      className="ms-2 text-danger"
      style={{ cursor: "pointer" }}
    />
  </div>
);

const PeopleHeader = () => {
  const {
    isLoading,
    user,
    searchStatus,
    searchMarital,
    searchGender,
    searchAnniversaryMonth,
    searchBirthMonth,
    searchCampus,
    sort,
    handleChange,
    clearFiltersMembers,
  } = useAppContext();

  const [showAdvanced, setShowAdvanced] = useState(false);
  const [localSearch, setLocalSearch] = useState("");
  const [showShareModal, setShowShareModal] = useState(false);
  const navigate = useNavigate();

  const handleSearch = (e) => {
    handleChange({ name: e.target.name, value: e.target.value });
  };

  const debounce = () => {
    let timeoutID;
    return (e) => {
      setLocalSearch(e.target.value);
      clearTimeout(timeoutID);
      timeoutID = setTimeout(() => {
        handleChange({ name: e.target.name, value: e.target.value });
      }, 300);
    };
  };

  const optimizedDebounce = useMemo(() => debounce(), []);

  const clearSearchFilter = (filterType) => {
    handleChange({ name: filterType, value: "all" });
    if (filterType === "search") {
      setLocalSearch("");
      handleChange({ name: "search", value: "" });
    }
  };

  const isFilterActive = () => {
    return (
      localSearch?.trim() ||
      searchStatus !== "all" ||
      searchGender !== "all" ||
      searchMarital !== "all" ||
      searchAnniversaryMonth !== "all" ||
      searchBirthMonth !== "all" ||
      searchCampus !== "all"
    );
  };

  return (
    <Container fluid className="py-4" style={{ marginTop: "2rem" }}>
      {/* Main Search and Actions */}
      <Row className="align-items-center mb-3">
        <Col>
          <div className="position-relative">
            <Form.Control
              type="text"
              placeholder="Search by name, email, or phone..."
              value={localSearch}
              onChange={optimizedDebounce}
              name="search"
              style={{
                height: "48px",
                paddingLeft: "40px", // More space from left edge
                paddingRight: "40px", // Space for the icon
                letterSpacing: "0.2px", // Slightly spread out text
              }}
            />
            <FaSearch
              className="position-absolute top-50 translate-middle-y text-secondary"
              style={{
                cursor: "pointer",
                left: "15px", // Position from right edge
              }}
            />
          </div>
        </Col>
        <Col xs="auto" className="d-flex gap-3">
          <button
            className="jiluil3 d-flex align-items-center gap-2"
            onClick={() => setShowShareModal(true)}
          >
            <FaLink /> Share Link
          </button>
          <button
            className="jiluil d-flex align-items-center gap-2"
            onClick={() => navigate("/auth/createpeople")}
          >
            <FaPlus /> Add Member
          </button>
        </Col>
      </Row>

      {/* Advanced Search Toggle */}
      <div className="d-flex align-items-center mb-3">
        <Button
          variant="link"
          className="p-0 d-flex align-items-center gap-2"
          onClick={() => setShowAdvanced(!showAdvanced)}
          style={{ color: "#243060" }}
        >
          {showAdvanced ? <FaChevronUp /> : <FaChevronDown />}
          {showAdvanced ? "Hide Advanced Search" : "Advanced Search"}
        </Button>
      </div>

      {/* Active Filters */}
      {isFilterActive() && (
        <div className="d-flex flex-wrap align-items-center mb-3">
          {/* Store the filtered entries in a variable first */}
          {(() => {
            const activeFilters = Object.entries({
              search: localSearch,
              status: searchStatus,
              gender: searchGender,
              marital: searchMarital,
              campus: searchCampus,
              "birth month": searchBirthMonth,
              "anniversary month": searchAnniversaryMonth,
            }).filter(([key, value]) => value && value !== "all");

            return (
              <>
                {activeFilters.map(([key, value]) => (
                  <FilterTag
                    key={key}
                    label={key.charAt(0).toUpperCase() + key.slice(1)}
                    value={value}
                    onClear={() => clearSearchFilter(key)}
                  />
                ))}
                {/* Only show Clear All if there are active filters */}
                {activeFilters.length > 0 && (
                  <div>
                    <button
                      className="jiluillink "
                      onClick={clearFiltersMembers}
                      style={{
                        marginBottom: "4px",
                        backgroundColor: "transparent",
                      }}
                    >
                      Clear All
                    </button>
                  </div>
                )}
              </>
            );
          })()}
        </div>
      )}

      {/* Advanced Search Form */}
      {showAdvanced && (
        <Form className="bg-light p-4 rounded-3 border">
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Form.Select
                  name="searchStatus"
                  value={searchStatus}
                  onChange={handleSearch}
                >
                  <option value="all">All</option>
                  <option value="Member">Member</option>
                  <option value="Visitor">Visitor</option>
                  <option value="Inactive">Inactive</option>
                  <option value="Membership In Progress">
                    Membership In Progress
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Campus</Form.Label>
                <Form.Select
                  name="searchCampus"
                  value={searchCampus}
                  onChange={handleSearch}
                >
                  <option value="all">All</option>
                  {user.churchCampuses.map((campus) => (
                    <option key={campus.campusName} value={campus.campusName}>
                      {campus.campusName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Gender</Form.Label>
                <Form.Select
                  name="searchGender"
                  value={searchGender}
                  onChange={handleSearch}
                >
                  <option value="all">All</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Marital Status</Form.Label>
                <Form.Select
                  name="searchMarital"
                  value={searchMarital}
                  onChange={handleSearch}
                >
                  <option value="all">All</option>
                  <option value="Single">Single</option>
                  <option value="Engaged">Engaged</option>
                  <option value="Married">Married</option>
                  <option value="Divorced">Divorced</option>
                  <option value="Widowed">Widowed</option>
                  <option value="Separated">Separated</option>
                  <option value="Other">Other</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Birth Month</Form.Label>
                <Form.Select
                  name="searchBirthMonth"
                  value={searchBirthMonth}
                  onChange={handleSearch}
                >
                  <option value="all">All</option>
                  {[
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                  ].map((month) => (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Anniversary Month</Form.Label>
                <Form.Select
                  name="searchAnniversaryMonth"
                  value={searchAnniversaryMonth}
                  onChange={handleSearch}
                >
                  <option value="all">All</option>
                  {[
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                  ].map((month) => (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      )}
      <hr />
      <AddShareLink show={showShareModal} setShow={setShowShareModal} />
    </Container>
  );
};

export default PeopleHeader;
