import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Table,
  Row,
  Col,
  Alert,
  Card,
} from "react-bootstrap";
import axios from "axios";
import { useAppContext } from "../../context/appContext";
import { toast } from "react-toastify";

const AddZoneToGroupModal = ({ show, onHide, zone, authFetch }) => {
  const { quickAddToContainer, getContainers, containers } = useAppContext();

  useEffect(() => {
    getContainers();
  }, []);

  const [selectedGroup, setSelectedGroup] = useState("");

  const handleSave = async () => {
    try {
      // Get all member IDs from the zone
      const response = await authFetch.get(`/zoning/${zone._id}/members`);
      const memberIds = response.data; // Now directly an array of IDs

      await authFetch.put(`/members/tag/quick/${selectedGroup}/${memberIds}`, {
        data: memberIds,
      });

      toast.success("Members added to group successfully");
      onHide();
    } catch (error) {
      console.error("Error adding members to group:", error);
      toast.error(
        error.response?.data?.error || "Failed to add members to group"
      );
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Zone Members to Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>Select Group</Form.Label>
        <Form.Select onChange={(e) => setSelectedGroup(e.target.value)}>
          <option value="">Select a Group</option>
          {containers.map((container) => (
            <option key={container._id} value={container._id}>
              {container.name}
            </option>
          ))}
        </Form.Select>

        <p className="mt-3">
          This will add {zone?.memberCount} members to the selected group
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleSave}
          disabled={!selectedGroup}
        >
          Add to Group
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default AddZoneToGroupModal;
