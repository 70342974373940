import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useParams, Link } from "react-router-dom";
import { useAppContext } from "../../context/appContext";
import Alert from "react-bootstrap/Alert";
import axios from "axios";
import "./addmember.css";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { v4 as uuid } from "uuid";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomToastContent from "../../components/Toast/CustomToastContent";
import SetupPrompt from "../../components/NewUserPrompt/NewUserPrompt";

const AddMember = () => {
  const { createMember } = useAppContext();
  const { churchurl } = useParams();
  const [formConfig, setFormConfig] = useState(null);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const unique_id = uuid();
  const unique_id_2 = uuid();
  const familyId = unique_id.slice(0, 8);
  const familySecCode = unique_id_2.slice(0, 8);
  const navigate = useNavigate();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  // Load form configuration
  useEffect(() => {
    const loadFormConfig = async () => {
      try {
        const response = await authFetch.get(`/new-member-setup/${churchurl}`);

        const config = response.data.config;

        // Merge fields and requiredFields, removing duplicates by fieldName
        const allFields = [
          ...config.fields,
          ...config.requiredFields.filter(
            (requiredField) =>
              !config.fields.some(
                (field) => field.fieldName === requiredField.fieldName
              )
          ),
        ];

        // Check if 'addFamily' field exists
        const hasFamilyToggle = allFields.some(
          (field) => field.fieldName.toLowerCase() === "addfamily"
        );
        setShowFamilyToggle(hasFamilyToggle);

        // Initialize form data with empty values for all merged fields
        const initialData = {};
        allFields.forEach((field) => {
          initialData[field.fieldName] = "";
        });

        // Update state with the merged fields and initialized form data
        setFormConfig({ ...config, fields: allFields });
        setFormData(initialData);
        setLoading(false);
      } catch (err) {
        console.error("Error loading form configuration:", err); // Log errors for debugging
        setError("Failed to load form configuration");
        setLoading(false);
      }
    };

    loadFormConfig();
  }, [churchurl]);

  const [showFamilyForm, setShowFamilyForm] = useState(false);
  const [showFamilyToggle, setShowFamilyToggle] = useState(false);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [familyMemberForm, setFamilyMemberForm] = useState({
    firstName: "",
    lastName: "",
    role: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddFamilyMember = () => {
    if (!familyMemberForm.firstName.trim()) {
      setError("First name is required for family member");
      return;
    }
    if (!familyMemberForm.lastName.trim()) {
      setError("Last name is required for family member");
      return;
    }
    if (!familyMemberForm.role) {
      setError("Role is required for family member");
      return;
    }

    setFamilyMembers([...familyMembers, familyMemberForm]);
    setFamilyMemberForm({ firstName: "", lastName: "", role: "" });
    setError(null);
  };
  const familyRoles = [
    { value: "Spouse", label: "Spouse" },
    { value: "Adult", label: "Adult" },
    { value: "Youth", label: "Youth" },
    { value: "Teenager", label: "Teenager" },
    { value: "Child", label: "Child" },
    { value: "Unassigned", label: "Unassigned" },
  ];
  const renderFamilyToggle = () => {
    return (
      <div className="family-section mb-4">
        <Form.Check
          type="switch"
          id="family-toggle"
          label={<span className="h6 mb-0">Add Family Members</span>}
          onChange={(e) => setShowFamilyForm(e.target.checked)}
          checked={showFamilyForm}
          className="mb-3"
        />

        {showFamilyForm && (
          <div className="family-form p-3 bg-light rounded">
            <Row className="g-2 mb-3">
              <Col md={4}>
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  value={familyMemberForm.firstName}
                  onChange={(e) =>
                    setFamilyMemberForm({
                      ...familyMemberForm,
                      firstName: e.target.value,
                    })
                  }
                  style={{ height: "38px" }}
                />
              </Col>
              <Col md={4}>
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  value={familyMemberForm.lastName}
                  onChange={(e) =>
                    setFamilyMemberForm({
                      ...familyMemberForm,
                      lastName: e.target.value,
                    })
                  }
                  style={{ height: "38px" }}
                />
              </Col>
              <Col md={3}>
                <Form.Select
                  value={familyMemberForm.role}
                  onChange={(e) =>
                    setFamilyMemberForm({
                      ...familyMemberForm,
                      role: e.target.value,
                    })
                  }
                  style={{ height: "38px" }}
                >
                  <option value="">Select Role</option>
                  {familyRoles.map((role) => (
                    <option key={role.value} value={role.value}>
                      {role.label}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col
                md={1}
                style={{
                  paddingTop: "0",
                  paddingBottom: "0",
                }}
              >
                <Button
                  variant="primary"
                  onClick={handleAddFamilyMember}
                  style={{
                    height: "38px",
                    width: "100%",
                    padding: "6px 12px",
                    marginTop: "0",
                  }}
                >
                  Add
                </Button>
              </Col>
            </Row>

            {error && (
              <Alert variant="danger" className="py-2 mb-3">
                {error}
              </Alert>
            )}

            {familyMembers.length > 0 && (
              <div className="table-responsive">
                <Table striped bordered hover className="mb-0 align-middle">
                  <thead className="bg-light">
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Role</th>
                      <th className="text-center" style={{ width: "100px" }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {familyMembers.map((member, index) => (
                      <tr key={index}>
                        <td className="align-middle">{member.firstName}</td>
                        <td className="align-middle">{member.lastName}</td>
                        <td className="align-middle">{member.role}</td>
                        <td className="text-center align-middle">
                          <Button
                            variant="danger"
                            onClick={() => {
                              const newFamilyMembers = familyMembers.filter(
                                (_, i) => i !== index
                              );
                              setFamilyMembers(newFamilyMembers);
                            }}
                          >
                            Remove
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderFormSection = (fields, sectionTitle) => {
    const sectionMapping = {
      "Personal Information": [
        "firstName",
        "lastName",
        "gender",
        "status",
        "maritalStatus",
        "familyRole",
        "birthday",
        "anniversary",
      ],
      "Contact Details": [
        "email",
        "mobilePhone",
        "homePhone",
        "workPhone",
        "address",
        "city",
        "state",
        "postal",
      ],
      "Church Information": ["dateJoined", "prayerRequest"],
    };
    // Filter fields that belong to this section
    const sectionFields = fields.filter((field) =>
      sectionMapping[sectionTitle]?.includes(field.fieldName)
    );

    // Sort fields according to the defined order
    const sortedFields = [...sectionFields].sort((a, b) => {
      const orderA = sectionMapping[sectionTitle].indexOf(a.fieldName);
      const orderB = sectionMapping[sectionTitle].indexOf(b.fieldName);
      return orderA - orderB;
    });

    if (sortedFields.length === 0) return null;

    return (
      <div className="form-section mb-4">
        <h3 className="section-title border-bottom pb-2 mb-4">
          {sectionTitle}
        </h3>
        <Row className="g-3">
          {sortedFields.map((field) => (
            <Col
              md={
                ["address", "prayerRequest"].includes(field.fieldName) ? 12 : 6
              }
              key={field.fieldName}
            >
              {renderField(field.fieldName)}
            </Col>
          ))}
        </Row>
        {sectionTitle === "Personal Information" &&
          showFamilyToggle &&
          renderFamilyToggle()}
      </div>
    );
  };

  const renderField = (fieldName) => {
    // Define field types based on field name
    const getFieldType = (name) => {
      const types = {
        gender: { type: "select", options: ["Male", "Female"] },
        status: {
          type: "select",
          options: ["Member", "Visitor", "Membership in Progress"],
        },
        maritalStatus: {
          type: "select",
          options: ["Single", "Married", "Divorced", "Widowed", "Separated"],
        },
        familyRole: {
          type: "select",
          options: [
            "Head of Household",
            "Spouse",
            "Adult",
            "Youth",
            "Teenager",
            "Child",
            "Unassigned",
          ],
        },
        birthday: { type: "customDate" },
        anniversary: { type: "customDate" },
        dateJoined: { type: "date" },
        prayerRequest: { type: "textarea" },
        address: { type: "textarea" },
        email: { type: "email" },
        mobilePhone: { type: "tel" },
        homePhone: { type: "tel" },
        workPhone: { type: "tel" },
      };

      return types[name] || { type: "text" };
    };
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const field = getFieldType(fieldName);
    const isRequired = formConfig?.requiredFields?.some(
      (rf) => rf.fieldName === fieldName
    );

    const label =
      field.label ||
      fieldName
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, (str) => str.toUpperCase());

    switch (field.type) {
      case "select":
        return (
          <Form.Group className="mb-3">
            <Form.Label>
              {fieldName.replace(/([A-Z])/g, " $1").trim()}
              {isRequired && <span className="text-danger">*</span>}
            </Form.Label>
            <Form.Select
              name={fieldName}
              value={formData[fieldName] || ""}
              onChange={handleInputChange}
              required={isRequired}
            >
              <option value="">
                Select {fieldName.replace(/([A-Z])/g, " $1").trim()}
              </option>
              {field.options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        );

      case "textarea":
        return (
          <Form.Group className="mb-3">
            <Form.Label>
              {fieldName.replace(/([A-Z])/g, " $1").trim()}
              {isRequired && <span className="text-danger">*</span>}
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name={fieldName}
              value={formData[fieldName] || ""}
              onChange={handleInputChange}
              required={isRequired}
            />
          </Form.Group>
        );

      case "customDate":
        // Your existing customDate rendering code
        return (
          <Form.Group className="mb-3">
            <Form.Label>
              {fieldName.replace(/([A-Z])/g, " $1").trim()}
              {isRequired && <span className="text-danger">*</span>}
            </Form.Label>
            <Row>
              <Col md={4}>
                <Form.Control
                  type="number"
                  min="1"
                  max="31"
                  name={`${fieldName}Day`}
                  value={formData[`${fieldName}Day`] || ""}
                  onChange={handleInputChange}
                  placeholder="Day"
                  required={isRequired}
                />
              </Col>
              <Col md={8}>
                <Form.Select
                  name={`${fieldName}Month`}
                  value={formData[`${fieldName}Month`] || ""}
                  onChange={handleInputChange}
                  required={isRequired}
                >
                  <option value="">Select Month</option>
                  {months.map((month) => (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
          </Form.Group>
        );

      default:
        return (
          <Form.Group className="mb-3">
            <Form.Label>
              {fieldName.replace(/([A-Z])/g, " $1").trim()}
              {isRequired && <span className="text-danger">*</span>}
            </Form.Label>
            <Form.Control
              type={field.type}
              name={fieldName}
              value={formData[fieldName] || ""}
              onChange={handleInputChange}
              required={isRequired}
              placeholder={`Enter ${fieldName
                .replace(/([A-Z])/g, " $1")
                .trim()}`}
            />
          </Form.Group>
        );
    }
  };

  const createMemberFromFamily = (member, allFamilyData) => {
    const familyMember = {
      mfirstName: member.mfirstName || "",
      mlastName: member.mlastName || "",
      memail: member.memail || "",
      mgender: member.mgender || "",
      mstatus: member.mstatus || "",
      mmarital: member.mmarital || "",
      mhomePhone: member.mhomePhone || "",
      mworkPhone: member.mworkPhone || "",
      mmobilePhone: member.mmobilePhone || "",
      mcity: member.mcity || "",
      maddress: member.maddress || "",
      maddress2: member.maddress2 || "",
      mpostal: member.mpostal || "",
      mstate: member.mstate || "",
      mProfilePicture: member.image || "",
      mAnniversaryDay: member.mAnniversaryDay || "",
      mAnniversaryMonth: member.mAnniversaryMonth || "",
      mBirthMonth: member.mBirthMonth || "",
      mBirthdayCont: member.mBirthMonth + " " + member.mBirthday || "",
      mAnniversaryCont:
        member.mAnniversaryMonth + " " + member.mAnniversaryDay || "",
      mBirthday: member.mBirthday || "",
      churchName: formConfig.churchName,
      familyId: familyId,
      familySecCode: familySecCode,
      familyRole: member.familyRole || "",
      role: "Standard",
      accountStatus: "Inactive",
      mfamily: allFamilyData || [], // Include all family members
    };

    createMember({ currentMember: familyMember });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const requiredFields = formConfig.requiredFields.map((f) => f.fieldName);
      const missingFields = requiredFields.filter((field) => !formData[field]);

      if (missingFields.length > 0) {
        toast(
          <CustomToastContent
            title="Error"
            message={`Please complete all required fields: ${missingFields.join(
              ", "
            )}`}
          />
        );
      }

      // Format dates like your original code
      const formatDate = (day, month) => {
        return day && month ? `${month} ${day}` : "";
      };

      // Map family members to match your original schema
      const mappedFamilyMembers = familyMembers.map((member) => ({
        mfirstName: member.firstName,
        mlastName: member.lastName,
        familyRole: member.role,
        familyId: familyId,
        familySecCode: familySecCode,
        churchName: formConfig.churchName,
        churchDomain: churchurl,
        role: "Standard",
        accountStatus: "Inactive",
      }));

      // Create main member with all family data
      let currentMember = {
        mfirstName: formData.firstName,
        mlastName: formData.lastName,
        mgender: formData.gender,
        mstatus: formData.status,
        mmarital: formData.maritalStatus,
        mworkPhone: formData.workPhone,
        mhomePhone: formData.homePhone,
        maddress: formData.address,
        mcity: formData.city,
        mstate: formData.state,
        mpostal: formData.postal,
        memail: formData.email,
        mBirthday: formData.birthdayDay,
        mBirthMonth: formData.birthdayMonth,
        mBirthdayCont: formatDate(formData.birthdayDay, formData.birthdayMonth),
        mAnniversaryDay: formData.anniversaryDay,
        mAnniversaryMonth: formData.anniversaryMonth,
        mAnniversaryCont: formatDate(
          formData.anniversaryDay,
          formData.anniversaryMonth
        ),
        mmobilePhone: formData.mobilePhone,
        churchName: formConfig.churchName,
        churchDomain: churchurl,
        familyRole: formData.familyRole,
        familyId: familyId,
        familySecCode: familySecCode,
        role: "Standard",
        accountStatus: "Active",
        mfamily: [...mappedFamilyMembers], // Include all family members
      };

      // Process each family member separately
      for (let i = 0; i < mappedFamilyMembers.length; i++) {
        createMemberFromFamily(mappedFamilyMembers[i], [
          ...mappedFamilyMembers,
          currentMember,
        ]);
      }

      // Create the main member
      createMember({ currentMember });

      // Reset form
      // setSubmitted(true);
      navigate("/redirect/success");
      setFormData({});
      setFamilyMembers([]);
    } catch (err) {
      setError("Failed to submit registration");
    }
  };

  if (loading) {
    return <SetupPrompt />;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (submitted) {
    return (
      <div className="success-message">
        Thank you for registering! The church will be in contact with you soon.
      </div>
    );
  }

  console.log(formConfig);
  return (
    <Container fluid className="contWwainer2">
      <div className="registration-card">
        <div className="registration-brand">
          {formConfig.churchLogo ? (
            <div className="church-logo-wrapper">
              <img
                src={formConfig.churchLogo}
                alt={`${formConfig.churchName} Logo`}
                className="church-logo"
                onError={(e) => {
                  e.target.style.display = "none";
                  e.target.nextSibling.style.display = "block";
                }}
              />
              <h2 className="church-name fallback" style={{ display: "none" }}>
                {formConfig.churchName}
              </h2>
            </div>
          ) : (
            <h2 className="church-name">{formConfig.churchName}</h2>
          )}
        </div>

        <div className="registration-header">
          <h2 className="registration-title">First Timer Registration</h2>
          {/* <p className="registration-subtitle">
            Welcome to our church family! Please complete the registration form
            below.
          </p> */}
        </div>

        <div className="registration-body">
          <p className="registration-instructions">
            Please complete all required fields marked with an asterisk (*). If
            you're registering with family members, use the "Add Family Members"
            toggle in the Personal Information section to include their details.
            Enter birth dates and anniversaries using the day and month format
            provided. Your privacy is important to us, and all information will
            be handled securely.
          </p>

          <Form onSubmit={handleSubmit}>
            {renderFormSection(formConfig.fields, "Personal Information")}
            {renderFormSection(formConfig.fields, "Contact Details")}
            {renderFormSection(formConfig.fields, "Church Information")}

            <p className="privacy-notice">
              By submitting this form, I consent to {formConfig.churchName}{" "}
              collecting, storing, and using my personal information exclusively
              for church-related purposes.
            </p>

            <button type="submit" className="submit-button">
              Submit Registration
            </button>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default AddMember;
