import React, { useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useAppContext } from "../../context/appContext";
import axios from "axios";

const SaveViewQuery = ({ results, handleClose, show }) => {
  const { logoutUser, user, savedView } = useAppContext();

  const authFetch = useMemo(() => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL,
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
    });

    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          logoutUser();
        }
        return Promise.reject(error);
      }
    );

    return instance;
  }, [logoutUser]);

  const [viewName, setViewName] = useState("");

  const handleSaveView = async () => {
    try {
      await savedView({
        viewName,
        userId: user._id,
        viewMembers: results,
      });
      handleClose();
      setViewName("");
      toast.success("View saved successfully");
    } catch (error) {
      toast.error("Failed to save view");
      console.error("Save view error:", error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Save View</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>View Name</Form.Label>
          <Form.Control
            type="text"
            value={viewName}
            onChange={(e) => setViewName(e.target.value)}
            placeholder="Enter view name"
            autoFocus
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSaveView}
          disabled={!viewName.trim()}
        >
          Save View
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SaveViewQuery;
