import React, { useState, useEffect, useRef, useMemo } from "react";
import axios from "axios";
import { useAppContext } from "../../../context/appContext";
import {
  Table,
  Container,
  Row,
  Card,
  Badge,
  Spinner,
  Form,
  Col,
} from "react-bootstrap";
import { currencyFormatter } from "../../../utils/helpers";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import { useReactToPrint } from "react-to-print";
import nodd from "../../../assets/newi/nodd.png";
import "react-datepicker/dist/react-datepicker.css";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
const COLORS = ["#FED970", "#FE7064", "#243060", "#FEF7E2", "#8884d8"];

const AccountStatement = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  // States
  const { logoutUser, user } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [aggregatedData, setAggregatedData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);
  const [currency, setCurrency] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [budgetComparison, setBudgetComparison] = useState([]);
  const [categoryTrend, setCategoryTrend] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [viewMode, setViewMode] = useState("yearly"); // 'monthly' or 'yearly'

  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1), // First day of current month
    endDate: new Date(),
  });
  const [filters, setFilters] = useState({
    category: "all",
    minAmount: "",
    maxAmount: "",
    sortBy: "date",
    sortOrder: "desc",
  });

  // Refs
  const componentRef = useRef();
  const csvLinkRef = useRef();

  // Memoized calculations
  const summaryStats = useMemo(() => {
    const totalIncome = aggregatedData.reduce(
      (acc, item) => acc + item.totalAmount,
      0
    );
    const totalExpenses = expenseData.reduce(
      (acc, item) => acc + item.totalAmount,
      0
    );
    const netBalance = totalIncome - totalExpenses;
    const monthlyAvgIncome = totalIncome / (aggregatedData.length || 1);
    const monthlyAvgExpense = totalExpenses / (expenseData.length || 1);

    return {
      totalIncome,
      totalExpenses,
      netBalance,
      monthlyAvgIncome,
      monthlyAvgExpense,
    };
  }, [aggregatedData, expenseData]);

  // Category lists for filters
  const categories = useMemo(() => {
    const incomeCategories = [
      ...new Set(aggregatedData.map((item) => item._id.category)),
    ];
    const expenseCategories = [
      ...new Set(expenseData.map((item) => item._id.expenseCategory)),
    ];
    return [...new Set([...incomeCategories, ...expenseCategories])];
  }, [aggregatedData, expenseData]);

  // Data fetch functions
  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const [incomeRes, expenseRes] = await Promise.all([
        authFetch.get("/giving/income-summary", {
          params: {
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
            ...filters,
          },
        }),
        authFetch.get("/giving/expense-summary", {
          params: {
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
            ...filters,
          },
        }),
      ]);

      setAggregatedData(incomeRes.data);
      setExpenseData(expenseRes.data);
    } catch (err) {
      setError(err.message || "Failed to fetch data");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMonthlyData = async (date) => {
    setIsLoading(true);
    try {
      const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      // First get budget comparison which includes categories
      const budgetRes = await authFetch.get("/giving/budget-comparison", {
        params: {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
        },
      });

      // Get other data
      const [incomeRes, expenseRes] = await Promise.all([
        authFetch.get("/giving/income-summary", {
          params: {
            startDate: startOfMonth,
            endDate: endOfMonth,
          },
        }),
        authFetch.get("/giving/expense-summary", {
          params: {
            startDate: startOfMonth,
            endDate: endOfMonth,
          },
        }),
      ]);

      // If we have budget data, get trends for each category
      if (budgetRes.data?.data?.comparison) {
        const categories = budgetRes.data.data.comparison.map(
          (item) => item.category
        );

        // Get trends for each category
        const trendPromises = categories.map((category) =>
          authFetch.get("/giving/category-trend", {
            params: {
              year: date.getFullYear(),
              month: date.getMonth() + 1,
              category,
            },
          })
        );

        const categoryTrends = await Promise.all(trendPromises);
        const trendData = categoryTrends.reduce((acc, trend, index) => {
          if (trend.data?.data) {
            acc[categories[index]] = trend.data.data;
          }
          return acc;
        }, {});

        setCategoryTrend(trendData);
      }

      setAggregatedData(incomeRes.data);
      setExpenseData(expenseRes.data);
      setBudgetComparison(budgetRes.data?.data?.comparison || []);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMonthlyData(selectedMonth);
  }, [selectedMonth]);

  // Effects
  useEffect(() => {
    setCurrency(user.currency);
    fetchData();
  }, [dateRange, filters]);

  // Export data preparation
  const exportData = useMemo(() => {
    const incomeRows = aggregatedData.map((item) => ({
      Type: "Income",
      Category: item._id.category,
      Amount: item.totalAmount,
      Year: item._id.year,
      Month: item._id.month,
    }));

    const expenseRows = expenseData.map((item) => ({
      Type: "Expense",
      Category: item._id.expenseCategory,
      Amount: -item.totalAmount,
      Year: item._id.year,
      Month: item._id.month,
    }));

    return [...incomeRows, ...expenseRows];
  }, [aggregatedData, expenseData]);

  // Handlers
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setDateRange({ startDate: start, endDate: end || start });
  };

  const handleFilterChange = (e) => {
    setFilters((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Account Statement ${dateRange.startDate.toLocaleDateString()} - ${dateRange.endDate.toLocaleDateString()}`,
  });

  const chartData = useMemo(() => {
    // Budget vs Actual comparison data
    const budgetVsActual = budgetComparison.map((item) => ({
      category: item.category,
      budget: item.budgetAmount,
      actual: item.actualAmount,
      variance: item.variance,
      utilizationRate: item.utilizationRate,
    }));

    // Income vs Expense Overview
    console.log(summaryStats);
    const overviewData = [
      { name: "Income", value: summaryStats.totalIncome },
      { name: "Expense", value: summaryStats.totalExpenses },
    ];

    // Category distribution data
    const incomeDistribution = aggregatedData.map((item) => ({
      name: item._id.category,
      value: item.totalAmount,
    }));

    const expenseDistribution = expenseData.map((item) => ({
      name: item._id.expenseCategory,
      value: item.totalAmount,
    }));

    // Category trends
    const categoryTrends = categoryTrend;

    return {
      budgetVsActual,
      overviewData,
      incomeDistribution,
      expenseDistribution,
      categoryTrends,
    };
  }, [
    budgetComparison,
    aggregatedData,
    expenseData,
    summaryStats,
    categoryTrend,
  ]);

  const getCategoryTrendMonths = () => {
    const months = [];
    const currentDate = new Date();

    // Get current month and 5 previous months
    for (let i = 5; i >= 0; i--) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - i,
        1
      );
      months.push({
        month: date.getMonth() + 1,
        year: date.getFullYear(),
        label: date.toLocaleString("default", {
          month: "short",
          year: "2-digit",
        }),
      });
    }
    return months;
  };

  // const renderCategoryTrendChart = () => {
  //   if (!categoryTrend || Object.keys(categoryTrend).length === 0) return null;

  //   const trendMonths = getCategoryTrendMonths();

  //   // Prepare data for chart
  //   const chartData = trendMonths.map((monthData) => {
  //     const dataPoint = { month: monthData.label };
  //     Object.entries(categoryTrend).forEach(([category, trends]) => {
  //       const monthTrend = trends.find(
  //         (t) => t.month === monthData.month && t.year === monthData.year
  //       );
  //       if (monthTrend) {
  //         dataPoint[`${category}_budget`] = monthTrend.budgetAmount;
  //         dataPoint[`${category}_actual`] = monthTrend.actualAmount;
  //       }
  //     });
  //     return dataPoint;
  //   });

  //   return (
  //     <Card className="mt-4">
  //       <Card.Header>
  //         <div className="d-flex justify-content-between align-items-center">
  //           <h6 className="mb-0">
  //             Category Trends ({trendMonths[0].label} -{" "}
  //             {trendMonths[trendMonths.length - 1].label})
  //           </h6>
  //           <Form.Select
  //             className="w-auto"
  //             onChange={(e) => setSelectedCategory(e.target.value)}
  //             value={selectedCategory}
  //           >
  //             <option value="">All Categories</option>
  //             {Object.keys(categoryTrend).map((category) => (
  //               <option key={category} value={category}>
  //                 {category}
  //               </option>
  //             ))}
  //           </Form.Select>
  //         </div>
  //       </Card.Header>
  //       <Card.Body>
  //         <ResponsiveContainer width="100%" height={300}>
  //           <LineChart data={chartData}>
  //             <CartesianGrid strokeDasharray="3 3" />
  //             <XAxis dataKey="month" />
  //             <YAxis />
  //             <Tooltip />
  //             <Legend />
  //             {selectedCategory ? (
  //               <>
  //                 <Line
  //                   type="monotone"
  //                   dataKey={`${selectedCategory}_budget`}
  //                   stroke="#8884d8"
  //                   name={`${selectedCategory} Budget`}
  //                 />
  //                 <Line
  //                   type="monotone"
  //                   dataKey={`${selectedCategory}_actual`}
  //                   stroke="#82ca9d"
  //                   name={`${selectedCategory} Actual`}
  //                 />
  //               </>
  //             ) : (
  //               Object.keys(categoryTrend).map((category, index) => (
  //                 <Line
  //                   key={category}
  //                   type="monotone"
  //                   dataKey={`${category}_actual`}
  //                   stroke={COLORS[index % COLORS.length]}
  //                   name={category}
  //                 />
  //               ))
  //             )}
  //           </LineChart>
  //         </ResponsiveContainer>
  //       </Card.Body>
  //     </Card>
  //   );
  // };

  const renderCharts = () => (
    <Row className="mb-4">
      <Col md={6}>
        <Card>
          <Card.Header>Budget vs Actual Expenses</Card.Header>
          <Card.Body>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={chartData.budgetVsActual}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="category" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="budget" fill="#243060" name="Budget" />
                <Bar dataKey="actual" fill="#FE7064" name="Actual" />
              </BarChart>
            </ResponsiveContainer>
          </Card.Body>
        </Card>
      </Col>
      <Col md={6}>
        <Card>
          <Card.Header>Income vs Expense Overview</Card.Header>
          <Card.Body>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={[
                    {
                      name: "Income",
                      value: Number(summaryStats.totalIncome) || 0,
                    },
                    {
                      name: "Expense",
                      value: Number(summaryStats.totalExpenses) || 0,
                    },
                  ]}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={({ name, percent }) =>
                    `${name}: ${(percent * 100).toFixed(0)}%`
                  }
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {[
                    { name: "Income", color: "#243060" },
                    { name: "Expense", color: "#FE7064" },
                  ].map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip
                  formatter={(value) =>
                    currencyFormatter({ amount: value, currency })
                  }
                />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );

  const renderSummarySection = () => (
    <Row className="mb-4">
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h5>
              Monthly Summary -{" "}
              {selectedMonth.toLocaleString("default", {
                month: "long",
                year: "numeric",
              })}
            </h5>
            <DatePicker
              selected={selectedMonth}
              onChange={(date) => setSelectedMonth(date)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              className="form-control w-auto"
            />
          </div>
          <div className="d-flex gap-4">
            <div className="flex-grow-1">
              <div className="text-muted">Total Income</div>
              <div className="h4 text-success">
                {currencyFormatter({
                  amount: summaryStats.totalIncome,
                  currency,
                })}
              </div>
            </div>
            <div className="flex-grow-1">
              <div className="text-muted">Total Expenses</div>
              <div className="h4 text-danger">
                {currencyFormatter({
                  amount: summaryStats.totalExpenses,
                  currency,
                })}
              </div>
            </div>
            <div className="flex-grow-1">
              <div className="text-muted">Net Balance</div>
              <div
                className={`h4 ${
                  summaryStats.netBalance >= 0 ? "text-success" : "text-danger"
                }`}
              >
                {currencyFormatter({
                  amount: summaryStats.netBalance,
                  currency,
                })}
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Row>
  );

  const renderSideBySideComparison = () => {
    // Group data by year and month
    const groupData = (data) => {
      return data.reduce(
        (acc, item) => {
          const yearKey = item._id.year.toString();
          const monthKey = `${item._id.year}-${item._id.month}`;

          // Yearly grouping
          if (!acc.yearly[yearKey]) {
            acc.yearly[yearKey] = {
              year: item._id.year,
              total: 0,
            };
          }
          acc.yearly[yearKey].total += item.totalAmount;

          // Monthly grouping
          if (!acc.monthly[monthKey]) {
            acc.monthly[monthKey] = {
              year: item._id.year,
              month: item._id.month,
              total: 0,
            };
          }
          acc.monthly[monthKey].total += item.totalAmount;

          return acc;
        },
        { yearly: {}, monthly: {} }
      );
    };

    const incomeGroups = groupData(aggregatedData);
    const expenseGroups = groupData(expenseData);

    const renderTable = (data, type, totalAmount) => {
      const isMonthly = viewMode === "monthly";
      const groupedData = isMonthly ? data.monthly : data.yearly;

      return (
        <Table bordered className="accounttable" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th className="err34">{isMonthly ? "Month" : "Year"}</th>
              <th className="err34">Total {type}</th>
              <th className="err34">% of Total</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(groupedData)
              .sort((a, b) => {
                if (isMonthly) {
                  return b.year - a.year || b.month - a.month;
                }
                return b.year - a.year;
              })
              .map((data, index) => {
                const percentage = ((data.total / totalAmount) * 100).toFixed(
                  1
                );
                return (
                  <tr key={index}>
                    <td>
                      {isMonthly
                        ? new Date(data.year, data.month - 1).toLocaleString(
                            "default",
                            {
                              month: "long",
                              year: "numeric",
                            }
                          )
                        : data.year}
                    </td>
                    <td>
                      {currencyFormatter({
                        amount: data.total,
                        currency,
                      })}
                    </td>
                    <td>{percentage}%</td>
                  </tr>
                );
              })}
            <tr className="table-active fw-bold">
              <td>Total</td>
              <td>
                {currencyFormatter({
                  amount: totalAmount,
                  currency,
                })}
              </td>
              <td>100%</td>
            </tr>
          </tbody>
        </Table>
      );
    };

    return (
      <>
        <Row className="mb-3">
          <Col>
            <div className="d-flex justify-content-end gap-2 align-items-center">
              <span>View by:</span>
              <Form.Select
                style={{ width: "auto" }}
                value={viewMode}
                onChange={(e) => setViewMode(e.target.value)}
              >
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
              </Form.Select>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Card style={{ height: "100%" }}>
              <Card.Header>
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="mb-0">
                    {viewMode === "monthly" ? "Monthly" : "Yearly"} Income
                    Summary
                  </h6>
                  <Badge bg="success">
                    {
                      Object.keys(
                        viewMode === "monthly"
                          ? incomeGroups.monthly
                          : incomeGroups.yearly
                      ).length
                    }
                    {viewMode === "monthly" ? " months" : " years"}
                  </Badge>
                </div>
              </Card.Header>
              <Card.Body style={{ padding: "1rem" }}>
                {renderTable(incomeGroups, "Income", summaryStats.totalIncome)}
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card style={{ height: "100%" }}>
              <Card.Header>
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="mb-0">
                    {viewMode === "monthly" ? "Monthly" : "Yearly"} Expense
                    Summary
                  </h6>
                  <Badge bg="danger">
                    {
                      Object.keys(
                        viewMode === "monthly"
                          ? expenseGroups.monthly
                          : expenseGroups.yearly
                      ).length
                    }
                    {viewMode === "monthly" ? " months" : " years"}
                  </Badge>
                </div>
              </Card.Header>
              <Card.Body style={{ padding: "1rem" }}>
                {renderTable(
                  expenseGroups,
                  "Expenses",
                  summaryStats.totalExpenses
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  };
  const renderBudgetComparison = () => (
    <Row className="mt-4">
      <Card>
        <Card.Header>Budget Analysis</Card.Header>
        <Card.Body>
          <Table bordered hover>
            <thead>
              <tr>
                <th>Category</th>
                <th>Budget Amount</th>
                <th>Actual Amount</th>
                <th>Variance</th>
                <th>Utilization</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {budgetComparison.map((item, index) => (
                <tr key={index}>
                  <td>{item.category}</td>
                  <td>
                    {currencyFormatter({ amount: item.budgetAmount, currency })}
                  </td>
                  <td>
                    {currencyFormatter({ amount: item.actualAmount, currency })}
                  </td>
                  <td>
                    {currencyFormatter({ amount: item.variance, currency })}
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div
                        className="progress flex-grow-1"
                        style={{ height: "20px" }}
                      >
                        <div
                          className={`progress-bar ${
                            item.isOverThreshold ? "bg-danger" : "bg-success"
                          }`}
                          style={{ width: `${item.utilizationRate}%` }}
                        >
                          {item.utilizationRate}%
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <Badge
                      bg={item.status === "Under Budget" ? "success" : "danger"}
                    >
                      {item.status}
                    </Badge>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Row>
  );

  // Render functions
  const renderSummaryCards = () => (
    <Row className="mb-4">
      <div className="d-flex gap-4">
        <Card className="flex-grow-1">
          <Card.Body>
            <Card.Title>Total Income</Card.Title>
            <Card.Text className="h3 text-success">
              {currencyFormatter({
                amount: summaryStats.totalIncome,
                currency,
              })}
            </Card.Text>
          </Card.Body>
        </Card>

        <Card className="flex-grow-1">
          <Card.Body>
            <Card.Title>Total Expenses</Card.Title>
            <Card.Text className="h3 text-danger">
              {currencyFormatter({
                amount: summaryStats.totalExpenses,
                currency,
              })}
            </Card.Text>
          </Card.Body>
        </Card>

        <Card className="flex-grow-1">
          <Card.Body>
            <Card.Title>Net Balance</Card.Title>
            <Card.Text
              className={`h3 ${
                summaryStats.netBalance >= 0 ? "text-success" : "text-danger"
              }`}
            >
              {currencyFormatter({
                amount: summaryStats.netBalance,
                currency,
              })}
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </Row>
  );

  const renderFilters = () => (
    <Row className="mb-4">
      <div className="d-flex gap-3 align-items-center">
        <DatePicker
          selectsRange
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          onChange={handleDateChange}
          className="form-control"
          placeholderText="Select date range"
        />

        <Form.Select
          name="category"
          value={filters.category}
          onChange={handleFilterChange}
          className="w-auto"
        >
          <option value="all">All Categories</option>
          {categories.map((cat) => (
            <option key={cat} value={cat}>
              {cat}
            </option>
          ))}
        </Form.Select>

        <Form.Control
          type="number"
          placeholder="Min Amount"
          name="minAmount"
          value={filters.minAmount}
          onChange={handleFilterChange}
          className="w-auto"
        />

        <Form.Control
          type="number"
          placeholder="Max Amount"
          name="maxAmount"
          value={filters.maxAmount}
          onChange={handleFilterChange}
          className="w-auto"
        />

        <div className="ms-auto">
          <button
            className="jiluil me-2"
            onClick={() => csvLinkRef.current.link.click()}
          >
            Export CSV
          </button>
          <button className="jiluil3" onClick={handlePrint}>
            Print
          </button>
        </div>

        <CSVLink
          data={exportData}
          filename={`account-statement-${
            dateRange.startDate.toISOString().split("T")[0]
          }-${dateRange.endDate.toISOString().split("T")[0]}.csv`}
          className="d-none"
          ref={csvLinkRef}
        />
      </div>
    </Row>
  );

  const renderTable = (data, type) => {
    if (data.length === 0) {
      return (
        <div className="noData">
          <img src={nodd} alt="No data available" />
          <p className="noDataBackup">No {type} data available for display.</p>
        </div>
      );
    }

    return (
      <Table bordered className="accounttable">
        <thead>
          <tr>
            <th className="err34">Category</th>
            <th className="err34">Amount</th>
            <th className="err34">Year</th>
            <th className="err34">Month</th>
            <th className="err34">% of Total</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            const amount = item.totalAmount;
            const total =
              type === "income"
                ? summaryStats.totalIncome
                : summaryStats.totalExpenses;
            const percentage = ((amount / total) * 100).toFixed(1);

            return (
              <tr key={index}>
                <td>
                  {type === "income"
                    ? item._id.category
                    : item._id.expenseCategory}
                </td>
                <td>
                  {currencyFormatter({
                    amount: amount,
                    currency,
                  })}
                </td>
                <td>{item._id.year}</td>
                <td>{item._id.month}</td>
                <td>{percentage}%</td>
              </tr>
            );
          })}
          <tr className="table-active fw-bold">
            <td>Total</td>
            <td>
              {currencyFormatter({
                amount:
                  type === "income"
                    ? summaryStats.totalIncome
                    : summaryStats.totalExpenses,
                currency,
              })}
            </td>
            <td colSpan="3"></td>
          </tr>
        </tbody>
      </Table>
    );
  };

  if (error) {
    return (
      <Container fluid>
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      </Container>
    );
  }

  return (
    <Container fluid>
      <div ref={componentRef}>
        <Row className="headerbanner">
          <div className="bannerbig">Account Statement</div>
          <div className="bannersmall">
            Monthly financial summary for{" "}
            {selectedMonth.toLocaleString("default", {
              month: "long",
              year: "numeric",
            })}
          </div>
        </Row>

        {/* Export/Print buttons */}
        <Row className="mb-4">
          <div className="d-flex justify-content-end">
            <button
              className="jiluil me-2"
              onClick={() => csvLinkRef.current.link.click()}
            >
              Export CSV
            </button>
            <button className="jiluil3" onClick={handlePrint}>
              Print
            </button>
          </div>
        </Row>

        {isLoading ? (
          <div className="text-center py-5">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            {renderSummarySection()}
            {renderCharts()}
            {renderSideBySideComparison()}
            {renderBudgetComparison()}
            {/* {renderCategoryTrendChart()} */}
          </>
        )}
      </div>

      <CSVLink
        data={exportData}
        filename={`account-statement-${
          selectedMonth.toISOString().split("T")[0]
        }.csv`}
        className="d-none"
        ref={csvLinkRef}
      />

      <Row className="bottomSpacer"></Row>
    </Container>
  );
};

export default AccountStatement;
