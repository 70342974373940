import React, { useState, useEffect } from "react";
import axios from "axios";
import { Col, Card } from "react-bootstrap";
import { useAppContext } from "../../../context/appContext";
import { Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarked,
  faToggleOn,
  faUsers,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import ZoneManagement from "./ZoneManagement";

const StatCard = ({ title, value, icon, color = "primary" }) => (
  <Col md={3} className="mb-4" style={{ marginTop: "30px" }}>
    <Card>
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h6 className="text-muted mb-1">{title}</h6>
            <h3 className="mb-0">{value}</h3>
          </div>
          <div className={`p-3 rounded-circle bg-${color} bg-opacity-10`}>
            <FontAwesomeIcon
              icon={icon}
              className={`text-${color}`}
              size="lg"
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  </Col>
);

const Zoning = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { logoutUser } = useAppContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState({
    totalZones: 0,
    activeZones: 0,
    totalMembers: 0,
    coverageRate: 0,
  });

  useEffect(() => {
    fetchStats();
  }, []);

  const fetchStats = async () => {
    try {
      const response = await authFetch.get("/zoning/zoneanalytics");
      setStats(response.data);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch stats");
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <Container fluid>
      <Row className="headerbanner">
        <div className="bannerbig">Zone Management</div>
        <p className="bannersmall">
          Streamline church community organization by geographic zones.
          Efficiently manage members based on postal codes, optimize resources,
          and enhance local ministry engagement through strategic zoning.
        </p>
      </Row>
      <Row>
        <StatCard
          title="Total Zones"
          value={stats.totalZones}
          icon={faMapMarked}
          color="primary"
        />
        <StatCard
          title="Active Zones"
          value={stats.activeZones}
          icon={faToggleOn}
          color="success"
        />
        <StatCard
          title="Members in Zones"
          value={stats.totalMembers}
          icon={faUsers}
          color="info"
        />
        <StatCard
          title="Coverage Rate"
          value={`${stats.coverageRate}%`}
          icon={faChartLine}
          color="warning"
        />
      </Row>

      <Row>
        <ZoneManagement refreshData={fetchStats} />
      </Row>
    </Container>
  );
};

export default Zoning;
