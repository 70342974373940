import React, { useEffect, useState, useRef } from "react";
import { useAppContext } from "../../../context/appContext";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import axios from "axios";
import Button from "react-bootstrap/Button";

import { Link } from "react-router-dom";
import moment from "moment";
import { CSVLink } from "react-csv";
import PrintCalendarList from "../../../components/PrintCalendar/PrintCalendarList";
import { FaCaretDown } from "react-icons/fa";
import { FaCaretUp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
const CalendarView = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { user, isLoading, logoutUser } = useAppContext();
  const navigate = useNavigate();
  const [viewNameFlag, setViewNameFlag] = useState(true);
  const [dateFlag, setDateFlag] = useState(false);
  const [allPayments, setAllPayments] = useState([]);
  const [currency, setCurrency] = useState("");

  const [event, setEvent] = useState([]);

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async () => {
    try {
      const { data } = await authFetch.get(`/events`);

      if (data) {
        setEvent(data.events);
      }
      // console.log(response.data.payments); // Assuming you want to log the response data
    } catch (error) {
      // Using console.error for logging errors
    }
  };

  const handleViewName = (viewNameFlag) => {
    if (!viewNameFlag) {
      let sorted = [...allPayments].sort(function (a, b) {
        return a.viewName.localeCompare(b.viewName);
      });
      setAllPayments(sorted);
    } else {
      let sorted = [...allPayments].reverse();
      setAllPayments(sorted);
    }
    setViewNameFlag(!viewNameFlag);
  };

  const handleDateSort = (e) => {
    let sorted = [...allPayments].sort((a, b) => {
      if (dateFlag) {
        return moment(b.createdAt).unix() - moment(a.createdAt).unix();
      } else {
        return moment(a.createdAt).unix() - moment(b.createdAt).unix();
      }
    });
    setAllPayments(sorted);
    setDateFlag(!dateFlag);
  };

  const copiedArray = event?.map((events) => ({
    EventName: events.eventTitle,
    EventDate: moment(`${events.eventDay}`).format("LL"),

    StartTime: events.reStartTime,
    EndTime: events.reEndTime,
    Interval: events.eventPattern ? events.eventPattern : "Single Event",
    VolunteersNeed: events.eventVolunteers ? "Yes" : "No",
    VisibleToMembers: events.eventVisible ? "Yes" : "No",
    EventLink: events.eventLink,
    EventMode:
      events.eventMode === "OnlineOnly"
        ? "Online"
        : events.eventMode === "InPerson"
        ? "In Person"
        : "Hybrid",
  }));
  const componentRef = useRef();
  const printView = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Calendar Report",
  });

  const printMe = () => {
    printView();
  };

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const [notFound, setNotFound] = useState([]);

  // useEffect(() => {
  //   if (notFound.length) {
  //     const emailIsNotFound = notFound.includes(
  //       allPayments.paymentDetails.payerEmail
  //     );
  //     setNotFoundArray(emailIsNotFound);
  //   }
  // }, [notFound]);

  const OpenCalendar = () => {
    navigate("/auth/calendar");
  };

  return (
    <>
      <Container fluid>
        <>
          {event && event.length > 0 ? (
            <Row className="tabw" style={{ paddingTop: "3rem" }}>
              <div className="flex justify-between items-center flex-row pb-5">
                {" "}
                <p
                  style={{
                    color: "var(--primary-color)",
                    fontWeight: "600",
                    margin: "0",
                  }}
                >
                  {event.length ? (
                    <p>
                      {event.length} record
                      {event.length > 1 ? "s" : ""} found
                    </p>
                  ) : (
                    <p>No Saved View</p>
                  )}
                </p>{" "}
                <div className="flex gap-3">
                  <button className="jiluil3" onClick={OpenCalendar}>
                    Calendar
                  </button>
                  <button className="jiluil">
                    <CSVLink
                      data={copiedArray}
                      filename="Calendar-churchease.csv"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Download
                    </CSVLink>
                  </button>
                  <button className="jiluil4" onClick={printMe}>
                    Print
                  </button>
                </div>
              </div>
              <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
                <thead>
                  <tr className="trdataheader">
                    <th onClick={handleViewName}>
                      Event Name{" "}
                      {viewNameFlag ? <FaCaretDown /> : <FaCaretUp />}
                    </th>
                    <th onClick={handleDateSort}>
                      Event Date {dateFlag ? <FaCaretDown /> : <FaCaretUp />}
                    </th>

                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Interval</th>
                    <th>Volunteers need</th>
                    <th>Visible to members</th>
                    <th>Event Mode</th>
                  </tr>
                </thead>
                {event
                  .sort((a, b) => moment(a.eventDay) - moment(b.eventDay)) // Sort events by dashBoardDay in descending order
                  .map((events, index) => (
                    <tbody key={index}>
                      {" "}
                      {/* Moved the key to <tbody> from <tr> */}
                      <tr className="trdata">
                        <td>{events.eventTitle}</td>
                        <td>{moment(events.eventDay).format("LL")}</td>
                        <td>{events.reStartTime}</td>
                        <td>{events.reEndTime}</td>
                        <td>
                          {events.eventPattern
                            ? events.eventPattern
                            : "Single Event"}
                        </td>
                        <td>{events.eventVolunteers ? "Yes" : "No"}</td>
                        <td>{events.eventVisible ? "Yes" : "No"}</td>
                        <td>
                          {events.eventMode === "OnlineOnly"
                            ? "Online"
                            : events.eventMode === "InPerson"
                            ? "In Person"
                            : "Hybrid"}
                        </td>
                      </tr>
                    </tbody>
                  ))}
              </Table>
              <div style={{ display: "none" }}>
                <PrintCalendarList ref={componentRef} />
              </div>
            </Row>
          ) : (
            <div className="noDatax">
              <p className="noDataBackup">No data available for display.</p>
            </div>
          )}
        </>
      </Container>
    </>
  );
};

export default CalendarView;
