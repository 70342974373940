import React, { useEffect, useState, useMemo } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./membernav.css";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Tooltip } from "antd";
import { useAppContext } from "../../context/appContext";
import { useNavigate, useLocation } from "react-router-dom";
import Image from "react-bootstrap/Image";
import avatar from "./../../assets/images/blank2.png";
import { createTrialManager } from "../TrialManager/TrialManager";
import { FaBell, FaBolt, FaQuestion, FaInfo } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import axios from "axios";
import CreateSubscription from "../ManageSubscription/CreateSubscription";
import EditSubscription from "../ManageSubscription/EditSubscription";
import OnboardingWizard from "../OnboardTour/OnboardingWizard";
import { differenceInDays, parseISO, format, formatISO } from "date-fns";

function MemberNav() {
  const { logoutUser, user, uniquememberdetails, getMemberById } =
    useAppContext();
  const [name, setName] = useState("...Loading!");

  const [admin, setAdmin] = useState(false);
  const [appAdmin, setAppAdmin] = useState(false);
  const [superAdmin, setSuperAdmin] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const [churchName, setChurchName] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  useEffect(() => {
    if (user && user._id) {
      getMemberById(user._id);
    }
  }, []);

  const [percentageCompleted, setPercentageCompleted] = useState(0);
  const [OnboardingWizardstatus, setOnboardingWizardstatus] = useState(false);
  const [myfirstname, setMyfirstname] = useState("...Loading!");

  useEffect(() => {
    if (uniquememberdetails) {
      setMyfirstname(user.mfirstName);
    }
  }, [uniquememberdetails]);
  useEffect(() => {
    if (user && user.churchName) {
      setChurchName(user.churchName);
      setName(user?.mfirstName);
      // setDaysUntilExpiry(user?.freeExpiryDays);
    }
  }, [user.churchName]);

  useEffect(() => {
    if (user && user.percentageCompleted) {
      setPercentageCompleted(user.percentageCompleted);
      // setOnboardingWizardstatus(user.onboardingWizardstatus);
    }
  }, [user.percentageCompleted]);

  const changeWizardOnboarding = async () => {
    setOnboardingWizardstatus(true);
  };

  useEffect(() => {
    const roles = ["Super Admin", "Application Admin", "Admin"];
    const hasRequiredRole = roles.some((role) =>
      user?.role[0]?.roleName?.includes(role)
    );

    if (hasRequiredRole) {
      setSuperAdmin(true);
      setAdmin(true);
      setAppAdmin(true);
    }
  }, [user.role]);

  const profile = () => {
    navigate("/auth/profile");
  };

  const userManagement = () => {
    navigate("/auth/usersmanagement");
  };

  const ChangePassword = () => {
    navigate("/auth/changepassword");
  };

  const accountManagement = () => {
    navigate("/auth/accountmanagement");
  };

  const bulkAction = () => {
    navigate("/auth/bulkaction");
  };

  const integrations = () => {
    navigate("/auth/integrations");
  };

  const messageCenter = () => {
    navigate("/auth/messagecenter");
  };

  const workflow = () => {
    navigate("/auth/workflow");
  };

  const [quickUpgrade, setQuickUpgrade] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [editUpgrade, setEditUpgrade] = useState(false);

  const handleModalx = () => {
    setShowModal(true);
    setQuickUpgrade(true);
  };

  const editMemberPlan = () => {
    setShowModal2(true);
    setEditUpgrade(true);
  };

  const [trialInfo, setTrialInfo] = useState({
    isFree: false,
    remainingDays: null,
    isExpired: false,
  });

  useEffect(() => {
    if (user?.memberShip === "Free" || user?.memberShip === undefined) {
      const calculateTrialStatus = () => {
        if (user?.freeTrialExpiry) {
          const expiryDate = new Date(user.freeTrialExpiry);
          expiryDate.setHours(0, 0, 0, 0);
          const currentDate = new Date();
          currentDate.setHours(0, 0, 0, 0);

          const diffTime = expiryDate - currentDate;
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

          setTrialInfo({
            isFree: true,
            remainingDays: Math.max(0, diffDays),
            isExpired: diffDays <= 0,
          });
        }
      };

      calculateTrialStatus();
      const intervalId = setInterval(calculateTrialStatus, 60 * 60 * 1000);
      return () => clearInterval(intervalId);
    } else {
      setTrialInfo({
        isFree: false,
        remainingDays: null,
        isExpired: false,
      });
    }
  }, [user?.memberShip, user?.freeTrialExpiry]);

  const showPowerQuery = () => {
    navigate("/auth/powerquery");
  };
  const authFetch = useMemo(() => createTrialManager(logoutUser), [logoutUser]);

  useEffect(() => {
    const calculateTrialStatus = () => {
      if (user?.memberShip === "Free" && user?.freeTrialExpiry) {
        const expiryDate = new Date(user.freeTrialExpiry);
        expiryDate.setHours(0, 0, 0, 0);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const diffTime = expiryDate - currentDate;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        setTrialInfo({
          remainingDays: Math.max(0, diffDays),
          isExpired: diffDays <= 0,
        });

        // If trial expires during session, trigger a check
      }
    };

    calculateTrialStatus();
    // Check every hour for potential expiration
    const intervalId = setInterval(calculateTrialStatus, 60 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [user?.freeTrialExpiry]);

  const TrialStatus = () => {
    if (user?.role?.some((role) => role.roleName === "App Admin")) {
      return (
        <div
          style={{
            fontSize: "1em",
            fontWeight: "600",
            color: "var(--primary-color)",
          }}
        >
          Welcome, App Admin!
        </div>
      );
    }

    if (trialInfo.isExpired) {
      return (
        <div style={{ fontSize: "1em", fontWeight: "600", color: "red" }}>
          Free trial ended
        </div>
      );
    }

    if (trialInfo.remainingDays > 0) {
      return (
        <div style={{ fontSize: "1em", fontWeight: "600", color: "red" }}>
          Trial ends in {trialInfo.remainingDays} days
        </div>
      );
    }

    return null;
  };

  const [showWizard, setShowWizard] = useState(false);

  const handleShowWizard = () => {
    setShowWizard(true);
  };

  return (
    <Navbar expand="lg" className="group">
      <Container fluid>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" className="justify-content-end">
          <Nav
            className="my-2 my-lg-0 me-4"
            style={{
              alignItems: "center",
              display: "flex",
              gap: "2rem",
            }}
            navbarScroll
          >
            <TrialStatus />

            {(admin || appAdmin || superAdmin) && (
              <>
                <button className="jiluil3" onClick={showPowerQuery}>
                  <FaBolt /> Power Query
                </button>
              </>
            )}

            {(admin || appAdmin || superAdmin) && (
              <Tooltip title="Message Center">
                <Nav.Link onClick={messageCenter}>
                  <FaBell
                    style={{ color: "var(--primary-color)", fontSize: "1.8em" }}
                  />
                </Nav.Link>
              </Tooltip>
            )}

            {percentageCompleted < 100 &&
              OnboardingWizardstatus === false &&
              (admin || appAdmin || superAdmin) && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handleShowWizard}
                >
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      backgroundColor: "var(--secondary-color)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "8px",
                    }}
                  >
                    <FaInfo className="vibrate" style={{ color: "white" }} />
                  </div>
                  <span style={{ fontWeight: "bold", color: "#333" }}>
                    {`${percentageCompleted}%`}
                  </span>
                </div>
              )}

            <div className="ilui" onClick={profile}>
              <Image
                src={
                  uniquememberdetails?.mProfilePicture?.Location
                    ? uniquememberdetails?.mProfilePicture?.Location
                    : avatar
                }
                roundedCircle
                style={{
                  width: "45px",
                  height: "45px",
                  objectFit: "cover",
                  border: "1px solid gray",
                  backgroundColor: "#f8f9fa", // optional: adds a light background if image is transparent
                }}
              />
            </div>
            <NavDropdown title={myfirstname} id="basic-nav-dropdown">
              <NavDropdown.Item onClick={ChangePassword} className="dropdd">
                Change Password
              </NavDropdown.Item>
              <NavDropdown.Divider />

              {(admin || appAdmin || superAdmin) && (
                <>
                  <NavDropdown.Item
                    onClick={userManagement}
                    className={
                      admin || appAdmin || superAdmin ? "dropdd" : "disabled"
                    }
                  >
                    Access Management
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={bulkAction} className="dropdd">
                    Bulk Operations
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    onClick={workflow}
                    className={
                      admin || appAdmin || superAdmin ? "dropdd" : "disabled"
                    }
                  >
                    WorkFlow
                  </NavDropdown.Item>
                  <NavDropdown.Divider />

                  <NavDropdown.Item
                    onClick={accountManagement}
                    className="dropdd"
                  >
                    Account Management
                  </NavDropdown.Item>
                  <NavDropdown.Divider />

                  {(admin || appAdmin || superAdmin) &&
                  user.memberShip === "Free" ? (
                    <NavDropdown.Item onClick={handleModalx} className="dropdd">
                      Upgrade Subscription
                    </NavDropdown.Item>
                  ) : (
                    <NavDropdown.Item
                      onClick={editMemberPlan}
                      className="dropdd"
                    >
                      Manage Subscription
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Divider />
                </>
              )}

              <NavDropdown.Item onClick={logoutUser} className="dropdd">
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
      <CreateSubscription
        show={showModal}
        onHide={() => setShowModal(false)}
        quickUpgrade={quickUpgrade}
      />
      <EditSubscription
        show={showModal2}
        onHide={() => setShowModal2(false)}
        quickUpgrade={editUpgrade}
      />
      <OnboardingWizard
        showWizard={showWizard}
        setShowWizard={setShowWizard}
        setPercentageCompleted={setPercentageCompleted}
        changeWizardOnboarding={changeWizardOnboarding}
      />{" "}
    </Navbar>
  );
}

export default MemberNav;
