import React, { forwardRef } from "react";
import moment from "moment";
import { useAppContext } from "../../context/appContext";

const PrintQuery = forwardRef((props, ref) => {
  const { user } = useAppContext();
  const { results } = props;

  return (
    <div ref={ref} className="print-container">
      <div className="text-center mb-8">
        <h2 className="text-2xl font-bold mb-5">Query Results Report</h2>
        <div className="text-right text-sm mt-2">
          {/* <div>{moment().format("MMMM Do, YYYY")}</div>
          <div>
            Page <span className="page-number"></span>
          </div> */}
        </div>
      </div>

      {/* <div className="mb-8">
        <h3 className="text-xl font-semibold">{user.churchName}</h3>
        {user.maddress && (
          <div className="text-sm">
            <div>{user.maddress}</div>
            <div>
              {user.mcity}, {user.mstate} {user.mpostal}
            </div>
          </div>
        )}
      </div> */}

      <div className="overflow-x-auto">
        <table className="hover">
          <thead>
            <tr className="bg-gray-100">
              <th className="p-2 border text-left">Date</th>
              <th className="p-2 border text-left">Name</th>
              <th className="p-2 border text-left">Email</th>
              <th className="p-2 border text-left">Gender</th>
              {/* <th className="p-2 border text-left">Marital Status</th> */}
              <th className="p-2 border text-left">Mobile Phone</th>
            </tr>
          </thead>
          <tbody>
            {results?.map((result, index) => (
              <tr key={index} className="border-b">
                <td className="p-2 border">
                  {moment(result.postDate).format("MMM Do, YYYY")}
                </td>
                <td className="p-2 border">
                  {result.mfirstName} {result.mlastName}
                </td>
                <td className="p-2 border">{result.memail}</td>
                <td className="p-2 border">{result.mgender}</td>
                {/* <td className="p-2 border">{result.mmarital}</td> */}
                <td className="p-2 border">{result.mmobilePhone}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <style>
        {`
          .print-container {
            display: none;
          }

          @media print {
            .print-container {
              display: block;
              padding-bottom:  2rem;
            }

            @page {
              size: A4;
              margin: 2cm 1cm;
            }

            .page-number::before {
              content: counter(page);
            }

            thead {
              display: table-header-group;
            }

            tr {
              page-break-inside: avoid;
            }
          }
        `}
      </style>
    </div>
  );
});

export default PrintQuery;
