import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../../context/appContext";
import Alert from "../../../components/Alert/Alert";
import Loading from "../../../components/Loading/Loading";
import axios from "axios";

const AddExpense = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [clicked, setClicked] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (e, fundss) => {
    setShow2(true);
  };

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const [expenseNamex, setExpenseNamex] = useState();
  const [expenseIdx, setExpenseIdx] = useState();
  const [expenseDescriptionx, setExpenseDescriptionx] = useState();
  const [taxDeductiblex, setTaxDeductiblex] = useState();
  const [uniqueData, setUniqueData] = useState(null);
  const [budgetedAmountx, setBudgetedAmountx] = useState();
  const [spentBudgetx, setSpentBudgetx] = useState();

  const [expenseDescription, setExpenseDescription] = useState();
  const [expenseName, setExpenseName] = useState();
  const [expenseId, setExpenseId] = useState();
  const [budgetedAmount, setBudgetedAmount] = useState();
  const [spentBudget, setSpentBudget] = useState();

  const [error, setError] = useState(false);
  const handleShow1 = (e, fundss) => {
    e.preventDefault();
    setShow1(true);
    setClicked(fundss);
  };

  const {
    displayAlert,
    showAlert,
    isLoading,
    deleteExpense,
    totalBudget,
    addExpense,
    getExpense,
    expenses,
    user,
    logoutUser,
    availableBudget,
  } = useAppContext();

  const getExpenseById = async (e, expensess) => {
    e.preventDefault();

    const { data } = await authFetch.get(
      `/giving/expense/getexpensesbyid/${expensess}`
    );

    // const uniqueDatax = data?.reduce((acc, obj) => ({ ...acc, ...obj }), {});

    setUniqueData(data);
    handleShow2();
  };

  useEffect(() => {
    if (uniqueData) {
      setExpenseNamex(uniqueData.expenseName);
      setExpenseIdx(uniqueData.expenseId);

      setExpenseDescriptionx(uniqueData.expenseDescription);
      setBudgetedAmountx(uniqueData.budget);
      setSpentBudgetx(uniqueData.spentBudget);
    }
  }, [uniqueData]);

  const handleDelete = async () => {
    deleteExpense(clicked._id, {
      alertText: "Expense Deleted",
    });

    handleClose1();
  };

  useEffect(() => {
    getExpense();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!expenseName || !expenseDescription) {
      setError(true);
      return;
    }
    const expenseDetails = {
      expenseName: expenseName,
      expenseId: expenseId,
      expenseDescription: expenseDescription,

      churchName: user.churchName,
      budget: budgetedAmount,
      spentBudget: spentBudget,
    };

    addExpense({ expenseDetails });
    getExpense();
    handleClose();
    setExpenseId();
    setExpenseName();

    setExpenseDescription();
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!expenseNamex) {
      displayAlert();
      return;
    }

    const updateExpense = {
      expenseName: expenseNamex,
      expenseId: expenseIdx,
      expenseDescription: expenseDescriptionx,
      budget: budgetedAmountx,
      spentBudget: spentBudgetx,
    };
    await authFetch.patch(
      `/giving/expense/updateexpense/${uniqueData._id}`,
      updateExpense
    );

    setExpenseName("");
    setExpenseId("");

    setExpenseDescription("");
    setBudgetedAmount("");
    setSpentBudget("");
    getExpense();
    handleClose2();
  };

  if (isLoading) {
    <Loading />;
    return;
  }

  return (
    <>
      <Container fluid>
        {showAlert && <Alert />}

        <Row className="headerbanner">
          <div className="bannerbig">Expense Categories</div>
        </Row>
        <Row className="sssssssecwef">
          <div className="bnmjo">
            <button className="jiluil" onClick={handleShow}>
              Add Category
            </button>
          </div>
        </Row>
        {expenses && expenses.length > 0 ? (
          <Row className="fundc2">
            <Table responsive hover style={{ backgroundColor: "#f7f7f7" }}>
              <thead>
                <tr>
                  <th style={{ fontSize: "1em" }}>EXPENSE ID</th>
                  <th style={{ fontSize: "1em" }}>EXPENSE NAME</th>
                  <th style={{ fontSize: "1em" }}>EXPENSE DESCRIPTION</th>

                  <th style={{ fontSize: "1em" }}>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {expenses?.map((expense) => (
                  <tr key={expense._id}>
                    <td style={{ fontSize: "1em" }}>{expense.expenseId}</td>
                    <td style={{ fontSize: "1em" }}>{expense.expenseName}</td>
                    <td style={{ fontSize: "1em" }}>
                      {expense.expenseDescription}
                    </td>

                    <td
                      style={{
                        gap: "10px",
                        display: "flex",
                        fontSize: "1em",
                      }}
                    >
                      <button
                        className="jiluil3"
                        onClick={(e) => getExpenseById(e, expense._id)}
                      >
                        Edit
                      </button>

                      <button
                        className="jiluil"
                        onClick={(e) => handleShow1(e, expense)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        ) : (
          <div className="noDatax">
            <p className="noDataBackup">No data available for display.</p>
          </div>
        )}
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
            Create Expense Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {error && (
              <p className="inlineerror">
                Category Name is required to proceed
              </p>
            )}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Expense Name"
                value={expenseName}
                onChange={(e) => setExpenseName(e.target.value)}
                name="expenseName"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                as="textarea" // Changes the input to a textarea
                placeholder="Expense Description"
                value={expenseDescription}
                onChange={(e) => setExpenseDescription(e.target.value)}
                name="expenseDescription"
                style={{ fontSize: "1em" }}
                rows={3} // Sets the default number of visible text lines
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Expense ID (optional)"
                value={expenseId}
                onChange={(e) => setExpenseId(e.target.value)}
                name="expenseId"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose}>
            Cancel
          </button>
          <button className="jiluil" onClick={handleSubmit}>
            Add
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "1em" }}>
            Delete Expense Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This action will permanently delete the selected expense category.
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose1}>
            Cancel
          </button>
          <button className="jiluil" onClick={handleDelete}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
            Update Expense Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Category Name"
                value={expenseNamex}
                onChange={(e) => setExpenseNamex(e.target.value)}
                name="fundName"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                as="textarea" // Changes the input to a textarea
                placeholder="Expense Description"
                value={expenseDescriptionx}
                onChange={(e) => setExpenseDescriptionx(e.target.value)}
                name="expenseDescriptionx"
                style={{ fontSize: "1em" }}
                rows={3} // Sets the default number of visible text lines
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Category ID"
                value={expenseIdx}
                onChange={(e) => setExpenseIdx(e.target.value)}
                name="fundId"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose2}>
            Cancel
          </button>
          <button className="jiluil" onClick={handleUpdate}>
            Update
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddExpense;
