import React, { useState, useEffect, useRef } from "react";
import { Select } from "antd";
import { useAppContext } from "../../context/appContext";
import "./addworkflow.css";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";
import axios from "axios";
import { Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FaTimes } from "react-icons/fa";
import moment from "moment-timezone";
import { Modal } from "react-bootstrap";
import he from "he";

const NewCalendarEventTrigger = ({
  workflowId,
  setShowNewCalendarTrigger,
  workflowName,
  onSuccessfulDelete,
  showEventTrigger,

  setNotice,
  selectedOption,
}) => {
  const quillRef = useRef(null);
  const smsMessageRef = useRef(null);
  const [uniqueSteps, setUniqueSteps] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getUniqueSteps = async () => {
      try {
        setLoading(true);

        const { data } = await authFetch.get(`/steps/${workflowId}`);
        const [uniqueschedule] = data;
        setUniqueSteps(uniqueschedule);

        setError(null);
      } catch (err) {
        setError(err.message); // Set error state for displaying in UI
      } finally {
        setLoading(false);
      }
    };

    if (workflowId) {
      getUniqueSteps();
    }
  }, [workflowId]);

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    getCompleteMembers();
  }, []);

  const { containers, logoutUser, user, getCompleteMembers, getContainers } =
    useAppContext();
  const [selectedEvent, setSelectedEvent] = useState("");
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [error, setError] = useState(false);
  const [selectedActionx, setSelectedActionx] = useState("");
  const [showAction, setShowAction] = useState(false);
  const [showFrequency, setShowFrequency] = useState(false);
  const [showTrigger, setShowTrigger] = useState(true);
  const [triggerOn, setTriggerOn] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [selectedFrequency, setSelectedFrequency] = useState("sendOnDate");
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [selectedRepeat, setSelectedRepeat] = useState("");
  const [numberOfRepeats, setNumberOfRepeats] = useState("");
  const [waitDays, setWaitDays] = useState("");
  const [beforeOrAfter, setBeforeOrAfter] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [sendTime, setSendTime] = useState("");
  const [senderName, setSenderName] = useState("");
  const [smsSender, setsmsSender] = useState(user.primaryPhoneNumber);
  const [smsData, setSmsData] = useState("");
  const [smsMessage, setSMSMessage] = useState("");
  const maxChars = 2500;

  const modules = {
    toolbar: {
      container: "#toolbar",
      "custom-dropdown": ["option1", "option2", "option3"],
      // Linking to our custom toolbar
    },
  };

  const handleActionClick = (actionxx) => {
    setSelectedActionx(actionxx);
  };

  useEffect(() => {
    if (quillRef.current && message !== null) {
      const quill = quillRef.current.getEditor();
      if (quill.root.innerHTML !== message) {
        quill.clipboard.dangerouslyPasteHTML(message);
      }
    }
  }, [message]);

  useEffect(() => {
    getContainers();
  }, []);

  const handleBgColorChange = (selectedOption) => {
    setEditorBgColor(selectedOption.value);
  };
  const [bgColor, setBgColor] = useState("");
  const [errorx, setErrorx] = useState(false);
  const [editorBgColor, setEditorBgColor] = useState("white"); // State for the editor's background color

  const colorOptions = [
    { value: "white", label: "White" },
    { value: "purple", label: " Purple" },
    { value: "orange", label: " Orange" },
    { value: "brown", label: " Brown" },
    { value: "black", label: "Black" },
    { value: "lightyellow", label: "Light Yellow" },
    { value: "lightblue", label: "Light Blue" },
    { value: "lightgreen", label: "Light Green" },
    { value: "lightcoral", label: "Light Coral" },
    { value: "lightpink", label: "Light Pink" },
    { value: "lightgray", label: "Light Gray" },
    { value: "lightsalmon", label: "Light Salmon" },
    { value: "lightseagreen", label: "Light Sea Green" },
    { value: "lightcyan", label: "Light Cyan" },

    // Add more colors as needed
  ];

  const insertPlaceholder = (placeholder) => {
    if (placeholder) {
      const quill = quillRef.current.getEditor();
      const cursorPosition = quill.getSelection()?.index ?? 0;
      quill.insertText(cursorPosition, placeholder);
      quill.setSelection(cursorPosition + placeholder.length);
    }
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.data.value,
      color: state.data.value === "white" ? "black" : "white",
    }),
    // Add more custom styles if needed
  };

  useEffect(() => {
    if (quillRef.current && message !== null) {
      const quill = quillRef.current.getEditor();
      quill.root.style.backgroundColor = bgColor; // Apply background color directly to Quill's content area

      // Check if the content is different before updating
      if (quill.root.innerHTML !== message) {
        const range = quill.getSelection(); // Save the current cursor position
        quill.clipboard.dangerouslyPasteHTML(message);

        if (range) {
          // Restore the saved cursor position
          quill.setSelection(range.index, range.length);
        }
      }
    }
  }, [message, bgColor]);

  /////SMS
  const handleVariableInsert = (event) => {
    const variable = event.target.value;
    if (variable === "Select Variables") return; // Ignore default select option
    insertAtCursorPosition(variable);
    event.target.value = "Select Variables"; // Reset dropdown
  };

  const insertAtCursorPosition = (content) => {
    // Check if content and smsMessageRef.current are defined
    if (!content || !smsMessageRef.current) {
      console.error("Content or smsMessageRef is undefined");
      return;
    }

    const cursorPosition = smsMessageRef.current.selectionStart;
    const textBeforeCursor = smsMessage
      ? smsMessage.slice(0, cursorPosition)
      : "";
    const textAfterCursor = smsMessage ? smsMessage.slice(cursorPosition) : "";
    const newText = textBeforeCursor + content + textAfterCursor;

    if (newText.length <= maxChars) {
      setSMSMessage(newText);
      setTimeout(() => {
        // Check if smsMessageRef.current is still defined
        if (smsMessageRef.current) {
          smsMessageRef.current.selectionStart =
            smsMessageRef.current.selectionEnd =
              cursorPosition + content.length;
        }
      }, 0);
    }
  };

  const handleSMSMessageChange = (e) => {
    setSMSMessage(e.target.value);
  };

  //////others

  useEffect(() => {
    getCompleteMembers();
  }, []);

  const handleChangex = (value) => {
    setSelectedEvent(value);
    if (error) setError("");
  };
  const handleChange = (value) => {
    setSelectedGroup(value);

    if (error) setError("");
  };

  useEffect(() => {
    getContainers();
  }, []);

  const handleDescriptionChange = (value) => {
    setMessage(value);
  };

  const handleRadioSelect = (e) => {
    setSelectedFrequency(e.target.value);
  };
  const handleRepeatChange = (e) => {
    setSelectedRepeat(e.target.value);
  };

  const handleFrequencyChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSwitchChange = (e) => {
    setIsSwitchOn(e.target.checked);
  };

  const options = containers.map((container) => ({
    value: container._id,
    label: container.name,
  }));

  const handleActionEmail = async (e, action) => {
    e.preventDefault();

    if (
      !triggerOn ||
      !eventTime ||
      !selectedGroup.length ||
      !selectedActionx ||
      !workflowName ||
      (selectedActionx === "Send Email" &&
        (!message || !email || !emailSubject || !senderName)) ||
      (selectedActionx === "Send Text" && (!smsMessage || !smsSender))
    ) {
      toast(
        <CustomToastContent
          title="Error"
          message="Please fill all fields to proceed!"
        />
      );
      return;
    }

    const datax = {
      churchName: user.churchName,
      churchId: user._id,
      workflow: workflowId,
      selectedEvent: selectedEvent,
      selectedGroup: selectedGroup,
      selectedAction: selectedActionx,
      message: message,
      replyEmail: email,
      emailSubject: emailSubject,
      selectedFrequency: selectedFrequency,
      eventTime: eventTime,
      senderName: senderName,
      workflowName: workflowName,
      editorBgColor: editorBgColor,
      senderEmail: user.memail,
      messageType: "email",
      selectedOption: selectedOption,
      status: action === "activate",
      triggerOn: triggerOn,
    };

    try {
      const response = await authFetch.post(`/steps`, datax);

      if (response.status === 200 || response.status === 201) {
        setNotice(true);
        onSuccessfulDelete();
        setShowNewCalendarTrigger(false);
        toast(
          <CustomToastContent title="Success" message="Operation Successful!" />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleActionSms = async (e, action) => {
    e.preventDefault();

    console.log(
      "smsMessage",
      smsMessage,
      selectedGroup,
      "selectedGroup",
      selectedActionx,
      "selectedActionx",
      selectedFrequency,
      "selectedFrequency",
      workflowName,
      "workflowName",
      eventTime,
      "eventTime",
      triggerOn,
      "triggerOn"
    );
    if (
      !selectedGroup ||
      !selectedActionx ||
      !smsMessage ||
      !selectedFrequency ||
      !workflowName ||
      !eventTime ||
      !triggerOn
    ) {
      toast(
        <CustomToastContent
          title="Error"
          message="Please fill all fields to proceed!"
        />
      );
      return;
    }

    const datax = {
      churchName: user.churchName,
      churchId: user._id,
      workflow: workflowId,
      selectedEvent: selectedEvent,
      selectedGroup: selectedGroup,
      selectedAction: selectedActionx,
      selectedFrequency: selectedFrequency,
      triggerOn: triggerOn,
      eventTime: eventTime,

      senderName: senderName,

      workflowName: workflowName,
      smsMessage: smsMessage,
      smsSender: user.primaryPhoneNumber,

      selectedOption: selectedOption,
      messageType: "text",
      status: action === "activate",
    };

    try {
      const response = await authFetch.post(`/steps/sms`, datax);
      if (response.status === 201 || response.status === 200) {
        setNotice(true);
        setShowNewCalendarTrigger(false);

        onSuccessfulDelete();
        toast(
          <CustomToastContent title="Success" message="Operation Successful!" />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose = () => {
    setShowNewCalendarTrigger(false);
  };

  const [showActivate, setShowActivate] = useState(false);
  const handleCloseActivation = () => setShowActivate(false);

  const activateEmail = (e) => {
    e.preventDefault();

    setShowActivate(true);
  };

  return (
    <>
      <div className="flex items-center justify-between pt-2">
        <p
          style={{
            fontSize: "1.2rem",
            fontWeight: "bold",

            marginBottom: "0px",
          }}
        >
          New Step
        </p>{" "}
        <p
          onClick={handleClose}
          style={{
            fontSize: "1.2rem",
            fontWeight: "bold",
            cursor: "pointer",
            marginBottom: "0px",
          }}
        >
          Close <FaTimes className="ml-2" />
        </p>
      </div>
      <hr />
      <div className="q3323">
        Trigger date {""}
        <Form.Control
          type="date"
          name="days"
          value={triggerOn}
          onChange={(e) => setTriggerOn(e.target.value)}
          className="my-3"
          required
        />{" "}
        at
        <br />
        <Form.Control
          type="time"
          name="time"
          value={eventTime}
          onChange={(e) => setEventTime(e.target.value)}
          className="my-3"
          required
        />
        for everyone in <br />
        <br />
        <Select
          mode="tags"
          style={{ width: "50%" }}
          placeholder="Select group(s)"
          onChange={handleChange}
          value={selectedGroup}
          options={options}
          required
        />{" "}
        group(s)
      </div>

      <div className="q3323">
        <p className="text-bold">Action</p>
        <p className="my-3">
          {" "}
          Here are actions that you can add to your Automated Workflow.
        </p>
        <div className="flex flex-row gap-3">
          <div
            className={`aqwsss ${
              selectedActionx === "Send Email" ? "selected-style" : ""
            }`}
            onClick={() => handleActionClick("Send Email")}
          >
            Send Email
          </div>
          <div
            className={`aqwsss ${
              selectedActionx === "Send Text" ? "selected-style" : ""
            }`}
            onClick={() => handleActionClick("Send Text")}
          >
            Send Text
          </div>
        </div>
      </div>

      <div className="q3323">
        <p className="text-bold">Scheduling Option</p>
        <div>
          <Form.Check
            type="radio"
            //label={`Send on ${triggerOn}`} // Corrected label
            label="Send on selected date and time"
            name="formHorizontalRadioss"
            id="formHorizontalRadios1s"
            value="sendOnDate"
            onChange={handleRadioSelect}
            checked={selectedFrequency === "sendOnDate"}
            className="my-3"
          />
        </div>
        <div>
          {selectedActionx === "Send Email" && (
            <>
              <Form>
                <Form.Group
                  className="my-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Sender Name</Form.Label>
                  <Form.Control
                    type="text"
                    autoFocus
                    value={senderName}
                    className="myb-3"
                    onChange={(e) => setSenderName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group
                  className="my-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Reply Email</Form.Label>
                  <Form.Control
                    type="email"
                    autoFocus
                    value={email}
                    className="myb-3"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group
                  className="my-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                    type="text"
                    autoFocus
                    value={emailSubject}
                    onChange={(e) => setEmailSubject(e.target.value)}
                  />
                </Form.Group>

                <div id="toolbar" className="flex items-center">
                  <button className="ql-bold">Bold</button>
                  <button className="ql-italic">Italic</button>
                  <button className="ql-underline">Underline</button>
                  <button className="ql-header" value="1">
                    Header 1
                  </button>
                  <button className="ql-header" value="2">
                    Header 2
                  </button>
                  <button className="ql-list" value="ordered">
                    Ordered List
                  </button>
                  <button className="ql-list" value="bullet">
                    Bullet List
                  </button>
                  <button className="ql-align" value=""></button>
                  <button className="ql-align" value="center"></button>
                  <button className="ql-align" value="right"></button>
                  <button className="ql-align" value="justify"></button>
                  <select className="ql-color">
                    <option value="red">Red</option>
                    <option value="green">Green</option>
                    <option value="blue">Blue</option>
                    <option value="black">Black</option>
                    <option value="yellow">Yellow</option>
                    <option value="orange">Orange</option>
                    <option value="purple">Purple</option>
                    <option value="pink">Pink</option>
                    <option value="gray">Gray</option>
                    <option value="pink">Pink</option>
                    <option value="brown">Brown</option>
                    <option value="grey">Grey</option>
                    <option value="white">White</option>
                    {/* Add more colors as needed */}
                  </select>

                  <select
                    className="ql-background"
                    onChange={handleBgColorChange}
                    placeholder="test"
                  >
                    <option value="red">Red</option>
                    <option value="green">Green</option>
                    <option value="blue">Blue</option>
                    <option value="black">Black</option>
                    <option value="yellow">Yellow</option>
                    <option value="orange">Orange</option>
                    <option value="purple">Purple</option>
                    <option value="pink">Pink</option>
                    <option value="gray">Gray</option>
                    <option value="pink">Pink</option>
                    <option value="brown">Brown</option>
                    <option value="grey">Grey</option>
                    {/* Add more colors as needed */}
                  </select>
                  <Select
                    options={colorOptions}
                    styles={customStyles}
                    onChange={handleBgColorChange}
                    placeholder="Background"
                  />

                  <select className="ql-size">
                    <option value="small">Small</option>
                    <option selected>Normal</option>
                    <option value="large">Large</option>
                    <option value="huge">Huge</option>
                  </select>
                  <button className="ql-link">Link</button>
                  <button className="ql-image">Image</button>
                </div>

                <ReactQuill
                  ref={quillRef}
                  value={message}
                  defaultValue={message}
                  onChange={handleDescriptionChange}
                  theme="snow"
                  className="react-quill-editor"
                  placeholder="Enter a message here..."
                  modules={modules}
                  style={{ backgroundColor: editorBgColor, minHeight: "400px" }}
                />
              </Form>
            </>
          )}

          {selectedActionx === "Send Text" && (
            <>
              <Form>
                <Form.Group
                  className="my-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Send From</Form.Label>
                  <Form.Control
                    value={user.primaryPhoneNumber}
                    className="mb-3"
                    disabled
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Message:</Form.Label>

                  <Form.Control
                    as="textarea"
                    ref={smsMessageRef}
                    rows={6}
                    required
                    value={smsMessage}
                    onChange={handleSMSMessageChange}
                  />
                </Form.Group>
                <div className="character-count flex items-center justify-end">
                  {`${smsMessage.length} / ${maxChars}`} characters. For better
                  delivery, use a maximum of 2500 characters.
                </div>
              </Form>
            </>
          )}
        </div>
        <div className="flex items-end justify-end my-5">
          {selectedActionx === "Send Text" && (
            <Form.Group className="mb-3">
              <Form.Label>Insert Variable:</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={handleVariableInsert}
                style={{ width: "100%" }}
              >
                <option>Select Variables</option>
                <option value="[FirstName]">First Name</option>
                <option value="[LastName]">Last Name</option>
                <option value="[Email]">Email</option>
                <option value="[PhoneNumber]">Phone Number</option>
              </Form.Select>
            </Form.Group>
          )}
          {selectedActionx === "Send Email" && (
            <div className="flex items-center justify-center gap-3 flex-row w-100">
              <button
                onClick={() => insertPlaceholder(`[FirstName]`)}
                className="jiluil4"
              >
                First Name
              </button>
              <button
                type="button"
                onClick={() => insertPlaceholder(`[LastName]`)}
                className="jiluil4"
              >
                Last Name
              </button>
              <button
                type="button"
                onClick={() => insertPlaceholder(`[Email]`)}
                className="jiluil4"
              >
                Email
              </button>
              <button
                type="button"
                onClick={() => insertPlaceholder(`[PhoneNumber]`)}
                className="jiluil4"
              >
                Phone Number
              </button>
            </div>
          )}
        </div>
        <div className="flex justify-center">
          {selectedActionx === "Send Email" && (
            <div className="flex gap-3">
              <button
                onClick={(e) => handleActionEmail(e, "save")}
                className="jiluil3"
              >
                Save
              </button>
              <button
                onClick={(e) => activateEmail(e, "activate")}
                className="jiluil"
              >
                Activate
              </button>
            </div>
          )}
          {selectedActionx === "Send Text" && (
            <div className="flex gap-3">
              <button
                onClick={(e) => handleActionSms(e, "save")}
                className="jiluil3"
              >
                Save
              </button>
              <button
                onClick={(e) => handleActionSms(e, "activate")}
                className="jiluil"
              >
                Activate
              </button>
            </div>
          )}
        </div>
        <p
          style={{ fontSize: "0.8em", textAlign: "center", paddingTop: "20px" }}
        >
          <span style={{ fontWeight: "600" }}>Note:</span> Workflow must be
          activated to be active.
        </p>
      </div>
      <Modal show={showActivate} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Activate Step</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you ready to activate this step?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleCloseActivation}>
            Close
          </button>
          <button
            className="jiluil"
            onClick={(e) => handleActionEmail(e, "activate")}
          >
            Activate
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewCalendarEventTrigger;
