import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useAppContext } from "../../../context/appContext";
import {
  FaChurch,
  FaMapMarkerAlt,
  FaListAlt,
  FaUsersCog,
  FaArchive,
  FaUserPlus,
  FaPalette,
  FaBuilding,
  FaLock,
  FaSms,
  FaChartBar,
  FaCog,
  FaGlobe,
  FaUserShield,
} from "react-icons/fa";

// Import your modal components
import Profiles from "../../../components/Profiles/Profiles";
import ChurchAddress from "../../../components/Profiles/ChurchAddress";
import MailChimp from "../../../components/Profiles/MailChimp";
import TextSetup from "../../../components/Profiles/TextSetup.js";
import MultiFactorAuth from "../../../components/Profiles/MultiFactorAuth";
import Intuit from "../../../components/Profiles/Intuit";
import ChurchCampuses from "../../../components/Profiles/ChurchCampuses";
import Campuses from "../../../components/Profiles/Campuses.js";
import ExternalIntegrations from "../../../components/Profiles/ExternalIntegrations";
import Location from "../../../components/Profiles/Location.js";
import Customize from "../../../components/Profiles/Customize.js";
import DynamicFields from "../../../components/Profiles/DynamicFields.js";
import ArchivedList from "../../../components/Profiles/ArchivedList";
import NewMembers from "../../../components/Profiles/NewMembers";
import TextMeter from "../../../components/Profiles/TextMeter";
import EmailSettings from "../../../components/Profiles/EmailSettings";
import ChurchInformation from "../../../components/Profiles/ChurchInformation.js";

const SettingsCard = ({
  title,
  icon: Icon,
  onClick,
  description,
  disabled,
}) => (
  <Card
    onClick={!disabled ? onClick : undefined}
    className={`h-100 transition-all ${
      !disabled ? "cursor-pointer hover:shadow-lg" : "opacity-60"
    }`}
  >
    <Card.Body className="d-flex flex-column p-4">
      <div className="d-flex align-items-center mb-3">
        <div
          className="rounded-circle p-2 me-3"
          style={{ backgroundColor: "var(--bs-primary-bg-subtle)" }}
        >
          <Icon style={{ color: "var(--primary-color)" }} size={24} />
        </div>
        <h5 className="mb-0">{title}</h5>
      </div>
      <p className="text-muted small mb-0">{description}</p>
    </Card.Body>
  </Card>
);

const Settings = () => {
  const { user } = useAppContext();
  const [modals, setModals] = useState({
    churchInfo: false,
    location: false,
    dynamicFields: false,
    email: false,
    archive: false,
    newMembers: false,
    text: false,
    textMeter: false,
    mfa: false,
    branding: false,
    campus: false,
    updateFields: false,
  });

  const isAdmin = user?.role?.some((role) =>
    ["Super Admin", "Admin", "App Admin"].includes(role.roleName)
  );

  const [isFree, setIsFree] = useState(false);

  useEffect(() => {
    if (user?.subscription?.length > 0) {
      setIsFree(user.subscription[0].plan.nickname === "Free");
    }
  }, [user?.subscription]);

  const handleModal = (modalName) => {
    setModals((prev) => ({
      ...prev,
      [modalName]: true,
    }));
  };

  const closeModal = (modalName) => {
    setModals((prev) => ({
      ...prev,
      [modalName]: false,
    }));
  };

  const settingsGroups = {
    "Church Settings": [
      {
        title: "Church Information",
        icon: FaChurch,
        onClick: () => handleModal("churchInfo"),
        description:
          "Manage church details, contact information, and basic settings",
        permission: "all",
      },
      {
        title: "Localization",
        icon: FaGlobe,
        onClick: () => handleModal("location"),
        description: "Set timezone, region, and language preferences",
        permission: "admin",
      },
      {
        title: "Zoning",
        icon: FaMapMarkerAlt,
        onClick: () => handleModal("zoning"),
        description: "Group members by postal/zip codes with map visualization",
        permission: "all",
      },
      // {
      //   title: "Dynamic Fields",
      //   icon: FaListAlt,
      //   onClick: () => handleModal("dynamicFields"),
      //   description: "Customize member registration and profile fields",
      //   permission: "admin",
      // },
    ],
    "Member Management": [
      {
        title: "Member Access",
        icon: FaUserShield,
        onClick: () => handleModal("email"),
        description: "Manage member roles, permissions, and access levels",
        permission: "admin",
      },
      {
        title: "Archived Members",
        icon: FaArchive,
        onClick: () => handleModal("archive"),
        description: "View and restore archived member records",
        permission: "admin",
      },
      {
        title: "Quick Registration",
        icon: FaUserPlus,
        onClick: () => handleModal("newMembers"),
        description: "Streamlined process for adding new members",
        permission: "all",
      },
    ],
    Communication: [
      {
        title: "Text Messaging",
        icon: FaSms,
        onClick: () => handleModal("text"),
        description: "Configure SMS settings and message templates",
        permission: "admin",
        disabled: isFree,
      },
      {
        title: "Text Analytics",
        icon: FaChartBar,
        onClick: () => handleModal("textMeter"),
        description: "Monitor message usage and engagement metrics",
        permission: "admin",
        disabled: isFree,
      },
      {
        title: "Security",
        icon: FaLock,
        onClick: () => handleModal("mfa"),
        description: "Two-factor authentication and security settings",
        permission: "admin",
      },
    ],
    Customization: [
      {
        title: "Branding",
        icon: FaPalette,
        onClick: () => handleModal("branding"),
        description: "Customize logos, colors, and church branding",
        permission: "admin",
      },
      {
        title: "Campus Management",
        icon: FaBuilding,
        onClick: () => handleModal("campus"),
        description: "Manage multiple locations and campus details",
        permission: "admin",
      },
      {
        title: "Field Configuration",
        icon: FaCog,
        onClick: () => handleModal("updateFields"),
        description: "Update and organize system fields",
        permission: "admin",
      },
    ],
  };
  console.log(user.role);
  return (
    <Container fluid>
      <div className="py-4 px-3">
        <h3 className="mb-4">Church Settings</h3>

        {Object.entries(settingsGroups).map(([groupName, settings]) => (
          <div key={groupName} className="mb-5">
            {/* <h5 className="mb-3 text-muted">{groupName}</h5> */}
            <Row className="g-4">
              {settings.map(
                (setting) =>
                  (setting.permission === "all" ||
                    (setting.permission === "admin" && isAdmin)) && (
                    <Col key={setting.title} xs={12} md={6} xl={4}>
                      <SettingsCard {...setting} />
                    </Col>
                  )
              )}
            </Row>
          </div>
        ))}
      </div>

      {/* Modals */}
      <ChurchInformation
        show={modals.churchInfo}
        setShow={(val) => closeModal("churchInfo")}
      />
      <Location
        show={modals.location}
        setShow={(val) => closeModal("location")}
      />
      <DynamicFields
        show={modals.dynamicFields}
        setShow={(val) => closeModal("dynamicFields")}
      />
      <EmailSettings
        show={modals.email}
        setShow={(val) => closeModal("email")}
      />
      <ArchivedList
        show={modals.archive}
        setShow={(val) => closeModal("archive")}
      />
      <NewMembers
        show={modals.newMembers}
        setShow={(val) => closeModal("newMembers")}
      />
      <TextSetup show={modals.text} setShow={(val) => closeModal("text")} />
      <TextMeter
        show={modals.textMeter}
        setShow={(val) => closeModal("textMeter")}
      />
      <MultiFactorAuth show={modals.mfa} setShow={(val) => closeModal("mfa")} />
      <Customize
        show={modals.branding}
        setShow={(val) => closeModal("branding")}
      />
      <Campuses
        show4={modals.campus}
        setShow4={(val) => closeModal("campus")}
      />
      <DynamicFields
        showx={modals.updateFields}
        setShowx={(val) => closeModal("updateFields")}
      />
      {/* Add UpdateFields modal component */}
    </Container>
  );
};

export default Settings;
