import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Alert } from "react-bootstrap";
import { useAppContext } from "../../../context/appContext";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment/moment";
import CreateSubscription from "../../../components/ManageSubscription/CreateSubscription";
import EditSubscription from "../../../components/ManageSubscription/EditSubscription";

const AccountManagement = () => {
  const location = useLocation();
  const { logoutUser, user } = useAppContext();
  const [subscription, setSubscription] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (user) {
      getSubscriptions();
    }
    // Show upgrade modal if redirected from trial expiry
    if (location.state?.trialExpired) {
      setShowCreateModal(true);
    }
  }, [user]);

  const getSubscriptions = async () => {
    try {
      setLoading(true);
      const { data } = await authFetch.get("/payment/subscriptions");
      setSubscription(data?.data || []);
      setError(null);
    } catch (error) {
      console.error("Failed to fetch subscriptions:", error);
      setError("Failed to load subscription information. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const uploadSuccessFunc = () => {
    getSubscriptions();
    window.location.reload();
  };

  // Calculate trial status
  const getTrialStatus = () => {
    if (user?.memberShip !== "Free") return null;

    const expiryDate = new Date(user.freeTrialExpiry);
    const currentDate = new Date();
    const diffTime = expiryDate - currentDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return {
      isExpired: diffDays <= 0,
      daysRemaining: Math.max(0, diffDays),
    };
  };

  const trialStatus = getTrialStatus();

  const renderTrialBanner = () => {
    if (!trialStatus) return null;

    return (
      <Alert
        variant={trialStatus.isExpired ? "danger" : "warning"}
        className="mb-4"
      >
        <Alert.Heading>
          {trialStatus.isExpired ? "Trial Expired" : "Trial Period"}
        </Alert.Heading>
        <p>
          {trialStatus.isExpired
            ? "Your free trial has expired. Please upgrade to continue using all features."
            : `Your free trial will expire in ${trialStatus.daysRemaining} days. Upgrade now to ensure uninterrupted service.`}
        </p>
        <button className="jiluil3" onClick={() => setShowCreateModal(true)}>
          Upgrade Now
        </button>
      </Alert>
    );
  };

  const renderSubscriptionCard = (sub) => (
    <Col key={sub.id}>
      <Card>
        <div className="card-body">
          <h5 className="card-title" style={{ fontWeight: "600" }}>
            {sub.plan.nickname}
          </h5>
          <p className="card-text">{sub.plan.description}</p>

          <div className="subscription-details">
            <InfoRow
              label="Last Payment Date"
              value={moment(sub.created * 1000).format(
                "dddd, MMMM Do YYYY h:mm:ss a"
              )}
            />
            <InfoRow
              label="Current Period End"
              value={moment(sub.current_period_end * 1000).format(
                "dddd, MMMM Do YYYY h:mm:ss a"
              )}
            />
            <InfoRow
              label="Account Status"
              value={
                <span
                  className={
                    sub.status === "active" ? "text-success" : "text-danger"
                  }
                >
                  {sub.status.charAt(0).toUpperCase() + sub.status.slice(1)}
                </span>
              }
            />
            <InfoRow
              label="Card Last 4 Digits"
              value={sub?.default_payment_method?.card?.last4 || "N/A"}
            />
            {sub.cancel_at_period_end && (
              <InfoRow
                label="Cancellation Status"
                value={
                  <span className="text-danger">Cancels at period end</span>
                }
              />
            )}
            <InfoRow
              label="Amount"
              value={(sub.plan.amount / 100).toLocaleString("en-US", {
                style: "currency",
                currency: sub.plan.currency,
              })}
            />
          </div>

          <button className="jiluil3" onClick={() => setShowEditModal(true)}>
            Manage Subscription
          </button>
        </div>
      </Card>
    </Col>
  );

  const InfoRow = ({ label, value }) => (
    <p className="card-text">
      <h6 className="card-title cardcoo" style={{ fontWeight: "600" }}>
        {label}:
      </h6>
      <small className="text-muted">{value}</small>
    </p>
  );

  return (
    <Container fluid>
      <Row className="headerbanner">
        <div className="bannerbig">Account Management</div>
        <div className="bannersmall">
          Manage your subscription, payments, and plan upgrades all in one
          place.
        </div>
      </Row>

      <Row className="px-4 pb-5">
        {renderTrialBanner()}

        {loading ? (
          <div className="text-center py-4">
            Loading subscription details...
          </div>
        ) : subscription.length > 0 ? (
          subscription.map(renderSubscriptionCard)
        ) : (
          <Col>
            <Card className="text-center py-5">
              <Card.Body>
                <h4>No Active Subscription</h4>
                <p>Choose a plan to access all features and benefits.</p>
                <button
                  className="jiluil3"
                  onClick={() => setShowCreateModal(true)}
                >
                  View Plans
                </button>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>

      <CreateSubscription
        show={showCreateModal}
        onHide={() => setShowCreateModal(false)}
        uploadSuccessFunc={uploadSuccessFunc}
      />

      <EditSubscription
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        uploadSuccessFunc={uploadSuccessFunc}
      />
    </Container>
  );
};

export default AccountManagement;
