import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CustomAlert from "../../../components/CustomAlert/CustomAlert";
import { useAppContext } from "../../../context/appContext";
import "./bulkaction.css";
import Alert from "../../../components/Alert/Alert";
import { Modal, Spinner } from "react-bootstrap";
import * as Sentry from "@sentry/react";
import axios from "axios";
import Loading from "../../../components/Loading/Loading";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import CustomToastContent from "../../../components/Toast/CustomToastContent";
import BulkValidation from "../../../components/Bulk/BulkValidation";

const BulkAction = () => {
  const { showAlert, logoutUser, uploadMemberMany, isLoading } =
    useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [file, setFile] = useState(null);
  const [loading, setIsLoading] = useState(false); // To track the loading state

  const memberFileChange = (event) => {
    const selectedFile = event.target.files[0];

    setFile(selectedFile);
  };

  const givingFileChange = (e) => {
    const selectedFile = e.target.files[0];

    // Validate file type
    if (selectedFile && !selectedFile.name.endsWith(".csv")) {
      toast(
        <CustomToastContent title="Please select a CSV file" type="error" />
      );
      return;
    }

    setFile(selectedFile);
  };

  const expenseFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return;
    }

    // Validate file type
    if (!selectedFile.name.endsWith(".csv")) {
      toast(
        <CustomToastContent
          title="Invalid file type"
          message="Please upload a CSV file"
          type="error"
        />
      );
      return;
    }

    setFile(selectedFile);
  };

  const memberUpload = async () => {
    if (!file) {
      toast(<CustomToastContent title="No file selected" type="error" />);

      return;
    }

    setIsLoading(true); // Start loading

    const formData = new FormData();
    formData.append("csv", file);

    try {
      const response = await authFetch.post("/bulk/bulkmember", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setFile(null); // Clear the file from state

      if (response.ok || response.status === 201 || response.status === 200) {
        // Checks for 2xx status codes

        toast(<CustomToastContent title="Upload Successful" type="success" />);
      } else {
        const errorMsg = await response.text();
        toast(<CustomToastContent title={errorMsg} type="error" />);
      }
    } catch (error) {
      Sentry.captureException(error);

      if (error.response) {
        const { status, data } = error.response;
        if (status === 401) {
          toast(
            <CustomToastContent title="You are not authorized" type="error" />
          );
        } else if (data.message === "Validation errors") {
          if (data.duplicates && data.duplicates.length > 0) {
            const duplicatesList = data.duplicates.join(", ");

            toast(<CustomToastContent title="Validation error" type="error" />);
            setFile(null);
          } else {
            toast(<CustomToastContent title="Validation error" type="error" />);
          }
        } else {
          const duplicatesList = data.duplicates.join(", ");

          toast(<CustomToastContent title="Validation error" type="error" />);
          setFile(null);
        }
      } else {
        toast(
          <CustomToastContent
            title="Member already exists. Please check the file and try again."
            type="error"
          />
        );
      }
    } finally {
      setIsLoading(false); // Ensure loading is stopped in case of error
    }
  };

  const givingUpload = async () => {
    if (!file) {
      toast(<CustomToastContent title="No file selected" type="error" />);
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append("csv", file);
    // formData.append("batchId", batch.batchId);

    try {
      const response = await authFetch.post("/bulk/bulkgiving", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setFile(null);

      if (response.data.unreconciled > 0) {
        toast(
          <CustomToastContent
            title={`Upload successful. ${response.data.reconciled} givings matched, ${response.data.unreconciled} need reconciliation.`}
            type="warning"
          />
        );
        // Optionally navigate to reconciliation page
        // navigate('/giving/reconciliation');
      } else {
        toast(
          <CustomToastContent
            title={`Successfully uploaded ${response.data.reconciled} givings`}
            type="success"
          />
        );
      }
    } catch (error) {
      console.error("Upload error:", error);

      // Handle specific error cases
      if (error.response?.status === 400) {
        toast(
          <CustomToastContent
            title="Invalid file format or data"
            message={error.response.data.message}
            type="error"
          />
        );
      } else if (error.response?.status === 401) {
        toast(
          <CustomToastContent
            title="Authorization error"
            message="Please login again"
            type="error"
          />
        );
      } else {
        toast(
          <CustomToastContent
            title="Upload failed"
            message="Please try again or contact support"
            type="error"
          />
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const expenseUpload = async () => {
    if (!file) {
      toast(<CustomToastContent title="No file selected" type="error" />);
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("csv", file);

    try {
      // Add logging to debug
      console.log("File being uploaded:", file);

      const response = await authFetch.post("/bulk/bulkexpense", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setFile(null);

      if (response.data.validationErrors?.length > 0) {
        // Show specific validation errors
        toast(
          <CustomToastContent
            title="Upload had validation errors"
            message={response.data.validationErrors[0]}
            type="warning"
          />
        );
      } else if (response.data.unreconciled > 0) {
        toast(
          <CustomToastContent
            title={`Upload successful. ${response.data.reconciled} expenses matched, ${response.data.unreconciled} need reconciliation.`}
            type="warning"
          />
        );
      } else {
        toast(
          <CustomToastContent
            title={`Successfully uploaded ${response.data.reconciled} expenses`}
            type="success"
          />
        );
      }
    } catch (error) {
      console.error("Upload error details:", error.response?.data);

      if (error.response?.status === 400) {
        // Show more specific error message if available
        const errorMessage =
          error.response?.data?.message ||
          "Please check your CSV format and try again";
        toast(
          <CustomToastContent
            title="Invalid file format or data"
            message={errorMessage}
            type="error"
          />
        );
      } else if (error.response?.status === 401) {
        toast(
          <CustomToastContent
            title="Authorization error"
            message="Please login again"
            type="error"
          />
        );
      } else {
        toast(
          <CustomToastContent
            title="Upload failed"
            message="Please try again or contact support"
            type="error"
          />
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const expensedownloadCSV = () => {
    const link = document.createElement("a");
    link.href = process.env.PUBLIC_URL + "/Expensee_Sample.csv"; // Adjust the path to your CSV file
    link.download = "Expense_Sample.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const expensedownloadTemplateCSV = () => {
    const link = document.createElement("a");
    link.href = process.env.PUBLIC_URL + "/Expense_Template.csv"; // Adjust the path to your CSV file
    link.download = "Expense_Template.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const memberdownloadCSV = () => {
    const link = document.createElement("a");
    link.href = process.env.PUBLIC_URL + "/People_Sample.csv"; // Adjust the path to your CSV file
    link.download = "People_Sample.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const memberdownloadTemplateCSV = () => {
    const link = document.createElement("a");
    link.href = process.env.PUBLIC_URL + "/People_Template.csv"; // Adjust the path to your CSV file
    link.download = "People_Template.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const givingdownloadCSV = () => {
    const link = document.createElement("a");
    link.href = process.env.PUBLIC_URL + "/giving_Sample.csv"; // Adjust the path to your CSV file
    link.download = "Giving_Sample.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const givingdownloadTemplateCSV = () => {
    const link = document.createElement("a");
    link.href = process.env.PUBLIC_URL + "/Giving_Template.csv"; // Adjust the path to your CSV file
    link.download = "Giving_Template.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [showValidation, setShowValidation] = useState(false);
  const runValidationShow = () => {
    setShowValidation(true);
  };

  if (isLoading) {
    return <Loading center />;
  }

  return (
    <>
      <Container fluid>
        {showAlert && <Alert />}
        <Row className="headerbanner">
          <div className="bannerbig">Bulk Operations</div>
          <div className="bannersmall">
            Streamline your administrative tasks with Bulk Operations, allowing
            you to efficiently manage large-scale actions like uploading member
            information in bulk, saving time and effort.
          </div>
        </Row>

        <Row className="bnmjo mb-5">
          <Col className="d-flex justify-content-end">
            <button className="jiluil" onClick={runValidationShow}>
              Member Validation
            </button>
          </Col>
        </Row>

        <Row className="fundc2">
          <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
            <thead>
              <tr>
                <th style={{ fontSize: "1em" }}>OPERATION </th>
                <th style={{ fontSize: "1em" }}> DESCRIPTION</th>
                <th style={{ fontSize: "1em" }}>ACTION</th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Import members</td>
                <td>
                  Download the member template, update the file with your church
                  member details and upload it.
                  <p
                    onClick={memberdownloadCSV}
                    style={{
                      color: "var(--secondary-color)",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    See sample file
                  </p>
                </td>

                <td>
                  <input
                    type="file"
                    id="file"
                    onChange={memberFileChange}
                    className="inputfile"
                    accept=".csv"
                  />
                </td>
                <td>
                  <button className="jiluil" onClick={memberUpload}>
                    Upload
                  </button>
                </td>
                <td>
                  <p
                    onClick={memberdownloadTemplateCSV}
                    style={{
                      color: "var(--primary-color)",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Download Template
                  </p>
                </td>
              </tr>

              <tr>
                <td>Import Givings</td>
                <td>
                  Download the giving template, update the file with your
                  various givings and upload.
                  <p
                    onClick={givingdownloadCSV}
                    style={{
                      color: "var(--secondary-color)",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    See sample file
                  </p>
                </td>

                <td>
                  <input
                    type="file"
                    id="file"
                    onChange={givingFileChange}
                    className="inputfile"
                    accept=".csv"
                  />
                </td>
                <td>
                  <button className="jiluil" onClick={givingUpload}>
                    Upload
                  </button>
                </td>
                <td>
                  <p
                    onClick={givingdownloadTemplateCSV}
                    style={{
                      color: "var(--primary-color)",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Download Template
                  </p>
                </td>
              </tr>

              <tr>
                <td>Import Expense</td>
                <td>
                  Download the expense template, update the file with your
                  various expenses and upload.
                  <p
                    onClick={expensedownloadCSV}
                    style={{
                      color: "var(--secondary-color)",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    See sample file
                  </p>
                </td>

                <td>
                  <input
                    type="file"
                    id="file"
                    onChange={expenseFileChange}
                    className="inputfile"
                    accept=".csv"
                  />
                </td>
                <td>
                  <button className="jiluil" onClick={expenseUpload}>
                    Upload
                  </button>
                </td>
                <td>
                  <p
                    onClick={expensedownloadTemplateCSV}
                    style={{
                      color: "var(--primary-color)",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Download Template
                  </p>
                </td>
              </tr>
            </tbody>
          </Table>
          <div
            className="flex align-center justify-center flex-column w-100"
            style={{ textAlign: "center" }}
          >
            <p className="qubulkf">Learn More</p>
            <p className="qubulkf2">
              Check our{" "}
              <a
                href="https://churchease.com/knowledgebaseview/6662855af3968a28d13ad1f5"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "var(--secondary-color)",
                  textDecoration: "none",
                }}
              >
                knowledge base
              </a>{" "}
              for more information on how to use the bulk operations feature.
            </p>
          </div>
          <Modal show={loading} centered backdrop="static" keyboard={false}>
            <Modal.Body>
              <div className="text-center">
                <Loading />
                <p className="mt-3">Uploading Members...</p>
              </div>
            </Modal.Body>
          </Modal>
        </Row>

        <BulkValidation show={showValidation} setShow={setShowValidation} />
      </Container>
    </>
  );
};

export default BulkAction;
