import React from "react";
import { Form } from "react-bootstrap";

const EventCheckboxes = ({ formData, setFormData }) => (
  <>
    <Form.Group className="mb-3">
      <Form.Check
        type="checkbox"
        label="Need Volunteers"
        checked={formData.volunteers}
        onChange={(e) =>
          setFormData({ ...formData, volunteers: e.target.checked })
        }
      />
    </Form.Group>

    <Form.Group className="mb-3">
      <Form.Check
        type="checkbox"
        label="Make visible to members"
        checked={formData.visible}
        onChange={(e) =>
          setFormData({ ...formData, visible: e.target.checked })
        }
      />
    </Form.Group>
  </>
);
export default EventCheckboxes;
