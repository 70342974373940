import axios from "axios";
import { useAppContext } from "../../context/appContext";

export const createTrialManager = (logoutUser) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  // Lightweight trial status check - only for active session monitoring
  authFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      // Handle 403 specifically for trial expiration
      if (
        error.response?.status === 403 &&
        error.response?.data?.message?.includes("trial ended")
      ) {
        logoutUser();
        window.location.href = "/auth/accountmanagement?trial=expired";
      }
      // Handle other auth errors
      if (error.response?.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  return authFetch;
};
