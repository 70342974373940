import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../../context/appContext";
import "./volunteer.css";
import Alert from "../../../components/Alert/Alert";
import moment from "moment/moment";
import axios from "axios";
import { CSVLink } from "react-csv";
import { format, parseISO } from "date-fns";

const Checkin = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const {
    showAlert,
    logoutUser,
    events,
    getEvents,
    getCompleteMembers,
    completemembers,
    createCheckin,
    user,
  } = useAppContext();

  const [event, setEvent] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [lastName, setLastName] = useState("");
  const [selectedName, setSelectedName] = useState([]);

  const [singleEvent, setSingleEvent] = useState("");
  const [singleCheckin, setSingleCheckin] = useState([]);

  const getEventById = async (e) => {
    e?.preventDefault();
    try {
      const { data } = await authFetch.get(
        `/events/getattendancebyid/${event}`
      );
      if (data) {
        setSingleEvent(data.singleEvent);
      }
    } catch (error) {
      logoutUser();
    }
  };

  const getCheckinById = async (e) => {
    e?.preventDefault();

    try {
      const { data } = await authFetch.get(`/checkin/${singleEvent.eventId}`);
      if (data) {
        setSingleCheckin(data.checkin);
      }
    } catch (error) {
      logoutUser();
    }
  };
  const [myAlert, setMyAlert] = useState(false);
  const [checkedin, setCheckedin] = useState(false);
  useEffect(() => {
    if (myAlert) {
      const timer = setTimeout(() => {
        setMyAlert(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [myAlert]);

  useEffect(() => {
    if (checkedin) {
      const timer = setTimeout(() => {
        setCheckedin(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [checkedin]);

  useEffect(() => {
    if (event) {
      getEventById();
    }
  }, [event]);

  useEffect(() => {
    if (singleEvent) {
      getCheckinById();
    }
  }, [singleEvent]);

  useEffect(() => {
    getCompleteMembers();
  }, []);

  useEffect(() => {
    events && getEvents();
  }, []);
  // Extract users older than 30 and add them to a new object

  const changeEvent = async () => {
    setShowForm(!showForm);
  };

  const [checkinData, setCheckinData] = useState(null);

  const onSearch = async (searchTerm) => {
    try {
      const currentTime = new Date().toISOString();
      const [fname, lname] = searchTerm.split(" ");
      const { data } = await authFetch(
        `/members/getmemberidonly/${fname}/${lname}`
      );
      const [memberIdOnly] = data || []; // Ensure memberIdOnly is an array

      const {
        churchName = "",
        eventTitle = "",
        _id = "",
        eventId = "",
        eventStartDate = "",
      } = singleEvent || {}; // Ensure singleEvent is an object

      const checkin = {
        selectedName: searchTerm,
        fname,
        lname,
        churchName,
        eventTitle,
        event_id: _id,
        eventId,
        eventDate: eventStartDate,
        checkIn: currentTime,
        mId: memberIdOnly ? memberIdOnly._id : "",
        familyId: memberIdOnly ? memberIdOnly.familyId : "",
      };

      setSelectedName((prevNames) => [...prevNames, searchTerm]); // Using callback function to ensure we get the most recent state

      setCheckinData(checkin);
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
    }
  };

  const checkMember = async () => {
    if (!checkinData) return;

    const checkValue = singleCheckin.some(
      (item) => item.selectedName === checkinData.selectedName // use checkinData instead of singleEvent for accuracy
    );

    if (checkValue) {
      setMyAlert(true);
    } else {
      createCheckin({ checkin: checkinData });
      setCheckedin(true);
      setSingleCheckin((prevCheckins) => [...prevCheckins, checkinData]); // Update the state directly
      getCheckinById();
    }
  };

  const copiedArray = singleCheckin?.map((obj) => {
    // Ensure the date strings are not undefined before parsing
    const postDate = obj?.postDate ? parseISO(obj.postDate) : new Date(); // Use current date as fallback
    const checkData = obj?.checkIn ? parseISO(obj.checkIn) : new Date(); // Use current date as fallback

    // Format the dates to the desired formats, now safely because parseISO is not called on undefined
    const posted_Date = format(postDate, "MMMM d, yyyy"); // Extracts and formats the date as "May 4, 2024"
    const posted_Time = format(postDate, "HH:mm:ss"); // Extracts just the time
    const checkin_Time = format(checkData, "HH:mm:ss"); // Extracts just the time for checkIn

    return {
      CheckinTime: checkin_Time,
      Event_Title: obj.eventTitle,
      Member_Name: obj.selectedName,
      posted_Date: posted_Date,
      posted_Time: posted_Time,
    };
  });

  console.log(singleCheckin);
  return (
    <>
      <Container fluid>
        {/* {showAlert && <Alert />}
        {myAlert && <div className="interte">Member is already checked.</div>} */}
        {showAlert ? (
          <Alert />
        ) : myAlert ? (
          <div className="inlineerror">Member is already checked</div>
        ) : null}

        <Row className="fundc" style={{ marginBottom: "2rem" }}>
          <Col className="sahs">Check In</Col>
        </Row>

        <Row className="fundc2">
          <div>
            <Form.Group
              className="mb-3"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
              controlId="formBasicCheckbox"
            >
              <Form.Select
                aria-label="Default select example"
                value={event}
                onChange={(e) => {
                  setEvent(e.target.value);
                  changeEvent();
                }}
                style={{ width: "60%", height: "50px", overflowY: "auto" }}
              >
                <option value="">Select an event to Check In</option>
                {events
                  .filter(
                    (e) =>
                      moment(e.eventDay).startOf("day") >=
                      moment().startOf("day")
                  ) // Filter to include today's events
                  .sort((a, b) => new Date(a.eventDay) - new Date(b.eventDay))
                  .slice(0, 10)
                  .map((event, index) => (
                    <option
                      key={event._id}
                      value={event._id}
                      style={{ height: "50px", padding: "10px 5px" }}
                    >
                      {event.eventTitle} -{" "}
                      {moment(event.eventDay).format("MMMM Do YYYY")}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>

            {showForm && (
              <div className="flex flex-column justify-center">
                <Form.Group
                  className="mb-3"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  controlId="formBasicEmail"
                >
                  <Form.Label className="hed"></Form.Label>
                  <Form.Control
                    type="text"
                    value={lastName}
                    name="lastName"
                    //handleChange={handleMemberInput}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Type the last name of the member"
                    className="formvalue"
                    required
                    style={{ width: "60%", height: "50px", overflowY: "auto" }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {completemembers
                      .filter((item) => {
                        const searchTerm = lastName?.toLowerCase();
                        const fname = item.mfirstName?.toLowerCase();
                        const lname = item.mlastName?.toLowerCase();

                        const fullname = fname + lname;

                        return (
                          searchTerm &&
                          fullname.includes(searchTerm) &&
                          fullname !== searchTerm
                        );
                      })
                      .slice(0, 10)
                      .map((item) => (
                        <div
                          onClick={() => {
                            onSearch(item.mfirstName + " " + item.mlastName);
                            setLastName(item.mfirstName + " " + item.mlastName);
                          }}
                          className="dropdownmy-row ewfwefwefwe"
                          style={{ width: "60%" }}
                          key={item.mfirstName}
                        >
                          {item.mfirstName} {item.mlastName}
                        </div>
                      ))}
                  </div>
                </Form.Group>
                <div className="flex justify-center">
                  <button
                    className="jiluil"
                    onClick={checkMember}
                    style={{ width: "13%" }}
                  >
                    Check In
                  </button>
                </div>
              </div>
            )}
          </div>
        </Row>
        <hr></hr>
        {singleCheckin.length > 0 && (
          <Row className="fundc2" style={{ paddingTop: "30px" }}>
            <div className="flex justify-end pb-5">
              {/* <button className="jiluil4" onClick={downloadAttendance}>
                Download
              </button> */}
              <button
                className="jiluil3"
                style={{
                  textDecoration: "none",
                  backgroundColor: "var(--primary-color)",
                  border: "none",
                }}
              >
                <CSVLink
                  data={copiedArray}
                  filename="savedData-churchease.csv"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Download
                </CSVLink>
              </button>
            </div>
            <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
              <thead>
                <tr>
                  <th style={{ fontSize: "1em" }}>Name</th>
                  <th style={{ fontSize: "1em" }}>Status</th>
                  <th style={{ fontSize: "1em" }}>Time</th>
                </tr>
              </thead>

              <tbody>
                {singleCheckin?.map((data, index) => (
                  <tr>
                    <td style={{ fontSize: "1em" }}>{data.selectedName}</td>
                    <td style={{ fontSize: "1em", width: "40%" }}>
                      {data.checkOut ? "Checked Out" : "Checked In"}
                    </td>
                    <td style={{ fontSize: "1em", width: "40%" }}>
                      {data.checkOut
                        ? moment(data.checkOut).format("h:mm:ss a")
                        : moment(data.checkIn).format("h:mm:ss a")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        )}
      </Container>
    </>
  );
};

export default Checkin;
