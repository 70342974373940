import React, { useState, useCallback, useRef } from "react";
import {
  Card,
  Button,
  Badge,
  Modal,
  Form,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import {
  FaEye,
  FaMailBulk,
  FaMobileAlt,
  FaDownload,
  FaTrash,
  FaRunning,
  FaBox,
  FaInfoCircle,
} from "react-icons/fa";
import { CSVLink } from "react-csv";
import { useAppContext } from "../../context/appContext";
import { toast } from "react-toastify";
import axios from "axios";
import debounce from "lodash.debounce";
import CustomToastContent from "../Toast/CustomToastContent";
import BulkEmailEditor from "./BulkEmailEditor";
import BulkSMSEditor from "./BulkSMSEditor";
import FollowUpAssign from "../FollowUp/FollowUpAssign";
import AddMultiple from "../ContainerGroup/AddMultiple";

// Quick action buttons configuration
const QUICK_ACTIONS = [
  {
    id: "saveView",
    icon: <FaEye size={18} />,
    label: "Save View",
    requiresPro: false,
  },
  {
    id: "email",
    icon: <FaMailBulk size={18} />,
    label: "Email Members",
    requiresPro: false,
  },
  {
    id: "sms",
    icon: <FaMobileAlt size={18} />,
    label: "Text Members",
    requiresPro: true,
  },
  {
    id: "export",
    icon: <FaDownload size={18} />,
    label: "Export Members",
    requiresPro: false,
  },
  {
    id: "delete",
    icon: <FaTrash size={18} />,
    label: "Delete Members",
    requiresPro: false,
    // variant: "danger",
  },
  {
    id: "followUp",
    icon: <FaRunning size={18} />,
    label: "Follow-Up",
    requiresPro: true,
    tooltip: "Only one member can be selected for follow up",
  },
  {
    id: "addToGroup",
    icon: <FaBox size={18} />,
    label: "Add to Group",
    requiresPro: true,
  },
];

const PeopleContact = ({
  isChecked,
  setIsChecked,
  onLoadingChange,
  members,
  getAllMembers,
}) => {
  const { user, logoutUser, bulkEmailtoMembers, sendBulkSMS, savedView } =
    useAppContext();
  const [showExport, setShowExport] = useState(false);
  const csvLinkRef = React.useRef();
  // API Configuration
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: { "Content-Type": "application/json" },
  });

  authFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401) logoutUser();
      return Promise.reject(error);
    }
  );

  // Modal States
  const [modals, setModals] = useState({
    view: false,
    email: false,
    sms: false,
    delete: false,
    followUp: false,
    addToGroup: false,
    notification: false,
  });

  // Form States
  const [viewName, setViewName] = useState("");

  const [followDate, setFollowDate] = useState("");
  const [followDesc, setFollowDesc] = useState("");
  const [followHow, setFollowHow] = useState("");
  const [followComplete, setFollowComplete] = useState(false);
  const [followReason, setFollowReason] = useState([]);
  const [followNote, setFollowNote] = useState("");

  const handleFollowUpSubmit = async (e) => {
    e.preventDefault();

    if (!followDate || !followDesc || !followHow || !followReason) {
      toast.error("Please fill all required fields");
      return;
    }

    const selectedMember = isChecked[0];
    const userNames = `${selectedMember.mfirstName} ${selectedMember.mlastName}`;

    const checkIt = {
      followUpName: userNames,
      followUpAssignTo: followDesc,
      followupDate: followDate,
      churchName: user?.churchName,
      mfname: selectedMember.mfirstName,
      mlname: selectedMember.mlastName,
      mId: selectedMember._id,
      followUpCompleteUpdatepDate: followComplete || false,
      followMedia: followHow,
      followReason: followReason,
      followNote: followNote,
      completed: false,
      assignedBy: `${user?.mfirstName} ${user?.mlastName}`,
    };

    try {
      const response = await authFetch.post("/followup/assignto", checkIt);
      if (response.status === 200 || response.status === 201) {
        // Reset all fields
        setFollowDesc("");
        setFollowDate("");
        setFollowComplete(false);
        setFollowHow("");
        setFollowReason([]);
        setFollowNote("");

        toggleModal("followUp", false);
        setIsChecked([]); // Clear selection

        toast.success("Follow-Up Assigned");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to assign follow-up");
    }
  };

  // Utility Functions
  const toggleModal = (modalName, value) => {
    setModals((prev) => ({ ...prev, [modalName]: value }));
  };

  const handleActionClick = (actionId) => {
    // First check if any members are selected (except for saveView which might not need selection)
    if (isChecked.length === 0 && actionId !== "saveView") {
      toggleModal("notification", true);
      return;
    }

    // For follow-up, check if exactly one member is selected
    if (actionId === "followUp" && isChecked.length !== 1) {
      toast.warning("Please select exactly one member for follow-up");
      return;
    }

    switch (actionId) {
      case "saveView":
        toggleModal("view", true);
        break;

      case "email":
        if (!isChecked.some((member) => member.memail)) {
          toast.warning("Selected members don't have email addresses");
          return;
        }
        toggleModal("email", true);
        break;

      case "sms":
        if (!isChecked.some((member) => member.mmobilePhone)) {
          toast.warning("Selected members don't have phone numbers");
          return;
        }
        toggleModal("sms", true);
        break;
      case "followUp":
        if (isChecked.length !== 1) {
          toast.warning("Please select exactly one member for follow-up");
          return;
        }
        // Reset all follow-up fields before opening modal
        setFollowDesc("");
        setFollowDate("");
        setFollowComplete(false);
        setFollowHow("");
        setFollowReason([]);
        setFollowNote("");
        toggleModal("followUp", true);
        break;

      case "addToGroup":
        if (isChecked.length > 50) {
          toast.warning("Maximum 50 members can be added to a group at once");
          return;
        }
        toggleModal("addToGroup", true);
        break;

      case "export":
        setShowExport(true);
        setTimeout(() => {
          if (csvLinkRef.current) {
            csvLinkRef.current.link.click();
          }
          setShowExport(false);
        }, 100);
        break;

      case "delete":
        toggleModal("delete", true);
        break;

      case "followUp":
        toggleModal("followUp", true);
        break;

      case "addToGroup":
        if (isChecked.length > 50) {
          toast.warning("Maximum 50 members can be added to a group at once");
          return;
        }
        toggleModal("addToGroup", true);
        break;

      default:
        console.warn(`Unhandled action: ${actionId}`);
        break;
    }
  };

  // Action Handlers
  // const handleEmailSend = async (emailData) => {
  //   try {
  //     await bulkEmailtoMembers({
  //       userMessage: {
  //         ...emailData,
  //         userId: user._id,
  //       },
  //     });
  //     toggleModal("email", false);
  //     toast.success("Email sent successfully");
  //   } catch (error) {
  //     toast.error("Failed to send email");
  //     console.error("Email error:", error);
  //   }
  // };

  // const handleSMSSend = async (smsData) => {
  //   try {
  //     await sendBulkSMS({ smsData });
  //     toggleModal("sms", false);
  //     toast.success("SMS sent successfully");
  //   } catch (error) {
  //     toast.error("Failed to send SMS");
  //     console.error("SMS error:", error);
  //   }
  // };

  const handleDelete = async (e) => {
    try {
      e.preventDefault();
      onLoadingChange(true);

      for (const member of isChecked) {
        await authFetch.delete(`/members/${member._id}`);
      }

      setIsChecked([]);
      toggleModal("delete", false);

      // Refresh the member list
      await getAllMembers();

      toast.success("Members deleted successfully");
    } catch (error) {
      toast.error("Failed to delete members");
      console.error("Delete error:", error);
    } finally {
      onLoadingChange(false);
    }
  };

  const handleSaveView = async () => {
    try {
      await savedView({
        viewName,
        viewMembers: isChecked,
        userId: user._id,
      });
      toggleModal("view", false);
      setViewName("");
      toast.success("View saved successfully");
    } catch (error) {
      toast.error("Failed to save view");
      console.error("Save view error:", error);
    }
  };

  // Export Data Preparation
  const getExportData = () =>
    isChecked.map((member) => ({
      "First Name": member.mfirstName,
      "Last Name": member.mlastName,
      Email: member.memail,
      Phone: member.mmobilePhone,
      Status: member.mstatus,
      Campus: member.campus,
      // Add other fields as needed
    }));

  // Quick Action Button Component
  const QuickActionButton = ({ action }) => {
    const button = (
      <Button
        variant={action.variant || "light"}
        className="d-flex align-items-center w-100 p-3 mb-2"
        onClick={() => handleActionClick(action.id)}
        disabled={
          action.requiresPro &&
          user?.subscription?.[0]?.plan?.nickname === "Free"
        }
      >
        <span className="me-3">{action.icon}</span>
        <span className="flex-grow-1 text-start">{action.label}</span>
        {isChecked.length > 0 && (
          <Badge bg="secondary">{isChecked.length}</Badge>
        )}
        {action.requiresPro && (
          <Badge bg="warning" text="dark" className="ms-2">
            PRO
          </Badge>
        )}
      </Button>
    );

    return action.tooltip ? (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id={`tooltip-${action.id}`}>{action.tooltip}</Tooltip>
        }
      >
        <div>{button}</div>
      </OverlayTrigger>
    ) : (
      button
    );
  };

  return (
    <Card className="shadow-sm">
      <Card.Header className="bg-light">
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="mb-0">Quick Actions</h6>
          {isChecked.length > 0 && (
            <Badge backgroundColor={{ color: "red" }}>
              {isChecked.length} selected
            </Badge>
          )}
        </div>
      </Card.Header>
      <Card.Body>
        {QUICK_ACTIONS.map((action) => (
          <QuickActionButton key={action.id} action={action} />
        ))}
      </Card.Body>

      {/* Save View Modal */}
      <Modal
        show={modals.view}
        onHide={() => toggleModal("view", false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Save View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>View Name</Form.Label>
            <Form.Control
              type="text"
              value={viewName}
              onChange={(e) => setViewName(e.target.value)}
              placeholder="Enter view name"
              autoFocus
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => toggleModal("view", false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSaveView}
            disabled={!viewName.trim()}
          >
            Save View
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Email Modal */}
      <BulkEmailEditor
        show={modals.email}
        handleClose={() => toggleModal("email", false)}
        checkedItems={isChecked.map((m) => m.memail).join(", ")}
        user={user}
        // onSend={handleEmailSend}
      />

      {/* SMS Modal */}
      <BulkSMSEditor
        show={modals.sms}
        handleClose={() => toggleModal("sms", false)}
        userPhone={isChecked.map((m) => m.mmobilePhone).join(", ")}
        // user={user}
      />
      <AddMultiple
        show={modals.addToGroup}
        setShow={(value) => {
          toggleModal("addToGroup", value);
          if (!value) {
            // If modal is closing and operation was successful
            if (isChecked.length > 0) {
              toast.success(`${isChecked.length} members added to group`);
              setIsChecked([]); // Clear selection after successful addition
            }
          }
        }}
        username={isChecked}
      />

      <FollowUpAssign
        show={modals.followUp}
        handleClose={() => toggleModal("followUp", false)}
        myHandleSubmit={handleFollowUpSubmit}
        followDesc={followDesc}
        setFollowDesc={setFollowDesc}
        followHow={followHow}
        setFollowComplete={setFollowComplete}
        followDate={followDate}
        setFollowDate={setFollowDate}
        handleCheckboxChange={(e, value) => {
          const newReasons = e.target.checked
            ? [...followReason, value]
            : followReason.filter((reason) => reason !== value);
          setFollowReason(newReasons);
        }}
        handleRadioChange={(e) => setFollowHow(e.target.value)}
        followReason={followReason}
        followNote={followNote}
        setFollowNote={setFollowNote}
        username={isChecked[0]?.mfirstName + " " + isChecked[0]?.mlastName}
      />
      {showExport && (
        <CSVLink
          data={getExportData()}
          filename="member-export.csv"
          className="d-none"
          ref={csvLinkRef}
          onClick={() => {
            setShowExport(false);
          }}
        />
      )}

      {/* Delete Confirmation Modal */}
      <Modal show={modals.delete} onHide={() => toggleModal("delete", false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete {isChecked.length} member
          {isChecked.length !== 1 ? "s" : ""}? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => toggleModal("delete", false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Follow Up Modal */}

      {/* Add to Group Modal */}
      <AddMultiple
        show={modals.addToGroup}
        setShow={(value) => toggleModal("addToGroup", value)}
        username={isChecked}
      />

      {/* Notification Modal */}
      <Modal
        show={modals.notification}
        onHide={() => toggleModal("notification", false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Members</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please select members to perform this action.</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => toggleModal("notification", false)}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      {/* CSV Export Link - Hidden */}
      <div className="d-none">
        <CSVLink
          data={getExportData()}
          filename="member-export.csv"
          className="hidden"
          ref={(el) => el && modals.export && el.link.click()}
        />
      </div>
    </Card>
  );
};

export default PeopleContact;
