import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import { useAppContext } from "../../context/appContext";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import "./subscript.css";
import {
  FaBible,
  FaCalculator,
  FaCalendar,
  FaChartPie,
  FaCheck,
  FaChess,
  FaChild,
  FaEnvelope,
  FaFont,
  FaMapMarked,
  FaMicrophone,
  FaMoneyBill,
  FaPen,
  FaPeopleArrows,
  FaPray,
  FaRunning,
  FaTextHeight,
  FaUserAlt,
  FaUserFriends,
  FaVideo,
} from "react-icons/fa";
import PricePageForTrial from "../../components/Price/PricePageForTrial";

const allFeatures = [
  {
    name: "People",
    cost: 0,
    description: "Manage church member details and records.",
    image: <FaUserAlt />,
  },
  {
    name: "Church Giving",
    cost: 9,
    description: "Facilitate tithes, offerings, and donations digitally.",
    image: <FaMoneyBill />,
  },
  {
    name: "Church Accounting",
    cost: 7,
    description:
      "Manage your church’s finances with ease using our Church Accounting feature",
    image: <FaCalculator />,
  },
  {
    name: "Ministry",
    cost: 6,
    description: "Tools for managing various ministries within the church.",
    image: <FaPeopleArrows />,
  },
  {
    name: "Groups",
    cost: 8,
    description: "Organize and manage small groups for better engagement.",
    image: <FaUserFriends />,
  },
  {
    name: "Follow-Up",
    cost: 6,
    description: "Track and follow up with visitors and members.",
    image: <FaRunning />,
  },
  {
    name: "Events",
    cost: 8,
    description: "Plan and manage church events seamlessly.",
    image: <FaCalendar />,
  },
  {
    name: "Service Planner",
    cost: 5,
    description:
      "Schedule and plan services, including volunteers and resources.",
    image: <FaCheck />,
  },
  {
    name: "Forms",
    cost: 7,
    description: "Create custom forms for surveys, signups, and more.",
    image: <FaFont />,
  },
  {
    name: "Fellowship",
    cost: 6,
    description:
      "Enhance the sense of community and fellowship within the church.",
    image: <FaPray />,
  },
  {
    name: "Learning Center",
    cost: 10,
    description: "Educational tools for discipleship and learning.",
    image: <FaEnvelope />,
  },
  {
    name: "Live Streaming",
    cost: 7,
    description:
      "Broadcast your services and events live to reach a wider audience.",
    image: <FaVideo />,
  },

  {
    name: "Communication",
    cost: 5,
    description:
      "Comprehensive tools for effective communication within the church community.",
    image: <FaTextHeight />,
  },
  {
    name: "Children",
    cost: 9,
    description:
      "Streamline children's management and development with automated, round-the-clock support.",
    image: <FaChild />,
  },
];

const levelWeights = {
  L1: 0.4,
  L2: 0.5,
  L3: 0.65,
  L4: 0.85,
  L5: 1.2,
  L6: 1.4,
  L7: 1.8,
  L8: 2.0,
};

const CreateSubscription = ({
  show,
  setShow,
  onHide,
  uploadSuccessFunc,
  quickUpgrade,
}) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { user, alertType, logoutUser } = useAppContext();
  const [size, setSize] = useState("");
  const [entitlement, setEntitlement] = useState(null);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [planDetails, setPlanDetails] = useState("monthly");
  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);
  const [hideView, setHideView] = useState(true);
  const [showxx, setShowxx] = useState(false);
  const [totalPriceAmount, setTotalPriceAmount] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleClose = () => {
    if (!quickUpgrade) {
      setShowConfirmModal(true);
    } else {
      onHide();
    }
  };

  const handleConfirmClose = () => {
    setShowConfirmModal(false);
    onHide();
  };

  const handleCancelClose = () => {
    setShowConfirmModal(false);
  };

  // Initialize user data
  useEffect(() => {
    if (user) {
      setSize(user.churchSize);
      setEntitlement(user.entitlement);
    }
  }, [user]);

  // Initialize selected features
  useEffect(() => {
    if (Array.isArray(entitlement) && Array.isArray(allFeatures)) {
      // For expired trials, ensure essential features are selected
      if (!quickUpgrade) {
        const essentialFeatures = ["People"]; // People is always included
        setSelectedFeatures(essentialFeatures);
      } else {
        // For active trials, use existing entitlements
        const initialSelectedFeatures = allFeatures
          .filter((feature) => entitlement.includes(feature.name))
          .map((feature) => feature.name);
        setSelectedFeatures(initialSelectedFeatures);
      }
    }
  }, [entitlement, allFeatures, quickUpgrade]);

  // Calculate prices
  useEffect(() => {
    const sumProductCost = selectedFeatures.reduce((total, featureName) => {
      const feature = allFeatures.find((f) => f.name === featureName);
      return total + (feature ? feature.cost : 0);
    }, 0);

    const weight = levelWeights[size] || 0;
    setTotalPrice(sumProductCost * weight);
  }, [selectedFeatures, size]);

  useEffect(() => {
    const calculateTotalPrice = () => {
      switch (planDetails) {
        case "monthly":
          setTotalPriceAmount(totalPrice);
          break;
        case "yearly":
          setTotalPriceAmount(Number(totalPrice) * 12 * 0.95); // 5% discount
          break;
        case "two-years":
          setTotalPriceAmount(Number(totalPrice) * 24 * 0.9); // 10% discount
          break;
        default:
          setTotalPriceAmount(totalPrice);
      }
    };
    calculateTotalPrice();
  }, [planDetails, totalPrice]);

  const handleFeatureChange = (featureName, isChecked) => {
    if (featureName === "People") return; // People feature can't be deselected

    setSelectedFeatures((prev) => {
      if (isChecked) {
        return [...prev, featureName];
      } else {
        return prev.filter((name) => name !== featureName);
      }
    });
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (!size || selectedFeatures.length === 0) {
      setError(true);
      return;
    }
    setError(false);
    setHideView(false);
    setShowxx(true);
  };

  const handlePriceCreate = async (e) => {
    e.preventDefault();
    const data = {
      entitlement: selectedFeatures,
      churchSize: size,
      currency: "usd",
      interval: planDetails,
      price: totalPriceAmount,
    };

    try {
      const response = await authFetch.put(
        "/payment/createsubscriptionfromtrial",
        data
      );
      if (response.status === 200 || response.status === 201) {
        onHide();
        window.location.reload();
      }
    } catch (error) {
      console.error("Subscription creation error:", error);
      setError(true);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {quickUpgrade
              ? "Ready to Take Full Advantage of Churchease?"
              : "Your Free Trial Has Ended"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!quickUpgrade && (
            <Alert variant="warning" className="mb-4">
              <Alert.Heading>Access Restricted</Alert.Heading>
              <p>
                Your trial period has ended. To continue using Churchease
                features, please select a plan below. Until you upgrade, access
                to most features will be restricted.
              </p>
            </Alert>
          )}

          {quickUpgrade ? (
            <p className="frete">
              You're currently in your trial period and there's no need to wait
              to make the most out of Churchease. Upgrade now to unlock all
              features immediately and ensure uninterrupted service.
            </p>
          ) : (
            <p className="frete">
              We hope you enjoyed your 90-days trial experience! Next steps,
              complete your registration process to continue using Churchease.
            </p>
          )}

          {error && (
            <Alert variant="danger" className="mb-4">
              Please complete all required fields to proceed.
            </Alert>
          )}

          {hideView && (
            <Form>
              <div>
                <Form.Label className="label-feature">
                  Select Church Size <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  className="select mb-4"
                  aria-label="Select church size"
                  onChange={(e) => setSize(e.target.value)}
                  value={size}
                  required
                >
                  <option value="">Church Size</option>
                  <option value="L1">1 - 50 members</option>
                  <option value="L2">51 - 100 members</option>
                  <option value="L3">101 - 200 members</option>
                  <option value="L4">201 - 300 members</option>
                  <option value="L5">301 - 400 members</option>
                  <option value="L6">401 - 500 members</option>
                  <option value="L7">501 - 1000 members</option>
                  <option value="L8">1001 - 2000 members</option>
                </Form.Select>
              </div>

              {size && (
                <div className="feature-selection w-100">
                  <Form.Label className="label-feature mb-3">
                    Select Features <span className="text-danger">*</span>
                  </Form.Label>

                  <div className="features-grid">
                    {allFeatures.map((feature, index) => (
                      <div
                        key={index}
                        className="feature-itemx p-3 mb-3 border rounded"
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Form.Check
                              type="checkbox"
                              id={`feature-${feature.name}`}
                              onChange={(e) =>
                                handleFeatureChange(
                                  feature.name,
                                  e.target.checked
                                )
                              }
                              checked={selectedFeatures.includes(feature.name)}
                              disabled={feature.name === "People"}
                              className="feature-checkbox me-3"
                            />
                            <span className="fw-bold">{feature.name}</span>
                          </div>
                          <div
                            style={{
                              color: "var(--primary-color)",
                              fontSize: "1.5em",
                            }}
                          >
                            {feature.image}
                          </div>
                        </div>
                        <p
                          style={{
                            marginLeft: "24px",
                            marginTop: "0.5rem",
                            marginBottom: 0,
                          }}
                        >
                          {feature.description}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </Form>
          )}

          {showxx && (
            <PricePageForTrial
              totalPrice={totalPriceAmount}
              selectedFeatures={selectedFeatures}
              size={size}
              planType={planDetails}
            />
          )}

          <div className="mt-4">
            <p className="text-muted" style={{ lineHeight: "2" }}>
              Need help? Contact our support team at{" "}
              <a
                href="mailto:info@churchease.com"
                style={{ color: "var(--secondary-color)" }}
              >
                info@churchease.com
              </a>
              .
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil" onClick={handleClose}>
            {quickUpgrade ? "Close" : "Close (Limited Access)"}
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showConfirmModal}
        onHide={handleCancelClose}
        centered
        size="md"
        backdrop="static" // Prevents closing on backdrop click
        className="confirmation-modal"
        style={{ background: "rgba(0, 0, 0, 0.5)" }} // Darkens the background
      >
        <div className="modal-content-wrapper">
          <Modal.Header closeButton>
            <Modal.Title>Confirm Close</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Without an active subscription, you'll have limited access to
              features. Are you sure you want to close?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button className="jiluil" onClick={handleCancelClose}>
              Cancel
            </button>
            <button className="jiluil3" onClick={handleConfirmClose}>
              Close Anyway
            </button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default CreateSubscription;
