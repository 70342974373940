import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useAppContext } from "../../context/appContext";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";

const AddExpense = ({ show, setShow, refreshWorkflows }) => {
  const { logoutUser, user, getExpense, expenses, getContainers, containers } =
    useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  // States for uploading
  const [documentFile, setDocumentFile] = useState(null);
  const [uploadButtonText, setUploadButtonText] = useState("Upload Expense");
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [saving, setSaving] = useState(false);

  const handleClose = () => {
    setShow(false);
    resetForm();
  };

  // Enhanced form state
  const [formData, setFormData] = useState({
    // Basic Information
    expenseName: "",
    expenseDescription: "",
    expenseCategory: "",
    expenseType: "",

    // Amount
    amount: "",

    // Dates
    expenseDate: "",
    dueDate: "",

    // Vendor Information
    vendorName: "",

    // Payment Details
    paymentMethod: "",
    invoiceNumber: "",

    // Department/Ministry
    department: "",

    // Notes
    notes: "",
  });

  const resetForm = () => {
    setFormData({
      expenseName: "",
      expenseDescription: "",
      expenseCategory: "",
      expenseType: "",
      amount: "",
      expenseDate: "",
      dueDate: "",
      vendorName: "",
      vendorContact: {
        email: "",
        phone: "",
        address: "",
      },
      paymentMethod: "",
      invoiceNumber: "",
      department: "",
      ministry: "",
      taxDeductible: false,
      taxCategory: "",
      notes: "",
    });
    setDocumentFile(null);
    setProgress(0);
    setUploading(false);
    setUploadButtonText("Upload Expense");
  };

  // Payment method options
  const paymentMethods = ["Cash", "Check", "Card", "Bank Transfer"];

  // Expense types
  const expenseTypes = ["Direct Expense", "Reimbursement", "Request"];

  const handleDocumentUpload = async (e) => {
    try {
      const file = e.target.files[0];
      if (!file) return;

      if (file.type !== "application/pdf") {
        toast(
          <CustomToastContent
            message="Only PDF files are allowed"
            type="error"
          />
        );
        return;
      }

      // Check file size (5MB limit)
      if (file.size > 5 * 1024 * 1024) {
        toast(
          <CustomToastContent
            message="File size should be less than 5MB"
            type="error"
          />
        );
        return;
      }

      setUploadButtonText(file.name);
      setUploading(true);

      const documentData = new FormData();
      documentData.append("document", file);

      try {
        const { data } = await authFetch.post(
          "/giving/expense/upload-expense",
          documentData,
          {
            headers: {
              Accept: "application/json",
            },
            onUploadProgress: (e) => {
              setProgress(Math.round((100 * e.loaded) / e.total));
            },
          }
        );

        setDocumentFile(data);
        toast(
          <CustomToastContent
            message="File uploaded successfully"
            type="success"
          />
        );
      } catch (uploadErr) {
        toast(
          <CustomToastContent message="Error uploading file" type="error" />
        );
        console.error("Upload error:", uploadErr);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setUploading(false);
    }
  };
  const [vendors, setVendors] = useState([]);
  useEffect(() => {
    getVendors();
  }, []);
  useEffect(() => {
    getContainers();
  }, []);
  const getVendors = async () => {
    try {
      const { data } = await authFetch.get("/vendors/get");

      setVendors(data.vendors);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name.includes(".")) {
      // Handle nested vendor contact fields
      const [parent, child] = name.split(".");
      setFormData((prev) => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value,
        },
      }));
    } else {
      // Handle regular fields
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();

    // Required fields validation
    const required = [
      "expenseName",
      "expenseCategory",
      "expenseType",
      "amount",
      "expenseDate",
      "vendorName",
    ];

    const missing = required.filter((field) => !formData[field]);
    if (missing.length > 0) {
      toast(
        <CustomToastContent
          message="Please fill in all required fields"
          type="error"
        />
      );
      return;
    }

    setSaving(true);

    try {
      const expenseData = {
        ...formData,
        churchName: user?.churchName,
        expenseDescription: formData.notes,
        documentFile,
        createdBy: user?._id,
        approvalStatus: "Pending",
        department: formData.department,
        paymentStatus: "Pending",
        reconciliationStatus: "pending",
        expenseDate: moment(formData.expenseDate).format("MM/DD/YYYY"),
        dueDate: formData.dueDate
          ? moment(formData.dueDate).format("MM/DD/YYYY")
          : null,
        budgetYear: moment(formData.expenseDate).year(),
        budgetMonth: moment(formData.expenseDate).month() + 1,
      };

      const response = await authFetch.post(
        "/giving/expense/upload-document",
        expenseData
      );

      if (response.status === 200 || response.status === 201) {
        resetForm();
        refreshWorkflows();
        handleClose();
        toast(
          <CustomToastContent
            message="Expense saved successfully"
            type="success"
          />
        );
      }
    } catch (error) {
      toast(<CustomToastContent message="Error saving expense" type="error" />);
      console.error("Error saving expense:", error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add Expense</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* Basic Information Section */}
          <h6 className="mb-3">Basic Information</h6>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  Expense Title <span className="myast">*</span>
                </Form.Label>
                <Form.Control
                  name="expenseName"
                  value={formData.expenseName}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  Category <span className="myast">*</span>
                </Form.Label>
                <Form.Select
                  name="expenseCategory"
                  value={formData.expenseCategory}
                  onChange={handleInputChange}
                >
                  <option value="">Select Category</option>
                  {expenses?.map((category, index) => (
                    <option key={index} value={category.expenseName}>
                      {category.expenseName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  Type <span className="myast">*</span>
                </Form.Label>
                <Form.Select
                  name="expenseType"
                  value={formData.expenseType}
                  onChange={handleInputChange}
                >
                  <option value="">Select Type</option>
                  {expenseTypes.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  Amount ({user?.currency}) <span className="myast">*</span>
                </Form.Label>
                <Form.Control
                  name="amount"
                  value={formData.amount}
                  onChange={handleInputChange}
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>

          {/* Dates and Payment Section */}
          <h6 className="mb-3 mt-4">Dates & Payment</h6>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  Expense Date <span className="myast">*</span>
                </Form.Label>
                <Form.Control
                  type="date"
                  name="expenseDate"
                  value={formData.expenseDate}
                  onChange={handleInputChange}
                  max={moment().format("YYYY-MM-DD")}
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label>Payment Method</Form.Label>
                <Form.Select
                  name="paymentMethod"
                  value={formData.paymentMethod}
                  onChange={handleInputChange}
                >
                  <option value="">Select Method</option>
                  {paymentMethods.map((method, index) => (
                    <option key={index} value={method}>
                      {method}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          {/* Department and Tax Section */}
          <h6 className="mb-3 mt-4">Additional Information</h6>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  Vendor Name <span className="myast">*</span>
                </Form.Label>
                <Form.Select
                  name="vendorName"
                  value={formData.vendorName}
                  onChange={handleInputChange}
                >
                  <option value="">Select Vendor</option>
                  {vendors?.map((vendor, index) => (
                    <option key={index} value={vendor.vendorName}>
                      {vendor.vendorName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  Group <span className="myast">*</span>
                </Form.Label>
                <Form.Select
                  name="department"
                  value={formData.department}
                  onChange={handleInputChange}
                >
                  <option value="">Select Group</option>
                  {containers?.map((container, index) => (
                    <option key={index} value={container.name}>
                      {container.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          {/* Description and Notes */}
          <h6 className="mb-3 mt-4">Description & Attachment</h6>

          <Row className="mt-3">
            <Col md={12}>
              <Form.Group>
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  name="notes"
                  value={formData.notes}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>

          {/* Attachment Section */}
          <Row className="mt-3">
            <Col md={12}>
              <Form.Group>
                <Form.Label>Attachment</Form.Label>
                <Form.Control
                  type="file"
                  onChange={handleDocumentUpload}
                  accept="application/pdf"
                  className="lpm"
                  disabled={uploading}
                />
                <p className="ww233">
                  Note: only PDF files up to 5MB can be uploaded.
                </p>
                {uploading && (
                  <div className="progress mt-2">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${progress}%` }}
                      aria-valuenow={progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {progress}%
                    </div>
                  </div>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose} disabled={saving}>
          Close
        </button>
        <button
          className="jiluil"
          onClick={handleSave}
          disabled={saving || uploading}
        >
          {saving ? "Saving..." : "Save"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddExpense;
