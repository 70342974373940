import React, { useState, useEffect } from "react";
import {
  Container,
  Table,
  Button,
  Modal,
  Form,
  Badge,
  Row,
  Col,
  InputGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faEdit,
  faTrash,
  faUsers,
  faSpinner,
  faSearch,
  faWandMagicSparkles,
  faMinus,
  faPeopleGroup,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useAppContext } from "../../../context/appContext";
import axios from "axios";
import AutoGenerateZonesModal from "../../../components/Zone/AutoGenerateZonesModal";
import UnassignedZonesModal from "../../../components/Zone/UnassignedZonesModal";
import AddZoneToGroupModal from "../../../components/Zone/AddZoneToGroupModal";

const ZoneManagement = ({ show, setShow, refreshData }) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { user, logoutUser } = useAppContext();
  const [zones, setZones] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showAutoGenerateModal, setShowAutoGenerateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedZone, setSelectedZone] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fullscreen, setFullscreen] = useState(true);

  // Pagination and filtering states
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [filteredZones, setFilteredZones] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [showUnassignedModal, setShowUnassignedModal] = useState(false);
  const [showAddToGroupModal, setShowAddToGroupModal] = useState(false);
  const [selectedZoneForGroup, setSelectedZoneForGroup] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    postalCodes: "",
    isActive: true,
  });

  useEffect(() => {
    fetchZones();
  }, []);

  useEffect(() => {
    filterZones();
  }, [zones, searchTerm, statusFilter]);

  const fetchZones = async () => {
    try {
      const response = await authFetch.get("/zoning");
      const { zones, total, totalPages } = response.data;
      setZones(zones || []);
      setFilteredZones(zones || []);
      setTotalPages(totalPages);
      setLoading(false);
    } catch (error) {
      toast.error("Failed to fetch zones");
      setLoading(false);
    }
  };
  const filterZones = () => {
    if (!Array.isArray(zones)) return;
    let filtered = [...zones];

    if (searchTerm) {
      const search = searchTerm.toLowerCase();
      filtered = filtered.filter(
        (zone) =>
          (zone.name?.toLowerCase() || "").includes(search) ||
          (zone.description?.toLowerCase() || "").includes(search) ||
          (Array.isArray(zone.postalCodes) &&
            zone.postalCodes.some((code) =>
              (code?.toLowerCase() || "").includes(search)
            ))
      );
    }

    if (statusFilter !== "all") {
      filtered = filtered.filter((zone) =>
        statusFilter === "active" ? zone.isActive : !zone.isActive
      );
    }

    setFilteredZones(filtered);
    setTotalPages(Math.ceil(filtered.length / itemsPerPage));
    setCurrentPage(1);
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      const postalCodesArray = formData.postalCodes
        .split(",")
        .map((code) => code.trim())
        .filter((code) => code);

      const payload = {
        ...formData,
        postalCodes: postalCodesArray,
      };

      const response = await authFetch.post("/zoning", payload);

      if (response.status === 201 || response.status === 200) {
        toast.success("Zone created successfully");
        fetchZones();
        refreshData();
        handleCloseModals();
      }
    } catch (error) {
      console.error("Create Zone Error:", error);
      toast.error("Failed to create zone");
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const postalCodesArray = formData.postalCodes
        .split(",")
        .map((code) => code.trim())
        .filter((code) => code);

      const payload = {
        ...formData,
        postalCodes: postalCodesArray,
      };

      const { data } = await authFetch.put(
        `/zoning/${selectedZone._id}`,
        payload
      );

      if (data) {
        toast.success("Zone updated successfully");
        fetchZones();
        refreshData();
        handleCloseModals();
      }
    } catch (error) {
      console.error("Update Zone Error:", error);
      toast.error("Failed to update zone");
    }
  };

  const handleDelete = async () => {
    try {
      const response = await authFetch.delete(`/zoning/${selectedZone._id}`);

      if (response.status === 200 || response.status === 201) {
        toast.success("Zone deleted successfully");
        fetchZones();
        refreshData();
        handleCloseModals();
      }
    } catch (error) {
      toast.error("Failed to delete zone");
    }
  };

  const handleEditClick = (zone) => {
    setSelectedZone(zone);
    setFormData({
      name: zone.name,
      description: zone.description,
      postalCodes: zone.postalCodes.join(", "),
      isActive: zone.isActive,
    });
    setShowEditModal(true);
  };

  const handleDeleteClick = (zone) => {
    setSelectedZone(zone);
    setShowDeleteModal(true);
  };

  const handleCloseAutoGenerateModal = () => {
    setShowAutoGenerateModal(false);
  };

  const handleAddToGroup = (zone) => {
    setSelectedZoneForGroup(zone);
    setShowAddToGroupModal(true);
  };

  const handleCloseModals = () => {
    setShowAddModal(false);
    setShowEditModal(false);
    setShowDeleteModal(false);
    setSelectedZone(null);
    setFormData({
      name: "",
      description: "",
      postalCodes: "",
      isActive: true,
    });
  };

  const handleClose = () => {
    setShow(false);
    handleCloseModals();
  };

  const Pagination = () => {
    // Use local totalPages instead of global state
    const pages = [];
    const pagesCount = Math.ceil((filteredZones?.length || 0) / itemsPerPage);

    for (let i = 1; i <= pagesCount; i++) {
      pages.push(
        <Button
          key={i}
          variant={currentPage === i ? "primary" : "outline-primary"}
          onClick={() => setCurrentPage(i)}
          className="mx-1"
        >
          {i}
        </Button>
      );
    }

    return (
      <div
        className="row justify-content-center align-items-center mt-3 gap-2"
        style={{ marginBottom: "6rem" }}
      >
        <div className="col-auto">
          <Button
            variant="outline-primary"
            onClick={() => setCurrentPage((p) => Math.max(p - 1, 1))}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <span className="mx-2">{pages}</span>
          <Button
            variant="outline-primary"
            onClick={() => setCurrentPage((p) => Math.min(p + 1, pagesCount))}
            disabled={currentPage === pagesCount}
          >
            Next
          </Button>
        </div>
        <div className="col-auto" style={{ marginTop: "1.3rem" }}>
          <Form.Select
            className="ms-3"
            style={{ width: "auto", minWidth: "150px" }}
            value={itemsPerPage}
            onChange={(e) => {
              setItemsPerPage(Number(e.target.value));
              setCurrentPage(1);
            }}
          >
            <option value={5}>5 per page</option>
            <option value={10}>10 per page</option>
            <option value={25}>25 per page</option>
            <option value={50}>50 per page</option>
          </Form.Select>
        </div>
      </div>
    );
  };

  const renderModal = (showModal, title, isDelete = false) => (
    <Modal show={showModal} onHide={handleCloseModals}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isDelete ? (
          <div>
            <p>
              Are you sure you want to delete the zone "{selectedZone?.name}"?
            </p>
            <p className="text-danger">This action cannot be undone.</p>
          </div>
        ) : (
          <Form onSubmit={selectedZone ? handleUpdate : handleCreate}>
            <Form.Group className="mb-3">
              <Form.Label>Zone Name</Form.Label>
              <Form.Control
                type="text"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Postal Codes (comma-separated)</Form.Label>
              <Form.Control
                type="text"
                value={formData.postalCodes}
                onChange={(e) =>
                  setFormData({ ...formData, postalCodes: e.target.value })
                }
                placeholder="e.g. 12345, 12346, 12347"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check
                type="switch"
                label="Active"
                checked={formData.isActive}
                onChange={(e) =>
                  setFormData({ ...formData, isActive: e.target.checked })
                }
              />
            </Form.Group>

            <div className="d-flex justify-content-end gap-2">
              <Button variant="secondary" onClick={handleCloseModals}>
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                {selectedZone ? "Update" : "Create"} Zone
              </Button>
            </div>
          </Form>
        )}
      </Modal.Body>
      {isDelete && (
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModals}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete Zone
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );

  if (loading) {
    return (
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Body>
          <div className="text-center p-5">
            <FontAwesomeIcon icon={faSpinner} spin /> Loading...
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Container fluid>
      <Row className="mb-3">
        <Col md={5}>
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            <Form.Control
              placeholder="Search by name, description, or postal code"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>
        </Col>
        <Col md={3}>
          <Form.Select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <option value="all">All Status</option>
            <option value="active">Active Only</option>
            <option value="inactive">Inactive Only</option>
          </Form.Select>
        </Col>
        {/* <Col md={3} className="text-end">
          <Button variant="primary" onClick={() => setShowAddModal(true)}>
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Add New Zone
          </Button>
        </Col> */}
        <Col md={4} className="text-end">
          <div className="d-flex gap-2 justify-content-end">
            <Button
              variant="primary"
              onClick={() => setShowAutoGenerateModal(true)}
            >
              <FontAwesomeIcon icon={faWandMagicSparkles} className="me-2" />
              Auto-Generate
            </Button>
            <Button variant="primary" onClick={() => setShowAddModal(true)}>
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Add New Zone
            </Button>
            <Button
              variant="primary"
              onClick={() => setShowUnassignedModal(true)}
            >
              <FontAwesomeIcon icon={faMinus} className="me-2" />
              Unassigned Zones
            </Button>
          </div>
        </Col>
      </Row>

      <Table responsive hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Postal Codes</th>
            <th>Members</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredZones && filteredZones.length > 0 ? (
            filteredZones
              ?.slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage
              )
              .map((zone) => (
                <tr key={zone._id} className="align-middle">
                  <td>{zone.name}</td>
                  <td>{zone.description}</td>
                  <td>
                    {zone.postalCodes.length > 3
                      ? `${zone.postalCodes.slice(0, 3).join(", ")}...`
                      : zone.postalCodes.join(", ")}
                  </td>
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      <FontAwesomeIcon icon={faUsers} className="me-2" />
                      {zone.memberCount || 0}
                    </div>
                  </td>
                  <td>
                    <Badge bg={zone.isActive ? "success" : "secondary"}>
                      {zone.isActive ? "Active" : "Inactive"}
                    </Badge>
                  </td>
                  <td>
                    <div className="d-flex justify-content-center gap-2">
                      <button
                        className="jiluil"
                        size="sm"
                        onClick={() => handleAddToGroup(zone)}
                      >
                        <FontAwesomeIcon icon={faUserGroup} />
                      </button>
                      <button
                        className="jiluil3"
                        size="sm"
                        onClick={() => handleEditClick(zone)}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                      <button
                        className="jiluil344"
                        size="sm"
                        onClick={() => handleDeleteClick(zone)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center align-middle">
                No zones found
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <Pagination />

      {renderModal(showAddModal, "Add New Zone")}
      {renderModal(showEditModal, "Edit Zone")}
      {renderModal(showDeleteModal, "Delete Zone", true)}

      <AutoGenerateZonesModal
        show={showAutoGenerateModal}
        setShow={setShowAutoGenerateModal}
        onHide={handleCloseAutoGenerateModal}
        onSave={fetchZones}
      />
      <UnassignedZonesModal
        show={showUnassignedModal}
        onHide={() => setShowUnassignedModal(false)}
        authFetch={authFetch}
        onAssign={() => {
          fetchZones();
          refreshData();
        }}
        existingZones={zones}
      />
      <AddZoneToGroupModal
        show={showAddToGroupModal}
        onHide={() => {
          setShowAddToGroupModal(false);
          setSelectedZoneForGroup(null);
        }}
        zone={selectedZoneForGroup}
        authFetch={authFetch}
      />
    </Container>
  );
};

export default ZoneManagement;
