import React, { useContext } from "react";
import dayjs from "dayjs";
import GlobalContext from "../../../calendarcontext/GlobalContext";

export default function Day({ day, events }) {
  const { setShowEventModal, setSelectedEvent } = useContext(GlobalContext);

  const getContrastColor = (bgColor) => {
    const colorMap = {
      brown: "#8B4513",
      orange: "#FFA500",
      green: "#008000",
      blue: "#0000FF",
      gray: "#808080",
      purple: "#800080",
      red: "#FF0000",
      black: "#000000",
    };

    const hex = colorMap[bgColor] || bgColor;
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance > 0.5 ? "#000000" : "#FFFFFF";
  };

  const isToday = day.format("DD-MM-YY") === dayjs().format("DD-MM-YY");

  const handleDayClick = () => {
    setShowEventModal(true);
  };

  const handleEventClick = (evt, e) => {
    e.stopPropagation();
    setSelectedEvent(evt);
    setShowEventModal(true);
  };

  return (
    <div className="day-cell" onClick={handleDayClick}>
      <div className={`day-header ${isToday ? "today" : ""}`}>
        <span className="day-number">{day.format("DD")}</span>
      </div>

      <div className="events-container">
        {events?.map((evt, idx) => (
          <div
            key={evt.eventId || idx}
            onClick={(e) => handleEventClick(evt, e)}
            className="event-item"
            style={{
              backgroundColor: evt.eventLabel,
              color: getContrastColor(evt.eventLabel),
              textAlign: "center",
              paddingTop: "0.5rem",
              paddingBottom: "0.5rem",
              fontSize: "0.8em",
              cursor: "pointer",
              marginBottom: "2px",
            }}
          >
            <span className="event-title">{evt.eventTitle}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
