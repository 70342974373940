import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import QRCode from "qrcode.react";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";

const AddShareLink = ({ show, setShow }) => {
  const { logoutUser, user } = useAppContext();
  const [copiedUrl, setCopiedUrl] = useState(false);

  // Set up authenticated axios instance
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  // Add interceptor for handling unauthorized responses
  authFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) logoutUser();
      return Promise.reject(error);
    }
  );

  const handleClose = () => setShow(false);

  const baseURL = "https://churchease.com/join";

  const getRegistrationUrl = () =>
    `${baseURL}/${user.churchDomain}/${user._id.substring(0, 6)}`;

  const handleCopy = () => {
    setCopiedUrl(true);
    setTimeout(() => setCopiedUrl(false), 2000);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Registration Link</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {copiedUrl && <div className="inlinesuccess">Copied!</div>}

        <Form.Label>Registration Link</Form.Label>
        <div className="flex flex-row gap-3">
          <Form.Control type="text" value={getRegistrationUrl()} disabled />
          <CopyToClipboard text={getRegistrationUrl()} onCopy={handleCopy}>
            <button className="jiluil">Copy</button>
          </CopyToClipboard>
        </div>

        <div className="mt-3 mb-3">
          <QRCode value={getRegistrationUrl()} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddShareLink;
