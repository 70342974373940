import React, { useState, useEffect } from "react";
import { Select } from "antd";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Container, Col, Row, Form, Modal } from "react-bootstrap";
import { useAppContext } from "../../../context/appContext";
import "./usermanagement.css";
import AddGroupTrigger from "../../../components/WorkFlow/AddGroupTrigger";
import EventTrigger from "../../../components/WorkFlow/EventTrigger";
import EditCalendarStep from "../../../components/WorkFlow/EditCalendarStep";
import NewEventTrigger from "../../../components/WorkFlow/NewEventTrigger";
import DeleteStep from "../../../components/WorkFlow/DeleteStep";
import NewCalendarEventTrigger from "../../../components/WorkFlow/NewCalendarEventTrigger";
import { convertTo12HourFormat } from "../../../utils/convertTo12HourFormat";
import { FaCopy, FaTrash } from "react-icons/fa";
import NewGroupTrigger from "../../../components/WorkFlow/NewGroupTrigger";
import EditGroupTrigger from "../../../components/WorkFlow/EditGroupTrigger";
import { format } from "date-fns";

const WorkFlowDetails = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { id, workflowName, selectedOption } = useParams();

  const { logoutUser } = useAppContext();
  const [workflowId, setWorkflowId] = useState("");
  //const [loading, setLoading] = useState(false);
  //sconst [error, setError] = useState(null);
  const [uniqueSteps, setUniqueSteps] = useState([]);
  const [myData, setMyData] = useState([]);
  const [activeStepId, setActiveStepId] = useState(null);
  const [showEventTrigger, setShowEventTrigger] = useState(false);
  const [showNewEventTrigger, setShowNewEventTrigger] = useState(false);
  const [showDeleteNotice, setShowDeleteNotice] = useState(false);
  const [showCalendarEditxTrigger, setShowCalendarEditxTrigger] =
    useState(false);
  const [showNewCalendarTrigger, setShowNewCalendarTrigger] = useState(false);
  const [notice, setNotice] = useState(false);
  const [show2, setShow2] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [showNewGroupTrigger, setShowNewGroupTrigger] = useState(false);
  const [showGroupEditTrigger, setShowGroupEditTrigger] = useState(false);

  useEffect(() => {
    setWorkflowId(id);
  }, [id]);

  useEffect(() => {
    if (workflowId) {
      getUniqueSteps();
    }
  }, [workflowId]);

  const getUniqueSteps = async () => {
    try {
      const { data } = await authFetch.get(`/steps/${workflowId}`);
      setUniqueSteps(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (uniqueSteps) {
      setMyData(uniqueSteps);
    }
  }, [uniqueSteps]);

  const newEventTrigger = () => {
    setShowNewEventTrigger(true);
    setShowEventTrigger(false);
  };

  const showEditable = (stepId) => {
    setShowEventTrigger(true);
    setShowNewEventTrigger(false);
    setActiveStepId(stepId);
  };

  const newCalendarEventTrigger = () => {
    setShowNewCalendarTrigger(true);
    setShowCalendarEditxTrigger(false);
  };

  const showCalendarEditable = (stepId) => {
    setShowCalendarEditxTrigger(true);
    setShowNewCalendarTrigger(false);
    setActiveStepId(stepId);
  };

  const handleCloseOnDelete = () => {
    setShowCalendarEditxTrigger(false);
    setShowEventTrigger(false);
    setShowNewEventTrigger(false);
    setShowNewCalendarTrigger(false);
    setShowNewGroupTrigger(false);
    setShowGroupEditTrigger(false);
  };

  const newGroupEventTrigger = () => {
    setShowNewGroupTrigger(true);
    setShowGroupEditTrigger(false);
  };
  const showGroupEditable = (stepId) => {
    setShowGroupEditTrigger(true);
    setShowNewGroupTrigger(false);
    setActiveStepId(stepId);
  };

  const handleDeleteStep = async (e, step) => {
    setShow2(true);
    setSelectedValue(step);
  };

  const handleClose2 = () => setShow2(false);

  const [showCopy, setShowCopy] = useState(false);
  const [selectedCopy, setSelectedCopy] = useState(null);
  const handleCloseCopy = () => setShowCopy(false);

  const handleCopy = async (e, step) => {
    setShowCopy(true);
    setSelectedCopy(step);
  };

  const handleCopyStep = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const copyStep = { ...selectedCopy };
    delete copyStep._id;
    copyStep.workflowName += " - Copy";
    try {
      const response = await authFetch.post(`/steps/copy`, copyStep);
      if (response.status === 200 || response.status === 201) {
        setNotice(true);
        getUniqueSteps();
        setShowCopy(false);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <Container fluid>
      <Row className="courseheader">
        {/* <p className="sahs">
          Workflow {"-"}{" "}
          {selectedOption === "calendarTrigger"
            ? "Scheduled Date Trigger"
            : "Personal Event Trigger"}
        </p> */}
        <p className="sahs">
          Workflow {"-"}{" "}
          {selectedOption === "calendarTrigger"
            ? "Scheduled Date Trigger"
            : selectedOption === "eventTrigger"
            ? "Personal Event Trigger"
            : "Group Event Trigger"}
        </p>
      </Row>
      <Row className="workingarea">
        <Col md={5} className="flex justify-start flex-column">
          {myData &&
            myData.map((step) => (
              <div key={step._id}>
                {step.selectedOption === "calendarTrigger" && (
                  <div>
                    {myData.length > 0 && (
                      <>
                        <div
                          className={`kififf2 mb-3 flex justify-between items-center ${
                            activeStepId === step._id ? "selected-style" : ""
                          }`}
                          onClick={() => showCalendarEditable(step._id)}
                        >
                          <div>
                            {step?.selectedAction} {step?.selectedEvent} at{" "}
                            {step?.eventTime
                              ? convertTo12HourFormat(step.eventTime)
                              : ""}{" "}
                            on {step.triggerOn} -{" "}
                            <span style={{ color: "var(--secondary-color)" }}>
                              {step?.status === true
                                ? "Activated"
                                : "Not Activated"}
                            </span>
                          </div>
                          <div className="gap-3 flex">
                            <FaCopy
                              onClick={(e) => {
                                e.stopPropagation();
                                handleCopy(e, step);
                              }}
                              size={18}
                            />
                            <FaTrash
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteStep(e, step);
                              }}
                              size={18}
                              nm
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}

                {step.selectedOption === "eventTrigger" && (
                  <div>
                    {myData.length > 0 && (
                      <div
                        className={`kififf2 mb-3 flex justify-between items-center ${
                          activeStepId === step._id ? "selected-style" : ""
                        }`}
                        onClick={() => showEditable(step._id)}
                      >
                        <div>
                          {step?.selectedAction} {step?.selectedEvent} at{" "}
                          {step?.eventTime
                            ? convertTo12HourFormat(step.eventTime)
                            : ""}{" "}
                          -{" "}
                          <span style={{ color: "var(--secondary-color)" }}>
                            {step?.status === true
                              ? "Activated"
                              : "Not Activated"}
                          </span>
                        </div>
                        <div className="flex gap-3 justify-center items-center">
                          <FaCopy
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCopy(e, step);
                            }}
                          />
                          <FaTrash
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteStep(e, step);
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {step.selectedOption === "groupTrigger" && (
                  <div>
                    {myData.length > 0 && (
                      <div
                        className={`kififf2 mb-3 flex justify-between items-center ${
                          activeStepId === step._id ? "selected-style" : ""
                        }`}
                        onClick={() => showGroupEditable(step._id)}
                      >
                        {step?.selectedFrequency === "sendGroupImmediately" ? (
                          <div>
                            {step?.selectedAction} {step?.selectedEvent} when
                            member is added to a selected group -{" "}
                            <span style={{ color: "var(--secondary-color)" }}>
                              {step?.status === true
                                ? "Activated"
                                : "Not Activated"}
                            </span>
                          </div>
                        ) : (
                          <div>
                            {step?.selectedAction} {step?.waitValue}{" "}
                            {step?.waitUnit} after member is added to a selected
                            group -{" "}
                            <span style={{ color: "var(--secondary-color)" }}>
                              {step?.status === true
                                ? "Activated"
                                : "Not Activated"}
                            </span>
                          </div>
                        )}
                        <FaCopy
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCopy(e, step);
                          }}
                        />
                        <FaTrash
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteStep(e, step);
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}

          {selectedOption === "calendarTrigger" && (
            <button onClick={newCalendarEventTrigger} className="jiluil">
              Click to add step
            </button>
          )}

          {selectedOption === "eventTrigger" && (
            <button onClick={newEventTrigger} className="jiluil">
              Click to add step
            </button>
          )}

          {selectedOption === "groupTrigger" && (
            <button onClick={newGroupEventTrigger} className="jiluil">
              Click to add step
            </button>
          )}
        </Col>
        <Col
          md={7}
          className={
            showCalendarEditxTrigger ||
            showEventTrigger ||
            showNewEventTrigger ||
            showNewCalendarTrigger ||
            showNewGroupTrigger ||
            showGroupEditTrigger
              ? "weokpw"
              : ""
          }
        >
          {showNewCalendarTrigger && (
            <NewCalendarEventTrigger
              workflowId={id}
              workflowName={workflowName}
              selectedOption={selectedOption}
              onSuccessfulDelete={getUniqueSteps}
              setNotice={setNotice}
              notice={notice}
              setShowNewCalendarTrigger={setShowNewCalendarTrigger}
            />
          )}

          {showNewGroupTrigger && (
            <NewGroupTrigger
              workflowId={id}
              workflowName={workflowName}
              selectedOption={selectedOption}
              onSuccessfulDelete={getUniqueSteps}
              setNotice={setNotice}
              notice={notice}
              setShowNewGroupTrigger={setShowNewGroupTrigger}
            />
          )}

          {showGroupEditTrigger && (
            <EditGroupTrigger
              workflowId={id}
              setShowGroupEditTrigger={setShowGroupEditTrigger}
              workflowName={workflowName}
              selectedOption={selectedOption}
              onSuccessfulDelete={getUniqueSteps}
              setNotice={setNotice}
              notice={notice}
              activeStepId={activeStepId}
            />
          )}

          {showCalendarEditxTrigger && (
            <EditCalendarStep
              workflowId={id}
              handleCloseOnDelete={setShowCalendarEditxTrigger}
              workflowName={workflowName}
              selectedOption={selectedOption}
              onSuccessfulDelete={getUniqueSteps}
              setNotice={setNotice}
              notice={notice}
              activeStepId={activeStepId}
            />
          )}

          {showNewEventTrigger && (
            <NewEventTrigger
              workflowId={id}
              setShowNewEventTrigger={setShowNewEventTrigger}
              onSuccessfulDelete={getUniqueSteps}
              workflowName={workflowName}
              selectedOption={selectedOption}
              setNotice={setNotice}
              notice={notice}
            />
          )}

          {showEventTrigger && (
            <EventTrigger
              workflowId={id}
              setShowEventTrigger={setShowEventTrigger}
              workflowName={workflowName}
              selectedOption={selectedOption}
              onSuccessfulDelete={getUniqueSteps}
              setNotice={setNotice}
              notice={notice}
              activeStepId={activeStepId}
            />
          )}
        </Col>
      </Row>
      <DeleteStep
        show2={show2}
        onSuccessfulDelete={getUniqueSteps}
        selectedValue={selectedValue}
        handleClose2={setShow2}
        setShowDeleteNotice={setShowDeleteNotice}
        showDeleteNotice={showDeleteNotice}
        handleCloseOnDelete={handleCloseOnDelete}
      />
      <Modal show={showCopy} onHide={handleCloseCopy}>
        <Modal.Header closeButton>
          <Modal.Title>Copy Step</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You are about to copy this step. Are you sure you want to proceed?
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleCloseCopy} className="cancel">
            Cancel
          </button>
          <button onClick={(e) => handleCopyStep(e)} className="save">
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default WorkFlowDetails;
