import React from "react";
import { Form } from "react-bootstrap";

export const EventModeSelector = ({ mode, onChange }) => {
  const modes = [
    { value: "InPerson", label: "In Person Only" },
    { value: "OnlineOnly", label: "Online Only" },
    { value: "Hybrid", label: "Hybrid (In Person and Online)" },
  ];

  return (
    <Form.Group className="mb-3">
      {modes.map(({ value, label }) => (
        <Form.Check
          key={value}
          type="radio"
          label={label}
          name="eventMode"
          value={value}
          onChange={() => onChange(value)}
          checked={mode === value}
        />
      ))}
    </Form.Group>
  );
};
export default EventModeSelector;
