import React, { useState, useRef, useMemo } from "react";
import ReactQuill from "react-quill";
import Select from "react-select";
import { Modal, Form } from "react-bootstrap";
import axios from "axios";
import { useAppContext } from "../../context/appContext";
import { toast } from "react-toastify";

const BulkEmailEditor = ({ show, checkedItems, handleClose }) => {
  const { user, logoutUser, bulkEmailtoMembers, sendBulkSMS, savedView } =
    useAppContext();

  const authFetch = useMemo(() => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL,
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
    });

    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          logoutUser();
        }
        return Promise.reject(error);
      }
    );

    return instance;
  }, [logoutUser]);
  const [message, setMessage] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [editorBgColor, setEditorBgColor] = useState("white");
  const [isError, setIsError] = useState(false);
  const quillRef = useRef();

  const colorOptions = [
    { value: "white", label: "White" },
    { value: "lightyellow", label: "Light Yellow" },
    { value: "lightblue", label: "Light Blue" },
    { value: "lightgreen", label: "Light Green" },
    { value: "lightpink", label: "Light Pink" },
    { value: "lightgray", label: "Light Gray" },
  ];

  const placeholders = [
    { label: "First Name", value: "[FirstName]" },
    { label: "Last Name", value: "[LastName]" },
    { label: "Email", value: "[Email]" },
    { label: "Phone Number", value: "[PhoneNumber]" },
  ];

  const modules = {
    toolbar: {
      container: [
        ["bold", "italic", "underline"],
        [{ header: [1, 2, false] }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        [{ color: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["link", "image"],
      ],
    },
  };

  const insertPlaceholder = (placeholder) => {
    const quill = quillRef.current.getEditor();
    const range = quill.getSelection() || { index: 0 };
    quill.insertText(range.index, placeholder);
    quill.setSelection(range.index + placeholder.length);
  };

  // const handleSend = () => {

  //   onSend({
  //     emails: checkedItems,
  //     message,
  //     subject: emailSubject, // Changed from emailSubject to subject
  //     editorBgColor,
  //     userId: user?._id,
  //     senderEmail: user?.memail,
  //   });
  //   handleClose();
  // };

  const handleSend = async () => {
    if (!message || !emailSubject) {
      setIsError(true);
      return;
    }
    setIsError(false);
    try {
      const emailData = {
        emails: checkedItems,
        message,
        subject: emailSubject,
        editorBgColor,
        userId: user?._id,
        senderEmail: user?.memail,
      };

      console.log("user", emailData);
      return;
      await bulkEmailtoMembers({
        userMessage: {
          ...emailData,
          userId: user._id,
        },
      });
      handleClose();
      toast.success("Email sent successfully");
    } catch (error) {
      toast.error("Failed to send email");
      console.error("Email error:", error);
    }
  };

  const handlePlaceholderSelect = (e) => {
    insertPlaceholder(e.target.value);
    e.target.value = ""; // Reset to default option
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Email Members</Modal.Title>
      </Modal.Header>
      <Modal.Body className="space-y-4">
        {isError && (
          <div className="p-3 text-red-600 bg-red-100 rounded">
            Email subject and message are required
          </div>
        )}

        <Form.Group>
          <Form.Label>Recipients</Form.Label>
          <Form.Control
            type="text"
            value={checkedItems}
            disabled
            className="bg-gray-50"
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Subject</Form.Label>
          <Form.Control
            type="text"
            value={emailSubject}
            onChange={(e) => setEmailSubject(e.target.value)}
            placeholder="Enter email subject..."
          />
        </Form.Group>

        <div className="space-y-2">
          <div className="flex justify-between items-center">
            <label>Background Color</label>
            <Select
              options={colorOptions}
              value={colorOptions.find((opt) => opt.value === editorBgColor)}
              onChange={(opt) => setEditorBgColor(opt.value)}
              className="w-48"
            />
          </div>

          <ReactQuill
            ref={quillRef}
            value={message}
            onChange={setMessage}
            modules={modules}
            className="bg-white rounded quill-editor" // Add custom class
            style={{
              backgroundColor: editorBgColor,
              minHeight: "500px", // Increased from 300px
              height: "500px", // Added explicit height
            }}
          />
        </div>
      </Modal.Body>

      <Modal.Footer className="flex flex-col space-y-4">
        <Form.Select onChange={handlePlaceholderSelect} className="mb-3">
          <option value="">Insert placeholder...</option>
          {placeholders.map((ph) => (
            <option key={ph.value} value={ph.value}>
              {ph.label}
            </option>
          ))}
        </Form.Select>

        <div className="flex justify-end gap-3">
          <button
            onClick={handleClose}
            className="px-4 py-2 text-gray-700 bg-gray-100 rounded hover:bg-gray-200"
          >
            Cancel
          </button>
          <button
            onClick={handleSend}
            className="px-4 py-2 jiluil text-white  rounded hover:bg-blue-700"
          >
            Send Email
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default BulkEmailEditor;
