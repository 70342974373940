import React, { useState, useEffect, useMemo } from "react";
import {
  Modal,
  Button,
  Form,
  ListGroup,
  Spinner,
  Alert,
} from "react-bootstrap";
import axios from "axios";
import { FaTrash, FaClock } from "react-icons/fa";
import moment from "moment";
import { toast } from "react-toastify";

import { useAppContext } from "../../context/appContext";
const LoadQueryModal = ({
  show,
  onHide,
  onLoad,
  onDelete,
  savedQueries,
  onQueriesChange,
}) => {
  const [loading, setLoading] = useState(false);
  const { logoutUser } = useAppContext();

  const authFetch = useMemo(() => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL,
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
    });

    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          logoutUser();
        }
        return Promise.reject(error);
      }
    );

    return instance;
  }, [logoutUser]);

  const handleLoad = async (queryId) => {
    try {
      setLoading(true);
      const response = await authFetch.get(`/powerquery/${queryId}`);

      if (response.data?.query?.query) {
        onLoad(response.data.query.query); // Pass the actual query configuration
        onHide();
      } else {
        toast.error("Invalid query data received");
      }
    } catch (error) {
      console.error("Error loading query:", error);
      toast.error("Failed to load query");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (queryId) => {
    try {
      await onDelete(queryId);
      onQueriesChange();
      toast.success("Query deleted successfully");
    } catch (error) {
      toast.error("Failed to delete query");
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Load Saved Query</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="text-center py-4">
            <Spinner animation="border" />
          </div>
        ) : (
          <ListGroup>
            {savedQueries.length === 0 ? (
              <p className="text-muted text-center">No saved queries found</p>
            ) : (
              savedQueries.map((query) => (
                <ListGroup.Item
                  key={query._id}
                  action
                  className="d-flex justify-content-between align-items-center"
                  onClick={() => handleLoad(query._id)}
                >
                  <div>
                    <h6 className="mb-1">{query.name}</h6>
                    {query.description && (
                      <p className="mb-1 text-muted small">
                        {query.description}
                      </p>
                    )}
                    <small className="text-muted">
                      <FaClock className="me-1" />
                      {query.lastUsed
                        ? `Last used ${moment(query.lastUsed).fromNow()}`
                        : `Created ${moment(query.createdAt).fromNow()}`}
                    </small>
                  </div>
                  <Button
                    variant="link"
                    className="text-danger"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(query._id);
                    }}
                  >
                    <FaTrash />
                  </Button>
                </ListGroup.Item>
              ))
            )}
          </ListGroup>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default LoadQueryModal;
