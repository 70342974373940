import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./register.css";
import { Link, useNavigate } from "react-router-dom";
import Image from "react-bootstrap/Image";
import LoginGrap from "../../assets/images/LoginGrap.png";
import Button from "react-bootstrap/Button";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useAppContext } from "../../context/appContext";
import Alert from "../../components/Alert/Alert.js";
import frame from "../../assets/images/myLogo.png";
import Form from "react-bootstrap/Form";
import { Helmet } from "react-helmet";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import sss from "../../assets/images/sss.png";

const Login = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const inputRef = useRef(null);
  useEffect(() => {
    // Focus on the input element when the component is mounted
    inputRef.current.focus();
  }, []);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isMember] = useState(true);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [subdomain, setSubdomain] = useState("");
  const {
    user,
    showAlert,
    displayAlert,
    setupUser,
    mfaRequired,
    isTrialExpired,
  } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    const userAgent = navigator.userAgent;
    // A simple check for mobile devices
    if (/Mobi|Android/i.test(userAgent)) {
      // Redirect to the mobile information page
      navigate("/mobileinfo");
    }
  }, [navigate]);

  const loginHandle = async (e) => {
    try {
      e.preventDefault();

      if (!password || !subdomain || (!isMember && !email)) {
        displayAlert();
        return;
      }

      const currentUser = {
        email,
        password,
        churchDomainx: subdomain,
      };

      if (isMember) {
        const response = await setupUser({
          currentUser,
          endPoint: "login",
          alertText: "Login Successful! Redirecting...",
        });
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  useEffect(() => {
    // First check for MFA requirement
    if (mfaRequired) {
      navigate("/multifactorauth", { state: { email: email } });
      return;
    }

    if (!user) return;

    // Then check if trial is expired
    if (isTrialExpired && user.memberShip === "Free") {
      navigate("/auth/accountmanagement", {
        state: {
          trialExpired: true,
          message: "Your free trial has ended. Please upgrade to continue.",
        },
      });
      return;
    }

    // Finally handle regular role-based navigation
    const hasStandardRole = user.role?.some((role) =>
      role.roleName?.includes("Standard")
    );
    const isAppAdmin = user.role?.some((role) =>
      role.roleName?.includes("App Admin")
    );

    const redirectDelay = 1000;
    setTimeout(() => {
      if (hasStandardRole) {
        navigate("/auth/standardhome");
      } else if (isAppAdmin) {
        navigate("/auth/appdashboard");
      } else {
        navigate("/auth/dashboard");
      }
    }, redirectDelay);
  }, [user, mfaRequired, isTrialExpired, navigate, email]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      loginHandle(event);
    }
  };
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <>
      <Helmet>
        <title>Login - Churchease</title>
        <meta
          name="description"
          content="Log in to access your Churchease account and manage your church's operations efficiently."
        />
      </Helmet>
      <Container fluid style={{ padding: "0" }}>
        <Row className="contaiwner2">
          <Col sm={7} md={7} className="logindiv2xx">
            <Image fluid src={sss} className="imgsizexs" />
            <p
              style={{
                color: "#fff",
                fontWeight: "bold",
                paddingTop: "2rem",
                fontSize: "1.9rem",
                width: "65%",
              }}
            >
              Transform Your Church Management with Churchease
            </p>
            <p className="redi">
              <Link to="/" className="single5">
                <FontAwesomeIcon icon={faArrowLeft} className="single1" /> Back
                to home page
              </Link>
            </p>
          </Col>
          <Col className="signupcolumn">
            {/* <div className="flex justify-center">
              <Image fluid src={frame} className="imgsizex22" />
            </div> */}
            <form className="formdiv">
              <p className="logintextmessagedd">Sign In</p>
              {showAlert && <Alert />}
              <div className="label">
                <Form.Label
                  htmlFor="inputPassword5"
                  style={{ fontWeight: "600", fontSize: "1em" }}
                >
                  Subdomain <span className="myast">*</span>
                </Form.Label>
                <input
                  type="text"
                  id="subdomain"
                  placeholder="Enter subdomain"
                  value={subdomain}
                  onChange={(e) => setSubdomain(e.target.value)}
                  className="forminput"
                  ref={inputRef}
                  onKeyDown={handleKeyDown}
                />
              </div>

              <div className="label">
                <Form.Label
                  htmlFor="inputPassword5"
                  style={{ fontWeight: "600", fontSize: "1em" }}
                >
                  Email <span className="myast">*</span>
                </Form.Label>
                <input
                  type="email"
                  id="email"
                  placeholder="Enter email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="forminput"
                />
              </div>
              <div style={{ position: "relative" }}>
                <Form.Label
                  htmlFor="inputPassword5"
                  style={{ fontWeight: "600", fontSize: "1em" }}
                >
                  Password <span className="myast">*</span>
                </Form.Label>
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  id="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="forminput"
                  onKeyDown={handleKeyDown}
                  style={{ paddingRight: "30px" }} // Add padding to avoid icon overlapping text
                />
                <span
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "68%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={togglePasswordVisibility}
                >
                  {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              <p className="welcometext3">
                <Link to="/forgotpassword" className="single5">
                  Forgot password?
                </Link>
              </p>
              <p>
                <Button
                  type="button"
                  className="buttonner"
                  onClick={loginHandle}
                >
                  Sign In
                </Button>
              </p>
              <br></br>
              <p className="welcometext2">
                Don't have an account?{" "}
                <Link to="/pricing" className="single5">
                  Sign Up
                </Link>
              </p>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
