import React, { useEffect, useState, useRef, useCallback } from "react";
import Col from "react-bootstrap/Col";
import { useAppContext } from "../../../context/appContext";
import Modal from "react-bootstrap/Modal";
import AddExpense from "../../../components/Expenses/AddExpenses";
import ViewExpense from "../../../components/Expenses/ViewExpense";
import axios from "axios";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Pagination from "react-bootstrap/Pagination";
import { FaCaretDown, FaCaretUp, FaSearch, FaFilter } from "react-icons/fa";
import moment from "moment";
import { toast } from "react-toastify";
import _ from "lodash";

import CustomToastContent from "../../../components/Toast/CustomToastContent";
import UpdateExpenses from "../../../components/Expenses/UpdateExpenses";
const Expenses = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [viewNameFlag, setViewNameFlag] = useState(true);
  const [expenseName, setExpenseName] = useState("");
  const [error, setError] = useState(false);
  const [uploadButtonText, setUploadButtonText] = useState("Upload Expenses");
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [expenseDescription, setExpenseDescription] = useState("");
  const [expenseCategory, setExpenseCategory] = useState("");
  const [expenseDate, setExpenseDate] = useState("");
  const [amount, setAmount] = useState("");
  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);
  const [show3, setShow3] = useState(false);
  const [show5, setShow5] = useState(false);
  const handleShow3 = () => setShow3(true);
  const [selectedValue, setSelectedValue] = useState(null);
  const [displayedExpenses, setDisplayedExpenses] = useState([]);

  const [handleRefresh, setHandleRefresh] = useState(false);
  const handleClose1 = () => setShow2(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const handleClose5 = () => setShow5(false);
  const [expenseData, setExpenseData] = useState([]);
  const [documentFile, setDocumentFile] = useState({});
  const [getTheExpense, setGetTheExpense] = useState(null);
  const [expenseNamex, setExpenseNamex] = useState("");
  const [expenseTypex, setExpenseTypex] = useState("");
  const [paymentMethodx, setPaymentMethodx] = useState("");
  const [vendorNamex, setVendorNamex] = useState("");
  const [departmentx, setDepartmentx] = useState("");
  const [notesx, setNotesx] = useState("");
  const [documentFilex, setDocumentFilex] = useState(null);
  const [expenseDescriptionx, setExpenseDescriptionx] = useState("");
  const [expenseCategoryx, setExpenseCategoryx] = useState("");
  const [expenseDatex, setExpenseDatex] = useState("");
  const [amountx, setAmountx] = useState("");

  const [newExpenseDocument, setNewExpenseDocument] = useState(null);

  const handleView = (e, expense) => {
    e.stopPropagation();
    setSelectedDocument(expense);
    setShow3(true);
  };

  const refreshWorkflows = () => {
    getExpenseDetails();
    setHandleRefresh(true);
  };

  useEffect(() => {
    getExpenseDetails();
    getExpense();
  }, [handleRefresh]);
  const {
    logoutUser,
    user,
    getExpense,
    expenses,
    getContainers,
    getExpenseDetails,
    containers,
    expensedetails,
  } = useAppContext();

  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  // Filtering State
  const [filters, setFilters] = useState({
    dateRange: {
      startDate: "",
      endDate: "",
    },
    expenseCategory: "",
    searchTerm: "",
    minAmount: "",
    maxAmount: "",
  });

  // Sorting State
  const [sortConfig, setSortConfig] = useState({
    key: "postDate",
    direction: "desc",
  });

  // Modified to handle filtered and paginated data
  const [filteredExpenses, setFilteredExpenses] = useState([]);

  // Handle filtered and sorted data
  useEffect(() => {
    if (expensedetails) {
      const filtered = filterExpenses(expensedetails);
      const totalItems = filtered.length;
      setTotalPages(Math.ceil(totalItems / itemsPerPage));

      // Get current page items
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      setDisplayedExpenses(filtered.slice(indexOfFirstItem, indexOfLastItem));
    }
  }, [expensedetails, filters, currentPage, itemsPerPage, sortConfig]);

  // Filter Logic
  const filterExpenses = (expensedetails) => {
    if (!expensedetails) return [];

    return expensedetails
      .filter((expense) => {
        // Date Range Filter
        const expenseDate = moment(expense.postDate);
        const afterStartDate =
          !filters.dateRange.startDate ||
          expenseDate.isSameOrAfter(filters.dateRange.startDate, "day");
        const beforeEndDate =
          !filters.dateRange.endDate ||
          expenseDate.isSameOrBefore(filters.dateRange.endDate, "day");

        // Category Filter
        const matchesCategory =
          !filters.expenseCategory ||
          expense.expenseCategory === filters.expenseCategory;

        // Amount Filter
        const aboveMin =
          !filters.minAmount || expense.amount >= parseFloat(filters.minAmount);
        const belowMax =
          !filters.maxAmount || expense.amount <= parseFloat(filters.maxAmount);

        // Search Term Filter
        const matchesSearch =
          !filters.searchTerm ||
          expense.expenseName
            .toLowerCase()
            .includes(filters.searchTerm.toLowerCase()) ||
          (expense.expenseDescription &&
            expense.expenseDescription
              .toLowerCase()
              .includes(filters.searchTerm.toLowerCase()));

        return (
          afterStartDate &&
          beforeEndDate &&
          matchesCategory &&
          aboveMin &&
          belowMax &&
          matchesSearch
        );
      })
      .sort((a, b) => {
        if (sortConfig.key === "amount") {
          return sortConfig.direction === "asc"
            ? a[sortConfig.key] - b[sortConfig.key]
            : b[sortConfig.key] - a[sortConfig.key];
        }
        return sortConfig.direction === "asc"
          ? String(a[sortConfig.key]).localeCompare(String(b[sortConfig.key]))
          : String(b[sortConfig.key]).localeCompare(String(a[sortConfig.key]));
      });
  };

  const getExpenseById = async (e, expense) => {
    e.preventDefault();
    e.stopPropagation();
    setGetTheExpense(expense);
    try {
      const response = await authFetch(
        `/giving/expense/selected/${expense._id}`
      );

      if (response.status === 200 || response.status === 201) {
        setExpenseNamex(response.data.expenseName);
        setExpenseDescriptionx(response.data.expenseDescription);
        setExpenseCategoryx(response.data.expenseCategory);
        setAmountx(response.data.amount);
        const formattedDate = new Date(response.data.expenseDate)
          .toISOString()
          .split("T")[0];
        setExpenseDatex(formattedDate);
        setDocumentFilex(response.data.documentFile);
        setSelectedValue(expense);
        setShow5(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const expenseTypes = ["Direct Expense", "Reimbursement", "Request"];
  const paymentMethods = ["Cash", "Check", "Card", "Bank Transfer"];

  // Get current page items
  const getCurrentItems = () => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return filteredExpenses.slice(indexOfFirstItem, indexOfLastItem);
  };

  const handleFilterChange = (field, value) => {
    setFilters((prev) => ({
      ...prev,
      [field]: value,
    }));
    setCurrentPage(1); // Reset to first page when filters change
  };

  // Handle sort
  const handleSort = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  const handleShow1 = (e, expense) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedValue(expense);

    setShow2(true);
  };

  const handleDocumentUploadewefwefwee = async (e) => {
    try {
      const file = e.target.files[0];
      setNewExpenseDocument(file);
      if (!file) {
        console.log("No file selected");
        return;
      }

      if (file.type !== "application/pdf") {
        setError("Only PDF files are allowed");
        console.log("File is not a PDF");
        return;
      }

      setUploadButtonText(file.name);
      setUploading(true);

      const documentData = new FormData();
      documentData.append("document", file);

      try {
        console.log("Uploading document");
        const response = await authFetch.post(
          "/giving/upload-new-expense-report",
          documentData,
          {
            headers: {
              Accept: "application/json",
            },
            onUploadProgress: (e) => {
              setProgress(Math.round((100 * e.loaded) / e.total));
              console.log(
                `Upload progress: ${Math.round((100 * e.loaded) / e.total)}%`
              );
            },
          }
        );

        setNewExpenseDocument(response.data);
      } catch (uploadErr) {
        console.error("Upload error:", uploadErr);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setUploading(false);
    }
  };

  const [vendors, setVendors] = useState([]);
  useEffect(() => {
    getVendors();
  }, []);
  useEffect(() => {
    getContainers();
  }, []);
  const getVendors = async () => {
    try {
      const { data } = await authFetch.get("/vendors/get");

      setVendors(data.vendors);
    } catch (error) {
      console.error(error);
    }
  };

  const openFile = (e) => {
    e.preventDefault();

    const selectedDocument = {
      documentFile: documentFilex,
    };

    setSelectedDocument(selectedDocument);
    setShow3(true);
  };

  const removeDocument = () => {
    try {
      const response = authFetch.patch(
        `/giving/expense/remove-expense-document/${selectedValue._id}`,
        { documentFilex }
      );

      if (response.status === 200 || response.status === 201) {
        setDocumentFile(null);
        refreshWorkflows();
        getExpenseDetails();
        toast(
          <CustomToastContent
            message="Document Removed Successfully"
            type="success"
          />
        );
      }
    } catch (error) {
      console.error("Error:", error);

      toast(
        <CustomToastContent
          message="An error occurred while removing the document"
          type="error"
        />
      );
    }
  };

  const handleDelete = async () => {
    try {
      console.log(selectedValue._id);
      const response = await authFetch.delete(
        `/giving/expense/selected/${selectedValue._id}`
      );
      if (response) {
        getExpenseDetails();
        handleClose1();
        toast(
          <CustomToastContent
            message="Expense Deleted Successfully"
            type="success"
          />
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (!expenseNamex || !expenseCategoryx || !amountx || !expenseDatex) {
      toast(
        <CustomToastContent
          message="Please fill in all required fields"
          type="error"
        />
      );
      return;
    }

    const expenseData = {
      expenseName: expenseNamex,
      expenseDescription: expenseDescriptionx,
      expenseCategory: expenseCategoryx,
      amount: amountx,
      expenseDate: expenseDatex,
      documentFile: newExpenseDocument,
    };

    try {
      const response = await authFetch.patch(
        `/giving/expense/selected/${selectedValue._id}`,
        expenseData
      );

      if (response.status === 200 || response.status === 201) {
        setExpenseName("");
        setExpenseDescription("");
        setExpenseCategory("");
        setAmount("");
        setExpenseDate("");
        setNewExpenseDocument(null);
        refreshWorkflows();
        handleClose5();
        toast(
          <CustomToastContent
            message="Expense Updated Successfully"
            type="success"
          />
        );
      } else {
        toast(
          <CustomToastContent
            message="An error occurred while updating the expense"
            type="error"
          />
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Filter Component
  const FilterSection = () => {
    // Local state for input values
    const [localFilters, setLocalFilters] = useState({
      search: filters.searchTerm || "",
      minAmount: filters.minAmount || "",
      maxAmount: filters.maxAmount || "",
    });

    // Debounced filter update function
    const debouncedFilter = useCallback(
      _.debounce((updatedFilters) => {
        setFilters((prev) => ({
          ...prev,
          searchTerm: updatedFilters.search,
          minAmount: updatedFilters.minAmount,
          maxAmount: updatedFilters.maxAmount,
        }));
      }, 800), // Debounce delay: 800ms
      [setFilters]
    );

    // Handle input changes with debounce
    const handleInputChange = (field, value) => {
      // Update local state immediately
      const updatedFilters = {
        ...localFilters,
        [field]: value,
      };
      setLocalFilters(updatedFilters);

      // Call debounced filter function
      debouncedFilter(updatedFilters);
    };

    // Handle date range and category changes
    const handleFilterChange = (field, value) => {
      setFilters((prev) => ({
        ...prev,
        [field]: value,
      }));
    };

    // Clear all filters
    const clearFilters = () => {
      setLocalFilters({
        search: "",
        minAmount: "",
        maxAmount: "",
      });
      setFilters({
        dateRange: {
          startDate: "",
          endDate: "",
        },
        expenseCategory: "",
        searchTerm: "",
        minAmount: "",
        maxAmount: "",
      });
    };

    return (
      <>
        <Row className="mb-4 mt-3">
          {/* Search Input */}
          <div className="col-md-3 mb-2">
            <InputGroup>
              <InputGroup.Text style={{ height: "35px" }}>
                <FaSearch />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search expenses..."
                value={localFilters.search}
                onChange={(e) => handleInputChange("search", e.target.value)}
                autoComplete="off"
              />
            </InputGroup>
          </div>

          {/* Date Range Inputs */}
          <div className="col-md-2 mb-2">
            <Form.Control
              type="date"
              value={filters.dateRange.startDate}
              onChange={(e) =>
                handleFilterChange("dateRange", {
                  ...filters.dateRange,
                  startDate: e.target.value,
                })
              }
              placeholder="Start Date"
            />
          </div>
          <div className="col-md-2 mb-2">
            <Form.Control
              type="date"
              value={filters.dateRange.endDate}
              onChange={(e) =>
                handleFilterChange("dateRange", {
                  ...filters.dateRange,
                  endDate: e.target.value,
                })
              }
              placeholder="End Date"
            />
          </div>

          {/* Expense Category Dropdown */}
          <div className="col-md-2 mb-2">
            <Form.Select
              value={filters.expenseCategory}
              onChange={(e) =>
                handleFilterChange("expenseCategory", e.target.value)
              }
            >
              <option value="">All Categories</option>
              {expenses?.map((category, index) => (
                <option key={index} value={category.expenseName}>
                  {category.expenseName}
                </option>
              ))}
            </Form.Select>
          </div>

          {/* Min and Max Amount Inputs */}
          <div className="col-md-3 mb-2">
            <InputGroup>
              <Form.Control
                placeholder="Min Amount"
                type="number"
                value={localFilters.minAmount}
                onChange={(e) => handleInputChange("minAmount", e.target.value)}
                autoComplete="off"
              />
              <Form.Control
                placeholder="Max Amount"
                type="number"
                value={localFilters.maxAmount}
                onChange={(e) => handleInputChange("maxAmount", e.target.value)}
                autoComplete="off"
              />
            </InputGroup>
          </div>
        </Row>

        {/* Clear Filters Button */}
        <Row className="mb-3">
          <div className="col-12 d-flex justify-content-end">
            {(localFilters.search ||
              localFilters.minAmount ||
              localFilters.maxAmount ||
              filters.dateRange.startDate ||
              filters.dateRange.endDate ||
              filters.expenseCategory) && (
              <button
                className="jiluil3"
                onClick={clearFilters}
                style={{ marginRight: "10px" }}
              >
                Clear Filters
              </button>
            )}
          </div>
        </Row>
      </>
    );
  };

  // Pagination Component
  const PaginationSection = () => (
    <Row className="justify-content-between align-items-center mt-3">
      <div className="col-auto">
        <Form.Select
          className="w-auto"
          value={itemsPerPage}
          onChange={(e) => {
            setItemsPerPage(Number(e.target.value));
            setCurrentPage(1); // Reset to first page when items per page changes
          }}
        >
          <option value={10}>10 per page</option>
          <option value={20}>20 per page</option>
          <option value={50}>50 per page</option>
        </Form.Select>
      </div>
      <div className="col-auto">
        <Pagination>
          <Pagination.First
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}
          />
          <Pagination.Prev
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          />
          {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
            const pageNum = currentPage - 2 + i;
            if (pageNum > 0 && pageNum <= totalPages) {
              return (
                <Pagination.Item
                  key={pageNum}
                  active={pageNum === currentPage}
                  onClick={() => setCurrentPage(pageNum)}
                >
                  {pageNum}
                </Pagination.Item>
              );
            }
            return null;
          })}
          <Pagination.Next
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages}
          />
          <Pagination.Last
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    </Row>
  );

  // Modify your existing return statement to include new components
  return (
    <Container fluid>
      {/* Keep your existing header */}
      <Row className="headerbanner">
        <div className="bannerbig">Expense Tracker</div>
        <div className="bannersmall">
          Simplify the management of church expenses, providing accurate
          tracking and comprehensive financial oversight for your church.
        </div>
      </Row>

      {/* Add Expense Button */}
      <Row className="courseheader" style={{ marginBottom: "2rem" }}>
        <div className="bnmjo">
          <button
            className="jiluil"
            onClick={handleShow}
            style={{ fontSize: "1em" }}
          >
            Add Expense
          </button>
        </div>
      </Row>

      {/* Add Filter Section */}
      <FilterSection />

      {/* Modified Table Section */}
      <Row style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
        <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
          {displayedExpenses && displayedExpenses.length > 0 ? (
            <>
              <thead>
                <tr className="trdataheader">
                  <th onClick={() => handleSort("postDate")}>
                    Posted Date{" "}
                    {sortConfig.key === "postDate" &&
                      (sortConfig.direction === "asc" ? (
                        <FaCaretUp />
                      ) : (
                        <FaCaretDown />
                      ))}
                  </th>
                  <th>Expense ID</th>
                  <th onClick={() => handleSort("expenseName")}>
                    Expense Name{" "}
                    {sortConfig.key === "expenseName" &&
                      (sortConfig.direction === "asc" ? (
                        <FaCaretUp />
                      ) : (
                        <FaCaretDown />
                      ))}
                  </th>
                  <th onClick={() => handleSort("expenseDescription")}>
                    Description
                  </th>
                  <th onClick={() => handleSort("amount")}>
                    Amount{" "}
                    {sortConfig.key === "amount" &&
                      (sortConfig.direction === "asc" ? (
                        <FaCaretUp />
                      ) : (
                        <FaCaretDown />
                      ))}
                  </th>
                  <th>View Attachment</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {displayedExpenses.map((expense, index) => (
                  <tr key={index} className="trdata">
                    <td>{moment(expense.postDate).format("MMM Do, YYYY")}</td>
                    <td>{expense.expenseId}</td>
                    <td>{expense.expenseName}</td>
                    <td>{expense.expenseDescription}</td>
                    <td>{expense.amount}</td>
                    <td onClick={(e) => handleView(e, expense)}>
                      {expense.documentFile ? (
                        <p
                          style={{
                            color: "var(--primary-color)",
                            marginBottom: "0px",
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          View
                        </p>
                      ) : (
                        <p
                          style={{
                            color: "var(--secondary-color)",
                            marginBottom: "0px",
                          }}
                        >
                          No Attachment
                        </p>
                      )}
                    </td>
                    <td
                      style={{
                        gap: "10px",
                        display: "flex",
                        fontSize: "1em",
                      }}
                    >
                      <button
                        className="jiluil3"
                        onClick={(e) => getExpenseById(e, expense)}
                      >
                        Edit
                      </button>
                      <button
                        className="jiluil"
                        onClick={(e) => handleShow1(e, expense)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </>
          ) : (
            <div className="flex items-center justify-center noData">
              No Expenses Found
            </div>
          )}
        </Table>
      </Row>
      {/* Add Pagination Section */}
      {displayedExpenses && displayedExpenses.length > 0 && (
        <PaginationSection />
      )}

      <AddExpense
        show={show}
        setShow={setShow}
        refreshWorkflows={refreshWorkflows}
      />

      <ViewExpense
        show3={show3}
        setShow3={setShow3}
        selectedDocument={selectedDocument}
        refreshWorkflows={refreshWorkflows}
        handleClose3={() => setShow3(false)}
      />

      <Modal show={show5} onHide={handleClose5} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Update Expense</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Basic Information Section */}
            <h6 className="mb-3">Basic Information</h6>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    Expense Title <span className="myast">*</span>
                  </Form.Label>
                  <Form.Control
                    name="expenseNamex"
                    value={expenseNamex}
                    onChange={(e) => setExpenseNamex(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    Category <span className="myast">*</span>
                  </Form.Label>
                  <Form.Select
                    name="expenseCategoryx"
                    value={expenseCategoryx}
                    onChange={(e) => setExpenseCategoryx(e.target.value)}
                  >
                    <option value="">Select Category</option>
                    {expenses?.map((category, index) => (
                      <option key={index} value={category.expenseName}>
                        {category.expenseName}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    Type <span className="myast">*</span>
                  </Form.Label>
                  <Form.Select
                    name="expenseTypex"
                    value={expenseTypex}
                    onChange={(e) => setExpenseTypex(e.target.value)}
                  >
                    <option value="">Select Type</option>
                    {expenseTypes.map((type, index) => (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    Amount ({user?.currency}) <span className="myast">*</span>
                  </Form.Label>
                  <Form.Control
                    name="amountx"
                    value={amountx}
                    onChange={(e) => setAmountx(e.target.value)}
                    type="text"
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Dates and Payment Section */}
            <h6 className="mb-3 mt-4">Dates & Payment</h6>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    Expense Date <span className="myast">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="expenseDatex"
                    value={expenseDatex}
                    onChange={(e) => setExpenseDatex(e.target.value)}
                    max={moment().format("YYYY-MM-DD")}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Payment Method</Form.Label>
                  <Form.Select
                    name="paymentMethodx"
                    value={paymentMethodx}
                    onChange={(e) => setPaymentMethodx(e.target.value)}
                  >
                    <option value="">Select Method</option>
                    {paymentMethods.map((method, index) => (
                      <option key={index} value={method}>
                        {method}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            {/* Additional Information */}
            <h6 className="mb-3 mt-4">Additional Information</h6>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    Vendor Name <span className="myast">*</span>
                  </Form.Label>
                  <Form.Select
                    name="vendorNamex"
                    value={vendorNamex}
                    onChange={(e) => setVendorNamex(e.target.value)}
                  >
                    <option value="">Select Vendor</option>
                    {vendors?.map((vendor, index) => (
                      <option key={index} value={vendor.vendorName}>
                        {vendor.vendorName}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    Group <span className="myast">*</span>
                  </Form.Label>
                  <Form.Select
                    name="departmentx"
                    value={departmentx}
                    onChange={(e) => setDepartmentx(e.target.value)}
                  >
                    <option value="">Select Group</option>
                    {containers?.map((container, index) => (
                      <option key={index} value={container.name}>
                        {container.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            {/* Description and Notes */}
            <h6 className="mb-3 mt-4">Description & Attachment</h6>
            <Row className="mt-3">
              <Col md={12}>
                <Form.Group>
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    name="notesx"
                    value={notesx}
                    onChange={(e) => setNotesx(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Attachment Section */}
            <Row className="mt-3">
              <Col md={12}>
                <Form.Group>
                  <Form.Label>Attachment</Form.Label>
                  {documentFile ? (
                    <div className="d-flex justify-content-between align-items-center">
                      <span>{documentFile.key || documentFile.name}</span>
                      <button
                        type="button"
                        className="btn btn-link text-danger"
                        onClick={() => setDocumentFile(null)}
                      >
                        Remove
                      </button>
                    </div>
                  ) : (
                    <>
                      <Form.Control
                        type="file"
                        onChange={handleDocumentUploadewefwefwee}
                        accept="application/pdf"
                        className="lpm"
                        disabled={uploading}
                      />
                      <p className="ww233">
                        Note: only PDF files up to 5MB can be uploaded.
                      </p>
                    </>
                  )}
                  {uploading && (
                    <div className="progress mt-2">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${progress}%` }}
                        aria-valuenow={progress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {progress}%
                      </div>
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose5}>
            Close
          </button>
          <button className="jiluil" onClick={handleUpdate}>
            Update
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "1em" }}>Delete Expense</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This action will permanently delete the selected Expense.
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose1}>
            Cancel
          </button>
          <button className="jiluil" onClick={handleDelete}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Expenses;
