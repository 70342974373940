import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Modal, Card, Alert } from "react-bootstrap";
import {
  FaQrcode,
  FaShieldAlt,
  FaUnlock,
  FaLock,
  FaCheck,
} from "react-icons/fa";
import { BiHelpCircle } from "react-icons/bi";
import QRCode from "qrcode.react";
import { useAppContext } from "../../context/appContext";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";
import axios from "axios";
import "./profile.css";

const MFA_STEPS = {
  INITIAL: "initial",
  SETUP: "setup",
  VERIFY: "verify",
  COMPLETE: "complete",
};

const MultiFactorAuth = ({ show, setShow }) => {
  const { user } = useAppContext();
  const [currentStep, setCurrentStep] = useState(MFA_STEPS.INITIAL);
  const [secretURL, setSecretURL] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  // Create auth fetch instance
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  useEffect(() => {
    if (show) {
      checkMFAStatus();
    }
  }, [show]);

  const checkMFAStatus = async () => {
    try {
      setIsLoading(true);
      const response = await authFetch.get("/multifactorauth/status");
      setMfaEnabled(response.data.mfaEnabled);
      setCurrentStep(MFA_STEPS.INITIAL);
    } catch (error) {
      console.error("Error checking MFA status:", error);
      setError("Failed to check MFA status");
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartSetup = async () => {
    try {
      setIsLoading(true);
      setError("");
      const response = await authFetch.get("/multifactorauth/generate-secret");
      setSecretURL(response.data.secretURL);
      setCurrentStep(MFA_STEPS.SETUP);
    } catch (error) {
      console.error("Error generating secret:", error);
      setError("Failed to start MFA setup");
      toast(
        <CustomToastContent
          title="Error"
          message="Failed to generate secret. Please try again."
        />
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    if (!verificationCode.trim()) {
      setError("Please enter verification code");
      return;
    }

    try {
      setIsLoading(true);
      setError("");
      const response = await authFetch.post(
        "/multifactorauth/verify-token-enroll",
        {
          token: verificationCode,
          secretUrl: secretURL,
          email: user.memail,
        }
      );

      setMfaEnabled(true);
      setCurrentStep(MFA_STEPS.COMPLETE);
      toast(
        <CustomToastContent
          title="Success"
          message="2FA has been successfully enabled"
        />
      );
    } catch (error) {
      console.error("Error verifying code:", error);
      setError("Invalid verification code");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnenroll = async () => {
    try {
      setIsLoading(true);
      setError("");
      const response = await authFetch.post("/multifactorauth/unenroll", {
        memail: user.memail,
      });
      setMfaEnabled(false);
      setCurrentStep(MFA_STEPS.INITIAL);
      toast(
        <CustomToastContent title="Success" message={response.data.message} />
      );
    } catch (error) {
      console.error("Error unenrolling from MFA:", error);
      setError("Failed to disable 2FA");
      toast(
        <CustomToastContent
          title="Error"
          message="Error unenrolling. Please try again."
        />
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setShow(false);
    setCurrentStep(MFA_STEPS.INITIAL);
    setVerificationCode("");
    setError("");
  };

  const renderContent = () => {
    switch (currentStep) {
      case MFA_STEPS.INITIAL:
        return (
          <div className="text-center">
            <div className="mb-4">
              {mfaEnabled ? (
                <>
                  <FaCheck className="text-success mb-3" size={40} />
                  <h5>Two-Factor Authentication is Enabled</h5>
                  <p className="text-muted">
                    Your account is protected with 2FA
                  </p>
                </>
              ) : (
                <>
                  <FaShieldAlt className="text-primary mb-3" size={40} />
                  <h5>Enhance Your Account Security</h5>
                  <p className="text-muted">
                    Enable two-factor authentication to add an extra layer of
                    security
                  </p>
                </>
              )}
            </div>
            <div className="d-grid gap-2">
              {mfaEnabled ? (
                <button
                  className="btn btn-danger"
                  onClick={handleUnenroll}
                  disabled={isLoading}
                >
                  <div className="d-flex align-items-center justify-content-center gap-2">
                    <FaUnlock size={18} />
                    <span>Disable 2FA Protection</span>
                  </div>
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  onClick={handleStartSetup}
                  disabled={isLoading}
                >
                  <div className="d-flex align-items-center justify-content-center gap-2">
                    <FaLock size={18} />
                    <span>Enable 2FA Protection</span>
                  </div>
                </button>
              )}
            </div>
          </div>
        );

      case MFA_STEPS.SETUP:
        return (
          <div className="text-center">
            <h5 className="mb-4">Set Up Two-Factor Authentication</h5>
            <ol className="text-start mb-4">
              <li className="mb-2">
                Install an authenticator app like Google Authenticator or Authy
              </li>
              <li className="mb-2">
                Scan the QR code below with your authenticator app
              </li>
              <li>Enter the 6-digit verification code from your app</li>
            </ol>
            <div className="bg-white p-4 d-inline-block mb-4 border rounded">
              <QRCode value={secretURL} size={200} />
            </div>
            <Form onSubmit={handleVerifyCode}>
              <Form.Group className="mb-3">
                <Form.Label>Verification Code</Form.Label>
                <Form.Control
                  type="text"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  placeholder="Enter 6-digit code"
                  maxLength={6}
                  pattern="\d*"
                  required
                />
              </Form.Group>
              <button
                type="submit"
                className="btn btn-primary w-100"
                disabled={isLoading}
              >
                Verify Code
              </button>
            </Form>
          </div>
        );

      case MFA_STEPS.COMPLETE:
        return (
          <div className="text-center">
            <FaCheck className="text-success mb-3" size={40} />
            <h5>Two-Factor Authentication Enabled</h5>
            <p className="text-muted">Your account is now protected with 2FA</p>
            <button className="btn btn-primary" onClick={handleClose}>
              Done
            </button>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      centered
      className="modern-mfa-modal"
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title>
          <div className="d-flex align-items-center">
            <FaShieldAlt className="me-2 text-primary" size={24} />
            <span>Two-Factor Authentication</span>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Container fluid>
          <Card className="border-0 shadow-sm">
            <Card.Body className="p-4">
              {error && (
                <Alert variant="danger" className="mb-4">
                  {error}
                </Alert>
              )}
              {renderContent()}
            </Card.Body>
          </Card>

          <div className="text-center mt-4">
            <p className="text-muted">
              <BiHelpCircle className="me-1" size={20} />
              Need help? Check our{" "}
              <a
                href="https://churchease.com/knowledgebaseview/66716941c206720057060802"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary text-decoration-none"
              >
                knowledge base
              </a>
            </p>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default MultiFactorAuth;
