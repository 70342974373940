import React, { useState, useEffect } from "react";
import { Modal, Button, Table, Form, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

const UnassignedZonesModal = ({
  show,
  onHide,
  authFetch,
  onAssign,
  existingZones,
}) => {
  const [unassignedCodes, setUnassignedCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedCodes, setSelectedCodes] = useState([]);

  useEffect(() => {
    if (show) {
      fetchUnassignedCodes();
    }
  }, [show]);

  const fetchUnassignedCodes = async () => {
    try {
      setLoading(true);
      const response = await authFetch.get("/zoning/unassigned");
      const formattedCodes = Object.entries(response.data).map(
        ([code, count]) => ({
          code,
          memberCount: count,
        })
      );
      setUnassignedCodes(formattedCodes);
    } catch (error) {
      console.error("Failed to fetch unassigned codes:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAssign = async () => {
    if (!selectedZone || selectedCodes.length === 0) return;

    try {
      await authFetch.put(`/zoning/${selectedZone}/add-codes`, {
        postalCodes: selectedCodes,
      });

      onAssign(); // Refresh parent component
      toast.success("Postal codes assigned successfully");
      setSelectedCodes([]);
      fetchUnassignedCodes(); // Refresh unassigned codes
    } catch (error) {
      toast.error("Failed to assign postal codes");
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Unassigned Postal Codes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="text-center p-4">
            <FontAwesomeIcon icon={faSpinner} spin /> Loading...
          </div>
        ) : (
          <>
            {unassignedCodes.length > 0 ? (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Assign to Zone</Form.Label>
                  <Form.Select
                    value={selectedZone}
                    onChange={(e) => setSelectedZone(e.target.value)}
                  >
                    <option value="">Select Zone</option>
                    {existingZones.map((zone) => (
                      <option key={zone._id} value={zone._id}>
                        {zone.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Table responsive>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedCodes(
                                unassignedCodes.map((c) => c.code)
                              );
                            } else {
                              setSelectedCodes([]);
                            }
                          }}
                        />
                      </th>
                      <th>Postal Code</th>
                      <th>Member Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {unassignedCodes.map(({ code, memberCount }) => (
                      <tr key={code}>
                        <td>
                          <Form.Check
                            type="checkbox"
                            checked={selectedCodes.includes(code)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedCodes([...selectedCodes, code]);
                              } else {
                                setSelectedCodes(
                                  selectedCodes.filter((c) => c !== code)
                                );
                              }
                            }}
                          />
                        </td>
                        <td>{code}</td>
                        <td>{memberCount}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            ) : (
              <Alert variant="info">No unassigned postal codes found.</Alert>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleAssign}
          disabled={!selectedZone || selectedCodes.length === 0}
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          Assign to Zone
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UnassignedZonesModal;
