import React, { useState, useEffect, Children } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useAppContext } from "../../../context/appContext";
import axios from "axios";
import "./children.css";
import Loading from "../../../components/Loading/Loading";
import nodd from "../../../assets/newi/nodd.png";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { FaFile, FaFileAudio, FaVideo } from "react-icons/fa";
import AddContent from "../../../components/ChildrenLibrary/AddContent";
import { Modal } from "react-bootstrap";
import ShareContent from "../../../components/ChildrenLibrary/ShareContent";
import EditContent from "../../../components/ChildrenLibrary/EditContent";
import ViewContent from "../../../components/ChildrenLibrary/ViewContent";

const ChildrenLibrary = () => {
  const { logoutUser } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  useEffect(() => {
    loadCourse();
  }, []);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleClose2 = () => setShow2(false);

  const [selectedValue, setSelectedValue] = useState(null);
  const [resourceData, setResourceData] = useState([]);
  const [clicked, setClicked] = useState("");
  const [selectedContent, setSelectedContent] = useState([]);
  const [show1, setShow1] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    try {
      await authFetch.delete(`/children/resource/${clicked._id}`);

      handleClose1();
      loadCourse();
    } catch (error) {
      console.error("Error deleting classroom:", error);
    }
  };

  const loadCourse = async () => {
    try {
      setIsLoading(true); // Set loading state to true before fetching data
      const { data } = await authFetch.get("/children/resource");
      setResourceData(data);
    } catch (error) {
      console.error("Error loading course resources:", error);
      // Optionally, set an error state here to display an error message to the user
      setError("Failed to load resources. Please try again later.");
    } finally {
      setIsLoading(false); // Reset loading state after fetching data or if an error occurs
    }
  };

  const handleReload = () => {
    loadCourse();
  };

  const addContent = (id) => {
    setShow(true);
  };

  const handleShow1 = (e, fundss) => {
    e.preventDefault();
    setShow1(true);
    setClicked(fundss);
  };

  const handleShow2 = (event, saved) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedValue(saved);
    setShow2(true);
  };

  const handleShow3 = (e, resource) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedContent(resource);
    setShow3(true);
  };

  const handleShow4 = (e, resource) => {
    e.preventDefault();
    e.stopPropagation();

    setSelectedContent(resource);
    setShow4(true);
  };

  return (
    <>
      <Container fluid className="maindigital">
        <Row className="courseheader course-header">
          <h1>Children Library</h1>
        </Row>

        <Row className="sssssssecwef mt-5">
          <div className="bnmjo">
            <button className="jiluil" onClick={addContent}>
              Upload Content
            </button>
          </div>
        </Row>
        {isLoading ? (
          <Loading />
        ) : resourceData && resourceData.length > 0 ? (
          <Row className="fundc2">
            <Table responsive hover style={{ backgroundColor: "#f7f7f7" }}>
              <thead>
                <tr>
                  <th style={{ fontSize: "1em", width: "15%" }}>TITLE</th>
                  <th style={{ fontSize: "1em", width: "45%" }}>DESCRIPTION</th>
                  <th style={{ fontSize: "1em", width: "10%" }}>TYPE</th>
                  <th style={{ fontSize: "1em", width: "10%" }}>TAG</th>

                  <th style={{ fontSize: "1em", width: "20%" }}>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {resourceData?.map((resource) => (
                  <tr key={resource._id}>
                    <td style={{ fontSize: "1em" }}>{resource.title}</td>
                    <td style={{ fontSize: "1em" }}>{resource.description}</td>
                    <td style={{ fontSize: "1em" }}>
                      {resource.type.charAt(0).toUpperCase() +
                        resource.type.slice(1)}
                    </td>
                    <td style={{ fontSize: "1em" }}>
                      {resource.tags && resource.tags.length > 0
                        ? resource.tags.join(", ")
                        : "No Tag"}
                    </td>

                    <td style={{ fontSize: "1em", whiteSpace: "nowrap" }}>
                      {" "}
                      {/* Prevent wrapping */}
                      <button
                        className="jiluil4"
                        style={{ marginRight: "10px", display: "inline-block" }} // Ensure display as inline-block
                        onClick={(e) => handleShow4(e, resource)}
                      >
                        Preview
                      </button>
                      <button
                        className="jiluil3"
                        style={{ marginRight: "10px", display: "inline-block" }} // Ensure display as inline-block
                        onClick={(e) => handleShow2(e, resource)}
                      >
                        Share
                      </button>
                      <button
                        className="jiluil"
                        style={{ marginRight: "10px", display: "inline-block" }} // Ensure display as inline-block
                        onClick={(e) => handleShow3(e, resource)}
                      >
                        Edit
                      </button>
                      <button
                        className="jiluil344d"
                        style={{ display: "inline-block" }} // Ensure display as inline-block
                        onClick={(e) => handleShow1(e, resource)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        ) : (
          <div className="noData">
            <img src={nodd} alt="no data" />
            <p className="noDataBackup">No data available for display.</p>
          </div>
        )}
      </Container>
      <AddContent show={show} setShow={setShow} handleReload={handleReload} />
      <ShareContent
        show={show2}
        setShow={setShow2}
        data={clicked}
        selectedValue={selectedValue}
      />
      <ViewContent show={show4} setShow={setShow4} data={selectedContent} />
      <EditContent
        show={show3}
        setShow={setShow3}
        data={selectedContent}
        handleReload={loadCourse}
      />
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "1em" }}>Delete Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This action will permanently delete the selected resource.
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil" onClick={handleClose1}>
            Cancel
          </button>
          <button className="jiluil3" onClick={handleDelete}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChildrenLibrary;
