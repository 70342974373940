import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import blankImage from "../../assets/images/blank2.png";
import "react-quill/dist/quill.snow.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../context/appContext";
import Alert from "../../components/Alert/Alert";
import { SiQuickbooks, SiMailchimp } from "react-icons/si";
import {
  FaPalette,
  FaUpload,
  FaImage,
  FaTrash,
  FaCheck,
  FaTimes,
} from "react-icons/fa";
import axios from "axios";
import "./profile.css";
import { useNavigate } from "react-router-dom";
import { ChromePicker } from "react-color";
import Badge from "react-bootstrap/Badge";
import Image from "react-bootstrap/Image";
import Profilez from "../../assets/images/blank2.png";
import Resizer from "react-image-file-resizer";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";

const Customize = ({ show, setShow }) => {
  const navigate = useNavigate();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const {
    showAlert,
    user,
    logoutUser,
    updateColor,
    primaryColor,
    secondaryColor,
    accentColor,
    churchLogo,
    updateUser,
  } = useAppContext();

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [displayColorPicker2, setDisplayColorPicker2] = useState(false);
  const [displayColorPicker3, setDisplayColorPicker3] = useState(false);
  const [primary, setPrimary] = useState(user.primaryColor);
  const [secondary, setSecondary] = useState(user.secondaryColor);
  const [accent, setAccent] = useState(user.accentColor);
  const [uploadButtonTextLogo, setUploadButtonTextLogo] =
    useState("Upload Image");
  const [previewLogo, setPreviewLogo] = useState("");
  const [imageLogo, setImageLogo] = useState(user.churchLogo);

  useEffect(() => {
    setPrimary(user.primaryColor);
    setSecondary(user.secondaryColor);
    setAccent(user.accentColor);
  }, [user.primaryColor, user.secondaryColor, user.accentColor]);

  // All your existing handlers remain exactly the same
  const handleClick = () => setDisplayColorPicker(!displayColorPicker);
  const handleClick2 = () => setDisplayColorPicker2(!displayColorPicker2);
  const handleClick3 = () => setDisplayColorPicker3(!displayColorPicker3);
  const handleClose = () => setDisplayColorPicker(false);
  const handleClose2 = () => setDisplayColorPicker2(false);
  const handleClose3 = () => setDisplayColorPicker3(false);

  // All color change handlers remain the same
  const handleChange = async (color, type) => {
    const newColor = color.hex;
    if (type === "primary") {
      setPrimary(newColor);
    }
    document.documentElement.style.setProperty(`--primary-color`, newColor);
  };

  const handleChange2 = async (color, type) => {
    const newColor = color.hex;
    if (type === "secondary") {
      setSecondary(newColor);
    }
    document.documentElement.style.setProperty(`--secondary-color`, newColor);
  };

  const handleChange3 = async (color, type) => {
    const newColor = color.hex;
    if (type === "accent") {
      setAccent(newColor);
    }
    document.documentElement.style.setProperty(`--accent-color`, newColor);
  };

  // All your existing update handlers remain the same
  const handleUpdate = async () => {
    const data = {
      userId: user._id,
      colorType: "primaryColor",
      colorValue: primary,
    };
    updateColor({ data });
    handleClose();
  };

  const handleUpdate2 = async () => {
    const data = {
      userId: user._id,
      colorType: "secondaryColor",
      colorValue: secondary,
    };
    updateColor({ data });
    handleClose2();
  };

  const handleUpdate3 = async () => {
    const data = {
      userId: user._id,
      colorType: "accentColor",
      colorValue: accent,
    };
    updateColor({ data });
    handleClose3();
  };

  // All image handlers remain exactly the same
  const handleImageLogo = (e) => {
    let file = e.target.files[0];
    setPreviewLogo(window.URL.createObjectURL(file));
    setUploadButtonTextLogo(file.name);
    Resizer.imageFileResizer(file, 520, 300, "JPEG", 100, 0, async (uri) => {
      try {
        let { data } = await authFetch.post("/members/logo/uploadImageLogo", {
          image: uri,
        });
        setImageLogo(data);
      } catch (err) {}
    });
  };

  const handleImageRemoveLogo = async () => {
    try {
      // Convert blankImage to a file object
      const response = await fetch(blankImage);
      const blob = await response.blob();
      const file = new File([blob], "blank.png", { type: "image/png" });

      // Resize blankImage
      Resizer.imageFileResizer(
        file,
        520,
        300,
        "JPEG",
        100,
        0,
        async (uri) => {
          try {
            // Send both the current imageLogo and the resized blankImage to the backend
            await authFetch.post("/members/remove-image-logo", {
              imageLogo,
              blankImage: uri,
            });

            // Set the default blank image as the new logo
            setImageLogo(uri);
            setPreviewLogo(""); // Clear the preview

            // Update the user context with the blank image
            const currentUser = {
              churchName: user.churchName,
              churchLogo: uri,
            };
            await updateUser({ currentUser });

            toast(
              <CustomToastContent
                title="Success"
                message="Operation Successful!"
              />
            );
          } catch (err) {
            console.error("Error in handleImageRemoveLogo function:", err);
            toast(
              <CustomToastContent title="Error" message="Operation Failed!" />
            );
          }
        },
        "base64"
      );
    } catch (err) {
      console.error("Error converting blank image:", err);
    }
  };

  const uploadImage = (e) => {
    e.preventDefault();
    const currentUser = {
      churchName: user.churchName,
      churchLogo: imageLogo,
    };

    updateUser({ currentUser });
    toast(
      <CustomToastContent title="Success" message="Operation Successful!" />
    );
  };

  const [fullscreen, setFullscreen] = useState(true);
  const handleClosex = () => setShow(false);

  return (
    <Modal show={show} fullscreen={fullscreen} onHide={handleClosex}>
      <Modal.Header closeButton className="border-0 shadow-sm">
        <Modal.Title className="text-xl font-semibold">
          Church Branding
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-gray-50">
        <Container fluid>
          {showAlert && <Alert />}

          <div className="max-w-7xl mx-auto py-8">
            <Row className="mb-8">
              <Col xs={12}>
                <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">
                  Church Identity
                </h2>
                <Row className="g-4">
                  <Col md={4}>
                    <div className="bg-white rounded-lg shadow-sm p-4 h-100">
                      <div
                        style={{
                          backgroundColor: primary,
                          borderRadius: "8px",
                          height: "100px",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                        className="p-6 mb-4 text-center"
                      >
                        <p className="font-semibold text-white mb-1">
                          Primary Color
                        </p>
                        <p className="text-white opacity-90 text-sm">
                          {primary}
                        </p>
                      </div>
                      <div className="flex gap-3 justify-center">
                        <button className="jiluil3" onClick={handleClick}>
                          <FaPalette /> {""}
                          Select
                        </button>
                        {displayColorPicker && (
                          <div className="color-picker-popover">
                            <div
                              className="color-picker-cover"
                              onClick={handleClose}
                            />
                            <ChromePicker
                              color={primary}
                              onChangeComplete={(color) =>
                                handleChange(color, "primary")
                              }
                            />
                          </div>
                        )}
                        <button
                          onClick={handleUpdate}
                          className="jiluil gap-2"
                          style={{ backgroundColor: primary }}
                        >
                          <FaCheck /> Apply
                        </button>
                      </div>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="bg-white rounded-lg shadow-sm p-4 h-100">
                      <div
                        style={{
                          backgroundColor: secondary,
                          borderRadius: "8px",
                          height: "100px",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                        className="p-6 mb-4 text-center"
                      >
                        <p className="font-semibold text-white mb-1">
                          Secondary Color
                        </p>
                        <p className="text-white opacity-90 text-sm">
                          {secondary}
                        </p>
                      </div>
                      <div className="flex gap-3 justify-center">
                        <button
                          className="jiluil3 gap-2"
                          onClick={handleClick2}
                        >
                          <FaPalette /> Select
                        </button>
                        {displayColorPicker2 && (
                          <div className="color-picker-popover">
                            <div
                              className="color-picker-cover"
                              onClick={handleClose2}
                            />
                            <ChromePicker
                              color={secondary}
                              onChangeComplete={(color) =>
                                handleChange2(color, "secondary")
                              }
                            />
                          </div>
                        )}
                        <button
                          onClick={handleUpdate2}
                          className="jiluil gap-2"
                          style={{ backgroundColor: secondary }}
                        >
                          <FaCheck /> Apply
                        </button>
                      </div>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="bg-white rounded-lg shadow-sm p-4 h-100">
                      <div
                        style={{
                          backgroundColor: accent,
                          borderRadius: "8px",
                          height: "100px",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                        className="p-6 mb-4 text-center"
                      >
                        <p className="font-semibold text-white mb-1">
                          Accent Color
                        </p>
                        <p className="text-white opacity-90 text-sm">
                          {accent}
                        </p>
                      </div>
                      <div className="flex gap-3 justify-center">
                        <button
                          className="jiluil3 gap-2"
                          onClick={handleClick3}
                        >
                          <FaPalette /> Select
                        </button>
                        {displayColorPicker3 && (
                          <div className="color-picker-popover">
                            <div
                              className="color-picker-cover"
                              onClick={handleClose3}
                            />
                            <ChromePicker
                              color={accent}
                              onChangeComplete={(color) =>
                                handleChange3(color, "accent")
                              }
                            />
                          </div>
                        )}
                        <button
                          onClick={handleUpdate3}
                          className="jiluil gap-2"
                          style={{ backgroundColor: accent }}
                        >
                          <FaCheck /> Apply
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <hr className="my-8" />

            <Row className="fundc flex items-center justify-center mb-5">
              <Col xs={12}>
                <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">
                  Church Logo
                </h2>
              </Col>
              <Col md={6} className="text-center">
                <div className="bg-white rounded-lg shadow-sm p-6">
                  <Image
                    style={{
                      maxWidth: "100%",
                      maxHeight: "150px",
                      objectFit: "contain",
                      width: "auto",
                      height: "auto",
                    }}
                    src={user?.churchLogo?.Location || Profilez}
                    fluid
                    className="mb-4 mx-auto"
                  />
                  <div className="flex gap-3 justify-center pb-5">
                    <Form.Group className="flex justify-center">
                      <Form.Label
                        htmlFor="imageLogoInput"
                        className="btn-custom-outline mb-0 cursor-pointer flex items-center gap-2"
                      >
                        <FaUpload />
                        Select Logo
                      </Form.Label>
                      <Form.Control
                        type="file"
                        id="imageLogoInput"
                        name="imageLogo"
                        onChange={handleImageLogo}
                        accept="image/*"
                        hidden
                      />
                    </Form.Group>
                    <button
                      className="jiluil gap-2"
                      onClick={handleImageRemoveLogo}
                    >
                      <FaTrash /> Remove
                    </button>
                  </div>
                </div>
              </Col>

              {previewLogo && (
                <Col md={6}>
                  <div className="bg-white rounded-lg shadow-sm p-6 text-center">
                    <h3 className="text-lg font-semibold mb-4">Preview Logo</h3>
                    <div className="logo-preview-container bg-gray-50 p-4 rounded-lg mb-4">
                      <Image
                        width="auto"
                        height={150}
                        src={previewLogo}
                        className="mb-4 mx-auto object-contain"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "150px",
                          borderRadius: "4px",
                        }}
                      />
                    </div>
                    <div className="flex gap-3 justify-center">
                      <button className="jiluil3" onClick={uploadImage}>
                        <FaCheck /> Update
                      </button>
                      <button
                        className="jiluil344d flex items-center gap-2"
                        onClick={handleImageRemoveLogo}
                      >
                        <FaTimes /> Cancel
                      </button>
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </Container>
      </Modal.Body>
      <Modal.Footer className="border-0 shadow-sm">
        <button className="jiluil" onClick={handleClosex}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Customize;
