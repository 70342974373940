import React, { useEffect, useState } from "react";
import { useAppContext } from "../../context/appContext";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Table,
  Form,
  Button,
  Modal,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { Avatar } from "antd";
import {
  FaEye,
  FaColumns,
  FaSync,
  FaCheck,
  FaTimes,
  FaChevronRight,
} from "react-icons/fa";
import { SiMailchimp } from "react-icons/si";
import moment from "moment";
import Loading from "../Loading/Loading";
import Alert from "../Alert/Alert";
import PageBtn from "../PageButton/PageBtn-old";
import PeopleContact from "../People/PeopleContact";

import axios from "axios";

// Column configuration for better management
const COLUMN_CONFIG = [
  {
    key: "photo",
    label: "PHOTO",
    field: "mProfilePicture",
    defaultVisible: true,
  },
  {
    key: "firstName",
    label: "FIRST NAME",
    field: "mfirstName",
    defaultVisible: true,
  },
  {
    key: "lastName",
    label: "LAST NAME",
    field: "mlastName",
    defaultVisible: true,
  },
  {
    key: "preferredName",
    label: "PREFERRED NAME",
    field: "preferredName",
    defaultVisible: true,
  },
  {
    key: "email",
    label: "EMAIL ADDRESS",
    field: "memail",
    defaultVisible: true,
  },
  {
    key: "mobile",
    label: "MOBILE",
    field: "mmobilePhone",
    defaultVisible: true,
  },
  { key: "gender", label: "GENDER", field: "mgender", defaultVisible: true },
  { key: "campus", label: "CAMPUS", field: "campus", defaultVisible: false },
  { key: "status", label: "STATUS", field: "mstatus", defaultVisible: false },
  {
    key: "accountStatus",
    label: "ACCOUNT STATUS",
    field: "accountStatus",
    defaultVisible: true,
  },
  {
    key: "marital",
    label: "MARITAL STATUS",
    field: "mmarital",
    defaultVisible: false,
  },
  {
    key: "birthDate",
    label: "BIRTH DATE",
    field: "mage",
    defaultVisible: false,
  },
  // Add other columns...
];

const PeopleContainer = () => {
  const navigate = useNavigate();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const {
    getAllMembers,
    logoutUser,
    members, // Only paginated members are required now
    isLoading,
    page,
    user,
    numOfPages,
    deleteMember,
    showAlert,
    sort,
    search,
    searchAge,
    searchGender,
    searchBirthMonth,
    searchAnniversaryMonth,
    searchAccountStatus,
    searchStatus,
    searchCampus,
    searchMarital,
    setPage,
    setRowsPerPage,
    rowsPerPage,
    totalMembers,
  } = useAppContext();

  useEffect(() => {
    getAllMembers();
  }, [
    page, // Pagination
    rowsPerPage, // Items per page
    search, // General search term
    searchAge, // Filter by age
    searchGender, // Filter by gender
    searchMarital, // Filter by marital status
    searchStatus, // Filter by member status
    searchCampus, // Filter by campus
    searchBirthMonth, // Filter by birth month
    searchAnniversaryMonth, // Filter by anniversary month
    sort, // Sorting order
  ]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
    // You might want to scroll to top when page changes
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // State Management
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const [showColumnSettings, setShowColumnSettings] = useState(false);
  const [enrollStatus, setEnrollStatus] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState(
    COLUMN_CONFIG.reduce(
      (acc, col) => ({
        ...acc,
        [col.key]: col.defaultVisible,
      }),
      {}
    )
  );
  const [syncStatus, setSyncStatus] = useState({
    lastSync: null,
    count: 0,
    isLoading: false,
  });

  const syncMembersToMailchimp = async () => {
    try {
      const response = await authFetch.patch("/mailchimp/sync-members");

      if (response?.data?.message === "Sync Successful") {
        setEnrollStatus(true);
      }
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const [syncLogData, setSyncLogData] = useState(null);

  useEffect(() => {
    fetchSyncLog();
  }, []);

  const fetchSyncLog = async () => {
    try {
      const response = await authFetch("/mailchimp/sync-log");
      const data = await response?.data;
      setSyncLogData(data);
    } catch (error) {
      console.error("Error fetching sync log:", error);
    }
  };

  useEffect(() => {
    // Clear selections when members list changes due to filtering
    setSelectedMembers((prev) => {
      // Keep only selections that still exist in filtered results
      return prev.filter((selected) =>
        members.some((member) => member._id === selected._id)
      );
    });
  }, [members]); // Depend on members list changes

  // Initialize column visibility from user preferences
  useEffect(() => {
    const fetchVisibility = async () => {
      try {
        const { data } = await authFetch(`/members/visibility/${user._id}`);
        if (data) {
          setVisibleColumns((prev) => ({
            ...prev,
            ...data,
          }));
        }
      } catch (error) {
        console.error("Error fetching visibility:", error);
      }
    };
    fetchVisibility();
  }, [user._id]);

  // Selection Handlers
  const handleSelectAll = (e) => {
    const { checked } = e.target;
    if (checked) {
      // Only select members visible on current page
      setSelectedMembers(members);
    } else {
      // Deselect all
      setSelectedMembers([]);
    }
  };

  const handleSelectMember = (e, member) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedMembers((prev) => [...prev, member]);
    } else {
      setSelectedMembers((prev) => prev.filter((m) => m._id !== member._id));
    }
  };

  // Column Visibility Handler
  const handleColumnToggle = async (columnKey) => {
    const newVisibility = {
      ...visibleColumns,
      [columnKey]: !visibleColumns[columnKey],
    };
    setVisibleColumns(newVisibility);

    try {
      await authFetch.patch(`/members/visibility/${user._id}`, newVisibility);
    } catch (error) {
      console.error("Failed to update column visibility:", error);
    }
  };

  // Table Cell Component
  const TableCell = ({ member, columnKey }) => {
    const config = COLUMN_CONFIG.find((col) => col.key === columnKey);
    if (!config) return null;

    const value = member[config.field];

    if (columnKey === "photo") {
      return (
        <td>
          <Avatar
            src={value?.Location || require("../../assets/images/blank2.png")}
            size={30}
          />
        </td>
      );
    }

    const displayValue = value || "NA";
    const isClickable = ["firstName", "lastName"].includes(columnKey);

    return (
      <td
        onClick={isClickable ? (e) => handleRowClick(e, member._id) : undefined}
        style={{ cursor: isClickable ? "pointer" : "default" }}
      >
        {displayValue}
      </td>
    );
  };

  const handleRowClick = (e, memberId) => {
    e.stopPropagation();
    navigate(`/auth/peopleview/${memberId}`);
  };

  return (
    <Container fluid className="pb-5">
      {/* Header Section */}
      <Row className="mb-4 align-items-center">
        <Col>
          <div className="d-flex align-items-center gap-3">
            <h6 className="mb-0">
              {isLoading
                ? "Loading..."
                : `${members.length} member${
                    members.length !== 1 ? "s" : ""
                  } found`}
            </h6>
            <button
              size="sm"
              onClick={() => setShowColumnSettings(true)}
              className="jiluil3 d-flex align-items-center gap-2"
            >
              <FaColumns /> Column Settings
            </button>
          </div>
        </Col>
        <Col xs="auto">
          {syncStatus.lastSync && (
            <div className="text-end">
              <Button
                variant="outline-success"
                size="sm"
                onClick={syncMembersToMailchimp}
                disabled={syncStatus.isLoading}
              >
                <FaSync className={syncStatus.isLoading ? "spin" : ""} />
                Sync with Mailchimp
              </Button>
              <div className="text-muted small">
                Last synced: {moment(syncStatus.lastSync).fromNow()}
              </div>
            </div>
          )}
        </Col>
      </Row>

      {/* Main Table */}
      <Row>
        <Col lg={9}>
          <div className="table-responsive">
            <Table hover className="align-middle">
              <thead>
                <tr>
                  <th style={{ width: "40px" }}>
                    <Form.Check
                      onChange={handleSelectAll}
                      checked={selectedMembers.length === members.length}
                    />
                  </th>
                  <th style={{ width: "40px" }}>SYNCED</th>
                  {COLUMN_CONFIG.map(
                    (col) =>
                      visibleColumns[col.key] && (
                        <th key={col.key}>{col.label}</th>
                      )
                  )}
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan={100}>
                      <div className="text-center py-4">
                        <Loading />
                        <div>Loading members...</div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  members.map((member) => (
                    <tr key={member._id}>
                      <td>
                        <Form.Check
                          checked={selectedMembers.some(
                            (m) => m._id === member._id
                          )}
                          onChange={(e) => handleSelectMember(e, member)}
                        />
                      </td>
                      <td>
                        {member.synced && (
                          <SiMailchimp className="text-success" />
                        )}
                      </td>
                      {COLUMN_CONFIG.map(
                        (col) =>
                          visibleColumns[col.key] && (
                            <TableCell
                              key={col.key}
                              member={member}
                              columnKey={col.key}
                            />
                          )
                      )}
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div>
        </Col>
        <Col lg={3}>
          <PeopleContact
            isChecked={selectedMembers} // Changed from selectedMembers to match your component
            setIsChecked={setSelectedMembers} // Changed from onClearSelection to match your component
            onLoadingChange={setShowLoadingModal} // Added to handle loading states
            members={members} // Added to pass the members prop
            getAllMembers={getAllMembers}
          />
        </Col>
      </Row>

      {/* Pagination */}
      <Row className="mt-4 align-items-center">
        <Col xs="auto">
          <Form.Select
            value={rowsPerPage}
            onChange={(e) => {
              setRowsPerPage(Number(e.target.value));
              setPage(1);
            }}
            className="w-auto"
          >
            {[20, 40, 80, 100].map((value) => (
              <option key={value} value={value}>
                {value} per page
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col className="text-center">
          {numOfPages > 1 && (
            <PageBtn
              currentPage={page}
              totalPages={numOfPages}
              handlePageChange={setPage}
            />
          )}
        </Col>
        <Col xs="auto">
          <span className="text-muted">
            Showing {(page - 1) * rowsPerPage + 1} to{" "}
            {Math.min(page * rowsPerPage, totalMembers)} of {totalMembers}{" "}
            entries
          </span>
        </Col>
      </Row>

      {/* Column Settings Modal */}
      <Modal
        show={showColumnSettings}
        onHide={() => setShowColumnSettings(false)}
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>Column Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column gap-2">
            {COLUMN_CONFIG.map((col) => (
              <div
                key={col.key}
                className="d-flex align-items-center justify-content-between p-2 border-bottom"
              >
                <span>{col.label}</span>
                <Form.Check
                  type="switch"
                  checked={visibleColumns[col.key]}
                  onChange={() => handleColumnToggle(col.key)}
                />
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default PeopleContainer;
