// Month.js
import React from "react";
import Day from "./Day";
import dayjs from "dayjs";
import "./calendar.css";

export default function Month({ month, events }) {
  const weekDays = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  return (
    <div className="calendar-container">
      <div className="calendar-grid">
        <div className="weekdays-row">
          {weekDays.map((day) => (
            <div key={day} className="weekday-cell">
              {day}
            </div>
          ))}
        </div>

        {month.map((row, i) => (
          <div key={i} className="calendar-row">
            {row.map((day, idx) => (
              <Day
                key={day.valueOf()}
                day={day}
                events={events?.filter(
                  (evt) =>
                    dayjs(evt?.eventDay).format("YYYY-MM-DD") ===
                    day.format("YYYY-MM-DD")
                )}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
