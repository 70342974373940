import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Table,
  Row,
  Col,
  Alert,
  Card,
} from "react-bootstrap";
import axios from "axios";
import { useAppContext } from "../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faExclamationTriangle,
  faCheckCircle,
  faDatabase,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

const AutoGenerateZonesModal = ({ show, onHide, onSave }) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const { logoutUser } = useAppContext();
  const [radius, setRadius] = useState(5);
  const [generatedZones, setGeneratedZones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [usageStats, setUsageStats] = useState(null);
  const [error, setError] = useState(null);
  const [showPendingModal, setShowPendingModal] = useState(false);
  const [pendingPostalCodes, setPendingPostalCodes] = useState([]);

  // Fetch usage stats
  useEffect(() => {
    if (show) {
      fetchUsageStats();
    }
  }, [show]);

  const fetchUsageStats = async () => {
    try {
      const response = await authFetch.get("/zoning/usage-stats");
      setUsageStats(response.data);
    } catch (error) {
      console.error("Failed to fetch usage stats:", error);
    }
  };

  const generateZones = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await authFetch.post("/zoning/generate", { radius });

      if (response.data.error === "LIMIT_EXCEEDED") {
        setError(
          "Monthly generation limit reached. Please try again next month."
        );
        return;
      }

      const zones = response.data.zones.map((zone, index) => ({
        ...zone,
        name: `Zone ${index + 1}`,
        isActive: true,
      }));

      setGeneratedZones(zones);
      fetchUsageStats(); // Refresh usage stats
    } catch (error) {
      setError("Failed to generate zones. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSaveGeneratedZones = async (generatedZones) => {
    try {
      const response = await authFetch.post("/zoning/save-zones", {
        zones: generatedZones,
      });

      const { newZones, updatedZones, pendingAssignment } = response.data;

      // Show success message with details
      toast.success(
        `Created ${newZones.length} new zones, Updated ${updatedZones.length} existing zones`
      );
      onSave();
      //   /If there are postal codes needing assignment, show them
      if (pendingAssignment.length > 0) {
        setPendingPostalCodes(pendingAssignment);
        setShowPendingModal(true);
      }

      onHide();
    } catch (error) {
      toast.error("Failed to save zones");
    }
  };

  const PendingAssignmentModal = ({ show, onHide, pendingPostalCodes }) => {
    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Postal Codes Needing Assignment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            The following postal codes have single members and need manual
            assignment:
          </p>
          <Table responsive>
            <thead>
              <tr>
                <th>Postal Code</th>
                <th>Member Count</th>
              </tr>
            </thead>
            <tbody>
              {pendingPostalCodes.map((item, index) => (
                <tr key={index}>
                  <td>{item.postalCode}</td>
                  <td>{item.memberCount}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <p className="text-muted">
            You can assign these postal codes to existing zones from the Zone
            Management page.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const UsageStatsCard = () => (
    <Card className="mb-3">
      <Card.Body>
        <Row>
          <Col md={4}>
            <div className="text-center">
              <h6>Generations Left</h6>
              <h3>{usageStats ? 10 - usageStats.currentMonth : "--"}/10</h3>
            </div>
          </Col>
          <Col md={4}>
            <div className="text-center">
              <h6>Cached Postal Codes</h6>
              <h3>{usageStats?.cachedCodes || "--"}</h3>
            </div>
          </Col>
          <Col md={4}>
            <div className="text-center">
              <h6>Cache Hit Rate</h6>
              <h3>{usageStats?.cacheHitRate || "--"}%</h3>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );

  return (
    <>
      <Modal show={show} onHide={onHide} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Auto Generate Zones</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {usageStats && <UsageStatsCard />}

          {error && (
            <Alert variant="danger" className="mb-3">
              <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
              {error}
            </Alert>
          )}

          <Row className="mb-4">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Radius (km): {radius}</Form.Label>
                <Form.Range
                  value={radius}
                  onChange={(e) => setRadius(Number(e.target.value))}
                  min={1}
                  max={20}
                  step={1}
                  disabled={loading}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="d-flex align-items-end justify-content-end">
              <Button
                variant="primary"
                onClick={generateZones}
                disabled={loading || usageStats?.currentMonth >= 10}
              >
                {loading ? (
                  <>
                    <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                    Generating...
                  </>
                ) : (
                  "Generate Zones"
                )}
              </Button>
            </Col>
          </Row>

          {generatedZones.length > 0 && (
            <Table responsive>
              <thead>
                <tr>
                  <th>Zone Name</th>
                  <th>Central Postal Code</th>
                  <th>Included Postal Codes</th>
                  <th>Member Count</th>
                </tr>
              </thead>
              <tbody>
                {generatedZones.map((zone, index) => (
                  <tr key={index}>
                    <td>
                      <Form.Control
                        type="text"
                        value={zone.name}
                        onChange={(e) => {
                          const updatedZones = [...generatedZones];
                          updatedZones[index].name = e.target.value;
                          setGeneratedZones(updatedZones);
                        }}
                      />
                    </td>
                    <td>{zone.centralPostalCode}</td>
                    <td>{zone.postalCodes.join(", ")}</td>
                    <td>{zone.memberCount}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => handleSaveGeneratedZones(generatedZones)}
            disabled={loading || generatedZones.length === 0}
          >
            Save Zones
          </Button>
        </Modal.Footer>
      </Modal>
      <PendingAssignmentModal
        show={showPendingModal}
        onHide={() => setShowPendingModal(false)}
        pendingPostalCodes={pendingPostalCodes}
      />
    </>
  );
};

export default AutoGenerateZonesModal;
