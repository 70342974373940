import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../../context/appContext";
import Alert from "../../../components/Alert/Alert";
import Loading from "../../../components/Loading/Loading";
import axios from "axios";
import { set } from "date-fns";

const VendorManagement = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [clicked, setClicked] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (e, fundss) => {
    setShow2(true);
  };

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const [vendorNamex, setVendorNamex] = useState();

  const [vendorDescriptionx, setVendorDescriptionx] = useState();
  const [contactPersonx, setContactPersonx] = useState();
  const [emailx, setEmailx] = useState();
  const [phonex, setPhonex] = useState();
  const [addressx, setAddressx] = useState();
  const [paymentTermsx, setPaymentTermsx] = useState();
  const [statusx, setStatusx] = useState();
  const [notesx, setNotesx] = useState();
  const [uniqueData, setUniqueData] = useState();
  const [vendorName, setVendorName] = useState();
  const [vendorDescription, setVendorDescription] = useState();
  const [contactPerson, setContactPerson] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [address, setAddress] = useState();
  const [paymentTerms, setPaymentTerms] = useState();
  const [status, setStatus] = useState();
  const [notes, setNotes] = useState();
  const [vendors, setVendors] = useState([]);

  const [error, setError] = useState(false);
  const handleShow1 = (e, fundss) => {
    e.preventDefault();
    setShow1(true);
    setClicked(fundss);
  };

  const {
    displayAlert,
    showAlert,
    isLoading,

    user,
    logoutUser,
  } = useAppContext();

  const getVendorById = async (e, vendorss) => {
    e.preventDefault();

    const { data } = await authFetch.get(`/vendors/${vendorss}`);

    // const uniqueDatax = data?.reduce((acc, obj) => ({ ...acc, ...obj }), {});

    setUniqueData(data);
    handleShow2();
  };

  useEffect(() => {
    if (uniqueData) {
      setVendorDescriptionx(uniqueData.vendorDescription);
      setVendorNamex(uniqueData.vendorName);
      setContactPersonx(uniqueData.contactPerson);
      setEmailx(uniqueData.email);
      setPhonex(uniqueData.phone);
      setAddressx(uniqueData.address);
      setPaymentTermsx(uniqueData.paymentTerms);
      setStatusx(uniqueData.status);
      setNotesx(uniqueData.notes);
    }
  }, [uniqueData]);

  const handleDelete = async () => {
    const response = await authFetch.delete(`/vendors/${clicked._id}`);
    if (response.status === 200) {
      getVendors();
    }

    handleClose1();
  };

  useEffect(() => {
    getVendors();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!vendorName || !vendorDescription) {
      setError(true);
      return;
    }
    const vendorDetails = {
      vendorName: vendorName,
      vendorDescription: vendorDescription,
      contactPerson: contactPerson,
      email: email,
      phone: phone,
      address: address,
      status: status,
      notes: notes,
      createdBy: user._id,
      paymentTerms: paymentTerms,
      churchName: user.churchName,
    };

    const response = await authFetch.post(`/vendors/create`, vendorDetails);
    if (response.status === 200 || response.status === 201) {
      getVendors();
      setVendorName("");
      setVendorDescription("");
      setContactPerson("");
      setEmail("");
      setPhone("");
      setAddress("");
      setPaymentTerms("");
      setStatus("");
      setNotes("");
    }

    handleClose();
  };

  const getVendors = async () => {
    try {
      const { data } = await authFetch.get("/vendors/get");
      console.log(data.vendors);
      setVendors(data.vendors);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!vendorNamex || !vendorDescriptionx) {
      displayAlert();
      return;
    }

    const updateVendor = {
      vendorName: vendorNamex,
      vendorDescription: vendorDescriptionx,
      contactPerson: contactPersonx,
      email: emailx,
      phone: phonex,
      address: addressx,
      status: statusx,
      notes: notesx,
      createdBy: user._id,
      paymentTerms: paymentTermsx,
      churchName: user.churchName,
    };
    await authFetch.patch(`/vendors/${uniqueData._id}`, updateVendor);

    setVendorName();
    setVendorDescription();
    setContactPerson();
    setEmail();
    setPhone();
    setAddress();
    setPaymentTerms();
    setStatus();
    setNotes();

    getVendors();
    handleClose2();
  };

  if (isLoading) {
    <Loading />;
    return;
  }

  return (
    <>
      <Container fluid>
        {showAlert && <Alert />}

        <Row className="headerbanner">
          <div className="bannerbig">Vendor Management</div>
        </Row>
        <Row className="sssssssecwef">
          <div className="bnmjo">
            <button className="jiluil" onClick={handleShow}>
              Add Vendor
            </button>
          </div>
        </Row>
        {vendors && vendors.length > 0 ? (
          <Row className="fundc2">
            <Table responsive hover style={{ backgroundColor: "#f7f7f7" }}>
              <thead>
                <tr>
                  <th style={{ fontSize: "1em" }}>VENDOR ID</th>
                  <th style={{ fontSize: "1em" }}>VENDOR NAME</th>
                  <th style={{ fontSize: "1em" }}>VENDOR DESCRIPTION</th>

                  <th style={{ fontSize: "1em" }}>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {vendors?.map((vendor) => (
                  <tr key={vendor._id}>
                    <td style={{ fontSize: "1em" }}>{vendor.vendorId}</td>
                    <td style={{ fontSize: "1em" }}>{vendor.vendorName}</td>
                    <td style={{ fontSize: "1em" }}>
                      {vendor.vendorDescription}
                    </td>

                    <td
                      style={{
                        gap: "10px",
                        display: "flex",
                        fontSize: "1em",
                      }}
                    >
                      <button
                        className="jiluil3"
                        onClick={(e) => getVendorById(e, vendor._id)}
                      >
                        Edit
                      </button>

                      <button
                        className="jiluil"
                        onClick={(e) => handleShow1(e, vendor)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        ) : (
          <div className="noDatax">
            <p className="noDataBackup">No data available for display.</p>
          </div>
        )}
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
            Add New Vendor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="formVendorName">
                  <Form.Control
                    type="text"
                    placeholder="Vendor Name"
                    value={vendorName}
                    onChange={(e) => setVendorName(e.target.value)}
                    name="vendorName"
                    style={{ fontSize: "1em" }}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formVendorDescription">
                  <Form.Control
                    as="textarea"
                    placeholder="Vendor Description"
                    value={vendorDescription}
                    onChange={(e) => setVendorDescription(e.target.value)}
                    name="vendorDescription"
                    style={{ fontSize: "1em" }}
                    rows={2}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="formContactPerson">
                  <Form.Control
                    type="text"
                    placeholder="Contact Person"
                    value={contactPerson}
                    onChange={(e) => setContactPerson(e.target.value)}
                    name="contactPerson"
                    style={{ fontSize: "1em" }}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formEmail">
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    name="email"
                    style={{ fontSize: "1em" }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="formPhone">
                  <Form.Control
                    type="text"
                    placeholder="Phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    name="phone"
                    style={{ fontSize: "1em" }}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formAddress">
                  <Form.Control
                    as="textarea"
                    placeholder="Address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    name="address"
                    style={{ fontSize: "1em" }}
                    rows={2}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="formNotes">
                  <Form.Control
                    as="textarea"
                    placeholder="Notes"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    name="notes"
                    style={{ fontSize: "1em" }}
                    rows={2}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formStatus">
                  <Form.Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    name="status"
                    style={{ fontSize: "1em" }}
                  >
                    <option value="">Select Status</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose}>
            Cancel
          </button>
          <button className="jiluil" onClick={handleSubmit}>
            Add
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "1em" }}>Delete Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This action will permanently delete the selected vendor.
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose1}>
            Cancel
          </button>
          <button className="jiluil" onClick={handleDelete}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
            Update Vendor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Row 1: Vendor Name and Description */}
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="formVendorName">
                  <Form.Control
                    type="text"
                    placeholder="Vendor Name"
                    value={vendorNamex}
                    onChange={(e) => setVendorNamex(e.target.value)}
                    name="vendorName"
                    style={{ fontSize: "1em" }}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formVendorDescription">
                  <Form.Control
                    as="textarea"
                    placeholder="Vendor Description"
                    value={vendorDescriptionx}
                    onChange={(e) => setVendorDescriptionx(e.target.value)}
                    name="vendorDescription"
                    style={{ fontSize: "1em" }}
                    rows={2}
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Row 2: Contact Person and Email */}
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="formContactPerson">
                  <Form.Control
                    type="text"
                    placeholder="Contact Person"
                    value={contactPersonx}
                    onChange={(e) => setContactPersonx(e.target.value)}
                    name="contactPerson"
                    style={{ fontSize: "1em" }}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formEmail">
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={emailx}
                    onChange={(e) => setEmailx(e.target.value)}
                    name="email"
                    style={{ fontSize: "1em" }}
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Row 3: Phone and Address */}
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="formPhone">
                  <Form.Control
                    type="text"
                    placeholder="Phone"
                    value={phonex}
                    onChange={(e) => setPhonex(e.target.value)}
                    name="phone"
                    style={{ fontSize: "1em" }}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formAddress">
                  <Form.Control
                    as="textarea"
                    placeholder="Address"
                    value={addressx}
                    onChange={(e) => setAddressx(e.target.value)}
                    name="address"
                    style={{ fontSize: "1em" }}
                    rows={2}
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Row 4: Notes and Status */}
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="formNotes">
                  <Form.Control
                    as="textarea"
                    placeholder="Notes"
                    value={notesx}
                    onChange={(e) => setNotesx(e.target.value)}
                    name="notes"
                    style={{ fontSize: "1em" }}
                    rows={2}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formStatus">
                  <Form.Select
                    value={statusx}
                    onChange={(e) => setStatusx(e.target.value)}
                    name="status"
                    style={{ fontSize: "1em" }}
                  >
                    <option value="">Select Status</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose2}>
            Cancel
          </button>
          <button className="jiluil" onClick={handleUpdate}>
            Update
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VendorManagement;
