import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../../context/appContext";
import { SiQuickbooks, SiMailchimp } from "react-icons/si";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import QRCode from "qrcode.react";
import CustomToastContent from "../../Toast/CustomToastContent";
import { toast } from "react-toastify";

const Step6 = ({ formData, handleChange, handleCompletionChange }) => {
  const navigate = useNavigate();
  const code = new URLSearchParams(window.location.search).get("code");
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { showAlert, user, logoutUser, getCurrentUser } = useAppContext();

  const [userName, setUserName] = useState("");
  const [authStatus, setAuthStatus] = useState(null);

  // const [showMessage, setShowMessage] = useState(false);
  // const [showMessage1, setShowMessage1] = useState(false);
  const [secretURL, setSecretURL] = useState(user.mfaSecretURL);

  const generateSecret = async (e) => {
    e.preventDefault();

    try {
      const response = await authFetch.get("/multifactorauth/generate-secret");

      setSecretURL(response.data.secretURL);
      //setShowMessage1(response.data.message);
      toast(
        <CustomToastContent title="Success" message={response.data.message} />
      );
      getSecret();
    } catch (error) {
      console.error("Error generating secret:", error);
    }
  };

  const handleUnenroll = async (e) => {
    e.preventDefault();
    try {
      const response = await authFetch.post("/multifactorauth/unenroll", {
        memail: user.memail, // This should be the email of the logged-in user
      });

      //setShowMessage(response.data.message);
      toast(
        <CustomToastContent title="Success" message={response.data.message} />
      );
      getSecret();
      // navigate("/auth/profile");
    } catch (error) {
      console.error("Error unenrolling from MFA:", error);
      //setShowMessage("Error unenrolling. Please try again.");
      toast(
        <CustomToastContent
          title="Error"
          message="Error unenrolling. Please try again."
        />
      );
    }
  };
  const [fullscreen, setFullscreen] = useState(true);
  const [mfaSecretURL, setMfaSecretURL] = useState("");

  useEffect(() => {
    getSecret();
  }, []);

  const getSecret = async () => {
    try {
      const response = await authFetch.get("/multifactorauth/generate-secret");

      setMfaSecretURL(response.data.secretURL);
    } catch (error) {
      console.error("Error getting secret:", error);
    }
  };

  return (
    <div className="pt-4 ">
      {" "}
      {/* Center the content */}
      <p className="text-lg text-bold pb-3">MultiFactor Authentication</p>
      <div>
        {authStatus && authStatus.access_token && (
          <div>Authenticated successfully!</div>
        )}
        {authStatus && authStatus.error && <div>{authStatus.error}</div>}
      </div>
      <div className="qedqxqx flex justify-center" style={{ width: "100%" }}>
        <div
          style={{
            backgroundColor: "#f7f7f7",
            padding: "2rem",
            maxWidth: "500px",
            width: "100%",
          }}
        >
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="mb-3">
                Enrol with your churchease email address
              </Form.Label>
              <Form.Control
                value={user.memail}
                onChange={(e) => setUserName(e.target.value)}
                placeholder="Enter Email"
                className="mb-3"
                disabled
              />
              <div className="w-100">
                {mfaSecretURL ? (
                  <button
                    onClick={handleUnenroll}
                    className="buttonner1ss mt-3 mb-3 w-100"
                  >
                    UNENROLL MFA
                  </button>
                ) : (
                  <button
                    onClick={generateSecret}
                    className="jiluil3 mb-3 w-100"
                  >
                    Enable MFA
                  </button>
                )}
              </div>
              {mfaSecretURL && (
                <div>
                  <Form.Label>Scan this QR Code</Form.Label>
                </div>
              )}
            </Form.Group>
            {mfaSecretURL && <QRCode value={secretURL} />}
          </Form>
        </div>
      </div>
      <div className="flex justify-end">
        <Form.Check
          type="checkbox"
          id="step6Complete"
          label="Mark as Completed"
          checked={formData.step6Completed}
          onChange={(e) => handleCompletionChange(e.target.checked)}
        />
      </div>
    </div>
  );
};

export default Step6;
