import React from "react";
import { Container, Card } from "react-bootstrap";
import { FaClipboardList } from "react-icons/fa";
import Header from "../Navbar/Navbar";

const SetupPrompt = () => {
  return (
    <>
      <Header />
      <div
        className="d-flex align-items-center justify-content-center min-vh-75"
        style={{ height: "75vh" }}
      >
        <Container className="d-flex align-items-center justify-content-center">
          <Card
            className="text-center border-0 shadow-sm"
            style={{ maxWidth: "600px" }}
          >
            <Card.Body className="py-5 px-4">
              <div className="mb-4">
                <FaClipboardList className="text-primary" size={48} />
              </div>
              <Card.Title className="mb-3 h4">
                Registration Form Not Configured
              </Card.Title>
              <Card.Text className="text-muted mb-4">
                The registration form needs to be set up before members can
                register. Please contact your church administrator to configure
                the form fields and settings.
              </Card.Text>
              <div className="bg-light p-3 rounded text-start">
                <p className="mb-2 fw-bold">Next Steps:</p>
                <ol className="mb-0 ps-3">
                  <li>Contact your church administrator</li>
                  <li>Request registration form setup</li>
                  <li>Once configured, the form will be available here</li>
                </ol>
              </div>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default SetupPrompt;
