import React from "react";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import Alert from "../Alert/Alert";
import "./addtag.css";
import { toast } from "react-toastify";

const AddMultiple = ({ show, setShow, username }) => {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { quickAddToContainer, getContainers, containers } = useAppContext();
  const [tag, setTag] = useState("");

  useEffect(() => {
    getContainers();
  }, []);

  const fullNames = username.map(
    (user) => `${user.mfirstName} ${user.mlastName}`
  );

  const ids = username.map((user) => `${user._id}`);

  // const handleSave = () => {
  //   const newTag = {
  //     id: tag,
  //     data: ids,
  //   };

  //   quickAddToContainer(newTag);
  //   handleClose();
  // };

  const handleSave = async () => {
    if (!tag) {
      toast.warning("Please select a group first");
      return;
    }
    const newTag = {
      id: tag,
      data: ids,
    };

    try {
      await quickAddToContainer(newTag);
      toast.success(
        `Successfully added ${ids.length} member${
          ids.length > 1 ? "s" : ""
        } to group`
      );
      handleClose();
    } catch (error) {
      console.error("Error adding to group:", error);
      toast.error("Failed to add members to group");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add to Group</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Label htmlFor="inputPassword5">Select Group</Form.Label>
        <Form.Select
          aria-label="Default select example"
          className="my-3"
          onChange={(e) => setTag(e.target.value)}
        >
          <option>Select a Group</option>
          {containers.map((container) => (
            <option value={container._id} key={container._id}>
              {container.name}
            </option>
          ))}
        </Form.Select>

        <p className="my-3 text-bold">{fullNames.length} members selected</p>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleClose} className="jiluil3">
          Close
        </button>
        <button onClick={handleSave} className="jiluil">
          Add to Group
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddMultiple;
