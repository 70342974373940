import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../../context/appContext";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { currencyFormatter } from "../../../utils/helpers";
import "./giving.css";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { CSVLink } from "react-csv";
import { useReactToPrint } from "react-to-print";
import PrintBatchView from "./PrintBatchView";
import { FaCaretDown } from "react-icons/fa";
import { FaCaretUp } from "react-icons/fa";
const BatchView = React.forwardRef((props, ref) => {
  const [showData, setShowData] = useState(false);
  const componentRef = useRef();
  const printView = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Batch Report",
  });

  const printMe = () => {
    printView();
    setShowData(!showData);
  };
  const { batchId } = useParams();
  const { batchview, batchDetails } = useAppContext();
  const [amountFlag, setAmountFlag] = useState(false);
  const [giverFlag, setGiverFlag] = useState(false);
  const [givingfilter, setGivingfilter] = useState([]);
  const [batchv, setBatchv] = useState([]);
  console.log(batchId);
  useEffect(() => {
    batchDetails(batchId);
  }, []);

  const handleAmount = (e) => {
    let sorted = batchview.sort((a, b) => {
      return amountFlag ? b.amount - a.amount : a.amount - b.amount;
    });
    setBatchv(sorted);
    setAmountFlag(!amountFlag);
  };

  const handleGiverSort = (e) => {
    if (giverFlag) {
      let sorted = batchview.sort(function (a, b) {
        return a.giverName.localeCompare(b.giverName);
      });
      setGivingfilter(sorted);
    } else {
      let sorted = givingfilter.reverse();
      setGivingfilter(sorted);
    }
    setGiverFlag(!giverFlag);
  };

  // Transform the batchview data to only include the desired fields
  const csvData = batchview.map((batchess) => ({
    Created_Date: new Date(batchess.createdAt).toLocaleDateString(),
    Payment_Date: new Date(batchess.givingDate).toLocaleDateString(),
    Giver_Name: batchess.giverName,
    Category: batchess.category,
    Payment_Method: batchess.paymentMethod,
    Amount: currencyFormatter({
      amount: batchess.amount,
      currency: "usd",
    }),
  }));

  return (
    <>
      <Container fluid>
        <Row className="courseheader">
          <Col>
            <p className="sahs">Batch Report</p>
          </Col>
        </Row>
        <Row className="sssssssecwef">
          <div className="bnmjo" style={{ gap: "15px" }}>
            <button className="jiluil">
              {" "}
              <CSVLink
                data={csvData}
                filename="report-batch.csv"
                style={{
                  textDecoration: "none",
                  color: "white",
                }}
              >
                Export
              </CSVLink>
            </button>
            <div style={{ display: "none" }}>
              <PrintBatchView ref={componentRef} giving={batchview} />
            </div>
            <button className="jiluil3" onClick={printMe}>
              Print
            </button>
          </div>
        </Row>
        <Row className="fundc2" style={{ paddingTop: "2rem" }}>
          <Table responsive hover style={{ backgroundColor: "#f7f7f7" }}>
            <thead>
              <tr>
                <th style={{ fontSize: "1em" }}>Created Date</th>
                <th style={{ fontSize: "1em" }}>Payment Date</th>
                <th onClick={handleGiverSort} style={{ fontSize: "1em" }}>
                  Giver Name {giverFlag ? <FaCaretDown /> : <FaCaretUp />}
                </th>
                <th style={{ fontSize: "1em" }}>Category</th>
                <th style={{ fontSize: "1em" }}>Giving Method</th>
                <th onClick={handleAmount} style={{ fontSize: "1em" }}>
                  Amount {amountFlag ? <FaCaretDown /> : <FaCaretUp />}
                </th>
              </tr>
            </thead>
            {batchview.map((batchess) => (
              <tbody>
                <tr>
                  <td style={{ fontSize: "1em" }}>
                    {new Date(batchess.createdAt).toLocaleDateString()}
                  </td>
                  <td style={{ fontSize: "1em" }}>
                    {new Date(batchess.givingDate).toLocaleDateString()}
                  </td>
                  <td style={{ fontSize: "1em" }}>
                    <Link
                      to={"/auth/peopleview/" + batchess.memberId}
                      className="pointer"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <a
                        className="text-primary"
                        style={{
                          textDecoration: "underline",
                          color: "black",
                          textDecorationColor: "black",
                        }}
                      >
                        <p style={{ color: "black" }}>{batchess.giverName}</p>
                      </a>
                    </Link>
                  </td>
                  <td style={{ fontSize: "1em" }}>{batchess.category}</td>
                  <td style={{ fontSize: "1em" }}>{batchess.paymentMethod}</td>
                  <td style={{ fontSize: "1em" }}>
                    {currencyFormatter({
                      amount: batchess.amount,
                      currency: "usd",
                    })}
                  </td>
                </tr>
              </tbody>
            ))}
          </Table>
        </Row>
      </Container>
    </>
  );
});

export default BatchView;
